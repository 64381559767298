import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '../../../node_modules/@angular/material/dialog';
import { APICenterService } from '../apicenter.service';

@Component({
  selector: 'app-menu-dialog',
  templateUrl: './menu-dialog.component.html',
  styleUrls: ['./menu-dialog.component.css']
})
export class MenuDialogComponent implements OnInit {

  constructor(public api: APICenterService,  public dialogRef: MatDialogRef<MenuDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

isPizza = true
isShowTitle = true
secondWord = ""

  ngOnInit() {
    let name 
    name = this.api.restaurant.name.replace("'", "").toLowerCase();
    
    this.isPizza  = (name == "smoothie king") ?  false : true
    this.isPizza  = (name == "pizzacottage") ?  false : true
    this.isPizza  = (name == "freebirds") ?  false : true
    this.isPizza  = (name == "wayback") ?  false : true
    this.isShowTitle = (name == "mannyolgas" ||name=='giovanni') ?  false : true
    this.findSecondWord(name)
  }


  findSecondWord(name) {
    if(name == "wayback" || name == "wayback burgers" ) {
      this.secondWord = "Burgers"
    }
    else if (name == "smoothie king" || name == "smoothieking") {
      this.secondWord = ""
    }
    else if (name == "joseppispizza") {
      this.secondWord = ""
    }
    else if (name == "freebirds") {
      this.secondWord = "world Burrito"
    }

    else {
      this.secondWord = "Pizza"
    }
  }

  close() {
    this.dialogRef.close();
  }




}
