import { Component, OnInit } from '@angular/core';
import { RestContactService } from './../../rest-contact.service';
import { APICenterService } from './../../apicenter.service';
import { jetsStore } from '../../rest-offer-page/jets/jets-store';
import {romeoStore} from '../../rest-offer-page/romeo/romeo-store';
import { Router } from '../../../../node_modules/@angular/router';
import { MatDialog } from '../../../../node_modules/@angular/material/dialog';
import { OrderingStepsDialogComponent } from '../../ordering-steps-dialog/ordering-steps-dialog.component';
import { ClassGetter } from '../../../../node_modules/@angular/compiler/src/output/output_ast';


@Component({
  selector: 'app-romeo-share',
  templateUrl: './romeo.component.html',
  styleUrls: ['./romeo.component.css']
})
export class RomeoComponentShare implements OnInit {

 
  stateObject = {
    'OH' : ['rm01', 'rm02','rm06','rm07','rm04','rm05','rm11','rm09','rm08','rm03','rm10', 'rm11', 'rm12', 'rm13', 'rm14'],
    'MI' : ['rm15', 'rm16']
    
  };
    storeList = [] ;
    storeFinalDisplayList = [];
    // storeList = ['mi26', 'mi73', 'mi51']
    restInfo;
    stateList = [
    
      {'statename': 'Ohio', 'statecode': 'OH'},
      {'statename': 'Michigan', 'statecode': 'MI'},
  
    ];
  
    showState = false;
    smsNumber;
    // addy1 = [];
   // addy2 = [];
  
    text = 'Hi Jets! I want...';
    constructor( private api: APICenterService, private _router: Router, public dialog: MatDialog) { }
  
    ngOnInit() {
  
     // tslint:disable-next-line:max-line-length
     /* this.storeList = this.storeList.concat(this.NY, this.SC, this.TX, this.PA, this.IN, this.CO, this.FL, this.IL, this.KY, this.NC, this.TN, this.MI, this.OH)
      console.log(this.storeList)
      console.log(this.storeList.length)
      this.api.isdemo = false;
      this.storeList.map((x) => {
        let sid  =  new romeoStore()
        this.restInfo = sid.findStore(x)
        this.addy1.push(this.restInfo.addy1)
        this.addy2.push(this.restInfo.addy2)
  
      })*/
  
      this.api.isdemo = true;
    }
    selectState(stateCode) {
      this.showState = true;
      this.storeList = [];
      this.storeFinalDisplayList = [];
      // this.addy1=[];
      // this.addy2=[];
      // var store;
  
      this.storeList = this.storeList.concat(this.stateObject[stateCode]);
      console.log(this.storeList);
      this.api.isdemo = false;
  
      this.storeList.map((x) => {
        const sid  =  new romeoStore();
        this.restInfo = sid.findStore(x);
  
        this.restInfo.id = x;
        console.log(this.restInfo);
        this.storeFinalDisplayList.push(this.restInfo);
        // this.addy1.push(this.restInfo.addy1);
        // this.addy2.push(this.restInfo.addy2);
      });
  
      console.log(this.storeFinalDisplayList);
      this.storeFinalDisplayList.sort(function(a, b) {
        const nameA = a.addy2.toLowerCase(), nameB = b.addy2.toLowerCase();
        if (nameA < nameB) { // sort string ascending
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
    console.log(this.storeFinalDisplayList);
  
      this.api.isdemo = true;
  
    }
  
    onMoreInfo(x) {
      // console.log(x)
      const routeId = `/romeos/${x}`;
      this._router.navigate([routeId]);
    }
  
    onStartText(x) {
      console.log(x);
      const sid  =  new romeoStore();
      this.restInfo = sid.findStore(x);
      this.smsNumber = this.restInfo.rid;
      console.log(this.smsNumber);
      this.api.displayNum = this.api.formatPhone(this.smsNumber);
      this.orderNowBtn();
    }
  
    onProOrder(x) {
      const routeId = `quick/jets/${x}`;
      this._router.navigate([routeId]);
    }
  
    orderNowBtn() {
  
      if (this.api.isMobile) {
        if (this.api.deviceName === 'iPhone') {
          console.log('IPHONE');
          window.location.href = `sms:${this.smsNumber}&body=` + encodeURIComponent(this.text);
        } else {
          console.log('MAY BE andriod');
          try {
            window.location.href = `sms:${this.smsNumber}?body=` + encodeURIComponent(this.text);
          } catch {
            window.location.href = `sms:${this.smsNumber};body=` + encodeURIComponent(this.text);
          }
           finally {
            window.location.href = `sms:${this.smsNumber};?&body=` + encodeURIComponent(this.text);
           }
        }
      } else if (this.api.isDesktopDevice) {
  
        const dialogRef = this.dialog.open(OrderingStepsDialogComponent, {
        });
  
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
        });
  
      } else if (this.api.isTablet) {
        console.log('TABLET');
        const dialogRef = this.dialog.open(OrderingStepsDialogComponent, {
        });
  
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
        });
      }
  
    }

}
