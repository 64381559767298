import { APICenterService } from './../apicenter.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-fax-summary',
  templateUrl: './fax-summary.component.html',
  styleUrls: ['./fax-summary.component.css']
})


export class FaxSummaryComponent implements OnInit {

  isActive: Boolean =  false;
  isCheckOut: Boolean =  false;
  isLoading: Boolean = true;
  isEditState: Boolean = false;
  msg: string;
  summary: string;
  restId: string;
  custId: string;
  isPIN: boolean = false;

  constructor(private route: ActivatedRoute, public api: APICenterService) { }

  ngOnInit() {
    this.route.url
      .subscribe(val => {
        this.api.ordrID = val[1].path;
        console.log(this.api.ordrID);
        console.log(val[1].path);
        //this.isLoading = this.api.isLoading;
      });

   

    }
  onUpdate() {
    this.isActive = false;
    this.isActive = !this.isActive;

    
    this.isActive = !this.isActive;
    this.api.updateUserMsg();
    
  }

  onEdit() {
    console.log(this.isActive);
    this.isActive = true;
    this.api.isEdit = true;
    this.api.isEdit_ed = true;
  }

  onCheckOut() {
    this.api.isState_0 = true;
  }





}
