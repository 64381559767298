import { APICenterService } from './../apicenter.service';
import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ActivatedRoute } from '../../../node_modules/@angular/router';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.css']
})
export class ReceiptComponent implements OnInit {
  receiptId;
  dateVal;
  year
  @ViewChild('content') content: ElementRef;
  constructor(private route: ActivatedRoute, public api: APICenterService) { }

  ngOnInit() {
    this.year = new Date().getFullYear()
    this.api.isDeleteBtn = false;
    this.api.isEditBtn = false;
    this.api.isAddItemBox = false;
    this.api.isMethodDisplay = true;

    this.route.url
      .subscribe(val => {
        this.receiptId = val[1].path;
        console.log(this.receiptId);
        this.api.rCustId = this.receiptId.slice(0, 4);
        this.api.rOrderId = this.receiptId.slice(-6);
        console.log(this.api.rCustId);
        console.log(this.api.rOrderId);
      });
    this.api.getReceipt();
    console.log('receipt');
    console.log(this.api.restaurant.address);
    const regex = /GMT.*/gi;
    this.dateVal = new Date();
    this.dateVal = this.dateVal.toString().replace(regex, '');
  }

  downloadPDF() {
    console.log('Pdf export is clicked');

    const data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      const imgWidth = 150;
      const pageHeight = 200;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(contentDataURL, 'PNG', 15, 15, imgWidth, imgHeight);
      pdf.save('test.pdf');
    });

    /*
    let doc =  new jsPDF();
 
    let specialElementHandlers = {
     '#editor' : function(element, renderer) {
       return true;
     }
    };
 
    let content = this.content.nativeElement;
 
    doc.fromHTML(content.innerHTML, 15, 15, {
       'width' : 190,
       'elementHandlers': specialElementHandlers
    });
 
    doc.save('test.pdf'); */


  }


}
