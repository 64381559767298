import { APICenterService } from './../apicenter.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-get-cust-phone',
  templateUrl: './get-cust-phone.component.html',
  styleUrls: ['./get-cust-phone.component.css']
})
export class GetCustPhoneComponent implements OnInit {

  constructor(public api: APICenterService) { }

  ngOnInit() {
  }

  submit(f) {
    this.api.isUIOrdrStart = false;
    this.api.customer.msg_text = null
    this.api.customer.cid = f.value.phoneNumber
    const prefix  = '+1'
    this.api.customer.cid = prefix.concat(this.api.customer.cid)
    console.log(this.api.customer.cid)
  }

}
