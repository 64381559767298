import { APICenterService } from './../apicenter.service';
import { Component, OnInit } from '@angular/core';
import { trigger, transition, animate, style } from '../../../node_modules/@angular/animations';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
  animations: [
    trigger('fade-out', [
      transition ('* => void', [
        animate(500, style({ opacity:0}))
      ] )
    ])
  ]
})
export class CheckoutComponent implements OnInit {
  qty = [];
  sizes = [];
  cursts = [];
  itemNames = [];
  items = [];
  amounts = [];
  isFull: boolean = false;
  isShort: boolean = false;


  constructor(public api: APICenterService) { }

  ngOnInit() {
    this.api.tipValidator()

}



shortView() {
  this.isFull = !this.isFull;
}

}

