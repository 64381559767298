import { Injectable} from '@angular/core';
import * as RecordRTC from 'recordrtc';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { APICenterService } from '../apicenter.service';
import {DataService} from './websocket';
import { interval, Subscription } from 'rxjs';
interface RecordedAudioOutput {
  blob: Blob;
  title: string;
}

@Injectable()
export class AudioRecordingService {
  buffer;
  message='';
  private stream;
  private recorder;
  private interval;
  private startTime;
  private _recorded = new Subject<RecordedAudioOutput>();
  private _recordingTime = new Subject<string>();
  private _recordingFailed = new Subject<string>();
  time:any;
 blob:any;
 ws:any;
 disaplyVar='';
 textVar='';
 saytimer=this.api.isTimer;
 
  constructor(private api: APICenterService, private sock: DataService) {
 
   }


 getRecordedBlob(): Observable<RecordedAudioOutput> {

    return this._recorded.asObservable();
  } 

  getRecordedTime(): Observable<string> {
    return this._recordingTime.asObservable();
  }

  recordingFailed(): Observable<string> {
    return this._recordingFailed.asObservable();
  }
  
  
  startRecording() {

    if (this.recorder) {
      // It means recording is already started or it is already recording something

      //console.log('mic is already recording')
      return;
    }
    this._recordingTime.next('00:00');
    //console.log(navigator);
    if (navigator) {
     // console.log(navigator)
      //console.log('first time you r alowing mic access')
      navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then(s => {
        this.stream = s;
       // console.log(this.stream)
        this.record();
      }).catch(error => {
       // console.log('An error is thrown while recording')
        this._recordingFailed.next();
      });
    }

  }

  abortRecording() {
    this.stopMedia();
  }
check1(){
if(this.blob!=window["datanew"]){
this.blob= window["datanew"];
console.log(this.blob)
var data= {
	event:"media",
	media:
		{
		payload:this.blob
		}
}
//this.ws.send(JSON.stringify(data));
this.sock.sendMessage(JSON.stringify(data));
}
}
endtimer(){

}
  async   record() {
    var ws:any;
   ws = new WebSocket("wss://tutalcs.com/");	
    ws.onopen = async function() {
    let data:any
    data={event:"start"}
   await ws.send(JSON.stringify(data));
    window["wss"]=ws;
   
  };
    ws.onmessage = (message)=>{ 
      var msg= JSON.parse(message.data);
      clearInterval(this.time);
      console.log(msg.data);
      if(msg.data.transcript.length==0){
         this.time = setInterval(() => {
           if(this.saytimer==0){
            this.api.time=true;
            console.log(this.saytimer);
           }
           else{
          //this.api.time=false;
          console.log(this.saytimer);
         this.saytimer=this.saytimer-this.api.isTimer;
           }
      },500)
      }
      else{
        clearInterval(this.time);
        this.api.time=false;
        this.saytimer=this.api.isTimer;
    if(!msg.data.is_final){
      this.api.disaplyVar=msg.data.transcript;
    }
    else{
       this.api.textVar= this.api.textVar +" "+  msg.data.transcript;
    }
  }

     
     // this.api.socketmessage= this.api.socketmessage+" "+ msg.data.transcript; 
      //console.log(msg);
      //console.log(this.api.socketmessage);
      
    }
    this.recorder = new RecordRTC.StereoAudioRecorder(this.stream, {
      type: 'audio',
      recorderType: 'StereoAudioRecorder',
      desiredSampRate: 8000,
      timeSlice: 1,
      ondataavailable:function(blob) {
        console.log(typeof(blob));
         window["datanew"]=blob;
         var wss:any;
         var reader = new FileReader();
         reader.readAsDataURL(blob); 
         reader.onloadend = function() {
             var base64data :any
             base64data = reader.result;  
             base64data=  base64data.replace("data:audio/wav;base64,","") 
             var stream={
              event:"media",
              media:
                {
                  payload:base64data
                }
            }
           
            window["wss"].send(JSON.stringify(stream));
          
         }
      },
    });
    
    this.recorder.record();
    this.startTime = moment();
    this.interval = setInterval(
      () => {
        const currentTime = moment();
        const diffTime = moment.duration(currentTime.diff(this.startTime));
        const time = this.toString(diffTime.minutes()) + ':' + this.toString(diffTime.seconds());
        
        this._recordingTime.next(time);
      },
      1000
    );
  }
  varsenddata(blob){
console.log(blob)
  }
  private toString(value) {
    let val = value;
    if (!value) {
      val = '00';
    }
    if (value < 10) {
      val = '0' + value;
    }
    return val;
  }

  stopRecording() {
    console.log(window["datanew"]);
    if (this.recorder) {
      this.recorder.stop((blob) => {
        if (this.startTime) {
          // const mp3Name = encodeURIComponent('audio_' + new Date().getTime() + '.wav');
          var abc = this.recorder;
          //console.log('buffer data')
          //console.log(abc)
          const sampleRate = this.recorder.sampleRate
          //console.log('#####recorder')
          const val = this.recorder.buffer;
          this.buffer = new DataView(val);
          //console.log(this.buffer)
          // console.log('buffer')

          // console.log(abc);
          this.stopMedia();
          this.api.blobData = abc.blob;
          this.api.recorderInfo()
          this._recorded.next({ blob: blob, title: sampleRate });
        }
      }, () => {
        this.stopMedia();
        this._recordingFailed.next();
      });
    }
  }
  download() {
    const blob = new Blob( this.recorder , {type : 'audio/mp3'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'test.mp3';
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 100);
  }

  private stopMedia() {
  //  console.log('stop-media')
    if (this.recorder) {
     // console.log('if recorder')
      this.recorder = null;
      clearInterval(this.interval);
      this.startTime = null;
      if (this.stream) {
       // console.log('Stop Media - steam')
        this.stream.getAudioTracks().forEach(track => track.stop());
        this.stream = null;
      }
    }
  }

}
