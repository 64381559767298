
/// <reference types="@types/googlemaps" />

import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input } from '@angular/core';
import { APICenterService } from './../../apicenter.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ProServiceService } from '../pro-service.service';


@Component({
  selector: 'app-pro-address-box',
  templateUrl: './pro-address-box.component.html',
  styleUrls: ['./pro-address-box.component.css']
})
export class ProAddressBoxComponent implements OnInit {

  constructor(private pro: ProServiceService, public api: APICenterService) { }
  apt = '';
  @Input() adressType: string;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @Output() setApt: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext') addresstext: any;

  autocompleteInput: string;
  queryWait: boolean;
  ngOnInit() {
    this.autocompleteInput=''
    if (this.pro.isProBack) {
      this.autocompleteInput = this.pro.onBackObj.custAddy;
      this.apt = this.pro.onBackObj.custAPt;
    }
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }
  
  focus() {
    this.pro.stickyHead = true;
    this.pro.isKeyBoard = true;
  }
  blur() {
    this.pro.stickyHead = false;
    this.pro.isKeyBoard = false;
  }
  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
      {
        componentRestrictions: { country: 'US' },
        types: [this.adressType]  // 'establishment' / 'address' / 'geocode'
      });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.invokeEvent(place);
    });
  }

  setapt() {
    this.setApt.emit(this.apt);
  }
  invokeEvent(place: Object) {
    this.setAddress.emit(place);
  }


}
