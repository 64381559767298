export class eastofchicagoStore {

    constructor() { }

    findStore(sid) {
        switch (sid) {
            case 'ec01':
             let sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 20% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+14199992299',
                    nickName: '2899 W Elm St location in Lima',
                    addy1: '2899 W Elm St',
                    addy2: 'Lima, OH'
                };
                return sidInfo;
            case 'ec02':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 20% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+19376428588',
                    nickName: '306 E 5th St location in Marysville',
                    addy1: '306 E 5th St',
                    addy2: 'Marysville, OH'
                };
                return sidInfo;
             case 'ec03':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 20% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+14192940093',
                    nickName: '200 S Sandusky Ave location in Upper Sandusky',
                    addy1: '200 S Sandusky Ave',
                    addy2: 'Upper Sandusky, OH'
                };
                return sidInfo;
             case 'ec04':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 20% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+14197383838',
                    nickName: '205 1/2, Defiance St, location in Wapakoneta',
                    addy1: '205 1/2, Defiance St',
                    addy2: 'Wapakoneta, OH'
                };
                return sidInfo;
            case 'ec05':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 20% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13048489980',
                    nickName: '165 W. Main St, location in Bridgeport',
                    addy1: '165 W. Main St',
                    addy2: 'Bridgeport, WV'
                };
                return sidInfo;
            default:
                sidInfo = {
                    discountTxt: '*',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'Texting is the fastest way to order Jet’s!',
                    successPro: 'PRO ORDERS ARE 20% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: 'empty',
                    nickName: 'empty',
                    addy1: 'empty',
                    addy2: 'empty'
                };
                return sidInfo;
        }
        // console.log(cust)
    }
}
