export class sammysStore {

    constructor() { }

    findStore(sid) {
        switch (sid) {

            case 'sm01':
                let sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON PIZZA & BREAD',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON PIZZA & BREAD',
                    successPro: 'PRO ORDERS ARE 20% OFF ON PIZZA & BREAD',
                    isTxt: true,
                    rid: '+17347286600',
                    nickName: '35299 Warren Rd location in Westland',
                    addy1: '35299 Warren Rd',
                    addy2: 'Westland, MI'
                };
                return sidInfo;
            case 'sm02':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON PIZZA & BREAD',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON PIZZA & BREAD',
                    successPro: 'PRO ORDERS ARE 20% OFF ON PIZZA',
                    isTxt: true,
                    rid: '+17346929000',
                    nickName: '20749 Gibraltar Rd location in Brownstown',
                    addy1: '20749 Gibraltar Rd',
                    addy2: 'Brownstown, MI'
                };
                return sidInfo;
            case 'sm03':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON PIZZA & BREAD',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON PIZZA & BREAD',
                    successPro: 'PRO ORDERS ARE 20% OFF ON PIZZA',
                    isTxt: true,
                    rid: '+17348441771',
                    nickName: '45530 Cherry Hill Rd location in Canton',
                    addy1: '45530 Cherry Hill Rd',
                    addy2: 'Canton, MI'
                };
                return sidInfo;
            case 'sm04':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON PIZZA & BREAD',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON PIZZA & BREAD',
                    successPro: 'PRO ORDERS ARE 20% OFF ON PIZZA',
                    isTxt: true,
                    rid: '+17343073300',
                    nickName: '22089 West Rd location in Woodhaven',
                    addy1: '22089 West Rd',
                    addy2: 'Woodhaven, MI'
                };
                return sidInfo;

            default:
                sidInfo = {
                    discountTxt: '*',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'Texting is the fastest way to order!',
                    successPro: 'Texting is the fastest way to order!',
                    isTxt: true,
                    rid: 'empty',
                    nickName: 'empty',
                    addy1: 'empty',
                    addy2: 'empty'
                };
                return sidInfo;
        }
        // console.log(cust)
    }
        // console.log(cust)
    }

