export class gattispizzaStore {

    constructor() { }

    findStore(sid) {
        switch (sid) {
            case 'gt01':
                let sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 25% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 25% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 25% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15123652222',
                    nickName: '2708 N Main St location in Taylor',
                    addy1: '2708 N Main St',
                    addy2: 'Taylor, TX'
                };
                return sidInfo;


            default:
                sidInfo = {
                    discountTxt: '*',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'Texting is the fastest way to order Jet’s!',
                    successPro: 'PRO ORDERS ARE 20% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: 'empty',
                    nickName: 'empty',
                    addy1: 'empty',
                    addy2: 'empty'
                };
                return sidInfo;
        }
        // console.log(cust)
    }
}
