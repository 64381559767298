import { APICenterService } from './../apicenter.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.css']
})
export class FeedbackFormComponent implements OnInit {
  
  isFeedback = false;
  Q1: string
  Q2: string
  answers: string[] = ['YES', 'NO'];
  constructor(public api: APICenterService) { }

  ngOnInit() {
  }

  onFeedback(){
    this.api.clickValue = 'Text - FeedBack';
    this.api.clickAnalytics() 
   
    // this.api.fbUserText = ''
    console.log(this.api.fbUserText)
    this.api.textFeedbacks() 
    console.log(this.Q1)
    this.isFeedback = true;

  }

}
