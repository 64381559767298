import { RestContactService } from './../rest-contact.service';
import { OrderingStepsDialogComponent } from './../ordering-steps-dialog/ordering-steps-dialog.component';
import { Component, OnInit } from '@angular/core';
import { APICenterService } from '../apicenter.service';
import { ActivatedRoute } from '../../../node_modules/@angular/router';
// import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '../../../node_modules/@angular/material/dialog';



@Component({
  selector: 'app-rest-text-tigger',
  templateUrl: './rest-text-tigger.component.html',
  styleUrls: ['./rest-text-tigger.component.css']
})
export class RestTextTiggerComponent implements OnInit {
  deviceInfo = null;
  // safeURL
  // videoURL = "https://www.youtube.com/watch?v=dCWXkU27L8k"
  // URL = "https://www.youtube.com/watch?v=dCWXkU27L8k"
  constructor(public api : APICenterService, private restNum: RestContactService, private route: ActivatedRoute, public dialog: MatDialog ) {
   
    // this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(videoURL);
   }


  restMenuId
  smsNumber
  text = "Hi Jets! I want ..."
  text_joe = "Hi J.O.E (Jets Ordering Engine)! I'd like to order a..."
  ngOnInit() {
    this.route.url
     .subscribe(val => {
       console.log('URL Cutout')
       console.log(val)
       this.restMenuId = val[0].path;
       console.log(this.restMenuId)
     }) 
    this.getRestId() 
    this.api.getrestinfo()
    this.api.today()
    this.api.getUpdatedRestInfo()
     .subscribe(data => {
       console.log('@menu')
       console.log(data)
       this.api.restaurant.address = data.restaurant.address;
       this.api.restaurant.name = data.restaurant.name;
       this.api.restaurant.rid = data.restaurant.rid;
       this.api.phoneFormat()
      })
    this.api.isFullFooter = true;
      
      // this.smsNumber = this.api.menuRestId.replace('+1','')
      this.smsNumber = '5869780888'
      console.log(this.smsNumber)
      this.api.isFullFooter = false;
      console.log(this.api.isMobile)
      console.log(this.api.isDesktopDevice)
      console.log(this.api.deviceName)
      console.log(this.api.deviceOs)
      
  }
 //  Add the friendly Name and rest Number here:
  getRestId() {
    if(this.restMenuId === 'jets') {
      console.log('Get Rest ID - jets')
      this.api.menuRestId = this.restNum.restaurant.jets
    } 


    if(this.restMenuId === 'jets_18_ryan') {
      console.log('Get Rest ID - jets')
      this.api.menuRestId = this.restNum.restaurant.jetsRyan
    } 

    if(this.restMenuId === 'jets18ryan') {
      console.log('Get Rest ID - jets')
      this.api.menuRestId = this.restNum.restaurant.jetsRyan
    } 

    if(this.restMenuId === 'jets18ryan') {
      console.log('Get Rest ID - jets')
      this.api.menuRestId = this.restNum.restaurant.jetsRyan
    } 

    if(this.restMenuId === 'demo') {
      console.log('Get Rest ID - jets')
      this.api.menuRestId = this.restNum.restaurant.jets
    } 

    if(this.restMenuId === 'joe') {
      console.log('Get Rest ID - jets')
      this.api.menuRestId = this.restNum.restaurant.joe
    } 

    if(this.restMenuId === 'scjets') {
      console.log('Get Rest ID - jets')
      this.api.menuRestId = this.restNum.restaurant.jetsSc
    } 

    if(this.restMenuId === 'sammy'){
      console.log('Get Rest ID - Sammy')
      this.api.menuRestId = this.restNum.restaurant.sammyWestland
    } 
    
    if (this.restMenuId === 'esammy') {
      console.log('Get Rest ID - eSammy')
      this.api.menuRestId = this.restNum.restaurant.sammyWestland
    } 
    
    if(this.restMenuId === 'faz') {
      console.log('Get Rest ID')
      this.api.menuRestId = this.restNum.restaurant.faz
    } 

    if(this.restMenuId === 'superfine') {
      console.log('Get Rest ID')
      this.api.menuRestId = this.restNum.restaurant.superFine
    } 

    if(this.restMenuId === 'tnjets') {
      console.log('Get Rest ID')
      this.api.menuRestId = this.restNum.restaurant.faz
    } 

    if(this.restMenuId === 'mapa') {
      console.log('Get Rest ID')
      this.api.menuRestId = this.restNum.restaurant.maPa
    } 
    
  }
  
  


  orderNowBtn() {
    //const val = Math.floor(1000 + Math.random() * 9000);
    //console.log(val);
    //this.api.ordrID = `OFF${val}`
    //this.api.clickValue = 'orderNow-btn'
    //this.api.clickAnalytics()
    console.log('button is clicked')
    if(this.api.isMobile) { 
      if(this.api.deviceName == 'iPhone') {
        console.log('IPHONE')
        window.location.href =`sms:${this.smsNumber}&body=`+ encodeURIComponent(this.text);
      } else {
        console.log('MAY BE andriod')
        try{
          window.location.href =`sms:${this.smsNumber}?body=`+ encodeURIComponent(this.text);
        }  
        catch {
          window.location.href =`sms:${this.smsNumber};body=`+ encodeURIComponent(this.text);
        }
         finally{
          window.location.href =`sms:${this.smsNumber};?&body=`+ encodeURIComponent(this.text);
         } 
      } 
    } else if(this.api.isDesktopDevice) {
      
      const dialogRef = this.dialog.open(OrderingStepsDialogComponent, {
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });

    } else if(this.api.isTablet) {
      console.log('TABLET')
      const dialogRef = this.dialog.open(OrderingStepsDialogComponent, {
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    }

  }

  openMenu() {
    if(this.restMenuId === 'jets') {
      window.location.href = `${this.restNum.url}menu/jets`
    } 

    if(this.restMenuId === 'sammy'){
      console.log('Get Rest ID - Sammy')
      window.location.href = `${this.restNum.url}menu/sammy`;
    } 
     
    if(this.restMenuId === 'faz') {
      console.log('Get Rest ID')
      window.location.href = `${this.restNum.url}menu/faz` 
    } 

    if(this.restMenuId === 'superfine') {
      console.log('Get Rest ID')
      window.location.href = `${this.restNum.url}menu/superfine` 
    } 

    if(this.restMenuId === 'jeo') {
      console.log('Get Rest ID')
      window.location.href = `${this.restNum.url}menu/jeo` 
    } 

    if(this.restMenuId === 'jets_18_ryan') {
      console.log('Get Rest ID')
      window.location.href = `${this.restNum.url}menu/jets` 
    } 

    if(this.restMenuId === 'jets18ryan') {
      console.log('Get Rest ID')
      window.location.href = `${this.restNum.url}menu/jets` 
    } 



    if(this.restMenuId === 'mapa') {
      console.log('Get Rest ID')
      window.location.href = `${this.restNum.url}menu/mapa` 
    } 

    if(this.restMenuId === 'tnjets') {
      console.log('Get Rest ID')
      window.location.href = `${this.restNum.url}menu/tnjets` 
    } 

    
  }


}
