import { APICenterService } from './../apicenter.service';
import { Component, OnInit } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

import { trigger, transition, animate, style } from '../../../node_modules/@angular/animations';
import { Router, ActivatedRoute } from '../../../node_modules/@angular/router';
import { ProServiceService } from '../pro-order/pro-service.service';
import { CurbsidePopupComponent } from './curbside-popup/curbside-popup.component';
import { MatDialog } from '@angular/material/dialog';
import {SmoothieKingAllergiesComponent} from './smoothie-king-allergies/smoothie-king-allergies.component'
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css'],
  animations: [
    trigger('fade-out', [
      transition('* => void', [
        animate(3500, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class EditComponent implements OnInit {
  isActive: Boolean = false;
  isCheckOut: Boolean = false;
  isLoading: Boolean = true;
  isEditState: Boolean = false;
  msg: string;
  summary: string;
  restId: string;
  custId: string;
  isPIN = false;

  template = `<img src="https://mir-s3-cdn-cf.behance.net/project_modules/disp/04de2e31234507.564a1d23645bf.gif" />`;
  data: any;
  contactLess = false;
  timerError=false;
   constructor(public pro: ProServiceService,
    private _router: Router,
    public api: APICenterService,
    private route: ActivatedRoute,
    private spinnerService: Ng4LoadingSpinnerService, public dialog: MatDialog) { }


  ngOnInit() {
    // onsole.log(this.msg)
    // console.log('@ start pin state');
    // console.log(this.api.pinState);

    this.api.currentState = {
      viewState: true,
      isContact: false,
      isTip: false,
      payatStore: false,
      payOption: false,
      payOnline: false,
      isPinStored: false,
      isBt: false,
      isBraintree: false
    };
    // console.log(this.pro.onBackObj);
    this.api.postFlask();
    this.isLoading = true;
    this.spinnerService.show();
    this.api.getUpdatedPost()
      .subscribe((data) => {
        this.spinnerService.hide();
        this.isLoading = false;
        this.api.showTotal = false;
        this.msg = data.msg;
        this.restId = data.restId;
        this.custId = data.custId;
        this.summary = data.ordrSum;
        this.data = data;
        this.api.curbData.make = this.data.curbCarInfo.make;
        this.api.curbData.model = this.data.curbCarInfo.model;
        this.api.curbData.color = this.data.curbCarInfo.carColor;
        if (this.data.curbCarInfo.make == '*') {
          this.api.curbData.make = '';
        }
        if (this.data.curbCarInfo.model == '*') {
          this.api.curbData.model = '';
        }
        if (this.data.curbCarInfo.carColor == '*') {
          this.api.curbData.color = '';
        }
        if (this.api.curbData.make != '' && this.api.curbData.make != undefined &&
          this.api.curbData.model != '' && this.api.curbData.model != undefined &&
          this.api.curbData.color != '' && this.api.curbData.color != undefined) {
          this.api.curbside = true;
        }

        // LAND IN PAYMENT SCREEN FOR RETURNING CUSTOMER 
         let name = this.api.restaurant.name.replace("'", "").toLowerCase()
         if (name == 'smoothieking' || name == 'smoothie king') {
         if (this.api.isStoreOpen) {
         if (!this.api.isPaid) {
           if (this.api.isFirstLoad) {
               if (!data.orderStatus.isProOrder) {
                 if (this.api.customer.isPwd || this.api.pinState === '1' ) {
                   this.stateManage();
                 }
              }
            }
          }
         } 
        }

        if (this.api.isPaid) {
          this.api.isDeleteBtn = false;
          this.api.isEditBtn = false;
          this.api.isAddItemBox = false;

          // isState_0 When true hide the viewState
          this.api.isState_0 = true;

          this.api.isContact = false;
          this.api.isTip = false;
          this.api.isBt = false;
          this.api.pinFailure = false;
          this.api.isSuccess = true;
          this.api.isFailure = false;
        } else {
          if (this.api.ordrType == "telX" || this.api.ordrType == "tel-PX" || this.api.ordrType == "tel-QX") {
            if (this.api.pinState = '0') {
              this.api.isState_0 = true;
              this.api.ispickupValidator()
              this.api.setPasswordState()
            }
          }
        }

        /*// Save current State
        this.api.currentState.isContact = false;
        this.api.currentState.isTip = false;
        this.api.currentState.isBt = false;*/


      });

    // console.log('pin state');
    // console.log(this.api.pinState);

  }

  onUpdate() {
    this.isActive = false;
    this.isActive = !this.isActive;
    this.isActive = !this.isActive;
    this.api.updateUserMsg();
  }

  onBack() {
    this.api.isMethodswap = false;
    this.pro.isProBack = true;
    let sid: string;
    if (typeof (this.pro.selectedSID) === 'undefined') {
      sid = localStorage.getItem('SID');
    } else {
      sid = this.pro.selectedSID;
    }
    if(this.api.chainname=='chowly'){
      const routeId = `/quick/chowly/${sid}`;
      this._router.navigate([routeId]);
    }
    if(this.api.chainname=='checkmate'){
      const routeId = `/quick/itsacheckmate/${sid}`;
      this._router.navigate([routeId]);
    }
    else{
    const routeId = `/quick/jets/${sid}`;
    this._router.navigate([routeId]);
    }
    
  }

  onEdit() {
    this.api.clickValue = 'Edit';
    this.api.clickAnalytics();
    if (this.api.isContactFree) {
      this.api.customer.msg_text = `${this.api.customer.msg_text} ${this.api.contactLessTxt}`;
    }
    this.isActive = true;
    this.api.isEdit = true;
    this.api.editScore = this.api.editScore + 1;
    if (this.api.isPayAtStore) {
      // Pay at store is TRUE
      // console.log('ONLY PAY AT STORE - View state');
      this.api.isShowMethodToggle = true;
    }

  }

  onBackViewState() {
    this.api.postFlask();
    this.isLoading = true;
    this.api.isMethodBox = true;
    this.spinnerService.show();
    this.api.getUpdatedPost()
      .subscribe((data) => {
        this.spinnerService.hide();
        this.isLoading = false;
        this.api.showTotal = false;
        // console.log(data);
        this.msg = data.msg;
        this.restId = data.restId;
        this.custId = data.custId;
      });
  }

  onCheckOut() {
    // ClickAnalytics - stores in db the user clicks
    this.api.actionbutton("checkout",	"VS011")
    if (this.api.method.mtype.toLowerCase()=='pickup' && this.api.isContactFree && !this.api.curbside && this.api.curbData.make == '' && this.api.curbData.model == '' && this.api.curbData.color == '') {
      const dialogRef = this.dialog.open(CurbsidePopupComponent, {
        width: '100%',
        maxWidth: 'unset',
        height: '60%'
      });

      dialogRef.afterClosed().subscribe(result => {
        if (this.api.curbside == true) {
          this.contactLess = true;
          this.onCheckOut();
        }
      });
    }
    else {
      if(this.data.restaurant.name=='Smoothie King' && !this.api.checkoutAllergy){
        const dialogRef = this.dialog.open(SmoothieKingAllergiesComponent, {
          width: '100%',
          maxWidth: 'unset',
          height: '100%',
          maxHeight:'unset',
          autoFocus:false
        }); 
        dialogRef.afterClosed().subscribe(result => {
          if (this.api.checkoutAllergy) {
            this.onCheckOut();
          }
        });
      }
      else{
      this.api.clickValue = 'Checkout';
      this.api.clickAnalytics();
      // End - CLickAnalytics

      // isDeleteBtn, isEditBtn, isAddItemBox - Hide the delete,edit and add Item btn present viewState
      // so that the user can not edit or add or delete  the items in payment state.
      this.api.isDeleteBtn = false;
      this.api.isEditBtn = false;
      this.api.isAddItemBox = false;

      // isState_0 When true hide the viewState
      this.api.isState_0 = true;

      if (!this.api.isBread && !this.api.isPop && !this.api.isDessert && !this.api.isPoppers) {
        // console.log('all upsell are selected');
        this.api.isShowUpsell = false;
      }

      if ((this.api.isPayOnline) && (this.api.isPayAtDoor) && (this.api.isPayAtStore)) {
        // console.log('all Are true');
        this.api.nameState();

      } else if ((this.api.isPayAtDoor) && (this.api.isPayAtStore)) {
        // console.log('pay at door and pay at store is true');
        this.api.nameState();

      } else if ((this.api.isPayOnline) && (this.api.isPayAtDoor)) {
        // console.log('pay online  and pay at door ');
        this.api.nameState();

      } else if ((this.api.isPayOnline) && (this.api.isPayAtStore)) {
        // console.log('pay online and pay at store');
        this.api.nameState();
      } else {
        if (!(this.api.isPayOnline)) {
          // PayOnline is False
          // console.log('PayOnline is False');
          if (!(this.api.isPayAtDoor)) {
            // Pay at door is False
            // console.log('Pay at door is False');
            if (this.api.isPayAtStore) {
              // Pay at store is TRUE
              // Next Customer Name State
              // console.log('ONLY PAY AT STORE - View state');
              this.api.nameState();
            } else {
              // console.log('PAY at store is False');
            }
          } else {
            // Pay at door is true
            // console.log('pay at door is true');
            // console.log('ONLY PAY AT Door - View state');
            this.api.nameState();

          }

        } else {
          // Pay online is true
          // // console.log('pay Online is true');
          this.api.nameState();
        }

      }


      /*
      if(this.api.customer.showDisclaimer) {
        this.api.isDisclaimer = true;
        this.api.isTip = false;
        this.api.isContact = false;
      } else {
        // console.log('inside ELSE')
        this.api.isContact = true;
        // console.log('isTIP')
        // console.log(this.api.isTip)
        // console.log('ISContact')
        // console.log(this.api.isContact)
      }
       */
    }
  }
  }

  stateManage() {
    this.api.isDeleteBtn = false;
    this.api.isEditBtn = false;
    this.api.isAddItemBox = false;

    // isState_0 When true hide the viewState
    this.api.isState_0 = true;
    if ((this.api.isPayOnline) && (this.api.isPayAtDoor) && (this.api.isPayAtStore)) {
      this.landInPaymentState();


    } else if ((this.api.isPayAtDoor) && (this.api.isPayAtStore)) {
      // console.log('pay at door and pay at store is true');
      this.payatStoreState();

    } else if ((this.api.isPayOnline) && (this.api.isPayAtDoor)) {
      // console.log('pay online  and pay at door ');
      this.landInPaymentState();

    } else if ((this.api.isPayOnline) && (this.api.isPayAtStore)) {
      // console.log('pay online and pay at store');
      // this.api.isContact = true;
      this.landInPaymentState();

    } else {
      if (!(this.api.isPayOnline)) {
        // PayOnline is False
        // console.log('PayOnline is False');
        if (!(this.api.isPayAtDoor)) {
          // Pay at door is False
          // console.log('Pay at door is False');
          if (this.api.isPayAtStore) {
            // Pay at store is TRUE
            // Next Customer Name State
            // console.log('ONLY PAY AT STORE - View state');
            this.payatStoreState();
          } else {
            // console.log('PAY at store is False');
          }
        } else {
          // Pay at door is true
          // console.log('pay at door is true');
          // console.log('ONLY PAY AT Door - View state');
          this.payatStoreState();

        }

      } else {
        // Pay online is true
        // console.log('pay Online is true');
        this.landInPaymentState();
      }

    }

  }

  landInPaymentState() {
    this.api.currentState = {
      viewState: true,
      isContact: false,
      isTip: false,
      payatStore: false,
      payOption: false,
      payOnline: false,
      isPinStored: false,
      isBt: false,
      isBraintree: false
    };
    this.api.isContact = false;
    this.api.ispickupValidator();
    if (this.api.customer.isPwd && this.api.pinState === '1') {
      // Both are set - treat as Password only customer
      if (this.api.isDelivery && false) {
        this.showTIPstate();
      } else {
        // console.log('pickup order');
        if (this.api.isShowTipState) {
          this.showTIPstate();
        } else {
          this.showBtState();
        }

      }
    } else {
      if (this.api.customer.isPwd) {
        if (this.api.isDelivery && false) {
          this.showTIPstate();
        } else {
          // console.log('pickup order');
          if (this.api.isShowTipState) {
            this.showTIPstate();
          } else {
            this.showBtState();
          }
        }
      } else if (this.api.pinState === '1') {
        // PIN Only customer
        if (this.api.isDelivery && false) {
          this.showTIPstate();
        } else {
          // console.log('pickup order');
          if (this.api.isShowTipState) {
            this.showTIPstate();
          } else {
            this.showBtState();
          }
        }
      }

    }
  }

  showTIPstate() {
    this.api.isOrdrSummary = true;
    this.api.isTip = true;
    this.api.payOnline = false;
    this.api.payatStore = true;
    this.api.payOption = true;
    this.api.isPinStored = false;
    this.api.defaultTip();
    this.api.currentState = {
      viewState: false,
      isContact: false,
      isTip: true,
      payatStore: true,
      payOption: true,
      payOnline: false,
      isPinStored: false,
      isBt: false,
      isBraintree: false
    };
  }

  showBtState() {
    this.api.isOrdrSummary = true;
    this.api.isBt = true;
    this.api.isBraintree = true;
    this.api.currentState = {
      viewState: false,
      isContact: false,
      isTip: false,
      payatStore: false,
      payOption: false,
      payOnline: false,
      isPinStored: false,
      isBt: true,
      isBraintree: true
    };
  }

  payatStoreState() {
    this.api.isContact = false;
    this.api.isOrdrSummary = true;
    this.api.isTip = true;
    this.api.payOnline = true;
    this.api.payatStore = false;
    this.api.payOption = true;
    this.api.currentState = {
      viewState: false,
      isContact: false,
      isTip: true,
      payatStore: false,
      payOption: true,
      payOnline: true,
      isPinStored: false,
      isBt: false,
      isBraintree: false
    };

  }

}

