import { RestContactService } from './../rest-contact.service';
import { APICenterService } from './../apicenter.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upsell',
  templateUrl: './upsell.component.html',
  styleUrls: ['./upsell.component.css']
})
export class UpsellComponent implements OnInit {
  msgList
  isWing 
  isBread
  isSoda

  constructor(public rest : RestContactService, public api : APICenterService) { }

  ngOnInit() {
    if(this.api.customer.msg_text !== null) {
        this.msgList = this.api.customer.msg_text.split(' ');
        // console.log(this.msgList);
        this.msgList.map((val) => {
          if((val.toLowerCase()) === "wings" || (val.toLowerCase()) === "wing" || (val.toLowerCase()) === "wings," || (val.toLowerCase()) === "wing," || (val.toLowerCase()) === "jet's wing," ){
           //  console.log("chicken wings there");
            this.isWing = true;
          } else if ((val.toLowerCase())==="bread" || (val.toLowerCase())==="breads" || (val.toLowerCase())==="bread," || (val.toLowerCase())==="breads,") {
            this.isBread = true;
            // console.log("cheese bread is there")
          } else if (
              (val.toLowerCase()) === "pepsi" ||
              (val.toLowerCase()) === "pepsi," ||
              (val.toLowerCase()) === "coke" ||
              (val.toLowerCase()) === "coke," ||
              (val.toLowerCase()) === "dew" ||
              (val.toLowerCase()) === "dew," ||
              (val.toLowerCase()) === "soda" ||
              (val.toLowerCase()) === "soda," ||
              (val.toLowerCase()) === "pop"||
              (val.toLowerCase()) === "pop,"||
              (val.toLowerCase()) ==="drpepper") {
            console.log("there is Sodsa");
            this.isSoda = true;
          } 
        })
   }

  }

  isWings() {
    this.api.clickValue = 'Add Wings'
    this.api.clickAnalytics()
    this.api.customer.msg_text =  `${this.api.customer.msg_text} and ${this.api.fbt.wings},`
    this.isWing = false;
    // Demo page
    if(this.api.isdemo) {
      this.api.resetItems()
      this.api.onSend = true;
      this.api.isSummaryTitle = false;
      this.api.isAddItemBox = false;
      this.api.restaurant.rid = this.rest.selectedRest.rid;
      this.api.ordrID =  this.rest.restaurant.ordrId;
      this.api.updateUserMsg();
    } else {

    }

  }

  isSodas() {
    this.api.clickValue = 'Add pop'
    this.api.clickAnalytics()
    this.api.customer.msg_text =  `${this.api.customer.msg_text} and ${this.api.fbt.pop},`
    this.isSoda = false;
   
    // Demo page
    if(this.api.isdemo) {
      this.api.resetItems()
      this.api.onSend = true;
      this.api.isSummaryTitle = false;
      this.api.isAddItemBox = false;
      this.api.restaurant.rid = this.rest.selectedRest.rid;
      this.api.ordrID =  this.rest.restaurant.ordrId;
      this.api.updateUserMsg();
    } else {

    }
  }

  isBreads() {
    this.api.clickValue = 'Add bread'
    this.api.clickAnalytics()
    this.api.customer.msg_text =  `${this.api.customer.msg_text} and ${this.api.fbt.bread},`
    this.isBread = false;

    // Demo page
    if(this.api.isdemo) {
      this.api.resetItems()
      this.api.onSend = true;
      this.api.isSummaryTitle = false;
      this.api.isAddItemBox = false;
      this.api.restaurant.rid = this.rest.selectedRest.rid;
      this.api.ordrID =  this.rest.restaurant.ordrId;
      this.api.updateUserMsg();
    } else {

    }
  }

}
