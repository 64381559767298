import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rating-stars',
  templateUrl: './rating-stars.component.html',
  styleUrls: ['./rating-stars.component.css']
})
export class RatingStarsComponent implements OnInit {
  fstar1 = false; 
  fstar2 = false;
  fstar3 = false;
  fstar4 = false;
  fstar5 = false;
  estar1 = true;
  estar2 = true;
  estar3 = true;
  estar4 = true;
  estar5 = true;
  hstar1 = false;
  hstar2 = false;
  hstar3 = false;
  hstar4 = false;
  hstar5 = false;
  rating = '4.5'
  ratingArr = [ ]

  constructor() { }

  ngOnInit() {
  }

  estar1fn(){
    this.fstar1 = true;
    this.estar1 = false;  
  }

  estar2fn(){
    this.fstar1 = true;
    this.fstar2 = true;
    this.estar2 = false;
    this.estar1 = false; 
  }

  estar3fn(){
    this.fstar1 = true;
    this.fstar2 = true;
    this.fstar3 = true;
    this.estar3 = false;
    this.estar2 = false;
    this.estar1 = false;  
  }

  estar4fn(){
    this.fstar1 = true;
    this.fstar2 = true;
    this.fstar3 = true;
    this.fstar4 = true;
    this.estar4 = false;
    this.estar3 = false;
    this.estar2 = false;
    this.estar1 = false;   
  }

  estar5fn(){
    this.fstar1 = true;
    this.fstar2 = true;
    this.fstar3 = true;
    this.fstar4 = true;
    this.fstar5 = true;
    this.estar5 = false;
    this.estar4 = false;
    this.estar3 = false;
    this.estar2 = false;
    this.estar1 = false;    
  }

  fstar1fn(){
    this.estar1 = true;
    this.fstar1 = false; 
  }

  fstar2fn(){
    this.estar1 = true;
    this.estar2 = true;
    this.fstar2 = false; 
    this.fstar1 = false;  
  }

  fstar3fn(){
    this.estar1 = true;
    this.estar2 = true;
    this.estar3 = true; 
    this.fstar3 = false;
    this.fstar2 = false; 
    this.fstar1 = false;   
  }

  fstar4fn(){
    this.estar1 = true;
    this.estar2 = true;
    this.estar3 = true; 
    this.estar4 = true;
    this.fstar4 = false; 
    this.fstar3 = false;
    this.fstar2 = false; 
    this.fstar1 = false;    
  }

  fstar5fn(){
    this.estar1 = true;
    this.estar2 = true;
    this.estar3 = true; 
    this.estar4 = true;
    this.estar5 = true;
    this.fstar5 = false; 
    this.fstar4 = false; 
    this.fstar3 = false;
    this.fstar2 = false; 
    this.fstar1 = false;    
  }

}
