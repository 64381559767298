import { Component, OnInit } from "@angular/core";
import { APICenterService } from "../../apicenter.service";
import { Router, ActivatedRoute } from "@angular/router";
@Component({
  selector: "app-reset-new",
  templateUrl: "./reset-new.component.html",
  styleUrls: ["./reset-new.component.css"],
})
export class ResetNewComponent implements OnInit {
  userName = "";
  errorMessage = "";
  errorFlag: boolean = false;
  newPassword = "";
  newPasswordConfirm = "";
  passwordLength = false;
  passwordAlphabet = false;
  passwordNumeric = false;
  passwordSpecialCharacter = false;
  constructor(
    private router: Router,
    public cp: APICenterService,
    private routes: ActivatedRoute
  ) {
    /* if(this.userName==""){
     this.errorFlag=true;
     this.errorMessage="Invalid Link/User."
   }*/
  }
  validation(newPassword) {
    var numbersregex = /^(?=.*[0-9])/;
    var alphabetsregex = /^(?=.*[a-zA-Z])/;
    var specialcharregex = /^(?=.*[!@#$%^&*])/;
    var numbers = numbersregex.test(newPassword);
    var alphabets = alphabetsregex.test(newPassword);
    var special = specialcharregex.test(newPassword);
    if (newPassword.length >= 8) {
      this.passwordLength = true;
    } else {
      this.passwordLength = false;
    }
    if (numbers == true) {
      this.passwordNumeric = true;
    } else {
      this.passwordNumeric = false;
    }
    if (alphabets == true) {
      this.passwordAlphabet = true;
    } else {
      this.passwordAlphabet = false;
    }
    if (special == true) {
      this.passwordSpecialCharacter = true;
    } else {
      this.passwordSpecialCharacter = false;
    }
  }
  passworddisable() {
    if (this.newPassword != "" || this.newPassword != null) {
      if (
        this.passwordLength &&
        this.passwordAlphabet &&
        this.passwordNumeric &&
        this.passwordSpecialCharacter
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
  reset() {
    if (this.newPassword != this.newPasswordConfirm) {
      this.errorFlag = true;
      this.errorMessage = "Passwords Don't Match.";
    } else if (this.newPassword == this.newPasswordConfirm) {
      this.routes.url.subscribe((val) => {
        var hashKey = val[1].path;
        this.cp.cpResetNew(this.newPassword, hashKey);
        this.cp.getResetNew().subscribe((data) => {
          if (data.statusCode == 200) {
            this.errorFlag = false;
            this.router.navigate(["/login"]);
          } else if (data.statusCode == 201) {
            this.errorFlag = true;
            this.errorMessage = data.message;
          }
        });
      });
    }
  }
  ngOnInit() {
    this.routes.url.subscribe((val) => {
      const hashKey = val[1].path;
    });
  }
}
