import { Component, OnInit, Inject  } from '@angular/core';
import { APICenterService } from '../../apicenter.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '../../../../node_modules/@angular/material/dialog';

@Component({
  selector: 'app-refer-friend-dialog',
  templateUrl: './refer-friend-dialog.component.html',
  styleUrls: ['./refer-friend-dialog.component.css']
})
export class ReferFriendDialogComponent implements OnInit {

  constructor(public api: APICenterService,  public dialogRef: MatDialogRef<ReferFriendDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
    
  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}





