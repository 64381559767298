import { APICenterService } from './../apicenter.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.css']
})
export class ServerErrorComponent implements OnInit {

  constructor(private api : APICenterService) { }

  ngOnInit() {
  }

  onTryAgain(){
    this.api.isError = false;
    this.api.isMic = false;
    this.api.isSummaryDemo = false;
    this.api.customer.msg_text = ""
  }

}
