import { APICenterService } from './../apicenter.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-new-payment',
  templateUrl: './new-payment.component.html',
  styleUrls: ['./new-payment.component.css']
})
export class NewPaymentComponent implements OnInit {

  tempTotal = 0;
  tipVal10;
  tipVal20;
  tipVal15;
  loading = true

  constructor(private route: ActivatedRoute, public api: APICenterService) { }

  ngOnInit() {
    this.route.url
      .subscribe(val => {
        this.api.ordrID = val[1].path;
        this.api.postFlask();
        this.api.getUpdatedPost()
        .subscribe((data) => {
          this.loading = false
          this.api.isOnlyPayment = true;
          this.api.isState_0 = true;
          this.api.cardPaymentState()
        })
      });
     
      }


      tip_1_button() {
        // console.log('tip-1 is clicked')
        this.api.isFirstLoad = false;
        this.api.clickValue = 'First tip button';
        this.api.clickAnalytics();
  
        document.getElementById('tip-button-0').style.border = '2px solid green';
        document.getElementById('tip-button-0').style.color = 'green';
        document.getElementById('tip-button-0').style.fontSize = 'large';
        document.getElementById('tip-button-1').style.border = '1px solid grey';
        document.getElementById('tip-button-1').style.color = 'grey';
        document.getElementById('tip-button-1').style.fontSize = 'medium';
        document.getElementById('tip-button-2').style.border = '1px solid grey';
        document.getElementById('tip-button-2').style.color = 'grey';
        document.getElementById('tip-button-2').style.fontSize = 'medium';
        document.getElementById('tip-other').style.border = '1px solid grey';
        document.getElementById('tip-other').style.color = 'grey';
        document.getElementById('tip-other').style.fontSize = 'medium';
  
        this.tempTotal = this.api.total;
        this.tipVal10 = (this.api.total * 0).toFixed(2);
        this.api.tip = parseFloat(this.tipVal10);
        // console.log(this.api.tip)
        // console.log(this.api.total + this.api.tip)
        this.api.tipValidator();
        this.api.total = this.tempTotal;
  
      }
      tip_2_button() {
        // console.log('tip2 is clicked')
        this.api.isFirstLoad = false;
        this.api.clickValue = 'Second tip button';
        this.api.clickAnalytics();
  
        document.getElementById('tip-button-0').style.border = '1px solid grey';
        document.getElementById('tip-button-0').style.color = 'grey';
        document.getElementById('tip-button-0').style.fontSize = 'medium';
        document.getElementById('tip-button-1').style.border = '2px solid green';
        document.getElementById('tip-button-1').style.color = 'green';
        document.getElementById('tip-button-1').style.fontSize = 'x-large';
        document.getElementById('tip-button-2').style.border = '1px solid grey';
        document.getElementById('tip-button-2').style.color = 'grey';
        document.getElementById('tip-button-2').style.fontSize = 'medium';
        document.getElementById('tip-other').style.border = '1px solid grey';
        document.getElementById('tip-other').style.color = 'grey';
        document.getElementById('tip-other').style.fontSize = 'medium';
  
        this.tempTotal = this.api.total;
        this.tipVal15 = (this.api.total * 0.15).toFixed(2);
        this.api.tip = parseFloat(this.tipVal15);
        // console.log(this.api.tip)
        // console.log(this.api.total + this.api.tip)
        this.api.tipValidator();
        this.api.total = this.tempTotal;
      }
      tip_3_button() {
        // console.log('tip3 is clicked')
        this.api.isFirstLoad = false;
        this.api.clickValue = 'Thrid tip button';
        this.api.clickAnalytics();
  
  
        document.getElementById('tip-button-0').style.border = '1px solid grey';
        document.getElementById('tip-button-0').style.color = 'grey';
        document.getElementById('tip-button-0').style.fontSize = 'medium';
        document.getElementById('tip-button-1').style.border = '1px solid grey';
        document.getElementById('tip-button-1').style.color = 'grey';
        document.getElementById('tip-button-1').style.fontSize = 'medium';
        document.getElementById('tip-button-2').style.border = '2px solid green';
        document.getElementById('tip-button-2').style.color = 'green';
        document.getElementById('tip-button-2').style.fontSize = 'x-large';
        document.getElementById('tip-other').style.border = '1px solid grey';
        document.getElementById('tip-other').style.color = 'grey';
        document.getElementById('tip-other').style.fontSize = 'medium';
  
        this.tempTotal = this.api.total;
        this.tipVal20 = (this.api.total * 0.2).toFixed(2);
        this.api.tip = parseFloat(this.tipVal20);
        // console.log(this.api.tip)
        // console.log(this.api.total + this.api.tip)
        this.api.tipValidator();
        this.api.total = this.tempTotal;
  
      }
  
      tip_4_button() {
        this.api.isFirstLoad = false;
        this.api.clickValue = 'Custom tip button';
        this.api.clickAnalytics();
  
        document.getElementById('tip-button-0').style.border = '1px solid grey';
        document.getElementById('tip-button-0').style.color = 'grey';
        document.getElementById('tip-button-0').style.fontSize = 'medium';
        document.getElementById('tip-button-1').style.border = '1px solid grey';
        document.getElementById('tip-button-1').style.color = 'grey';
        document.getElementById('tip-button-1').style.fontSize = 'medium';
        document.getElementById('tip-button-2').style.border = '1px solid grey';
        document.getElementById('tip-button-2').style.color = 'grey';
        document.getElementById('tip-button-2').style.fontSize = 'medium';
        document.getElementById('tip-other').style.border = '2px solid green';
        document.getElementById('tip-other').style.color = 'green';
        document.getElementById('tip-other').style.fontSize = 'large';
  
        document.getElementById('custom-tip-box').focus();
        this.api.tip = 0.00;
  
      }
  
  }


