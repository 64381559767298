import { APICenterService } from './../apicenter.service';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-pin-set-b',
  templateUrl: './pin-set-b.component.html',
  styleUrls: ['./pin-set-b.component.css']
})
export class PinSetBComponent implements OnInit {
  boxes = ['0','1','2','3']
  pinVal = {
    pinVal_1 : ' ',
    pinVal_2 : ' ',
    pinVal_3 : ' ',
    pinVal_4 : ' '

  }

  pinVal_2 = {
    pinVal_1 : ' ',
    pinVal_2 : ' ',
    pinVal_3 : ' ',
    pinVal_4 : ' '

  }
  pinValAi
  pinValBi
  pinValCi
  pinValDi
  name
  userPin_1
  userPin_2
  isNumber: boolean = true;
  isNumber_2: boolean = true;
  ischecked: boolean = true;
  pinSet: boolean = true;
  enterPin: boolean = true;
  confirmPin: boolean = true;
  wrongPin: boolean = true;
  completePin: boolean = true;

  constructor(public api : APICenterService) { }

  ngOnInit() {
  }

  setPin() {
    console.log("the set PIN is clicked!!")
  }

  pin_1(event) {
    console.log(this.pinValAi)
    console.log(event.isTrusted);
    if(event.keyCode < 47 || event.keyCode > 59) {
      console.log("please enter a valid number")
      this.isNumber = false;
    } else {
      this.pinVal.pinVal_1 = event.key
      document.getElementById("pinVal_2").focus()
      console.log(event)
    }

  }

  pin_2(event) {
    console.log(this.pinValBi)
    console.log(event.isTrusted);
    if(event.keyCode < 47 || event.keyCode > 59) {
      console.log("please enter a valid number")
      this.isNumber = false;
    } else {
      document.getElementById("pinVal_3").focus()
      console.log(event)
    }

  }

  pin_3(event) {
    console.log(this.pinValCi)
    console.log(event.isTrusted);
    if(event.keyCode < 47 || event.keyCode > 59) {
      console.log("please enter a valid number")
      this.isNumber = false;
    } else {
      document.getElementById("pinVal_4").focus()
      console.log(event)
    }

  }

  pin_4(event) {
    console.log(this.pinValDi)
    if(event.keyCode < 47 || event.keyCode > 59) {
      console.log("please enter a valid number")
      this.isNumber = false;
    } else {
      document.getElementById("pinVal_5").focus()
    }

  }
  pin_5(event) {
    console.log(event.isTrusted);
    if(event.keyCode < 47 || event.keyCode > 59) {
      console.log("please enter a valid number")
      this.isNumber_2 = false;
    } else {
      document.getElementById("pinVal_6").focus()
      console.log(event)
    }

  }

  pin_6(event) {
    console.log(event.isTrusted);
    if(event.keyCode < 47 || event.keyCode > 59) {
      console.log("please enter a valid number")
      this.isNumber_2 = false;
    } else {
      this.pinVal_2.pinVal_1 = event.key;
      document.getElementById("pinVal_7").focus()
      console.log(event)
    }

  }

  pin_7(event) {
    console.log(event.isTrusted);
    if(event.keyCode < 47 || event.keyCode > 59) {
      console.log("please enter a valid number")
      this.isNumber_2 = false;
    } else {
      this.pinVal_2.pinVal_2 = event.key;
      document.getElementById("pinVal_8").focus()
      console.log(event)
    }

  }

  pin_8(event) {
    if(event.keyCode < 47 || event.keyCode > 59) {
      console.log("please enter a valid number")
      this.isNumber_2 = false;
    } else {
     console.log('Valid PIN')
    }

  }

  log(x) {

    console.log(x)

  }

  pinA(s){

  }

/*

  pinA(s) {
    if((s.value.charCodeAt('0')) < 47 || (s.value.charCodeAt('0')) > 59) {
      console.log("please enter a valid number")

    } else {
     this.pinVal.pinVal_1 = s.value;
     console.log(this.pinVal.pinVal_1)
     document.getElementById("pinVal_2").focus()
     console.log('focus tiggered')
    }
  }

  pinB(s) {
    if((s.value.charCodeAt('0')) < 47 || (s.value.charCodeAt('0')) > 59) {
      console.log("please enter a valid number")

    } else {
     this.pinVal.pinVal_2 = s.value;
     console.log(this.pinVal.pinVal_2)
     document.getElementById("pinVal_3").focus()
     console.log('focus tiggered')
    }
  }
 */
  pin(s){

  }

  submit(f) {
    console.log('form')
    console.log(f.value)
    this.userPin_1 = `${f.value.pinValA}${f.value.pinValB}${f.value.pinValC}${f.value.pinValD}`;
    console.log(this.userPin_1);
    this.userPin_2 = `${f.value.pinValE}${f.value.pinValF}${f.value.pinValG}${f.value.pinValH}`;
    this.validate();
    this.api.setPin()
  }


  validate() {
    if (!(this.userPin_1 === this.userPin_2)) {
      console.log('The PIN doesnt match');
      this.wrongPin = false;
      this.pinSet = true;
     // (<HTMLInputElement>document.getElementById("pinA").reset());

    } else{
      console.log('Congrates !!! your PIN is SET')
      this.api.enteredPin = this.userPin_1;
      this.pinSet = false;
      this.wrongPin = true;
    }
  }

  onNext() {
    console.log('clicked with skip button')
  }



  skip() {
    console.log('skip is clicked')
  }
}
