export class gioniosStore {

    constructor() { }

    findStore(sid) {
        switch (sid) {
                case 'gn01':
                    let sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 10% OFF ON FIRST ORDER',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 10% OFF ON FIRST ORDER',
                        successPro: 'PRO ORDERS ARE 10% OFF ON FIRST ORDER',
                        isTxt: true,
                        rid: '+13309674600',
                        nickName: '375 E Main St location in Canfield',
                        addy1: '375 E Main St',
                        addy2: 'Canfield, OH'
                    };
                    return sidInfo;
                case 'gn02':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 10% OFF ON FIRST ORDER',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 10% OFF ON FIRST ORDER',
                        successPro: 'PRO ORDERS ARE 10% OFF ON FIRST ORDER',
                        isTxt: true,
                        rid: '+13307222727',
                        nickName: '203 Lafayette Rd location in Medina',
                        addy1: '203 Lafayette Rd',
                        addy2: 'Medina, OH'
                    };
                    return sidInfo;
                case 'gn03':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 10% OFF ON FIRST ORDER',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 10% OFF ON FIRST ORDER',
                        successPro: 'PRO ORDERS ARE 10% OFF ON FIRST ORDER',
                        isTxt: true,
                        rid: '+13306506060',
                        nickName: '60 W Streetsboro St location in Hudson',
                        addy1: '60 W Streetsboro St',
                        addy2: 'Hudson, OH'
                    };
                    return sidInfo;
                case 'gn04':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 10% OFF ON FIRST ORDER',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 10% OFF ON FIRST ORDER',
                        successPro: 'PRO ORDERS ARE 10% OFF ON FIRST ORDER',
                        isTxt: true,
                        rid: '+13309700700',
                        nickName: '365 Towpath Ave location in Canal Fulton',
                        addy1: '365 Towpath Ave',
                        addy2: 'Canal Fulton, OH'
                    };
                    return sidInfo;
               case 'gn05':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 10% OFF ON FIRST ORDER',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 10% OFF ON FIRST ORDER',
                    successPro: 'PRO ORDERS ARE 10% OFF ON FIRST ORDER',
                    isTxt: true,
                    rid: '+13309533777',
                    nickName: '4903 Mahoning Ave location in Austintown',
                    addy1: '4903 Mahoning Ave',
                    addy2: 'Austintown, OH'
                };
                return sidInfo;
            case 'gn06':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 10% OFF ON FIRST ORDER',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 10% OFF ON FIRST ORDER',
                    successPro: 'PRO ORDERS ARE 10% OFF ON FIRST ORDER',
                    isTxt: true,
                    rid: '+13305467555',
                    nickName: '2929 Cleveland Ave location in Canton',
                    addy1: '2929 Cleveland Ave',
                    addy2: 'Canton, OH'
                };
                return sidInfo;
            case 'gn07':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 10% OFF ON FIRST ORDER',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 10% OFF ON FIRST ORDER',
                    successPro: 'PRO ORDERS ARE 10% OFF ON FIRST ORDER',
                    isTxt: true,
                    rid: '+14407080208',
                    nickName: '8549 E Washington St location in Chagrin Falls',
                    addy1: '8549 E Washington St',
                    addy2: 'Chagrin Falls, OH'
                };
                return sidInfo;
            case 'gn08':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 10% OFF ON FIRST ORDER',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 10% OFF ON FIRST ORDER',
                    successPro: 'PRO ORDERS ARE 10% OFF ON FIRST ORDER',
                    isTxt: true,
                    rid: '+13306545561',
                    nickName: '17606 Mahoning Ave location in Lake Milton',
                    addy1: '17606 Mahoning Ave',
                    addy2: 'Lake Milton, OH'
                };
                return sidInfo;


            default:
                sidInfo = {
                    discountTxt: '*',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'Texting is the fastest way to order Gioninos!',
                    successPro: 'PRO ORDERS ARE 20% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: 'empty',
                    nickName: 'empty',
                    addy1: 'empty',
                    addy2: 'empty'
                };
                return sidInfo;
        }
        // console.log(cust)
    }
}
