export class smoothieStore {

    constructor() { }

    findStore(sid) {
        switch (sid) {
            case 'sk001':
                let sidInfo = {
                    discountTxt: '*',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'Texting is the fastest way to order!',
                    successPro: 'Texting is the fastest way to order!',
                    isTxt: true,
                    rid: '41515',
                    nickName: '6155 N Fry Rd Ste location in North Houston',
                    addy1: '6155 N Fry Rd Ste',
                    addy2: 'Houston, TX'
                };
                return sidInfo;

            default:
                sidInfo = {
                    discountTxt: '*',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'Texting is the fastest way to order!',
                    successPro: 'Texting is the fastest way to order!',
                    isTxt: true,
                    rid: 'empty',
                    nickName: 'empty',
                    addy1: 'empty',
                    addy2: 'empty'
                };
                return sidInfo;
        }
        // console.log(cust)
    }
        // console.log(cust)
    }

