import { APICenterService } from './../apicenter.service';
import { Component, OnInit } from '@angular/core';
import { MenuDialogComponent } from '../menu-dialog/menu-dialog.component';
import { MatDialog } from '../../../node_modules/@angular/material/dialog';
import { flattenStyles } from '@angular/platform-browser/src/dom/dom_renderer';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  title = `Ordr`
  isMob
  homePage=true;
  messageBox=false;
  name="";
  businessName="";
  email=""
  schedule="";
  responseFlag=false;
  constructor(public api : APICenterService, public dialog: MatDialog) { 

  }

  ngOnInit() {
    this.api.isdemo = true;
    if(this.api.isMobile) { 
      console.log('mobile view')
      this.isMob = true;
    } else {
      console.log('Desktop view')
      this.isMob = false;
    }
    this.api.isFullFooter = false;
    this.api.homePageDis = true;
    this.api.restaurant.menuPic = 'https://s3.us-east-2.amazonaws.com/ordr-assets/menu/MaPa+Mobile+Menu.png'
  }
  contactSubmit(){
    this.api.ordrAiEnquiry(this.name,this.businessName,this.email,this.schedule);
    this.api.getordrAiEnquiry()
    .subscribe((x) => {
      console.log(x);
      this.messageBox=true;
      if(x.msg==true){
        this.responseFlag=true;
      }
      else{
        this.responseFlag=false;
      }
     
      
    })

    
  }
 disabled(){
   if(this.name!="" && this.name!= undefined && this.name!=null &&
      this.businessName!="" && this.businessName!= undefined && this.businessName!=null &&
      this.email!="" && this.email!= undefined && this.email!=null &&
      this.schedule!="" && this.schedule!= undefined && this.schedule!=null 
   )
   {
     return false;
   }
   else{
     return true;
   }
 }
  onDemo() {
    this.api.onSubmit = false; 
  }
  home(){
    
  this.homePage=true;
  this.messageBox=false;
  this.name="";
  this.businessName="";
  this.email=""
  this.schedule="";
  this.responseFlag=false;
  }
  contact(){
    this.homePage=!this.homePage;
  }
  
  
  onUpdate(){
   this.api.homePageLoad = true;
   this.api.delivery_fee = '0.00';
   this.api.saving = '0.00';
   this.api.subtotal = '0.00';
   this.api.tax = '0.00';
   this.api.total = 0;
   this.api.tip = 0.00;
   this.api.itemsTotal = '0.00';
   this.api.grandTotal = ' ';
   this.api.qty = [];
   this.api.items = [];
   this.api.toppings = [];
   this.api.amounts = [];
   this.api.itemNames = [];
   this.api.sizes = [ ];
   this.api.cursts = [ ];
   this.api.firstHalf = [ ];
   this.api.secondHalf = [ ];
   this.api.bake = [ ];
   this.api.dressing = [ ];
   this.api.seasoning = [ ];
   this.api.sides = [ ];
   this.api.sauce = [ ];
   this.api.notApplicable = [ ];
   this.api.crustTopper = [ ];
   this.api.bread = [ ];
   this.api.drizzle = [ ];
   this.api.cut = [ ];
   this.api.dipping = [ ];
   this.api.seasoningPackets = [ ];
   this.api.sauceBase = [ ];
   this.api.wingType = [ ];
   this.api.cheese = [ ];
   this.api.naItems = ' ';
   this.api.keywordText = ' ';
   this.api.method.mtype = ' ';
   this.api.keywordText = ' ';
   this.api.errorText = ' ';
   this.api.irrelevantText = ' ';
   this.api.naItems = '0';

    this.api.homePage() 
    this.api.getHomePageInfo()
     .subscribe((x) => {
       this.api.homePageLoad = false;
       this.api.onGetData = true;
       
     })
  }
  close(){
    this.api.msg="";
    this.api.onGetData=false;
    this.api.onSubmit = false; 
  }
  
  openDialog(): void {
    this.api.clickValue = 'Menu'
    this.api.clickAnalytics() 
    const dialogRef = this.dialog.open(MenuDialogComponent, {
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}
