import { Component, OnInit, Input } from '@angular/core';
import { GiovannisStore } from './giovannispizzapower-store';
import { APICenterService } from '../../apicenter.service';
import { MatDialog } from '../../../../node_modules/@angular/material/dialog';
import { DeviceDetectorService } from '../../../../node_modules/ngx-device-detector';
import { ActivatedRoute } from '../../../../node_modules/@angular/router';
import { RestContactService } from './../../rest-contact.service';
import { OrderingStepsDialogComponent } from './../../ordering-steps-dialog/ordering-steps-dialog.component';



@Component({
  selector: 'app-giovannispizzapower',
  templateUrl: './giovannispizzapower.component.html',
  styleUrls: ['./giovannispizzapower.component.css']
})
export class GiovannispizzapowerComponent implements OnInit {
  @Input() restMenuId;
  mobile = false;
  smsNumber;
  restInfo;
  restNickname;
  restNickname1;
  discountTxt;
  restDisplayNo;
  isMob;
  isShowService
  year;
  loadingPage = false;
  deviceInfo = null;
  storeFinder = false;
  text = 'Hi Giovannis ! I want...';
  constructor(public api: APICenterService, private restNum: RestContactService, private route: ActivatedRoute, private deviceService: DeviceDetectorService, public dialog: MatDialog) {
    this.deviceDetect();
  }

  ngOnInit() {
    console.log("giovannis")
    const dt = new Date();
    this.year = dt.getFullYear();
    if (this.api.isMobile) {
      this.isMob = true;
    } else {
      this.isMob = false;
    }
    
    let colorData = {
      restaurant : {
        name:"giovannis"
      }
    }

    const style = document.createElement('style');
    style.innerHTML = `
:root {
background: black
}
`;
    document.head.appendChild(style);

    this.api.colourPicker(colorData)
    this.restInfo = this.restMenuId;
    let city = this.restInfo.addy2.split(',')[0]
    this.restNickname = `${this.restInfo.addy1}, ${city}`;
    // this.restNickname = this.restInfo.addy1;
    this.restNickname1 =  this.restInfo.addy2;
    this.discountTxt = this.restInfo.discountTxt;
    this.isShowService = this.restInfo.isServiceFee;

    this.restDisplayNo = this.api.formatPhone(this.restInfo.rid);

    this.api.menuRestId = this.restInfo.rid;
    this.api.displayNum = this.api.formatPhone(this.restInfo.rid);
    this.api.isFullFooter = true;

    this.smsNumber = this.api.menuRestId.replace('+1', '');
    this.api.isFullFooter = false;
    setInterval(() => this.loadingPage = true, 50);
    if(this.restMenuId.toLowerCase() == 'stores') {
      console.log(this.restMenuId.toLowerCase())
      this.storeFinder = true;
    } 

  }
  orderNowBtn() {

    if (this.api.isMobile) {
      if (this.api.deviceName === 'iPhone') {
        console.log('IPHONE');
        window.location.href = `sms:${this.smsNumber}&body=` + encodeURIComponent(this.text);
        console.log(`sms:${this.smsNumber}&body=` + encodeURIComponent(this.text))
      } else {
        console.log('MAY BE andriod');
        try {
          window.location.href = `sms:${this.smsNumber}?body=` + encodeURIComponent(this.text);
        } catch {
          window.location.href = `sms:${this.smsNumber};body=` + encodeURIComponent(this.text);
        }
        finally {
          window.location.href = `sms:${this.smsNumber};?&body=` + encodeURIComponent(this.text);
        }
      }
    } else if (this.api.isDesktopDevice) {

      const dialogRef = this.dialog.open(OrderingStepsDialogComponent, {
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });

    } else if (this.api.isTablet) {
      console.log('TABLET');
      const dialogRef = this.dialog.open(OrderingStepsDialogComponent, {
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    }

  }

  storeFinderBtn() {
   // window.location.replace("https://www.jetspizza.com/stores/");
  }
  deviceDetect() {
    console.log(this.deviceService);
    this.deviceInfo = this.deviceService.getDeviceInfo();
    console.log('device  Infomation');
    console.log(this.deviceInfo);
    this.api.browserVal = this.deviceInfo.browser;
    this.api.deviceVal = this.deviceInfo.device;
    const d = new Date();
    this.api.timeVal = d;

    this.api.isMobile = this.deviceService.isMobile();
    this.api.isTablet = this.deviceService.isTablet();
    this.api.isDesktopDevice = this.deviceService.isDesktop();
    console.log(this.deviceInfo);
    this.api.deviceName = this.deviceInfo.userAgent.split('(')[1].split(';')[0];
    this.api.deviceOs = this.deviceInfo.userAgent.split('(')[1].split(';')[1];

  }

}
