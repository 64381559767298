export class mannyandolgasStore {
    constructor() {}
    findStore(sid) {
      switch (sid) {
        case "stores":
          let sidInfo = {
            discountTxt: "FOR A LIMITED TIME GET 20% OFF ON YOUR FIRST ORDER",
            isPro: false,
            isServiceFee: false,
            successTxt: "TEXT ORDERS ARE 20% OFF ON YOUR FIRST ORDER",
            successPro: "PRO ORDERS ARE 20% OFF ON YOUR FIRST ORDER",
            isTxt: true,
            rid: "+1941729538799",
            nickName: "",
            addy1: "",
            addy2: "",
          };
          return sidInfo;


          case 'mg01':
                sidInfo = {
                    discountTxt: 'GET 20% OFF ON FIRST ORDER OVER $20',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON FIRST ORDER OVER $20',
                    successPro: 'PRO ORDERS ARE 20% OFF ON FIRST ORDER OVER $20',
                    isTxt: true,
                    rid: '+13019626363',
                    nickName: '11222 Georgia Ave location in Wheaton',
                    addy1: '11222 Georgia Ave',
                    addy2: 'Wheaton, MD'
                };
                return sidInfo;
            case 'mg02':
                sidInfo = {
                    discountTxt: 'GET 20% OFF ON FIRST ORDER OVER $20',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON FIRST ORDER OVER $20',
                    successPro: 'PRO ORDERS ARE 20% OFF ON FIRST ORDER OVER $20',
                    isTxt: true,
                    rid: '+13016088050',
                    nickName: '8107 Fenton St location in Silver Spring',
                    addy1: '8107 Fenton St',
                    addy2: 'Silver Spring, MD'
                };
                return sidInfo;
             case 'mg03':
                sidInfo = {
                    discountTxt: 'GET 20% OFF ON FIRST ORDER OVER $20',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON FIRST ORDER OVER $20',
                    successPro: 'PRO ORDERS ARE 20% OFF ON FIRST ORDER OVER $20',
                    isTxt: true,
                    rid: '+12023870025',
                    nickName: '1841 14th St NW location in D.C.',
                    addy1: '1841 14th St NW',
                    addy2: 'Washington DC'
                };
                return sidInfo;
             case 'mg04':
                sidInfo = {
                    discountTxt: 'GET 20% OFF ON FIRST ORDER OVER $20',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON FIRST ORDER OVER $20',
                    successPro: 'PRO ORDERS ARE 20% OFF ON FIRST ORDER OVER $20',
                    isTxt: true,
                    rid: '+12023371000',
                    nickName: '1641 Wisconsin Ave NW location in D.C.',
                    addy1: '1641 Wisconsin Ave NW',
                    addy2: 'Washington DC'
                };
                return sidInfo;
            case 'mg05':
                sidInfo = {
                    discountTxt: 'GET 20% OFF ON FIRST ORDER OVER $20',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON FIRST ORDER OVER $20',
                    successPro: 'PRO ORDERS ARE 20% OFF ON FIRST ORDER OVER $20',
                    isTxt: true,
                    rid: '+12023972222',
                    nickName: '1409 H St NE location in D.C.',
                    addy1: '1409 H St NE',
                    addy2: 'Washington DC'
                };
                return sidInfo;
            case 'mg06':
                sidInfo = {
                    discountTxt: 'GET 20% OFF ON FIRST ORDER OVER $20',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON FIRST ORDER OVER $20',
                    successPro: 'PRO ORDERS ARE 20% OFF ON FIRST ORDER OVER $20',
                    isTxt: true,
                    rid: '+12025264100',
                    nickName: '2117 Rhode Island Ave NE location in D.C.',
                    addy1: '2117 Rhode Island Ave NE',
                    addy2: 'Washington DC'
                };
                return sidInfo;  
            case 'mg07':
                sidInfo = {
                    discountTxt: 'GET 20% OFF ON FIRST ORDER OVER $20',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON FIRST ORDER OVER $20',
                    successPro: 'PRO ORDERS ARE 20% OFF ON FIRST ORDER OVER $20',
                    isTxt: true,
                    rid: '+12027231850',
                    nickName: '3624 Georgia Ave NW location in D.C.',
                    addy1: '3624 Georgia Ave NW',
                    addy2: 'Washington DC'
                };
                return sidInfo;
            case 'mg08':
                sidInfo = {
                    discountTxt: 'GET 20% OFF ON FIRST ORDER OVER $20',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON FIRST ORDER OVER $20',
                    successPro: 'PRO ORDERS ARE 20% OFF ON FIRST ORDER OVER $20',
                    isTxt: true,
                    rid: '+13016561450',
                    nickName: '8118 Woodmont Ave location in Bethesda',
                    addy1: '8118 Woodmont Ave',
                    addy2: 'Bethesda, MD'
                };
                return sidInfo;
              case 'mg10':
                  sidInfo = {
                      discountTxt: 'GET 20% OFF ON FIRST ORDER OVER $20',
                      isPro: false,
                      isServiceFee: false,
                      successTxt: 'TEXT ORDERS ARE 20% OFF ON FIRST ORDER OVER $20',
                      successPro: 'PRO ORDERS ARE 20% OFF ON FIRST ORDER OVER $20',
                      isTxt: true,
                      rid: '+12025715252',
                      nickName: '1430 Pennsylvania Ave location in D.C.',
                      addy1: '1430 Pennsylvania Ave',
                      addy2: 'Washington, D.C.'
                  };
                  return sidInfo;
             
           

  
        case "demo":
          sidInfo = {
            discountTxt: "*",
            isPro: false,
  
            isServiceFee: false,
            successTxt: "Texting is the fastest way to order Manny and olgas!",
            successPro: "PRO ORDERS ARE 20% OFF ON ALL PIZZAS",
            isTxt: true,
            rid: "+15862033838",
            nickName: "Research park dr ",
            addy1: "3917 Research Park Dr",
            addy2: "Ann Arbor, MI",
          };
          return sidInfo;
        case "xxx":
          return "+14194661111";
        case "xxx":
          return "+12159608888";
        default:
          sidInfo = {
            discountTxt: "*",
            isPro: false,
            isServiceFee: false,
            successTxt: "Texting is the fastest way to order Manny and olgas!",
            successPro: "PRO ORDERS ARE 20% OFF ON ALL PIZZAS",
            isTxt: true,
            rid: "empty",
            nickName: "empty",
            addy1: "empty",
            addy2: "empty",
          };
          return sidInfo;
      }
      // console.log(cust)
    }
  }
  