import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.css']
})
export class AudioComponent implements OnInit {
  audiorecord=false;
  constructor( private _router: Router) { }

  ngOnInit() {
  }
  voiceorder(){
    this._router.navigate(["/mic/jets/mi26"])
  }
}
