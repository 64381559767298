import { Component, OnInit } from '@angular/core';
import { APICenterService } from '../apicenter.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  storeName: string;
  storeAddress: string;
  displayNum
  phone
  constructor(public api: APICenterService) { }

  ngOnInit() {
    this.api.getUpdatedPost()
      .subscribe((val) => {
       // console.log(val.restaurant.color)
        // document.getElementById('navbar-color').style.background = val.restaurant.color;
      })
   // document.getElementById('navbar-color').style.background = this.api.restaurant.color;
   // console.log(this.api.restaurant.color);

  }

}
