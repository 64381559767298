import { Component, OnInit } from '@angular/core';
import { APICenterService } from '../apicenter.service';
import { RestContactService } from '../rest-contact.service';
import { ActivatedRoute } from '../../../node_modules/@angular/router';
import { jetsStore } from '../rest-offer-page/jets-store';

@Component({
  selector: 'app-quick-order',
  templateUrl: './quick-order.component.html',
  styleUrls: ['./quick-order.component.css']
})
export class QuickOrderComponent implements OnInit {

  constructor(public api : APICenterService, private restNum: RestContactService, private route: ActivatedRoute) {
    //document.body.style.maxWidth = "50%";
    //document.body.style.maxHeight = "auto";
    //document.body.style.margin = "auto";
   }
  restName
  restId
  restInfo
  rid
  
  ngOnInit() {
    this.route.url
     .subscribe(val => {
       console.log('URL Cutout')
       console.log(val)
       this.restName =  val[1].path;
       this.restId = val[2].path;
       console.log(this.restId)

       let sid  =  new jetsStore()
       this.restInfo = sid.findStore(this.restId)
       // this.restDisplayNo = this.api.formatPhone(this.restInfo.rid)
       console.log(this.restInfo.rid)

     }) 
  }

  /*ngOnDestroy(){
    document.body.className="";
  } */

}
