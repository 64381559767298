import { ProServiceService } from "./../../pro-order/pro-service.service";
import { APICenterService } from "./../.././apicenter.service";
import { RestContactService } from "./../../rest-contact.service";
import { ActivatedRoute } from "./../../../../node_modules/@angular/router";
import { jetsStore } from "./../../rest-offer-page/jets-store";
import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import { truncate } from "fs";

@Component({
  selector: "app-feedback-componenent",
  templateUrl: "./feedback-componenent.component.html",
  styleUrls: ["./feedback-componenent.component.css"],
})
export class FeedbackComponenentComponent implements OnInit {
  constructor(
    public pro: ProServiceService,
    public api: APICenterService,
    private restNum: RestContactService,
    private route: ActivatedRoute
  ) {}
  restName;
  crubId;
  restInfo;
  data;
  name = null;
  phone = null;
  vehicle = null;
  notes = null;
  specialOffers = false;
  loading = false;
  feedbackSuccess = false;
  rating = null;
  selected = 0;
  hovered = 0;
  readonly = false;
  ngOnInit() {
    this.deviceDetect();
    this.route.url.subscribe((val) => {
      console.log("URL Cutout");
      console.log(val);
      this.crubId = val[1].path;
      this.loading = true;
      this.api.feedbackInfoUser(this.crubId);
      this.api.getfeedbackInfoUser().subscribe((data) => {
        console.log(data);
        this.data = data;

        this.loading = false;
        if (this.data.fbInfo.status == true) {
          this.feedbackSuccess = true;
        } else {
          this.api.restaurant.address = data.restInfo[0].completeAddress;
          this.api.displayNum = this.api.formatPhone(data.restInfo[0].restId);
          if (this.data.fbInfo.Name != "*") {
            this.name = this.data.fbInfo.name;
          }
          if (this.data.fbInfo.CustId != "*") {
            this.phone = this.api.formatPhone(this.data.fbInfo.custId);
          }
          if (this.data.fbInfo.isOptedIn == true) {
            this.specialOffers = true;
          }
         

          if (this.data.restInfo[0].isDefaultFeedback == undefined) {
            this.data.restInfo[0]["isDefaultFeedback"] = true;
            this.data.restInfo[0]["customQuestionFeedback"] = [];
            console.log(this.data.restInfo[0].isDefaultFeedback);
          } else if (this.data.restInfo[0].isDefaultFeedback == true) {
            this.data.restInfo[0].customQuestionFeedback = []; //comment before pushing
          } 
          console.log(this.data)
        }
      });
    });
  }
  parse(data){
    return JSON.parse(data);
  }
  onRate(event) {
    this.rating = event.newValue;
  }
  onChange(e, field, value) {
    if (e.checked) {
      for (var i = 0; i < this.data.fbInfo.customQuestion.length; i++) {
        if (this.data.fbInfo.customQuestion[i] == field) {
          if (this.data.fbInfo.customQuestion[i].value.indexOf(value) === -1) {
            this.data.fbInfo.customQuestion[i].value.push(value);
          }
        }
      }
    } else if (!e.checked) {
      for (var i = 0; i < this.data.fbInfo.customQuestion.length; i++) {
        if (this.data.fbInfo.customQuestion[i] == field) {
          const index = this.data.fbInfo.customQuestion[i].value.indexOf(value);
          if (index > -1) {
            this.data.fbInfo.customQuestion[i].value.splice(index, 1); // 2nd parameter means remove one item only
          }
        }
      }
    }
  }
  submitFeedback() {
    // this.feedbackSuccess = true;
    this.restName = this.api.restaurant.name.replace("'", "").toLowerCase();
    var special;
    if (this.specialOffers) {
      special = false;
    } else {
      special = true;
    }
    var data = {
      fid: this.data.fbInfo.fid,
      feedbackInfo: this.notes,
      name: this.name,
      custId: this.data.fbInfo.custId,
      restId: this.data.fbInfo.restId,
      storeDate: this.data.restInfo[0].storeDate,
      isDefaultFeedback: this.data.restInfo[0].isDefaultFeedback,
      customQuestionFeedback: this.data.restInfo[0].customQuestionFeedback,
      starRating: this.selected,
      type: "SMS",
      isOptedIn: special,
    };
    this.api.feedbackInfoUserPost(data);
       this.api.getfeedbackInfoUserPost()
         .subscribe((data) => {
         if(data.status==200){
          this.feedbackSuccess = true;
         }
         });
    
    console.log(data);
  }
  deviceDetect() {
    const isMobile = /iPhone|Android/i.test(navigator.userAgent);

    if (isMobile) {
      this.pro.mobile = true;
    } else {
      this.pro.mobile = false;
    }
  }
  validate() {
    if (this.data.restInfo[0].isDefaultFeedback) {
      if (
        this.name != null &&
        this.name != "" &&
        this.phone != null &&
        this.phone != "" &&
        this.phone.length == 14 &&
        this.notes != null &&
        this.notes != "" &&
        this.selected != null &&
        this.selected != 0
      ) {
        return true;
      } else {
        return false;
      }
    } else if (!this.data.restInfo[0].isDefaultFeedback) {
      if (
        this.name != null &&
        this.name != "" &&
        this.phone != null &&
        this.phone != "" &&
        this.phone.length == 14 &&
        this.selected != null &&
        this.selected != 0
      ) {
        for (var i = 0; i < this.data.restInfo[0].customQuestionFeedback.length; i++) {
          if (this.data.restInfo[0].customQuestionFeedback[i].isMandatory) {
            if (
              this.data.restInfo[0].customQuestionFeedback[i].value == null ||
              this.data.restInfo[0].customQuestionFeedback[i].value == "" ||
              this.data.restInfo[0].customQuestionFeedback[i].value == []
            ) {
              return false;
            }
          }
        }
        return true;
      } else {
        return false;
      }
    }
  }
}
