import { Component, OnInit } from '@angular/core';
import { APICenterService } from '../apicenter.service';

@Component({
  selector: 'app-pin-plate',
  templateUrl: './pin-plate.component.html',
  styleUrls: ['./pin-plate.component.css']
})
export class PinPlateComponent implements OnInit {

  constructor(public api: APICenterService) { }

  ngOnInit() {

}

set() {
 console.log('set is clicked');
}

skip() {
  console.log('skip is clicked');
}
}


