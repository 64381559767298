import { APICenterService } from './../apicenter.service';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { NgModel } from '../../../node_modules/@angular/forms';


@Component({
  selector: 'app-tip',
  templateUrl: './tip.component.html',
  styleUrls: ['./tip.component.css']
})

export class TipComponent implements OnInit {
  tempTotal = 0;
  tipVal20;
  tipVal15;
  tipVal10;
  paymentSubmitted = false;
  payatStore = true;
  tipVal;
  checkTipState = true
  tipType='';
  constructor(public api: APICenterService) { }


  ngOnInit() {
   console.log('@ TIP component');
   this.api.isDeleteBtn = false;
   this.api.isEditBtn = false;
   this.api.isAddItemBox = false;
   this.api.cashEligible();
   if (!this.api.payOption){
    if (!this.api.isPayAtDoor && this.api.isDelivery) {
      this.api.payOnline = false
      this.api.payOption = true
      this.api.payatStore = true
    }
   }
  }



  submitPayment() {
    // console.log('button is clicked')
    this.api.actionbutton("cash_submit_order",	"PS001")
    this.api.clickValue = 'PayAtStore - Sumbit your Order';
    this.api.clickAnalytics();
    this.api.clickAanlysis.push('PayAtStore - Sumbit your Order');
    this.api.payAtStore();
    this.api.pinMsg = true;
    this.paymentSubmitted = true;
    document.getElementById('payStoreButton').style.display = 'none';
  }


  tipKeyup(event: any) {
    this.tempTotal = this.api.total;
    if (event.target.value < 0) {
      this.api.tip = 0;
    } else {
      this.api.tip = parseFloat(event.target.value);
    }
    // console.log(this.api.tip)
    // console.log(this.api.total + this.api.tip)
    this.api.tipValidator();
    this.api.total = this.tempTotal;

  }

    tip_1_button() {
      // console.log('tip-1 is clicked')
      this.tipType='cash_tip';
      this.api.actionbutton("cash_tip",	"TS001")
      this.api.isFirstLoad = false;
      this.api.clickValue = 'First tip button';
      this.api.clickAnalytics();

      document.getElementById('tip-button-0').style.border = '2px solid green';
      document.getElementById('tip-button-0').style.color = 'green';
      document.getElementById('tip-button-0').style.fontSize = 'large';
      document.getElementById('tip-button-1').style.border = '1px solid grey';
      document.getElementById('tip-button-1').style.color = 'grey';
      document.getElementById('tip-button-1').style.fontSize = 'medium';
      document.getElementById('tip-button-2').style.border = '1px solid grey';
      document.getElementById('tip-button-2').style.color = 'grey';
      document.getElementById('tip-button-2').style.fontSize = 'medium';
      document.getElementById('tip-other').style.border = '1px solid grey';
      document.getElementById('tip-other').style.color = 'grey';
      document.getElementById('tip-other').style.fontSize = 'medium';

      this.tempTotal = this.api.total;
      this.tipVal10 = (this.api.total * 0).toFixed(2);
      this.api.tip = parseFloat(this.tipVal10);
      // console.log(this.api.tip)
      // console.log(this.api.total + this.api.tip)
      this.api.tipValidator();
      this.api.total = this.tempTotal;

    }

    tip_2_button() {
      // console.log('tip2 is clicked')
      this.tipType='fifteen_percentage';
      this.api.actionbutton("fifteen_percentage",	"TS002")
      this.api.isFirstLoad = false;
      this.api.clickValue = 'Second tip button';
      this.api.clickAnalytics();

      document.getElementById('tip-button-0').style.border = '1px solid grey';
      document.getElementById('tip-button-0').style.color = 'grey';
      document.getElementById('tip-button-0').style.fontSize = 'medium';
      document.getElementById('tip-button-1').style.border = '2px solid green';
      document.getElementById('tip-button-1').style.color = 'green';
      document.getElementById('tip-button-1').style.fontSize = 'x-large';
      document.getElementById('tip-button-2').style.border = '1px solid grey';
      document.getElementById('tip-button-2').style.color = 'grey';
      document.getElementById('tip-button-2').style.fontSize = 'medium';
      document.getElementById('tip-other').style.border = '1px solid grey';
      document.getElementById('tip-other').style.color = 'grey';
      document.getElementById('tip-other').style.fontSize = 'medium';

      this.tempTotal = this.api.total;
      this.tipVal15 = (this.api.total * 0.15).toFixed(2);
      this.api.tip = parseFloat(this.tipVal15);
      // console.log(this.api.tip)
      // console.log(this.api.total + this.api.tip)
      this.api.tipValidator();
      this.api.total = this.tempTotal;
    }

    tip_3_button() {
      // console.log('tip3 is clicked')
      this.tipType='twenty_percentage';
      this.api.actionbutton("twenty_percentage",	"TS003")
      this.api.isFirstLoad = false;
      this.api.clickValue = 'Thrid tip button';
      this.api.clickAnalytics();


      document.getElementById('tip-button-0').style.border = '1px solid grey';
      document.getElementById('tip-button-0').style.color = 'grey';
      document.getElementById('tip-button-0').style.fontSize = 'medium';
      document.getElementById('tip-button-1').style.border = '1px solid grey';
      document.getElementById('tip-button-1').style.color = 'grey';
      document.getElementById('tip-button-1').style.fontSize = 'medium';
      document.getElementById('tip-button-2').style.border = '2px solid green';
      document.getElementById('tip-button-2').style.color = 'green';
      document.getElementById('tip-button-2').style.fontSize = 'x-large';
      document.getElementById('tip-other').style.border = '1px solid grey';
      document.getElementById('tip-other').style.color = 'grey';
      document.getElementById('tip-other').style.fontSize = 'medium';

      this.tempTotal = this.api.total;
      this.tipVal20 = (this.api.total * 0.2).toFixed(2);
      this.api.tip = parseFloat(this.tipVal20);
      // console.log(this.api.tip)
      // console.log(this.api.total + this.api.tip)
      this.api.tipValidator();
      this.api.total = this.tempTotal;

    }

    tip_4_button() {
      this.tipType='custom_percentage';
      this.api.actionbutton("custom_percentage",	"TS004")
      this.api.isFirstLoad = false;
      this.api.clickValue = 'Custom tip button';
      this.api.clickAnalytics();

      document.getElementById('tip-button-0').style.border = '1px solid grey';
      document.getElementById('tip-button-0').style.color = 'grey';
      document.getElementById('tip-button-0').style.fontSize = 'medium';
      document.getElementById('tip-button-1').style.border = '1px solid grey';
      document.getElementById('tip-button-1').style.color = 'grey';
      document.getElementById('tip-button-1').style.fontSize = 'medium';
      document.getElementById('tip-button-2').style.border = '1px solid grey';
      document.getElementById('tip-button-2').style.color = 'grey';
      document.getElementById('tip-button-2').style.fontSize = 'medium';
      document.getElementById('tip-other').style.border = '2px solid green';
      document.getElementById('tip-other').style.color = 'green';
      document.getElementById('tip-other').style.fontSize = 'large';

      document.getElementById('custom-tip-box').focus();
      this.api.tip = 0.00;

    }

    onBackSelect() {
      this.api.actionbutton("options_back",	"OS004")
      this.api.clickValue = 'Back btn on PayAtStore/PayOnline';
      this.api.clickAnalytics();
      this.api.nameState();
    }


    onBack() {
      this.api.actionbutton("tip_back",	" TS005")
      this.api.isFirstLoad = false;
      console.log('tip state back btn');
      this.api.clickValue = 'Back_Tip';
      this.api.clickAnalytics();
      this.api.isDeleteBtn = true;
      this.api.isEditBtn = true;
      this.api.isAddItemBox = true;

      // console.log('TIP VALUE')
      // console.log('isCashEligible')
      // console.log(this.api.isCashEligible)


    if ((this.api.isPayOnline) && (this.api.isPayAtDoor) && (this.api.isPayAtStore)) {
        console.log('all Are true');
        if(this.api.isDelivery){
          this.api.checkTipState = true
        }
        if (this.api.method.mtype === "PICKUP") {
        if (this.api.checkTipState || !this.api.isDelivery)  {
          // Pick Up Order
          if (this.api.pinState === '0') {
            // new customer
            this.api.setPasswordState();
          } else {
            // existing customer
            if (this.api.isPayAtStoreEligible) {
              // Pay at Store
              this.api.payOptionState();

            } else  {
              // No at store
              this.api.nameState();
            }

          }
        }} else {
          // Delivery order
          if (this.api.pinState === '0') {
            // new customer
            this.api.setPasswordState();
          } else {
            // existing customer
            if (this.api.isPayAtDoorEligible) {
              // Pay at Store
              this.api.payOptionState();

            } else  {
              // No at store
              this.api.nameState();
            }

          }


        }

    } else if ((this.api.isPayAtDoor) && (this.api.isPayAtStore)) {
      console.log ('pay at door and pay at store is true');
         } else if ((this.api.isPayOnline) && (this.api.isPayAtDoor) ) {
              console.log ('pay online  and pay at door ');
              if(this.api.isDelivery){
                this.api.checkTipState = true
              }
              if (this.api.method.mtype === "PICKUP") {
              if (this.api.checkTipState || !this.api.isDelivery) {
                // PIckUp Order
                if (this.api.pinState === '0') {
                  // New Customer
                  this.api.setPasswordState();
                } else {
                  // Existing Customer
                  this.api.nameState();
                }
              }} else {
                // Delivery Order
                if (this.api.pinState === '0') {
                  this.api.setPasswordState();
                } else {
                  // Existing Customer
                  if (this.api.isPayAtDoorEligible) {
                    this.api.payOptionState();
                  } else {
                    this.api.nameState();
                  }

                }

              }
             } else if ((this.api.isPayOnline) && (this.api.isPayAtStore) ) {
                    console.log('pay online and pay at store');
                    if (!(this.api.pinState === '0')) {
                      // existing Customer
                      if(this.api.isDelivery && this.api.isPayAtDoor){
                        this.api.checkTipState = true
                      } else {
                        this.api.checkTipState = false
                      }
                      if (this.api.method.mtype === "PICKUP") {
                      if (this.api.checkTipState || !this.api.isDelivery){
                        // Pickup Order
                        if (this.api.isPayAtStoreEligible) {
                          // Pay at store eligible
                          this.api.payOptionState();
                        } else {
                          // pay at store not eligible
                          this.api.nameState();
                        }
                      }} else {
                        // delivery Order
                        this.api.nameState();
                      }
                    } else {
                      // New customer
                      this.api.setPasswordState();
                    }
                 } else {
                      if (!(this.api.isPayOnline)) {
                        if (!(this.api.isPayOnline)) {
                          // PayOnline is False
                          if (!(this.api.isPayAtDoor)) {
                            // Pay at door is False
                            if (this.api.isPayAtStore) {
                              // Pay at store is TRUE
                                  // Next Customer Name State
                                console.log('ONLY PAY AT STORE - TIP (back) state');
                                this.api.nameState();
                            }
                          } else {
                                console.log('ONLY PAY AT DOOR - TIP (back) state');
                                this.api.nameState();

                          }
                        }
                      } else {
                                console.log('online is true');
                                // online is true
                                if (this.api.pinState === '0') {
                                       // New customer/ NO PIN set customer
                                       console.log('new customer/ No PIN set customer');

                                       if(this.api.isDelivery){
                                        this.api.checkTipState = true
                                      }
                                      if (this.api.method.mtype === "PICKUP") {
                                      if (this.api.checkTipState || !this.api.isDelivery)  {
                                        // Pick up orders

                                           if (this.api.isShowTipState) {

                                             // show Tip State
                                             this.api.setPasswordState();

                                           } else {
                                             // with out Tip State
                                             console.log('dont show tip state');


                                           }

                                      } }else {

                                             // delivery order
                                             this.api.setPasswordState();

                                      }



                                     } else {
                                                 // Existing customer
                                                 console.log('existing customer');


                                                 if(this.api.isDelivery){
                                                  this.api.checkTipState = true
                                                }
                                                if (this.api.method.mtype === "PICKUP") {
                                                if (this.api.checkTipState || !this.api.isDelivery)  {
                                                   // Pick up orders

                                                      if (this.api.isShowTipState) {

                                                        // show Tip State
                                                        this.api.nameState();

                                                      } else {
                                                        // with out Tip State
                                                        console.log('dont show tip state');


                                                      }

                                                 }} else {

                                                        // delivery order
                                                        this.api.nameState();
                                                 }


                                              }


                      }

                     }





/*

    if(!(this.api.isPayOnline)) {
      // PayOnline is False
      if(!(this.api.isPayAtDoor)) {
        //Pay at door is False
        if(this.api.isPayAtStore) {
          // Pay at store is TRUE
              // Next Customer Name State
            console.log('ONLY PAY AT STORE - TIP (back) state')
            this.api.isContact = true;
        }
      } else {
            console.log('ONLY PAY AT DOOR - TIP (back) state')
            this.api.isContact = true;

      }
    }
*/

/*
      if(this.api.pinState === '0'){
        console.log('new customer - tip')
        if(this.api.isDelivery) {
          console.log('delivery order - tip state ')
          if(!(this.api.isCashEligible)) {
            console.log('NOT cash eligible - tip state')
            this.api.isContact = true;

          } else {
            console.log('cash eligble  - tip state')
            this.api.isTip = true;
            this.api.payOnline = true;
            this.api.payOption = false;
            this.payatStore = true;
          }


        }else {
          console.log('pickup order  - tip state')
          if(!(this.api.isCashEligible)) {
            console.log('NOT cash eligible  - tip state')
            this.api.isContact = true;

          } else {
            console.log('cash eligble  - tip state')
            this.api.payOnline = true;
            this.api.payOption = false;
            this.payatStore = true;

          }

        }

      } else {
        console.log('existing customer - tip state')
        if(this.api.isDelivery) {
          console.log('delivery order  - tip state')
          if(!(this.api.isCashEligible)) {
            console.log('NOT cash eligible  - tip state')
            this.api.isContact = true;

          } else {
            console.log('cash eligble  - tip state')
            this.api.payOnline = true;
            this.api.payOption = false;
            this.payatStore = true;
          }

        } else  {
          console.log('pickup order  - tip state')
          if(!(this.api.isCashEligible)) {
            console.log('NOT cash eligible  - tip state')
            this.api.isContact = true;

          } else {
            console.log('cash eligible  - tip state')
            this.api.payOnline = true;
            this.api.payOption = false;
            this.payatStore = true;

          }
        }

      } */




    }


    onBackInstPay() {
      this.api.actionbutton("cash_back",	"PS002")
        this.api.isFirstLoad = false;
        console.log('tip state back btn');
        this.api.clickValue = 'Back Inst Pay';
        this.api.clickAnalytics();
        this.api.isDeleteBtn = true;
        this.api.isEditBtn = true;
        this.api.isAddItemBox = true;

        // console.log('TIP VALUE')
        // console.log('isCashEligible')
        // console.log(this.api.isCashEligible)


      if ((this.api.isPayOnline) && (this.api.isPayAtDoor) && (this.api.isPayAtStore)) {
          console.log('all Are true');
          this.api.payOptionState();

      } else if ((this.api.isPayAtDoor) && (this.api.isPayAtStore)) {
               console.log ('pay at door and pay at store is true');
               this.api.nameState();

           } else if ((this.api.isPayOnline) && (this.api.isPayAtDoor) ) {
                  console.log ('pay online  and pay at door ');
                  this.api.payOptionState();

               } else if ((this.api.isPayOnline) && (this.api.isPayAtStore) ) {
                      console.log('pay online and pay at store');
                      this.api.payOptionState();
                   } else {
                        if (!(this.api.isPayOnline)) {
                          if (!(this.api.isPayOnline)) {
                            // PayOnline is False
                            if (!(this.api.isPayAtDoor)) {
                              // Pay at door is False
                              if (this.api.isPayAtStore) {
                                // Pay at store is TRUE
                                    // Next Customer Name State
                                  console.log('ONLY PAY AT STORE - TIP (back) state');
                                  this.api.nameState();
                              }
                            } else {
                                  console.log('ONLY PAY AT DOOR - TIP (back) state');
                                  this.api.nameState();

                            }
                          }
                        } else {
                                  console.log('online is true');
                                  // online is true
                                  if (this.api.pinState === '0') {
                                         // New customer/ NO PIN set customer
                                         console.log('new customer/ No PIN set customer');

                                         if(this.api.isDelivery){
                                          this.api.checkTipState = true
                                        }
                                        if (this.api.method.mtype === "PICKUP") {
                                        if (this.api.checkTipState || !this.api.isDelivery) {
                                          // Pick up orders

                                             if (this.api.isShowTipState) {

                                               // show Tip State
                                               console.log('show tip state');
                                               this.api.setPasswordState();

                                             } else {
                                               // with out Tip State
                                               console.log('dont show tip state');


                                             }

                                        }} else {

                                               // delivery order
                                               console.log('delivery order');
                                               this.api.setPasswordState();
                                        }



                                       } else {
                                                   // Existing customer
                                                   console.log('existing customer');


                                                   if(this.api.isDelivery){
                                                    this.api.checkTipState = true
                                                  }
                                                  if (this.api.method.mtype === "PICKUP") {
                                                  if (this.api.checkTipState || !this.api.isDelivery)  {
                                                     // Pick up orders

                                                        if (this.api.isShowTipState) {

                                                          // show Tip State
                                                          this.api.nameState();

                                                        } else {
                                                          // with out Tip State
                                                          console.log('dont show tip state');


                                                        }

                                                   }}else {

                                                          // delivery order
                                                          this.api.nameState();
                                                   }


                                                }


                        }

                       }


    }


    onBackPin() {
      this.api.actionbutton("save_pin_back",	"PS002")
      this.api.clickValue = 'Back_PIN Payment';
      this.api.clickAnalytics();
    if ((this.api.isPayOnline) && (this.api.isPayAtDoor) && (this.api.isPayAtStore)) {
        console.log('all Are true');
        if(this.api.isDelivery){
          this.api.checkTipState = true
        }
        if (this.api.method.mtype === "PICKUP") {
        if (this.api.checkTipState || !this.api.isDelivery){
          // Pickup order

        if (this.api.isPayAtStoreEligible) {
          // pay at store eligble
          this.api.payOptionState();
        } else {
          // pay at store NOT eligible
          this.api.nameState();
        }

        }} else {
          // delivery order
          if (this.api.isPayAtDoorEligible) {
            // Pay at door eligible
            this.api.payOptionState();
          } else {
            // Pay at DOOR NOT ELIgible
            this.api.nameState();
          }

        }



    } else if ((this.api.isPayAtDoor) && (this.api.isPayAtStore)) {
      console.log ('pay at door and pay at store is true');
         } else if ((this.api.isPayOnline) && (this.api.isPayAtDoor) ) {
              console.log ('pay online  and pay at door ');
              if(this.api.isDelivery){
                this.api.checkTipState = true
              }
              if (this.api.method.mtype === "PICKUP") {
              if (this.api.checkTipState || !this.api.isDelivery){
                // Pickup Orders
                this.api.nameState();

              }} else {
                // delivery Orders
                if (this.api.isPayAtDoorEligible) {
                  // Pay at door eligible
                  this.api.payOptionState();

                } else {
                  // pay at door not eligible
                  // Hide TIP state
                  this.api.nameState();
                }
              }

             } else if ((this.api.isPayOnline) && (this.api.isPayAtStore) ) {
                    console.log('pay online and pay at store');
                    if (this.api.isPayAtStoreEligible) {
                      // pay at store eligible
                      this.api.nameState();
                    } else {
                      // pay at store not Eligible
                      this.api.nameState();
                    }
                 } else {
                      if (this.api.isPayOnline) {
                                console.log('online is true');
                                // online is true
                                if (this.api.pinState === '0') {
                                       // New customer/ NO PIN set customer
                                       this.api.nameState();
                                     }

                      }

                     }

    }

    submit(res: NgModel) {
    this.api.tipError=false;
    this.api.tipErrorMessage='';
     this.api.isFirstLoad = false;
     this.api.clickValue = 'Next Tip';
     this.api.clickAnalytics();
     this.api.isDeleteBtn = false;
     this.api.isEditBtn = false;
    this.api.isAddItemBox = false;
  
     // //console.log(res.value)
     // console.log('NEXT - TIP')
     // console.log('isTip')
     // console.log(this.api.isTip)
      this.api.tip = 0.00;
      this.tipVal = parseFloat(res.value.tip_amt);
     
      if (this.tipVal < 0 || this.tipVal=='' || this.tipVal== undefined || this.tipVal== null  || Number.isNaN(this.tipVal)) {
        this.api.tip = 0;
        console.log(this.api.tip);
      } else {
        this.api.tip = this.tipVal;
      }
      if(this.tipVal>(this.api.total*this.api.tipLimit)){
      this.api.tipError=true;
      this.api.tipErrorMessage='The max tip is '+this.api.tipLimit*100+'% of the order total. Please enter a smaller tip and try again.'
      return;
      }
     // //console.log('@TIP')
      //// console.log(this.api.diffCard);
      if (this.api.diffCard) {
        this.api.pinState = '1';
        this.api.isPinset = false;
       // //console.log('IF isPinSet - false')
      } else {
        this.api.isPinset = true;
        //// console.log('Else isPinSet - true ')
      }


if ((this.api.isPayOnline) && (this.api.isPayAtDoor) && (this.api.isPayAtStore)) {
    console.log('all Are true');
    if (this.api.pinState === '0') {
      // New Customer
      this.api.cardPaymentState();
    } else {
      // Existing Customer
      if (!(this.api.isResetPinClicked)) {
        // Reset PIN Not clicked
      this.api.pinPaymentState();
     } else {
      // Reset PIN clicked
      this.api.cardPaymentState();
     }
    }

} else if ((this.api.isPayAtDoor) && (this.api.isPayAtStore)) {
  console.log ('pay at door and pay at store is true');
     } else if ((this.api.isPayOnline) && (this.api.isPayAtDoor) ) {
           console.log ('pay online  and pay at door ');
           if (this.api.pinState === '0') {
             // new customer
             this.api.cardPaymentState();
           } else {
              // existing customer
              if (!(this.api.isResetPinClicked)) {
                this.api.pinPaymentState();
              } else {
                this.api.cardPaymentState();
              }
            }

         } else if ((this.api.isPayOnline) && (this.api.isPayAtStore) ) {
              console.log('pay online and pay at store');
              if (this.api.pinState === '0') {
                // new customer
                this.api.cardPaymentState();
              } else {
                // existing customer
                if (this.api.isResetPinClicked) {
                  this.api.cardPaymentState();
                } else  {
                  this.api.pinPaymentState();
                }

              }
             } else {
                  if (this.api.isPayOnline) {
                            console.log('online is true');
                            // online is true
                            if (this.api.pinState === '0') {
                                   // New customer/ NO PIN set customer
                                   console.log('new customer/ No PIN set customer');

                                   if(this.api.isDelivery){
                                    this.api.checkTipState = true
                                  }
                                  if (this.api.method.mtype === "PICKUP") {
                                  if (this.api.checkTipState || !this.api.isDelivery)  {
                                    // Pick up orders

                                       if (this.api.isShowTipState) {

                                         // show Tip State
                                         this.api.cardPaymentState();


                                       } else {
                                         // with out Tip State
                                         console.log('dont show tip state');


                                       }

                                  }} else {

                                         // delivery order
                                         this.api.cardPaymentState();


                                  }



                                 } else {
                                             // Existing customer
                                             console.log('existing customer');


                                             if(this.api.isDelivery){
                                              this.api.checkTipState = true
                                            }
                                            if (this.api.method.mtype === "PICKUP") {
                                            if (this.api.checkTipState || !this.api.isDelivery)  {
                                               // Pick up orders

                                                  if (this.api.isShowTipState) {

                                                    // show Tip State
                                                    this.api.pinPaymentState();

                                                  }

                                             } }else {

                                                    // delivery order
                                                    this.api.pinPaymentState();

                                             }


                                          }


                  }

                 }



    }

    tipChecker(val) {
      console.log(val);
      if (val.value.length = 4) {
        // tslint:disable-next-line:no-unused-expression
        val.key === ' ';
      }

    }

    cash(value) {
     // //console.log("cash radio btn is clicked");
     if(value=='store'){
      this.api.actionbutton("pay_at_store",	"OS001")
     }
     else{
      this.api.actionbutton("pay_at_door",	"OS003")
     }
     
      this.api.isDeleteBtn = false;
      this.api.isEditBtn = false;
      this.api.isAddItemBox = false;
      this.api.tip = 0.00;
      this.api.payAtStoreState();
      //// console.log('@ PAY AT STORE')
      // console.log(this.api.payOption);
      // console.log(this.api.payOnline);
    }

    credit() {
      this.api.actionbutton("pay_online",	"OS002")
      this.api.isDeleteBtn = false;
      this.api.isEditBtn = false;
      this.api.isAddItemBox = false;
      this.api.pinValidator();

      if ((this.api.isPayOnline) && (this.api.isPayAtDoor) && (this.api.isPayAtStore)) {
        console.log('all Are true');
        if(this.api.isDelivery){
          this.api.checkTipState = true
        }
        if (this.api.method.mtype === "PICKUP") {
        if (this.api.checkTipState || !this.api.isDelivery) {
          // Pickup Order
          if (this.api.pinState === '0') {
            // new Customer
            this.api.setPasswordState();
          } else {
            // Existing Customer
            if (this.api.isShowTipState) {
              // SHow Tip State
              this.api.tipState();

            } else {
              // Dont Show Tip State
              if (this.api.isResetPinClicked) {
                this.api.cardPaymentState();
              } else {
                this.api.pinPaymentState();
              }
            }
          }

        }} else {
          // Delivery order
          if (this.api.pinState === '0') {
            // New Customer
            this.api.setPasswordState();

          } else {
            // Existing Customer
            this.api.tipState();
          }
        }

    } else if ((this.api.isPayAtDoor) && (this.api.isPayAtStore)) {
      console.log ('pay at door and pay at store is true');
         } else if ((this.api.isPayOnline) && (this.api.isPayAtDoor) ) {
                console.log ('pay online  and pay at door ');
                if (this.api.pinState === '0') {
                  // New Customer
                  this.api.setPasswordState();
                } else {
                  // Existing Customer
                  if (this.api.isShowTipState) {
                    // Show Tip State
                    this.api.tipState();

                  } else {
                    // No Tip State
                    this.api.pinPaymentState();
                  }
                }
             } else if ((this.api.isPayOnline) && (this.api.isPayAtStore) ) {
                   console.log('pay online and pay at store');
                   if(this.api.isDelivery){
                    this.api.checkTipState = true
                  }
                  if (this.api.method.mtype === "PICKUP") {
                  if (this.api.checkTipState || !this.api.isDelivery) {
                     // Pickup order
                     if (this.api.isPayAtStoreEligible) {
                       // Pay at store is eligible
                          if (this.api.pinState === '0') {
                            // New customer
                            this.api.setPasswordState();

                          } else {
                            // Existing customer
                              if (this.api.isShowTipState) {
                                // show tip state
                                this.api.tipState();

                              } else {
                                // DONT show tip state
                                if (this.api.isResetPinClicked) {
                                  this.api.cardPaymentState();
                                } else {
                                  this.api.pinPaymentState();
                                }

                              }
                          }


                     } else {
                       // pay at store not eligible
                       if (this.api.pinState === '0') {
                          // New customer/ NO PIN set customer


                       } else {
                                // existing customer
                                 if (this.api.isShowTipState) {
                                   // show tip state


                                 } else {
                                    // NO Tip state

                                 }

                       }
                     }
                   }} else {
                     // delivery order
                     if (this.api.pinState === '0') {
                        // New customer/ NO PIN set customer

                     } else {
                       // existing customer

                     }
                   }


                 }

    }



}


