import { Component, OnInit } from '@angular/core';
import { APICenterService } from '../../apicenter.service';
import { Router } from '@angular/router';
@Component({
  selector: 'home-contact',
  templateUrl: './home-contact.component.html',
  styleUrls: ['./home-contact.component.css']
})
export class HomeContact implements OnInit {
  messageBox=false;
  name="";
  businessName="";
  email=""
  schedule="";
  responseFlag=false;
  constructor(public api : APICenterService, private _router : Router) { }

  ngOnInit() {
  }
  contactSubmit(){
    this.api.ordrAiEnquiry(this.name,this.businessName,this.email,this.schedule);
    this.api.getordrAiEnquiry()
    .subscribe((x) => {
      console.log(x);
      this.messageBox=true;
      if(x.msg==true){
        this.responseFlag=true;
      }
      else{
        this.responseFlag=false;
      }
     
      
    })

    
  }
 disabled(){
   if(this.name!="" && this.name!= undefined && this.name!=null &&
      this.businessName!="" && this.businessName!= undefined && this.businessName!=null &&
      this.email!="" && this.email!= undefined && this.email!=null 
      
   )
   {
     return false;
   }
   else{
     return true;
   }
 }
 tryit(){
  window.open('https://www.txtordr.com/jets/share');

}

home() {
  this._router.navigate(['']);
}
tryquick(){
  window.open('https://www.txtordr.com/quick/jets/mi26');
}

}
