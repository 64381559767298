import { RestContactService } from "./rest-contact.service";

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Subject } from "rxjs";
import { parse } from "path";
import { chain } from "underscore";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    Accept: "*",
    "Access-Control-Allow-Origin": "*",
  }),
};

const httpOptions_lambda = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class APICenterService {
  ordrID = " ";

  audioOptions = {
    headers: new HttpHeaders({
      "Content-Type": "audio/wav",
      value: this.ordrID,
    }),
  };
  faqQuestions = [
    {
      id: "1",
      question: "Sample Text sample text ?",
      answer:
        "Sample Text sample text Sample Text sample text Sample Text sample text Sample Text sample text",
    },
    {
      id: "2",
      question: "Sample Text-2 sample text-2 ?",
      answer:
        "2. Sample Text sample text Sample Text sample text Sample Text sample text Sample Text sample text",
    },
  ];
  chainname: any;
  restName = "";
  tipLimit=1;
  tipError=false;
  tipErrorMessage='';
  isFirstLoad = true;
  isErrorTimer = false;
  isErrorTimerMsg = "";
  punchUserName = "";
  punchPassword = "";
  // Papa Clicks
  timeVal;
  name = "";
  deviceVal;
  browserVal;
  ws: any;
  lastName = "";
  isdemo = false;
  isSaveCard = true;
  msg = null;
  homePageDis = false;
  onGetData = false;
  homePageLoad = false;
  checkoutAllergy = false;
  popupLoginSuccess = false;
  loginSuccess = "";
  loadingGif =
    "https://rptsvr1.tea.texas.gov/perfreport/bi_images/please_wait.gif";
  allergiesList = [
    {
      name: "Egg Allegry",
      id: "egg",
      description:
        "I acknowledge I am responsible for removing specific allergens by customizing this order.",
      value: false,
    },
    {
      name: "Milk/Diary Allegry",
      id: "diary",
      description:
        "I acknowledge I am responsible for removing specific allergens by customizing this order.",
      value: false,
    },
    {
      name: "Peanuts Allegry",
      id: "peanut",
      description:
        "I acknowledge I am responsible for removing specific allergens by customizing this order.",
      value: false,
    },
    {
      name: "Tree Nuts Allegry",
      id: "treenut",
      description:
        "I acknowledge I am responsible for removing specific allergens by customizing this order.",
      value: false,
    },
    {
      name: "None",
      id: "none",
      description: "I don't have any allergies",
      value: false,
    },
  ];

  // Packets to backend
  sendPacket;
  homePagePacket;
  sendPin;
  vPin;
  socketmessage = "";
  disaplyVar = "Listening...";
  isTimer = 0.8;
  finalVoiceText = "";
  textVar = "";
  payPacket;
  sendAd;
  updatePacket;
  UIOrdrPacket;
  addCardPacket;
  receiptPacket;
  restInfoPacket;
  checkPhonePacket;
  schedulerPacket;
  clickInfoPacket;
  faxGenerationPacket;
  textFeedbackPacket;
  emojiFeedbackPacket;
  papaClickPacket;
  micInfoPacket;
  posPackets;
  quickOrdrPacket;
  isOnlyPayment = false;

  isEditItemBox = false;
  isAddItemBox = true;
  isSummaryTitle: boolean;
  isTotalBox: boolean;

  rewards = {
    availablePoints: "",
    afterRedemption: "",
    items: [],
    code: "",
    savings: "",
    isPoints: false,
    isRewards: false,
    isRewardsSelected: false,
  };

  // Customer Info
  customer = {
    cash_eligible: 5,
    cash_opted: " ",
    cid: " ",
    msg_text: " ",
    msg_type: " ",
    lastFour: " ",
    cardType: " ",
    name: null,
    custCode: " ",
    showDisclaimer: false,
    isPwd: false,
    lastName: "",
  };

  dropin = "braintree";
  preferredProcessor = "braintree";

  // Restaurant Info
  restaurant = {
    // #f63440
    futureMktDiscounts: "Your next 5 text orders are 10% Off!",
    refFrndDiscount: "Get $5 OFF every time you refer a friend!",
    logo: " ",
    color: " ",
    rid: " ",
    isUpsize: false,
    isUpsell: false,
    isReferFrnd: false,
    name: " ",
    address: " ",
    restCode: " ",
    menuPic:
      "https://s3.us-east-2.amazonaws.com/ordr-assets/menu/jets_menu.jpg",
  };

  // Method of order - needed address and type of order
  method = {
    maddress: " ",
    mtype: " ",
    fOrder: " ",
    maddress_db: " ",
  };

  isFutureOrder = true;

  // Upsell
  isUpsell = false;
  isUpsize = false;

  upSell = {
    isItem1: true,
    isItem2: true,
    isItem3: true,
    isItem4: true,
  };

  upSellItemUrl = {
    item1:
      "https://ordr-assets.s3.us-east-2.amazonaws.com/upsell/pj/Cheesesticks+Large+Text.jpg",
    item2:
      "https://ordr-assets.s3.us-east-2.amazonaws.com/upsell/pj/Cookie+large+text.jpg",
    item3:
      "https://ordr-assets.s3.us-east-2.amazonaws.com/upsell/pj/Pepsi+Large+Text.jpg",
    item4:
      "https://ordr-assets.s3.us-east-2.amazonaws.com/upsell/pj/Popper+large+text.jpg",
  };

  upSellItemName = {
    item1: "Added: Cheesesticks", // 10 inch cheesesticks
    item2: "Added: Chocolate Chip Cookies", // Chocolate Chip Cookies
    item3: "Added: 2 Liter Pepsi", // 2 liter
    item4: "Added: 10pc Chicken Poppers", // 10 pcs chhicken poppers
  };

  upsellAddString = {
    item1: "10 inch cheesesticks",
    item2: "Chocolate Chip Cookies",
    item3: "2 Liter Pepsi",
    item4: "10 pcs chhicken poppers",
  };

  isShowUpsell = false;
  onUpSellSelect: boolean;

  // tslint:disable-next-line:max-line-length
  // restArr = ['Next - Dominon\'s',  'Next - McDonalds',  'Next -  Subway', 'Next - StarBucks', 'Next - Papa John\'s', 'Next - FiveGuys', 'Next - JimmyJohn\'s', 'Next - Qdoba', 'Next - Domino\'s' ]
  // tslint:disable-next-line:max-line-length
  // restArr = ['Next - McDonalds', 'Next - Five Guys', 'Next - Domino\'s', 'Next - Papa John\'s', 'Next -  Subway', 'Next - Starbucks', 'Next - Jimmy John\'s', 'Next - Qdoba', 'Next - McDonalds'];
  restArr = [
    "Next - McDonalds",
    "Next - Five Guys",
    "Next - Papa John's",
    "Next -  Subway",
    "Next - Jimmy John's",
    "Next - Qdoba",
    "Next - McDonalds",
  ];

  nextRest: String;
  curRestNum: any;
  isWing = true;
  isBread = true;
  isPoppers = true;
  isPop = true;
  isDessert = true;
  isFries = true;
  isWater = true;
  isChips = true;
  isCof1 = true;
  isCof2 = true;
  isPickle = true;

  isError = false;
  isSummaryDemo = false;
  todayText = "*";

  // imgAddTxt = [ ]
  // imgUrl= [ ]

  imgAddTxt = [
    "10 inch cheesesticks",
    "Chocolate Chip Cookies",
    "2 Liter Pepsi",
    "10 pcs chhicken poppers",
  ];
  imgUrl = [
    "https://ordr-assets.s3.us-east-2.amazonaws.com/upsell/pj/Cheesesticks+Large+Text.jpg",
    "https://ordr-assets.s3.us-east-2.amazonaws.com/upsell/pj/Cookie+large+text.jpg",
    "https://ordr-assets.s3.us-east-2.amazonaws.com/upsell/pj/Pepsi+Large+Text.jpg",
    "https://ordr-assets.s3.us-east-2.amazonaws.com/upsell/pj/Popper+large+text.jpg",
  ];

  resetImgAddTxt = [];
  resetImgUrl = [];
  imgTag = ["item1", "item2", "item3", "item4"];
  // Dollar values for the logisitics
  delivery_fee = "0.00";
  saving = "0.00";
  serviceFee = "0.00";
  subtotal = "0.00";
  tax = "0.00";
  total = 0;
  tip: any = 0.0;
  itemsTotal = "0.00";
  grandTotal = " ";
  calcTaxnFee: any = 0.0;
  calcServiceFee = 0.0;

  // Paid Dollar values
  paidSavings = "0.00";
  paidServiceFee = "0.00";
  paidSubTotal = "0.00";
  paidTax = "0.00";
  paidTotal = "0.00";
  paidTip = "0.00";
  paidDeliveryFee = "0.00";
  paidCouponName = " ";

  keywordResult = " ";

  pickupText;

  isPaid;
  closedOrdrId;
  isStoreOpen;

  // Device Info
  isMobile: boolean;
  isTablet: boolean;
  isDesktopDevice: boolean;
  deviceName: string;
  deviceOs: string;

  // Booleans for state management
  isSummary = true;
  isLoading: Boolean;
  isEdit = false;
  isEdit_ed = true;
  isState_0 = false;
  isState_1 = false;
  isState_2 = false;
  isState_3 = false;
  tipDisplay = false;
  setPIN = false;
  isSuccess = false;
  isUserPin = false;
  isFirstWrong = false;
  isSecondWrong = false;
  isThridWrong = false;
  pinValue = " ";
  isPickupVal;
  onSend = false;
  isDelivery;
  checkTipState = true;
  storeName = "";
  editStateAddress = " ";
  storeAddress = " ";
  pinState = " ";
  deliveryAddress = "123 Main st. Warren Rd, MI, 48576.";
  isPickup = true;
  counter = "0";
  pinPayment = "empty";
  passwordPayment = "empty";
  pinMsg = false;

  isMktOptIn = true;
  showMktOptIn: boolean;

  couponName;

  isMic = false;

  isSticky = false;
  isDeleteBtn = true;
  isEditBtn = true;

  isOrdrSummary;

  // Frequently bought together:
  fbt = {
    wings: "",
    pop: "",
    bread: "",
  };

  isContact = false;
  isCompSummary = false;
  isMethod = false;
  isPinset = false;
  isTip = false;
  isPaymentSuccess = false;
  isPinPayment = false;
  isBt = false;
  payOnline = true;
  payOption = false;
  payatStore: boolean;
  isDisclaimer = false;
  userSelectionDisClaimer = true;
  showTotal = false;
  curbData: any = {
    make: "",
    model: "",
    color: "",
  };

  isUIOrdrStart: boolean;

  isPinStored = false;
  isBraintree;
  pinFailure = false;
  curbside = false;
  clickAanlysis: Array<string> = [];
  clickValue: string;

  isColg: boolean;

  ppTitle = [];
  // Items list JSON from the backend
  qty = [];
  items = [];
  toppings = [];
  amounts = [];
  itemNames = [];
  sizes = [];
  cursts = [];
  firstHalf = [];
  secondHalf = [];
  bake = [];
  crustTopper = [];
  sauce = [];
  bun = [];
  tenderPrep = [];
  syrupChoice = [];
  silverware = [];
  pieSlice = [];
  remove = [];
  proteinChoice = [];
  meatChoice = [];
  iceCreamFlavor = [];
  fixins = [];
  eggPrep = [];
  dippingSauce = [];
  dairy = [];
  sauceType = [];
  drinks = [];
  prep = [];
  chips = [];

  pasta = [];
  condiments = [];
  buttersAndJams = [];
  breadChoice = [];
  addOns = [];
  addCheese = [];
  threeSelections = [];
  twoSides = [];
  beverageChoice = [];
  reciepe = [];
  sides = [];
  flavor = [];
  dressing = [];
  seasoning = [];
  drizzle = [];
  fry = [];
  bread = [];
  allergies = [];
  drink = [];
  side = [];
  additionals = [];
  cheese = [];
  sauceBase = [];
  dipping = [];
  dip = [];

  gravy = [];
  tenderChoice = [];
  enchilada = [];
  guacamole = [];
  sidetwo = []; //Side_two
  quesadilla = [];
  soup = [];
  burrito = [];

  style = [];
  cut = [];
  calories = [];
  addies = [];
  defaults = [];
  kidsDrink = [];
  sideTortilla = [];
  extra = [];
  required = [];
  wingType = [];
  isRewardsArr = [];
  rewardCodeArr = [];
  seasoningPackets = [];
  isUpSize = [];
  upSizeDisplayString = [];
  upSizeText = [];
  milk = [];
  crustFlip = [];
  temp = [];
  wingDip = [];
  caf = [];
  options = [];
  sideBread = [];
  isProOrder: boolean = false;
  tacoTortilla = [];
  lettuce = [];
  yours = [];

  notes = [];
  meatTopping = [];
  veggieTopping = [];
  shell = [];
  breadtype = [];
  tortilla = [];
  rice = [];
  beans = [];
  protein = [];
  salsa = [];
  queso = [];
  espresso = [];
  meat = [];
  piece = [];
  onSide = [];
  modNA = [];

  notApplicable = [];
  keywordText = "0";
  errorText = "0";
  irrelevantText = "0";
  naItems = "0";

  tNum: string;
  isFullFooter = true;

  // PIN components
  enteredPin: string;
  displayNum;
  pin_2;
  isCash;
  isCashEligible;
  diffCard = false;
  editScore = 0;
  checkScore = 0;
  nameScore = 0;

  isFailure = false;
  onChangeLink = false;

  // Recepit
  rCustId: string;
  rOrderId: string;
  paymentPin = "";
  paymentPwd: string;

  // Referral a friend
  referralCustCode: string;
  refCode = "ZREFAB12BB";
  refFrndContact: string;
  isRefFrndContact: boolean;
  isRefCustomer = false;
  storeCode = "mi26";

  onSubmit;

  tempFix = false;
  infoMsg = false;
  userName = null;
  CustdisplayNum;
  paidTime;
  panelData = "2019-01-15";

  itemsMarker = [];
  data;
  menuRestId;
  date;
  currentDate;
  regex = /deliv.*/gi;

  isShowMethodToggle = false;

  // FeedBacks
  fbEmojiText: string;
  fbUserText: string;

  // Mic Dataclic
  blobData;
  printAudioText;

  rec = {
    oid: null,
    rid: null,
    cid: null,
  };

  /// Default Tip
  tempTotal;
  tipVal15;
  isDefaultTip = false;

  // Chabot performance time in seconds
  performanceTime;
  ordrType = "text";

  /// Switch flow variables
  isPayAtStoreEligible: boolean;
  isPayAtDoorEligible: boolean;
  isTipDelivery = true;

  isPayAtStore = true;
  isPayOnline = true;
  isPayAtDoor = true;

  isShowTipState = true;
  isResetPinClicked = false;
  isDiffCardClicked = false;
  isContactFree = false;
  contactLessTxt: string;

  splInstText = null;
  isSplInst = false;

  isOrdrAdmin = false;
  tempTip;
  managegroups;
  /// Quick Order
  quickOrder = {
    isPickup: true,
    isDelivery: false,
    address: null,
    text: null,
    fullText: null,
    restId: null,
    isQuickName: false,
    cusTxt: null,
    custId: null,
    isAPt: false,
  };
  time = false;
  currentState = {
    viewState: false,
    isContact: false,
    isTip: false,
    payatStore: false,
    payOption: false,
    payOnline: false,
    isPinStored: false,
    isBt: false,
    isBraintree: false,
  };

  isSwapMethod = false;
  puchLoggedin = false;

  isMethodswap = false;
  isMethodChanged = false;
  isMethodDisplay = false;

  currentStateArr = [];

  isFraud = false;
  fraudCounter = 0;
  fraudcounterLimit = 7;

  isMethodBox = true;
  // rewards: any;
  ETAResponse = {
    Delivery: "*",
    PickUp: "*",
  };

  ETA = "10";
  primaryColor = "";

  // Server Address
  // B Server
  // address = 'https://tutalcs.com/';

  // development Server
  // address = 'https://tutalme.com/';

  // NAT Address
  // address = "http://lb-nat-dev-1293214280.us-east-2.elb.amazonaws.com/"

  // Demo Serv
  // address = 'https://ordr-demo.com/';

  // address = 'https://ordr-demo.com/';

  // Production Server
  address = "https://www.tutalec.com/";
  address1 = "https://www.ordradminbk.com/";

  // Quick Order Test - Prod
  // address = 'http://18.224.56.83/';

  // Ordr DEV server

  // address = 'http://3.22.209.201/'

  // Local server
  // address = 'http://localhost:5000/';

  // txt monitor dev
  // address = 'http://18.190.37.172/';

  // Pizza Hut
  // address = 'http://3.134.96.156/';

  //
  // address = 'http://3.15.138.187/';

  // Revention Test
  // address = 'http://18.190.145.89/'

  // Prod Admin Server
  //address = 'http://18.216.196.164/';

  //reporting test server
  //address= 'http://3.15.238.96/'

  // Test Prod Server
  // address = 'http://18.190.49.238/';

  private postUpdated = new Subject<any>();
  private postSummaryUpdated = new Subject<any>();
  private postRestinfoUpdated = new Subject<any>();
  private postIsRefFrnd = new Subject<any>();
  private postHomePage = new Subject<any>();
  private postUIOrdr = new Subject<any>();
  private postQUickOrdr = new Subject<any>();
  private postPOS = new Subject<any>();
  private getName = new Subject<any>();
  private LoginInfo = new Subject<any>();
  private postordrAiEnquiry = new Subject<any>();
  private postSpeedlineInfo = new Subject<any>();
  private LoginInfoNew = new Subject<any>();
  private resetInfoNew = new Subject<any>();
  private resetNew = new Subject<any>();
  private distHistory = new Subject<any>();
  private uploadDisbursement = new Subject<any>();
  private profileUpdate = new Subject<any>();
  private curbInformation = new Subject<any>();
  private feedbackInformation = new Subject<any>();
  private feedbackInformationPost = new Subject<any>();
  private updateCurbInformation = new Subject<any>();
  private gaCounterInformation = new Subject<any>();
  private worldPayTransation = new Subject<any>();
  private worldPaySuccessData = new Subject<any>();
  private OnSubmitWorldPayIframeData = new Subject<any>();
  private OnWorldPayNewCustomerData = new Subject<any>();
  private WorldPayReturnCustomerData = new Subject<any>();
  private curbInfoData = new Subject<any>();
  private faqInfoData = new Subject<any>();
  private updateAllergyData = new Subject<any>();
  private saveNameData = new Subject<any>();
  private rewardsDataData = new Subject<any>();
  private rewardsLoginData = new Subject<any>();
  private resetPunchhPasswordData = new Subject<any>();
  private validatePunchhOTPData = new Subject<any>();
  private updatePunchhPasswordData = new Subject<any>();
  private punchhSignoutData = new Subject<any>();
  private summaryData = new Subject<any>();
  private manageDetails = new Subject<any>();
  animals = [];

  constructor(private http: HttpClient, private rest: RestContactService) {}
  getsummaryData() {
    return this.summaryData.asObservable();
  }
  getFaqInfo() {
    return this.faqInfoData.asObservable();
  }
  getUpdateAllergy() {
    return this.updateAllergyData.asObservable();
  }
  getUploadDisbursement() {
    return this.uploadDisbursement.asObservable();
  }
  getLoginInfoNew() {
    return this.LoginInfoNew.asObservable();
  }
  getpasswordResetNew() {
    return this.resetInfoNew.asObservable();
  }
  getResetNew() {
    return this.resetNew.asObservable();
  }

  getUpdatedPost() {
    return this.postUpdated.asObservable();
  }

  getIsRefFrnd() {
    return this.postIsRefFrnd.asObservable();
  }
  getordrAiEnquiry() {
    return this.postordrAiEnquiry.asObservable();
  }
  getUpdateSummary() {
    return this.postSummaryUpdated.asObservable();
  }
  updateDelivery() {
    return this.postSummaryUpdated.asObservable();
  }
  updatePickup() {
    return this.postSummaryUpdated.asObservable();
  }
  profileupdateGet() {
    return this.profileUpdate.asObservable();
  }
  getUpdatedRestInfo() {
    return this.postRestinfoUpdated.asObservable();
  }
  getHomePageInfo() {
    return this.postHomePage.asObservable();
  }
  getUpdatedUIOrdr() {
    return this.postUIOrdr.asObservable();
  }
  getPostQuickOrdr() {
    return this.postQUickOrdr.asObservable();
  }
  getPostName() {
    return this.getName.asObservable();
  }
  getPostPOS() {
    return this.postPOS.asObservable();
  }
  getLoginInfo() {
    return this.LoginInfo.asObservable();
  }
  getSpeedlineInfo() {
    return this.postSpeedlineInfo.asObservable();
  }
  getDistHistory() {
    return this.distHistory.asObservable();
  }
  getcurbInfoUser() {
    return this.curbInformation.asObservable();
  }
  getfeedbackInfoUser() {
    return this.feedbackInformation.asObservable();
  }
  getfeedbackInfoUserPost() {
    return this.feedbackInformationPost.asObservable();
  }
  getUpdateCurbInfoUser() {
    return this.updateCurbInformation.asObservable();
  }
  getupdateGaCounter() {
    return this.gaCounterInformation.asObservable();
  }
  getworldPayTransation() {
    return this.worldPayTransation.asObservable();
  }
  getworldPaySuccessData() {
    return this.worldPaySuccessData.asObservable();
  }
  getOnSubmitWorldPayIframe() {
    return this.OnSubmitWorldPayIframeData.asObservable();
  }
  getWorldPayNewCustomer() {
    return this.OnWorldPayNewCustomerData.asObservable();
  }
  getWorldPayReturnCustomer() {
    return this.WorldPayReturnCustomerData.asObservable();
  }
  getcurbInfo() {
    return this.curbInfoData.asObservable();
  }
  getsaveName() {
    return this.saveNameData.asObservable();
  }
  getrewardsData() {
    return this.rewardsDataData.asObservable();
  }
  getrewardsLogin() {
    return this.rewardsLoginData.asObservable();
  }

  getresetPunchhPassword() {
    return this.resetPunchhPasswordData.asObservable();
  }
  getvalidatePunchhOTP() {
    return this.validatePunchhOTPData.asObservable();
  }
  getupdatePunchhPassword() {
    return this.updatePunchhPasswordData.asObservable();
  }
  getpunchhSignout() {
    return this.punchhSignoutData.asObservable();
  }
  getManageDetails() {
    return this.manageDetails.asObservable();
  }
  cpFetchManageDetail(code) {
    let sendPacketLambda = {
      code: code,
    };
    const lambdaAddress = this.address1 + "getLandingPage?restId=" + code;
    let fetchData = this.http
      .get<any>(lambdaAddress, httpOptions_lambda)
      .subscribe((data) => {
        this.manageDetails.next(data);
      });
  }
  punchhSignout(data) {
    let punchsignOut = JSON.stringify(data);
    const localAddress = `${this.address}punchhSignout`;
    this.http
      .post<any>(localAddress, punchsignOut, httpOptions)
      .subscribe((resp) => {
        this.punchhSignoutData.next(resp);
      });
  }
  updatePunchhPassword(data) {
    let punchPassword = JSON.stringify(data);
    const localAddress = `${this.address}updatePunchhPassword`;
    this.http
      .post<any>(localAddress, punchPassword, httpOptions)
      .subscribe((resp) => {
        this.updatePunchhPasswordData.next(resp);
      });
  }
  validatePunchhOTP(data) {
    let punchOTPValidate = JSON.stringify(data);
    const localAddress = `${this.address}validatePunchhOTP`;
    this.http
      .post<any>(localAddress, punchOTPValidate, httpOptions)
      .subscribe((resp) => {
        this.validatePunchhOTPData.next(resp);
      });
  }
  resetPunchhPassword(data) {
    let punchReset = JSON.stringify(data);
    const localAddress = `${this.address}resetPunchhPassword`;
    this.http
      .post<any>(localAddress, punchReset, httpOptions)
      .subscribe((resp) => {
        this.resetPunchhPasswordData.next(resp);
      });
  }
  rewardsLogin(data) {
    let rewards = JSON.stringify(data);
    const localAddress = `${this.address}rewardsSignIn`;
    this.http
      .post<any>(localAddress, rewards, httpOptions)
      .subscribe((resp) => {
        this.rewardsLoginData.next(resp);
      });
  }
  rewardsData(data) {
    // // // console.log("hello");
    this.http
      .get<any>("/assets/json/rewardsData.json", httpOptions)
      .subscribe((resp) => {
        this.rewardsDataData.next(resp);
      });
    /*let rewards = JSON.stringify(data);
    const localAddress = `${this.address}updateName`;
    this.http.post<any>(localAddress, rewards, httpOptions)
      .subscribe(resp => {
        this.rewardsDataData.next(resp);
      });*/
  }
  saveName(data) {
    let saveName = JSON.stringify(data);
    const localAddress = `${this.address}updateName`;
    this.http
      .post<any>(localAddress, saveName, httpOptions)
      .subscribe((resp) => {
        this.saveNameData.next(resp);
      });
  }
  updateAllergy(data) {
    let updateAllergyPacket = JSON.stringify(data);
    const localAddress = `${this.address}updateAllegry`;
    this.http
      .post<any>(localAddress, updateAllergyPacket, httpOptions)
      .subscribe((resp) => {
        this.updateAllergyData.next(resp);
      });
  }
  faqInfo(data) {
    let faqPacket = JSON.stringify(data);
    const localAddress = `${this.address}fetchFaq`;
    this.http
      .post<any>(localAddress, faqPacket, httpOptions)
      .subscribe((resp) => {
        this.faqInfoData.next(resp);
      });
  }
  curbInfo(data) {
    this.UIOrdrPacket = JSON.stringify(data);
    const localAddress = `${this.address}carInfo`;
    this.http
      .post<any>(localAddress, this.UIOrdrPacket, httpOptions)
      .subscribe((resp) => {
        this.curbInfoData.next(resp);
      });
  }
  WorldPayReturnCustomer(data) {
    this.UIOrdrPacket = JSON.stringify(data);
    const localAddress = `${this.address}wpReturnCustomer`;
    this.http.post<any>(localAddress, this.UIOrdrPacket, httpOptions).subscribe(
      (resp) => {
        this.WorldPayReturnCustomerData.next(resp);
      },
      (response) => {
        // // // // console.log("POST call in error", response);
        if (response.status === 500 || 0) {
          // // // // console.log('internal server error')
          this.WorldPayReturnCustomerData.next(response);
        }
      }
    );
  }
  WorldPayNewCustomer(data) {
    this.UIOrdrPacket = JSON.stringify(data);
    const localAddress = `${this.address}wpNewCustomer`;
    this.http.post<any>(localAddress, this.UIOrdrPacket, httpOptions).subscribe(
      (resp) => {
        this.OnWorldPayNewCustomerData.next(resp);
      },
      (response) => {
        // // // // console.log("POST call in error", response);
        if (response.status === 500 || 0) {
          // // // // console.log('internal server error')
          this.OnWorldPayNewCustomerData.next(response);
        }
      }
    );
  }
  OnSubmitWorldPayIframe(data) {
    this.UIOrdrPacket = JSON.stringify(data);
    const localAddress = `${this.address}onSubmitIframe`;
    this.http.post<any>(localAddress, this.UIOrdrPacket, httpOptions).subscribe(
      (resp) => {
        this.OnSubmitWorldPayIframeData.next(resp);
      },
      (response) => {
        // // // // console.log("POST call in error", response);
        if (response.status === 500 || 0) {
          // // // // console.log('internal server error')
          this.OnSubmitWorldPayIframeData.next(response);
        }
      }
    );
  }
  updateGaCounter(id) {
    var d = new Date(),
      minutes;
    var day = d.getDate();
    var month = new Array();
    month[0] = "Jan";
    month[1] = "Feb";
    month[2] = "Mar";
    month[3] = "Apr";
    month[4] = "May";
    month[5] = "Jun";
    month[6] = "Jul";
    month[7] = "Aug";
    month[8] = "Sep";
    month[9] = "Oct";
    month[10] = "Nov";
    month[11] = "Dec";
    var mon = month[d.getMonth()];
    var hours = d.getHours();
    minutes = d.getMinutes();
    var newformat = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    hours + ":" + minutes + " " + newformat;
    let finalvalue =
      day + " " + mon + " " + hours + ":" + minutes + " " + newformat;
    // // // console.log(finalvalue);
    const gaCounterPacket = {
      counter: 1,
      storeCode: id,
      custId: finalvalue,
    };
    var gaInfo = JSON.stringify(gaCounterPacket);
    const localAddress = `${this.address}updateGaCounter`;
    this.http.post<any>(localAddress, gaInfo, httpOptions).subscribe((data) => {
      // // // console.log(data);
      this.gaCounterInformation.next(data);
    });
  }
  updateCurbInfoUser(curbId, custId, restId, carInfo, notes, name, optedin) {
    const updatecurbInfoPacket = {
      curbId: curbId,
      custId: custId,
      restId: restId,
      carInfo: carInfo,
      notes: notes,
      name: name,
      isOptedIn: optedin,
    };
    var UpdateCurbInfo = JSON.stringify(updatecurbInfoPacket);
    const localAddress = `${this.address}updateCurbInfo`;
    this.http
      .post<any>(localAddress, UpdateCurbInfo, httpOptions)
      .subscribe((data) => {
        // // // console.log(data);
        this.updateCurbInformation.next(data);
      });
  }
  feedbackInfoUser(fid) {
    const feedbackInfoPacket = {
      fid: fid,
    };
    var feedbackInfo = JSON.stringify(feedbackInfoPacket);
    const localAddress = `${this.address}getfbInfo`;
    this.http
      .post<any>(localAddress, feedbackInfo, httpOptions)
      .subscribe((data) => {
        // // // console.log(data);
        let chainname = data.restInfo[0].friendlyName.split(" ");
        data["restaurant"] = [];
        data.restaurant["name"] = chainname[1];
        this.restaurant.name = chainname[1];
        this.colourPicker(data);
        this.feedbackInformation.next(data);
      });
  }
  feedbackInfoUserPost(data) {
    var feedbackInfoPost = JSON.stringify(data);
    const localAddress = `${this.address}updatefbInfo`;
    this.http
      .post<any>(localAddress, feedbackInfoPost, httpOptions)
      .subscribe((data) => {
        // // // console.log(data);
        this.feedbackInformationPost.next(data);
      });
  }
  curbInfoUser(curbId) {
    const curbInfoPacket = {
      curbId: curbId,
    };
    var CurbInfo = JSON.stringify(curbInfoPacket);
    const localAddress = `${this.address}getCurbInfo`;
    this.http
      .post<any>(localAddress, CurbInfo, httpOptions)
      .subscribe((data) => {
        // // // console.log(data);
        let chainname = data.restInfo[0].friendlyName.split(" ");
        data["restaurant"] = [];
        data.restaurant["name"] = chainname[1];
        this.restaurant.name = chainname[1];
        this.curbInformation.next(data);
      });
  }
  UploadFile(fileToUpload: File) {
    const endpoint = this.address + "addDisbursment";
    var formData = new FormData();
    formData.set("file", fileToUpload);
    this.http.post<any>(endpoint, formData).subscribe((data) => {
      this.uploadDisbursement.next(data);
    });
  }
  getrestinfo() {
    this.restInfoPacket = JSON.stringify(this.menuRestId);
    const localAddress = `${this.address}getRestinfo`;
    // // // console.log('@API get rest info');
    // // // console.log(this.restInfoPacket);
    this.http
      .post<any>(localAddress, this.restInfoPacket, httpOptions)
      .subscribe((data) => {
        // Restaurant Info
        // // // // console.log('Post return value');
        //  // // // console.log(data);
        this.restaurant.futureMktDiscounts = data.restaurant.futureMktDiscounts;
        this.restaurant.refFrndDiscount = data.restaurant.refFrndDiscount;
        this.restaurant.logo = data.restaurant.logo;
        this.restaurant.address = data.restaurant.address;
        this.restaurant.color = data.restaurant.color;
        this.restaurant.name = data.restaurant.name;
        this.restaurant.rid = data.restaurant.rid;
        this.restaurant.menuPic = data.restaurant.menuPic;
        this.phoneFormat();

        this.postRestinfoUpdated.next(data);
      });
  }

  // Important !!! initial POST request to fetch user information
  postFlask() {
    this.tempFix = false;
    this.infoMsg = false;
    this.isColg = false;
    this.showTotal = false;
    this.sendPacket = JSON.stringify(this.ordrID);
    const localAddress = `${this.address}rec`;
    let sendPacketLambda = {
      orderId: this.ordrID,
      loadType: "fetch",
    };
    const lambdaAddress =
      "https://cbl11za0u5.execute-api.us-east-2.amazonaws.com/prod/getSummary";
    //this.http.post<any>(localAddress, this.sendPacket, httpOptions)
    let fetchData = this.http
      .post<any>(lambdaAddress, sendPacketLambda, httpOptions_lambda)
      .subscribe((data) => {
        data = data["body-json"];
        let keys = Object.keys(data);
        if (keys[0].includes("error")) {
          fetchData.unsubscribe();
          let fetchDataAgain = this.http
            .post<any>(localAddress, this.sendPacket, httpOptions)
            .subscribe((data) => {
              if (
                this.data.rewards.punchh_cust_emailId == "*" ||
                this.data.rewards.punchh_cust_emailId == "" ||
                this.data.rewards.punchh_cust_emailId == null ||
                this.data.rewards.punchh_cust_emailId == undefined
              ) {
                this.loginSuccess = "loggedout";
              } else {
                this.loginSuccess = "loggedin";
              }
              this.fetchWorkFlow(data);
            });
        } else {
          if (
            data.rewards.punchh_cust_emailId == "*" ||
            data.rewards.punchh_cust_emailId == "" ||
            data.rewards.punchh_cust_emailId == null ||
            data.rewards.punchh_cust_emailId == undefined
          ) {
            this.loginSuccess = "loggedout";
          } else {
            this.loginSuccess = "loggedin";
          }
          this.fetchWorkFlow(data);
        }
      });
  }

  fetchWorkFlow(data) {
    this.isTotalBox = true;
    // // // console.log(data);
    this.customerInfo(data);
    this.methodInfo(data);
    this.customerCardInfo(data);
    this.restaurantInfo(data);
    this.orderStatus(data);
    this.dollarValue(data);
    this.puchLoggedin = data.isPunchhLoggedIn;
    this.isContactFree = data.method.contactLess;
    this.contactLessTxt = data.method.specialInstruc.replace(this.regex, "");
    this.ETAResp(data);
    this.addressFromDb(data);
    this.paidDollarValue(data);
    this.itemsInfo(data);
    this.rewardsInfo(data);
    this.taxnfee(data);
    this.helperFunctions();

    if (this.restaurant.isUpsell) {
      this.upsellValidator();
    }
    this.colourPicker(data);
    // this.passwordChangefix();
    // Subject to make the data available as observable
    this.postUpdated.next(data);
  }

  colourPicker(data) {
    let name = data.restaurant.name.replace("'", "").toLowerCase();
    this.chainname = name;
    if (name == "jets") {
      this.primaryColor = "#00ab4e";
    } else if (name == "marcos") {
      this.primaryColor = "#FED400";
    } else if (name == "howies") {
      this.primaryColor = "#ec0029";
    } else if (name == "romeos") {
      this.primaryColor = "#2E7B47";
    } else if (name == "pizzaworld") {
      this.primaryColor = "#038763";
    } else if (name == "blaze") {
      this.primaryColor = "#c9500f";
    } else if (name == "smoothieking" || name == "smoothie king") {
      this.primaryColor = "#b5121b";
    } else if (name == "sammys") {
      this.primaryColor = "#C60303";
    } else if (name == "centercourt") {
      this.primaryColor = "#191d28";
    } else if (name == "gattis") {
      this.primaryColor = "#005958";
    } else if (name == "gioninos") {
      this.primaryColor = "#00735F";
    } else if (name == "eastofchicago") {
      this.primaryColor = "#27A8B4";
    } else if (name == "coolrunnings") {
      this.primaryColor = "#47A644";
    } else if (name == "hungerrush") {
      this.primaryColor = "#0E8476";
    } else if (name == "wayback") {
      this.primaryColor = "#262626";
    } else if (
      name == "bcpizza" ||
      name == "b.c.pizza" ||
      name == "bc pizza" ||
      name == "bc"
    ) {
      this.primaryColor = "#E8BA00";
    } else if (name == "freebirds") {
      this.primaryColor = "#FF8000";
    } else if (name == "mannyolgas") {
      this.primaryColor = "#c9242a";
    } else if (name == "giovannis") {
      this.primaryColor = "#c91a21";
    } else if (name == "blackjack") {
      this.primaryColor = "#f91820";
    } else if (name == "extreme") {
      this.primaryColor = "#bf2f38";
    } else if (name == "wingstogo") {
      this.primaryColor = "#ea1c2a";
    } else if (name == "chowly") {
      this.primaryColor = "#1B51A4";
    } else if (name == "checkmate" || name == "itsacheckmate") {
      this.primaryColor = "#23a69a";
    } else if (name == "simplesimon") {
      this.primaryColor = "#b5121b";
    } else if (name === "LittleCaesars" || name === "littlecaesars") {
      this.primaryColor = "#ff6000";
    } else if (name === "fajitapetes") {
      this.primaryColor = "#921016";
    } else if (name == "defelicepizza" || name == "defelicebros") {
      this.primaryColor = "#2F8E53";
    } else if (name == "paparomanos") {
      this.primaryColor = "#EA1D2C";
    } else if (name == "pizzacottage") {
      this.primaryColor = "#ca1c1e";
    } else if (name == "joseppispizza") {
      this.primaryColor = "#f20c0c";
    }else if (name=='northernlights'){
      this.primaryColor='#76bd43'
    }
    else {
      this.primaryColor = "#00ab4e";
    }
    const style = document.createElement("style");
    style.innerHTML =
      `
:root {
--primaryColor:` +
      this.primaryColor +
      `
}
`;
    document.head.appendChild(style);
  }
  // !! important POST route to get updated response from the server
  updateUserMsg() {
    const updateList = {
      ordrId: this.ordrID,
      custId: this.customer.cid,
      restId: this.restaurant.rid,
      msg: this.customer.msg_text,
    };
    // // // console.log('Before hit');
    // // // console.log(this.currentState);
    this.tempFix = false;
    this.infoMsg = false;
    this.isColg = false;
    this.isShowUpsell = false;
    this.isOrdrSummary = false;
    this.updatePacket = JSON.stringify(updateList);
    // // // console.log('update msg');
    // // // console.log(this.updatePacket);
    const localAddress = `${this.address}updateUserText`;
    const t0 = performance.now();
    let sendPacketLambda = {
      custId: this.customer.cid,
      restId: this.restaurant.rid,
      msg: this.customer.msg_text,
      orderId: this.ordrID,
      loadType: "update",
    };
    let username = localStorage.getItem("punchUserName");
    let password = localStorage.getItem("punchPassword");
    if (username != "" && username != undefined) {
      sendPacketLambda["userId"] = username;
    }
    if (password != "" && password != undefined) {
      sendPacketLambda["password"] = password;
    }
    const lambdaAddress =
      "https://cbl11za0u5.execute-api.us-east-2.amazonaws.com/prod/getSummary";
    //this.http.post<any>(localAddress, this.sendPacket, httpOptions)
    let update = this.http
      .post<any>(lambdaAddress, sendPacketLambda, httpOptions_lambda)
      // let update=this.http.post<any>(localAddress, this.updatePacket, httpOptions)
      .subscribe((data) => {
        data = data["body-json"];
        if (data.rewards.punchh_cust_emailId == "*") {
          this.loginSuccess = "loggedout";
        } else {
          this.loginSuccess = "loggedin";
        }
        this.summaryData.next(data);
        let keys = Object.keys(data);
        if (keys[0].includes("error")) {
          update.unsubscribe();
          this.http
            .post<any>(localAddress, this.updatePacket, httpOptions)
            .subscribe((data) => {
              this.updateWorkFlow(data);
            });
        } else {
          this.updateWorkFlow(data);
        }
      });
  }

  /**
   * 
   * (response) => {
          // // // // console.log("POST call in error", response);
          if (response.status === 500 || 0) {
            // // // // console.log('internal server error')
            this.isError = true;
          }
        }
   */

  updateWorkFlow(data) {
    this.isOrdrSummary = true;
    // // // console.log('@update_User_Txt')

    // // // // console.log("ChatBot Reply" + this.performanceTime + " Seconds.")
    // // // console.log('@api update ');
    // // // console.log(data);
    this.onSend = false;
    this.isTotalBox = true;
    this.isSummaryTitle = true;
    this.isEditItemBox = false;
    this.isShowUpsell = true;
    this.isMethodBox = true;
    this.isSticky = false;
    this.customerInfo(data);
    this.customerCardInfo(data);
    this.puchLoggedin = data.isPunchhLoggedIn;
    this.isContactFree = data.method.contactLess;
    // this.contactLessTxt = data.method.specialInstruc.replace(this.regex, '');
    this.methodInfo(data);
    // this.ETAResp(data);
    this.dollarValue(data);
    this.itemsInfo(data);
    this.rewardsInfo(data);
    this.taxnfee(data);
    if (this.restaurant.isUpsell) {
      this.upsellValidator();
    }

    //this.restaurantInfo(data);
    this.ordrAdmin();

    if (this.isTip || this.isBt) {
      this.isDeleteBtn = false;
      this.isEditBtn = false;
      this.isAddItemBox = false;
    } else {
      this.isDeleteBtn = true;
      this.isEditBtn = true;
      this.isAddItemBox = true;
    }
    // this.passwordChangefix();

    if (this.isSwapMethod) {
      // // // console.log('current state');
      // // // console.log(this.currentState);
      this.methodSwap(data);
    }
    this.helperFunctions();
    this.isShowTipState = data.restaurant.isShowTipState;
    if (this.method.mtype === "PICKUP") {
      this.isShowTipState = data.restaurant.isShowTipStatePickup;
    } else {
      this.isShowTipState = data.restaurant.isShowTipStateDelivery;
    }

    this.postSummaryUpdated.next(data);

    this.curbData.make = data.curbCarInfo.make;
    this.curbData.model = data.curbCarInfo.model;
    this.curbData.color = data.curbCarInfo.carColor;
    if (data.curbCarInfo.make == "*") {
      this.curbData.make = "";
    }
    if (data.curbCarInfo.model == "*") {
      this.curbData.model = "";
    }
    if (data.curbCarInfo.carColor == "*") {
      this.curbData.color = "";
    }
    if (
      this.curbData.make != "" &&
      this.curbData.make != undefined &&
      this.curbData.model != "" &&
      this.curbData.model != undefined &&
      this.curbData.color != "" &&
      this.curbData.color != undefined
    ) {
      this.curbside = true;
    }
  }

  worldPaySuccess(data) {
    // // // console.log(data);
    this.UIOrdrPacket = JSON.stringify(data);
    const localAddress = `${this.address}wpOnSubmit`;
    this.http
      .post<any>(localAddress, this.UIOrdrPacket, httpOptions)
      .subscribe((data) => {
        this.worldPaySuccessData.next(data);
      });
  }
  worldPayTracationId(data) {
    // // // console.log(data);
    this.UIOrdrPacket = JSON.stringify(data);
    const localAddress = `${this.address}loadIframe`;
    this.http
      .post<any>(localAddress, this.UIOrdrPacket, httpOptions)
      .subscribe((data) => {
        this.worldPayTransation.next(data);
      });
  }

  updateSpInst(orderId, Inst) {
    const splPacket = {
      orderId: orderId,
      splInst: Inst,
    };
    // // // console.log(splPacket);
    this.UIOrdrPacket = JSON.stringify(splPacket);
    const localAddress = `${this.address}fetchSplInst`;
    this.http
      .post<any>(localAddress, this.UIOrdrPacket, httpOptions)
      .subscribe((data) => {
        // // // console.log(data);
      });
  }

  genUIOrdr() {
    const UIOrdr = {
      custId: this.customer.cid,
      restId: this.restaurant.rid,
      msg: this.customer.msg_text,
    };
    // // // // console.log(UIOrdr)
    this.UIOrdrPacket = JSON.stringify(UIOrdr);
    const localAddress = `${this.address}genUIOrdr`;
    this.http
      .post<any>(localAddress, this.UIOrdrPacket, httpOptions)
      .subscribe((data) => {
        this.ordrID = data.id;

        this.postUIOrdr.next(data);
      });
  }

  ordrAiEnquiry(name, businessName, email, schedule) {
    const data = {
      Name: name,
      businessName: businessName,
      email: email,
      schedule: schedule,
    };
    const ordrAiEnquiryPacket = JSON.stringify(data);
    const localAddress = `https://www.tutalec.com/ordrAiEnquiry`;
    this.http
      .post<any>(localAddress, ordrAiEnquiryPacket, httpOptions)
      .subscribe((data) => {
        this.postordrAiEnquiry.next(data);
      });
  }

  getReceipt() {
    const receiptList = {
      ordrId: this.rOrderId,
      custId: this.rCustId,
    };
    this.receiptPacket = JSON.stringify(receiptList);
    const localAddress = `${this.address}receipt`;
    this.http
      .post<any>(localAddress, this.receiptPacket, httpOptions)
      .subscribe((data) => {
        const regex = /deliv.*/gi;
        // // // console.log(data);
        this.customerInfo(data);
        // this.restaurantInfo(data);
        this.paidDollarValue(data);
        this.paidDeliveryFee = data.paidDollarValue.paidDeliveryFee;

        this.itemsInfo(data);

        // Restaurant Info
        this.restaurant.futureMktDiscounts = data.restaurant.futureMktDiscounts;
        this.restaurant.refFrndDiscount = data.restaurant.refFrndDiscount;
        this.restaurant.logo = data.restaurant.logo;
        this.restaurant.address = data.restaurant.address;
        this.restaurant.color = data.restaurant.color;
        this.restaurant.name = data.restaurant.name;
        this.restaurant.rid = data.restaurant.rid;
        this.isSplInst = data.splInst != "*" ? true : false;
        this.splInstText = data.splInst;
        this.phoneFormat();

        // Order Placed time
        this.paidTime = data.paidTime;

        this.methodInfo(data);
        this.taxnfee(data);
        this.helperFunctions();

        // customer Info
        // this.customer.cash_eligible = data.customer.cash_eligible;

        this.customer.cash_opted = data.customer.cash_opted;
        this.customer.cid = data.customer.id;
        this.customer.msg_type = data.customer.msg.type;
        this.pickupText = data.customer.msg.text.replace(regex, "");
        this.customer.msg_text = data.customer.msg.text.replace(regex, "");

        this.pinState = data.customer.pin_opted;
        // // // // console.log('pin state');
        // // // // console.log(this.pinState);
        this.customer.name = data.customer.name;

        // Coupon
        this.couponName = data.couponName;

        // tNUm
        this.tNum = data.tNum;
        // Order Status
        this.isPaid = true;
      });
  }

  taxnfee(data) {
    try {
      if (data.paidTime) {
        this.serviceFee = data.serviceFee;
        this.calcTaxnFee = (
          parseFloat(data.serviceFee) + parseFloat(data.paidDollarValue.paidTax)
        ).toFixed(2);
        this.calcServiceFee = parseFloat(data.serviceFee);
      } else {
        this.calcTaxnFee = (
          parseFloat(data.serviceFee) + parseFloat(data.tax)
        ).toFixed(2);
        this.calcServiceFee = parseFloat(data.serviceFee);
      }
    } catch {
      this.calcTaxnFee = (
        parseFloat(data.serviceFee) + parseFloat(data.tax)
      ).toFixed(2);
      this.calcServiceFee = parseFloat(data.serviceFee);
    }
  }

  onlyPayAtStore() {
    if (this.total.toString() === "0") {
      this.isColg = true;
    }
  }

  // SET new PASSWORD POST route
  setPassword() {
    const pwdPacket = JSON.stringify({
      custPhone: this.customer.cid,
      custPwd: this.enteredPin,
    });
    // // // // console.log(this.enteredPin)
    // // // console.log(pwdPacket);
    const localAddress = `${this.address}quickUpdatePassword`;
    this.http
      .post<any>(localAddress, pwdPacket, httpOptions)
      .subscribe((data) => {
        // // // // console.log('SET PIN Success FUL')
        if (data.msg === "pin_save") {
        }
      });
  }

  ///  PIN Routes
  // SET new PIN POST route
  setPin() {
    this.sendPin = JSON.stringify({
      custId: this.customer.cid,
      pin: this.enteredPin,
    });
    // // // console.log('this.enteredPin')
    // // // console.log(this.sendPin)
    const localAddress = `${this.address}setPin`;
    this.http
      .post<any>(localAddress, this.sendPin, httpOptions)
      .subscribe((data) => {
        // // // // console.log('SET PIN Success FUL')
        if (data.msg === "pin_save") {
        }
      });
  }
  profileupdate(data) {
    const localAddress = this.address + "usermanage/updateProfile";
    this.http.post<any>(localAddress, data, httpOptions).subscribe((data) => {
      this.profileUpdate.next(data);
    });
  }
  //actionbutton
  actionbutton(btnId, btnName) {
    let payload = {
      orderId: this.ordrID,
      restId: this.restaurant.rid,
      custId: this.rest.selectedRest.cid,
      btnId: btnId,
      btnName: btnName,
    };
    let actionBtnPacket = JSON.stringify(payload);
    // // // console.log(payload);
    const localAddress = `${this.address}clickAnalyticsV2`;
    this.http
      .post<any>(localAddress, actionBtnPacket, httpOptions)
      .subscribe((data) => {});
  }

  // Click Analytics Route
  clickAnalytics() {
    this.clickInfoPacket = JSON.stringify({
      ordrId: this.ordrID,
      clickData: this.clickValue,
    });
    // // // // console.log(this.clickInfoPacket )
    const localAddress = `${this.address}clickAnalytics`;
    this.http
      .post<any>(localAddress, this.clickInfoPacket, httpOptions)
      .subscribe((data) => {
        // // // // console.log(data)
      });
  }

  /// Ordr Admin Tip
  ordrAdmin() {
    if (this.isOrdrAdmin) {
      this.tip = this.tempTip;
    } else {
    }
  }

  // Click Analytics Route
  papaClick() {
    this.papaClickPacket = JSON.stringify({
      timeStamp: this.timeVal,
      device: this.deviceVal,
      browser: this.browserVal,
    });
    // // // // console.log(this.clickInfoPacket )
    const localAddress = `${this.address}papaData`;
    this.http
      .post<any>(localAddress, this.papaClickPacket, httpOptions)
      .subscribe((data) => {
        // // // // console.log(data)
      });
  }

  // MicroPhone info
  recorderInfo() {
    this.micInfoPacket = this.blobData;
    // // // // console.log(this.micInfoPacket)
    // // // console.log('@ mic route is Hit');
    const localAddress = `${this.address}micInfo`;
    if (this.isdemo) {
      this.rec.oid = this.rest.restaurant.ordrId;
      this.rec.cid = this.rest.selectedRest.cid;
      this.rec.rid = this.rest.selectedRest.rid;
    } else {
      this.rec.oid = this.ordrID;
      this.rec.cid = this.customer.cid;
      this.rec.rid = this.restaurant.rid;
    }
    const audioOptions = {
      headers: new HttpHeaders({
        "Content-Type": "audio/wav",
        value: this.rec.oid,
        rid: this.rec.rid,
        cid: this.rec.cid,
      }),
    };
    this.http
      .post<any>(localAddress, this.micInfoPacket, audioOptions)
      .subscribe(
        (data) => {
          // // // console.log('mic Info ');
          // // // // console.log(data.status)
          // // // // console.log('sS')
          this.printAudioText = data;
          // // // console.log(data);

          this.isEdit = false;
          this.resetItems();
          this.isAddItemBox = true;
          this.isTotalBox = true;
          this.isSummaryTitle = true;
          this.isEditItemBox = false;

          this.isSticky = false;
          this.isDeleteBtn = true;
          this.isEditBtn = true;
          this.ordrID = data.orderId;
          this.customerInfo(data);
          this.methodInfo(data);
          this.dollarValue(data);
          this.itemsInfo(data);
          this.restaurantInfo(data);
          this.mcDFix();
          this.upsellValidator();

          this.helperFunctions();

          this.postSummaryUpdated.next(data);
        },
        (response) => {
          // // // // console.log("POST call in error", response);
          if (response.status === 500 || 0) {
            // // // // console.log('internal server error')
            this.isError = true;
          }
        }
      );
  }

  // FeedBack - Emoji - Route
  emojiFeedbacks() {
    this.emojiFeedbackPacket = JSON.stringify({
      ordrId: this.ordrID,
      emojiText: this.fbEmojiText,
    });
    // // // // console.log(this.emojiFeedbackPacket )
    const localAddress = `${this.address}emojiFeedback`;

    this.http
      .post<any>(localAddress, this.emojiFeedbackPacket, httpOptions)
      .subscribe((data) => {
        // // // // console.log(data)
      });
  }

  // FeedBack - Emoji - Route
  textFeedbacks() {
    this.textFeedbackPacket = JSON.stringify({
      ordrId: this.ordrID,
      userText: this.fbUserText,
    });
    // // // // console.log(this.textFeedbackPacket )
    const localAddress = `${this.address}textFeedback`;
    this.http
      .post<any>(localAddress, this.textFeedbackPacket, httpOptions)
      .subscribe((data) => {
        // // // // console.log(data)
      });
  }

  // FeedBack - Form
  feedbackForm() {
    this.textFeedbackPacket = JSON.stringify({
      ordrId: this.ordrID,
      userText: this.fbUserText,
    });
    // // // // console.log(this.textFeedbackPacket )
    const localAddress = `${this.address}textFeedback`;
    this.http
      .post<any>(localAddress, this.textFeedbackPacket, httpOptions)
      .subscribe((data) => {
        // // // // console.log(data)
      });
  }

  // Create Purchase
  createPurchase(payload) {
    let purchasePacket = JSON.stringify(payload);
    const localAddress = `${this.address}create_purchase`;
    this.http
      .post<any>(localAddress, purchasePacket, httpOptions)
      .subscribe((response) => {
        // // // console.log(response)
        if (response.msg === "True") {
          // this.api.notificationScheduler();
          this.isSuccess = true;
          this.isBt = false;
          if (!this.quickOrder.isQuickName) {
            // text customer
            this.setPin();
          } else {
            this.setPassword();
          }
          this.sendordrPOS(response.orderId);
        } else {
          // // // console.log(response.msg);
          // // // console.log('msg = false');
          this.isFailure = true;
          this.isBt = false;
        }
      });
  }

  // validate PIN POST route
  validatePin() {
    // // // // console.log(this.clickAanlysis);
    this.vPin = JSON.stringify({
      ordrId: this.ordrID,
      custId: this.customer.cid,
      pin: this.pinPayment,
      dropin: this.dropin,
      splInst: this.splInstText !== null ? this.splInstText : "*",
      isPassword: this.customer.isPwd,
      password: this.passwordPayment,
      chargeAmount: (this.total + this.tip).toFixed(2).toString(),
      counter: this.counter,
      name: this.userName,
      tip: this.tip,
      showDisclaim: this.userSelectionDisClaimer,
      couponName: this.couponName,
      mktOptIn: !this.isMktOptIn,
      editScore: this.editScore,
    });
    // // // console.log(this.vPin);
    // // // // console.log(this.total)
    const localAddress = `${this.address}secretSauce`;
    this.http
      .post<any>(localAddress, this.vPin, httpOptions)
      .subscribe((data) => {
        if (data.msg === "True") {
          // this.notificationScheduler()
          this.isSuccess = true;
          this.isBt = false;
          this.pinMsg = false;
          this.sendordrPOS(data.orderId);
        } else if (data.msg === "Fail") {
          this.isFailure = true;
          this.isBt = false;
          this.pinMsg = false;
        } else {
          // // // // console.log('invalid')

          if (data.counter === "0") {
            // // // // console.log('1st time wrong - resend the pin')
            this.isFirstWrong = true;
            this.counter = "1";
            this.pinMsg = false;
            this.paymentPin = "";
            // this.pinValue = '';
          } else if (data.counter === "1") {
            // // // // console.log('2st time wrong - resend the pin')
            this.isFirstWrong = false;
            this.isSecondWrong = true;
            this.isThridWrong = true;
            this.counter = "2";
            this.pinMsg = false;
            this.paymentPin = "";
            // this.pinValue = '';
          } else if (data.counter === "2") {
            // // // // console.log('load brain tree UI');
            this.pinFailure = true;
            this.isBt = false;
            this.isUserPin = false;
            this.isState_2 = true;
            this.pinMsg = false;
          }
        }
      });
  }

  sendordrPOS(ordrId) {
    this.posPackets = JSON.stringify({ ordrId: ordrId });
    const localAddress = `${this.address}speedLineUI`;
    this.http
      .post<any>(localAddress, this.posPackets, httpOptions)
      .subscribe((data) => {
        this.postPOS.next(data);
      });
  }

  speedLineUIDirect(ordrId) {
    const posPackets = JSON.stringify({ ordrId: ordrId });
    const localAddress = `${this.address}speedLineUIDirect`;
    this.http
      .post<any>(localAddress, posPackets, httpOptions)
      .subscribe((data) => {
        this.postSpeedlineInfo.next(data);
      });
  }

  // validate PIN POST route
  addCard() {
    let obj = {
      custId: this.customer.cid,
      dropin: this.dropin,
    };
    this.addCardPacket = JSON.stringify(obj);
    // // // // console.log(this.addCardPacket)
    const localAddress = `${this.address}addCard`;
    this.http
      .post<any>(localAddress, this.addCardPacket, httpOptions)
      .subscribe((data) => {
        this.diffCard = true;
        this.isUserPin = false;
        this.isState_2 = true;
        this.isBraintree = false;
      });
  }

  // Check the phone number for Refer a Friend

  checkPhoneRefFrnd() {
    this.checkPhonePacket = JSON.stringify({
      refFrndContact: this.refFrndContact,
      CustWhoReferred: this.referralCustCode,
    });
    // // // // console.log(this.checkPhonePacket);
    const localAddress = `${this.address}checkRefFrnd`;
    this.http
      .post<any>(localAddress, this.checkPhonePacket, httpOptions)
      .subscribe((data) => {
        this.isRefFrndContact = data.Status;
        this.postIsRefFrnd.next(data);
      });
  }

  // !! important POST route to get updated response from the server
  homePage() {
    const input = {
      custId: this.rest.selectedRest.cid,
      restId: this.rest.selectedRest.rid,
      msg: this.rest.selectedRest.msg,
    };
    this.homePagePacket = JSON.stringify(input);
    const localAddress = `${this.address}homePage`;
    // // console.log(this.homePagePacket);
    this.http
      .post<any>(localAddress, this.homePagePacket, httpOptions)
      .subscribe(
        (data) => {
          // // console.log(data);

          this.onSend = false;
          this.isAddItemBox = true;
          this.isTotalBox = true;
          this.isSummaryTitle = true;
          this.isEditItemBox = false;
          this.isShowUpsell = true;
          this.isSticky = false;
          this.isDeleteBtn = true;
          this.isEditBtn = true;
          this.restaurant.isUpsell = true;
          this.restaurant.isUpsize = true;
          this.ordrID = data.orderId;
          this.dollarValue(data);
          this.itemsInfo(data);
          this.customerInfo(data);
          this.methodInfo(data);
          this.helperFunctions();
          // // // console.log('@ homepage route - response')
          this.restaurantInfo(data);
          this.ispickupValidator();
          this.mcDFix();
          this.upsellValidator();

          this.postHomePage.next(data);
        },
        (response) => {
          // // // console.log("POST call in error", response);
          if (response.status === 500 || 0) {
            // // // console.log('internal server error')
            this.isError = true;
          }
        }
      );
  }

  // validate PIN POST route
  payAtStore() {
    // // // console.log(this.clickAanlysis)
    this.payPacket = JSON.stringify({
      custName: this.userName,
      chargeAmount: this.total,
      custId: this.customer.cid,
      ordrId: this.ordrID,
      tip: "0.00",
      splInst: this.splInstText !== null ? this.splInstText : "*",
      name: this.userName,
      showDisclaim: this.userSelectionDisClaimer,
      editScore: this.editScore,
      mktOptIn: !this.isMktOptIn,
      couponName: this.couponName,
    });
    // // // console.log(this.payPacket);
    const localAddress = `${this.address}payAtStore`;
    this.http
      .post<any>(localAddress, this.payPacket, httpOptions)
      .subscribe((data) => {
        // // // console.log(data)
        this.notificationScheduler();
        this.isTip = false;
        this.isSuccess = true;
        this.pinMsg = false;
        this.sendordrPOS(data.orderId);
      });
  }

  notificationScheduler() {
    this.schedulerPacket = JSON.stringify({
      rid: this.restaurant.rid,
      ordrId: this.ordrID,
    });
    // // console.log('scheudler ');
    // // // console.log(this.schedulerPacket);
    const localAddress = `${this.address}notificationScheduler`;
    this.http
      .post<any>(localAddress, this.schedulerPacket, httpOptions)
      .subscribe((data) => {
        // // console.log('sucess');
      });
  }

  // Quick Order
  quickOrdr() {
    this.quickOrdrPacket = JSON.stringify({
      restId: this.quickOrder.restId,
      custText: this.quickOrder.fullText,
    });
    // // console.log(this.quickOrdrPacket);
    const localAddress = `${this.address}quickOrder`;
    this.http
      .post<any>(localAddress, this.quickOrdrPacket, httpOptions)
      .subscribe((data) => {
        // // console.log(data);
        // // console.log('quick order');
        this.postQUickOrdr.next(data);
      });
  }

  custInfo(custPhone, ordrId) {
    custPhone = "+1" + custPhone;
    custPhone = this.phoneReformat(custPhone);
    this.posPackets = JSON.stringify({ custPhone: custPhone, ordrId: ordrId });
    // // console.log(this.posPackets);
    const localAddress = `${this.address}custInfo`;
    this.http
      .post<any>(localAddress, this.posPackets, httpOptions)
      .subscribe((data) => {
        // // console.log('cust Info');
        // // console.log(data);
        this.pinState = data.customer.pin_opted;
        this.customer.isPwd = data.customer.isPassword;
        this.customerCardInfo(data);
        this.customer.cid = data.customer.id;
        this.passwordChangefix();
        this.getName.next(data);
      });
  }

  // tslint:disable-next-line:no-shadowed-variable
  restInfo(restId) {
    const posPackets = JSON.stringify(restId);
    const localAddress = `${this.address}getRestinfo`;
    this.http
      .post<any>(localAddress, posPackets, httpOptions)
      .subscribe((data) => {
        this.quickRestInfo(data);
        // // console.log('Rest Info');
        // // console.log(data);
      });
  }

  // HELPER FUNCTIONS
  justReplaceUndefined(val) {
    return typeof val === "undefined" || val === "-1" ? "-1" : val;
  }

  // Replace the undefined with string 0
  replaceUndefined(val) {
    if (typeof val === "undefined") {
      return "-1";
    }
    if (val === "-1") {
      return val;
    } else {
      //  // // console.log('cap result')
      //  // // console.log(this.capitialize(val))
      let retext = this.capitialize(val);
      return retext;
    }
  }

  // First letter caps lock in Items name - order summary JSON

  capitialize(val) {
    // // // console.log('@cap')
    // // // console.log(val)
    val = val.toLowerCase().trim();
    const newArr = [];
    if (val.includes(",")) {
      // // // console.log('Comma seperated string')
      // // // console.log(val)
      const arr = val.split(",");
      arr.map((x) => {
        // x = x.trim();
        // newArr.push(this.FirstWord(x.replace(/[^\w\s\-,&]/g, "")));
        if (x.includes("_")) {
          x = x.trim();
          newArr.push(this.replaceUnderScore(x));
        } else if (x.includes(" ")) {
          x = x.trim();
          newArr.push(this.spaceAfter(x));
        } else if (x.includes("-")) {
          x = x.trim();
          newArr.push(this.hyphenRemoval(x));
        } else {
          x = x.trim();
          newArr.push(this.FirstWord(x));
        }
      });
      // // // console.log(newArr)
      const word = newArr.join(", ");
      return word;
    } else {
      val = val.trim();
      // return this.FirstWord(val.replace(/[^\w\s\-,&]/g, ""));
      if (val.includes("_")) {
        return this.replaceUnderScore(val);
      } else if (val.includes("-")) {
        return this.hyphenRemoval(val);
      } else if (val.includes(" ")) {
        // // // console.log('space ')
        // // // console.log(val)
        // // // console.log('after er')
        // // // console.log(this.spaceAfter(val))
        return this.spaceAfter(val);
      }
      // // // console.log('Single word string')
      // // // console.log(val)
      return this.FirstWord(val);
    }
  }

  replaceUnderScore(val) {
    const indexVal = val.indexOf("_") + 1;
    let prefix = val.substring(0, indexVal - 1);
    prefix = prefix.charAt(0).toUpperCase() + prefix.substr(1);
    val = val.charAt(indexVal).toUpperCase() + val.substr(indexVal + 1);
    val = this.FirstWord(val);
    val = val.replace(/_/g, " ");
    const reVal = `${prefix} ${val}`;
    return reVal;
  }

  spaceAfter(val) {
    if (this.numOfSpaces(val) > 1) {
      // // // console.log('SpaceAfter')
      // // // console.log(val)
      const firstIndex = val.indexOf(" ") + 1;
      let prefixval = val.substring(0, firstIndex - 1);
      val = val.charAt(0).toUpperCase() + val.substr(1);
      prefixval = prefixval.charAt(0).toUpperCase() + prefixval.substr(1);
      val = val.charAt(firstIndex).toUpperCase() + val.substr(firstIndex + 1);
      let firstVal = `${prefixval} ${val}`;

      const LastindexVal = firstVal.lastIndexOf(" ") + 1;
      let lastPrefix = firstVal.substring(0, LastindexVal - 1);
      // // // console.log(lastPrefix)
      lastPrefix = lastPrefix.charAt(0).toUpperCase() + lastPrefix.substr(1);
      firstVal =
        firstVal.charAt(LastindexVal).toUpperCase() +
        firstVal.substr(LastindexVal + 1);
      const lastVal = `${lastPrefix} ${firstVal}`;
      return lastVal;
    }
    // // // console.log('SpaceAfter')
    // // // console.log(val)
    const indexVal = val.lastIndexOf(" ") + 1;
    let prefix = val.substring(0, indexVal - 1);
    prefix = prefix.charAt(0).toUpperCase() + prefix.substr(1);
    // // // console.log(prefix)
    val = val.charAt(indexVal).toUpperCase() + val.substr(indexVal + 1);
    // // // console.log(val)
    const reVal = `${prefix} ${val}`;
    // // // console.log(reVal)
    return reVal;
  }

  hyphenRemoval(val) {
    // const indexVal = val.lastIndexOf("-") + 1;
    // let prefix = val.substring(0, indexVal - 1);
    // prefix = prefix.charAt(0).toUpperCase() + prefix.substr(1);
    // val = val.charAt(indexVal).toUpperCase() + val.substr(indexVal + 1);
    // // // // console.log(val)
    // const reVal = `${prefix} ${val}`;
    return val;
  }

  numOfSpaces(str) {
    // // // console.log(str)
    let letterCount = 0;
    for (let position = 0; position < str.length; position++) {
      if (str.charAt(position) === " ") {
        letterCount += 1;
      }
    }
    return letterCount;
  }

  capFirstWord(val) {
    try {
      const newArr = [];
      val = val;
      if (val.includes(" ")) {
        const arr = val.split(" ");
        for (let i = 0; i < arr.length; i++) {
          newArr.push(this.FirstWord(arr[i]));
        }
        // // // console.log('CAPFIRST')
        // // // console.log(newArr)
        const word = newArr.join().replace(/,/g, " ");
        return word;
      } else {
        const res = `${val
          .toLowerCase()
          .charAt(0)
          .toUpperCase()}${val
          .toLowerCase()
          .replace(val.toLowerCase().charAt(0), "")}`;
        return res;
      }
    } catch {
      // // // console.log('Cap First Word error');
    }
  }

  FirstWord(word) {
    const res = `${word.charAt(0).toUpperCase()}${word.substr(1)}`;
    // // // console.log(res)
    return res;
    // return `${word.toLowerCase().charAt(0).toUpperCase()}${word.toLowerCase().replace(word.toLowerCase().charAt(0), '')}`;
  }

  cashEligible() {
    this.isCash = this.customer.cash_eligible;
    if (this.isCash) {
      this.isCashEligible = true;
      // // // console.log('@ API IF  cashEligible func')

      // // // console.log(this.isCashEligible)
    } else {
      // // // console.log('@ API ELSE cashEligible func')
      this.isCashEligible = false;
      this.payOnline = false;
      // // // console.log(this.isCashEligible)
    }
  }

  // Delivery Pickup decider
  ispickupValidator() {
    // // // console.log('API service - inside ISPICKUP VAL ');
    // // // console.log(this.method.mtype);
    if (this.method.mtype === "PICKUP") {
      this.isPickupVal = true;
      this.isDelivery = false;
      this.isTip = false;
      this.editStateAddress = this.method.maddress_db;
    } else {
      this.isPickupVal = false;
      this.isDelivery = true;
      this.isTip = false;
      this.editStateAddress = this.method.maddress;
    }
  }

  /// naItems Helper

  naItemsHelper() {}
  /// Keyword Validator
  keywordValidator() {
    if (!(this.customer.msg_type === "keyword")) {
      this.keywordText = "-1";
    } else {
      this.delivery_fee = "0.00";
      this.saving = "0.00";
      this.serviceFee = "0.00";
      this.subtotal = "0.00";
      this.tax = "0.00";
      this.total = 0;
      this.tip = 0;
      this.itemsTotal = "0.00";
      this.tempFix = true;
      this.infoMsg = true;
      this.isSummaryTitle = false;
      this.isAddItemBox = false;
      this.isTotalBox = false;
      this.showTotal = true;
      this.isMethodBox = false;
      this.isTotalBox = false;
      this.isSticky = true;
      this.customer.msg_text = "";
      this.method.mtype = "pickup";
      this.isFutureOrder = false;
    }
  }

  errorWordValidator() {
    if (!(this.customer.msg_type === "error")) {
      this.errorText = "-1";
      // // // console.log('Errorword  IF')
    } else {
      // // // console.log(" errorWOrd inside ELSE")
      // // // console.log(this.errorText )
      this.delivery_fee = "0.00";
      this.saving = "0.00";
      this.serviceFee = "0.00";
      this.subtotal = "0.00";
      this.tax = "0.00";
      this.total = 0;
      this.tip = 0;
      this.itemsTotal = "0.00";
      this.tempFix = true;
      this.infoMsg = true;
      this.isSummaryTitle = false;
      this.isAddItemBox = false;
      this.isTotalBox = false;
      this.showTotal = true;
      this.isMethodBox = false;
      this.isSticky = true;
      this.customer.msg_text = "";
      this.method.mtype = "pickup";
      this.isFutureOrder = false;
    }
  }

  genRefCode() {
    this.refCode = `REF${this.customer.custCode}${this.restaurant.restCode}`;
  }

  irrelevantValidator() {
    if (!(this.customer.msg_type === "irrelevant")) {
      this.irrelevantText = "-1";
      // // // console.log('irrelevant  IF')
    } else {
      // // // console.log(" irrelevant nside ELSE")
      // // // console.log(this.irrelevantText)
      this.delivery_fee = "0.00";
      this.saving = "0.00";
      this.serviceFee = "0.00";
      this.subtotal = "0.00";
      this.tax = "0.00";
      this.total = 0;
      this.tip = 0;
      this.itemsTotal = "0.00";
      this.tempFix = true;
      this.infoMsg = true;
      this.isSummaryTitle = false;
      this.isAddItemBox = false;
      this.isTotalBox = false;
      this.showTotal = true;
      this.isMethodBox = false;
      this.isSticky = true;
      this.customer.msg_text = "";
      this.method.mtype = "pickup";
      this.isFutureOrder = false;
    }
  }

  /// Phone Formatting
  phoneFormat() {
    const val = this.restaurant.rid.split("");
    //  // // console.log(val)
    this.displayNum = `(${val[2]}${val[3]}${val[4]}) ${val[5]}${val[6]}${val[7]}-${val[8]}${val[9]}${val[10]}${val[11]}`;
  }

  formatPhone(x) {
    // // // console.log('format phone')
    const val = x.split("");
    // // // console.log(val)
    const displayNo = `(${val[2]}${val[3]}${val[4]}) ${val[5]}${val[6]}${val[7]}-${val[8]}${val[9]}${val[10]}${val[11]}`;
    return displayNo;
  }

  adminLogin(userName, pwd) {
    const userLoginPacket = JSON.stringify({ userName: userName, pwd: pwd });
    // // console.log(userLoginPacket);
    const localAddress = `${this.address}adminLogin`;
    // // console.log(localAddress);
    this.http
      .post<any>(localAddress, userLoginPacket, httpOptions)
      .subscribe((data) => {
        // // console.log('loginRoute');
        // // console.log(data);
        this.LoginInfo.next(data);
      });
  }

  CustphoneFormat() {
    const val = this.customer.cid.split("");
    // // // console.log('test')
    //  // // console.log(val)
    this.CustdisplayNum = `(${val[2]}${val[3]}${val[4]}) ${val[5]}${val[6]}${val[7]}-${val[8]}${val[9]}${val[10]}${val[11]}`;
  }

  // Tip Validator
  tipValidator() {
    if (this.tip === 0.0) {
      this.tipDisplay = false;
    } else {
      this.tipDisplay = true;
    }
  }

  /* printerFormat()
  {
  this.itemNames.map((x, i) => {
    let stringVal = `${this.qty[i]}         ${this.cursts[i]} ${this.itemNames[i]}               ${this.amounts[i]}`
    const regex = /-1/gi;
    stringVal = stringVal.replace(regex, '')
    this.ppTitle.push(stringVal)
  })
  // // console.log(this.ppTitle)
  } */

  mcDFix() {
    this.itemNames.map((x, i) => {
      // // // console.log(i)
      if (x === "Side") {
        if (this.side[i] !== "-1" && this.drink[i] !== "-1") {
          // // // console.log('there is side and drink')
          this.itemNames[i] = `${this.side[i]} & ${this.drink[i]}`;
          this.side[i] = "-1";
          this.drink[i] = "-1";
        } else if (this.drink[i] !== "-1") {
          // // // console.log('there is drink')
          // // // console.log(this.drink[i])
          this.itemNames[i] = this.drink[i];
          this.drink[i] = "-1";
        } else if (this.side[i] !== "-1") {
          // // // console.log('there is side')
          this.itemNames[i] = this.side[i];
          this.side[i] = "-1";
        } else {
          // // // console.log('no side')
        }
      }
      // // // console.log(x)
    });
  }
  passwordChangefix() {
    if (this.pinState === "0") {
      // New customer - accroding to old flow
      if (this.customer.isPwd) {
        // Customer have a pwd
        this.pinState = "1";
      }
    } else {
      // customer with a PIN
      if (!this.customer.isPwd) {
        this.pinState = "0";
      }
    }
  }

  proOrderPasswordfix() {}

  pinValidator() {
    if (this.pinState === "0") {
      // // // console.log('new customer')
      this.isPinStored = true;
      this.isBraintree = false;
    } else {
      // // // console.log('existing customer')
      this.isPinStored = false;
      this.isBraintree = true;
    }
  }

  today() {
    this.date = new Date();
    this.currentDate = `${
      this.date.getMonth() + 1
    }/${this.date.getDate()}/${this.date.getFullYear()}`;
  }

  defaultTip() {
    this.tempTotal = this.total;
    this.tipVal15 = (this.total * 0.15).toFixed(2);
    this.tip = parseFloat(this.tipVal15);
    this.tipValidator();
    this.total = this.tempTotal;
    this.isDefaultTip = true;
  }

  /// Upsell validator
  upsellValidator() {
    // // // console.log('img list - inside the validtor function')
    // // // console.log(this.imgAddTxt)
    this.restaurant.isUpsell = true;
    this.restaurant.isUpsize = true;
    // // // console.log(this.restaurant.isUpsell + 'UPsell')
    // // // console.log(this.isShowUpsell + 'show upsell')
    // this.upsellValidator1()
    this.upsellValidator2();
    this.upsellValidator3();
    this.upsellValidator4();
    this.upsellValidator5();
    // // // console.log('img vaildatoer')
    // // // console.log(this.imgUrl.length)
    if (this.imgUrl.length === 2) {
      // // // console.log('length -2 ')
      this.imgUrl.push(
        "https://ordr-assets.s3.us-east-2.amazonaws.com/upsell/pj/zzwhite.jpg"
      );
    }
    if (this.imgUrl.length === 1) {
      // // // console.log('length -1 ')
      this.imgUrl.push(
        "https://ordr-assets.s3.us-east-2.amazonaws.com/upsell/pj/zzwhite.jpg"
      );
      this.imgUrl.push(
        "https://ordr-assets.s3.us-east-2.amazonaws.com/upsell/pj/zzwhite.jpg"
      );
    }
    if (this.imgUrl.length <= 0) {
      this.isShowUpsell = false;
    } else {
      this.isShowUpsell = true;
    }
    // // console.log(this.isShowUpsell);

    // REMOVE THIS IF UPSELL NEEDS TO WORK
    this.restaurant.isUpsell = false;
    this.restaurant.isUpsize = false;
  }
  /*
  upsellValidator1() {
    if(this.customer.msg_text !== null) {
      const msgList1 = this.customer.msg_text.replace(/,/g,' ')
      const msgList = msgList1.split(' ');
      msgList.map((val) => {

        if((val.toLowerCase()) === "fries"
            || (val.toLowerCase()) === "fries,"
            || (val.toLowerCase()) === ","
            || (val.toLowerCase()) === ","
            || (val.toLowerCase()) === "," ) {
          this.isFries = false;
          // // console.log('fries is there')
          this.isShowUpsell = true;
          this.imgUrl.map((el, i) => {
          // tslint:disable-next-line:max-line-length
          // tslint:disable-next-line:max-line-length
          // tslint:disable-next-line:max-line-length
          if(el === this.upSellItemUrl.item3 || el === this.upSellItemUrl.item2 || el === this.upSellItemUrl.item1
             || el === this.upSellItemUrl.item4 ){
              // // console.log('Fries identified')
             this.imgUrl.splice(i,1)
             this.imgAddTxt.splice(i,1)
            }
          })
          // // console.log(this.imgAddTxt)
        }
    })
  }
  }

  upsWater() {
    if(this.customer.msg_text !== null) {
      const msgList1 = this.customer.msg_text.replace(/,/g,' ')
      const msgList = msgList1.split(' ');
      msgList.map((val) => {

        if((val.toLowerCase()) === "bottled"
            || (val.toLowerCase()) === "bottle,"
            || (val.toLowerCase()) === "bottled,"
            || (val.toLowerCase()) === "bottle,"
            || (val.toLowerCase()) === "water"
            || (val.toLowerCase()) === "water," ) {
          this.isWater = false;
          // // console.log('water is there')
          this.isShowUpsell = true;
          this.imgUrl.map((el, i) => {
          // tslint:disable-next-line:max-line-length
          // tslint:disable-next-line:max-line-length
          if(el === this.upSellItemUrl.item3 || el === this.upSellItemUrl.item2
            || el === this.upSellItemUrl.item1 || el === this.upSellItemUrl.item4 ){
              // // console.log('water identified')
             this.imgUrl.splice(i,1)
             this.imgAddTxt.splice(i,1)
            }
          })
          // // console.log(this.imgAddTxt)
        }
    })
  }
  }

  upsChips() {
    if(this.customer.msg_text !== null) {
      const msgList1 = this.customer.msg_text.replace(/,/g,' ')
      const msgList = msgList1.split(' ');
      msgList.map((val) => {

        if((val.toLowerCase()) === "chip"
            || (val.toLowerCase()) === "chip,"
            || (val.toLowerCase()) === "chips,"
            || (val.toLowerCase()) === "chips"
            || (val.toLowerCase()) === "chipsn,"
            || (val.toLowerCase()) === "chipsn" ) {
          this.isChips = false;
          // // console.log('chips is there')
          this.isShowUpsell = true;
          this.imgUrl.map((el, i) => {
          if(el === this.upSellItemUrl.item3 || el === this.upSellItemUrl.item2
            || el === this.upSellItemUrl.item1 || el === this.upSellItemUrl.item4 ){
              // // console.log('chips identified')
             this.imgUrl.splice(i,1)
             this.imgAddTxt.splice(i,1)
            }
          })
          // // console.log(this.imgAddTxt)
        }
    })
  }
  }


  upsCoffee1() {
    if(this.customer.msg_text !== null) {
      const msgList1 = this.customer.msg_text.replace(/,/g,' ')
      const msgList = msgList1.split(' ');
      msgList.map((val) => {

        if((val.toLowerCase()) === "nitro"
            || (val.toLowerCase()) === "nitro," ) {
          this.isCof1 = false;
          // // console.log('Nitro is there')
          this.isShowUpsell = true;
          this.imgUrl.map((el, i) => {
          // tslint:disable-next-line:max-line-length
          // tslint:disable-next-line:max-line-length
          // tslint:disable-next-line:max-line-length
          // tslint:disable-next-line:max-line-length
          // tslint:disable-next-line:max-line-length
          // tslint:disable-next-line:max-line-length
          if(el === this.upSellItemUrl.item3 || el === this.upSellItemUrl.item2
            || el === this.upSellItemUrl.item1 || el === this.upSellItemUrl.item4 ){
             this.imgUrl.splice(i,1)
             this.imgAddTxt.splice(i,1)
            }
          })
          // // console.log(this.imgAddTxt)
        }
    })
  }
  }

  upsCoffee2() {
    if(this.customer.msg_text !== null) {
      const msgList1 = this.customer.msg_text.replace(/,/g,' ')
      const msgList = msgList1.split(' ');
      msgList.map((val) => {

        if((val.toLowerCase()) === "pumpkin"
          || (val.toLowerCase()) === "spice,"
          || (val.toLowerCase()) === "pumpkin,"
          || (val.toLowerCase()) === "spice"
          || (val.toLowerCase()) === "spices"
          || (val.toLowerCase()) === "spices," ) {
          this.isCof2 = false;
          // // console.log('Pumpkin is there')
          this.isShowUpsell = true;
          this.imgUrl.map((el, i) => {
          // tslint:disable-next-line:max-line-length
          // tslint:disable-next-line:max-line-length
          if(el === this.upSellItemUrl.item3 || el === this.upSellItemUrl.item2
            || el === this.upSellItemUrl.item1 || el === this.upSellItemUrl.item4 ){
             this.imgUrl.splice(i,1)
             this.imgAddTxt.splice(i,1)
            }
          })
          // // console.log(this.imgAddTxt)
        }
    })
  }
  }

  upsPickle() {
    if(this.customer.msg_text !== null) {
      const msgList1 = this.customer.msg_text.replace(/,/g,' ')
      const msgList = msgList1.split(' ');
      msgList.map((val) => {

        if((val.toLowerCase()) === "pickle"
          || (val.toLowerCase()) === "pickle,"
          || (val.toLowerCase()) === "pickles,"
          || (val.toLowerCase()) === "pickles"
          || (val.toLowerCase()) === "pickels"
          || (val.toLowerCase()) === "pickels," ) {
          this.isPickle = false;
          // // console.log('Pickle is there')
          this.isShowUpsell = true;
          this.imgUrl.map((el, i) => {
          // tslint:disable-next-line:max-line-length

          if(el === this.upSellItemUrl.item3 || el === this.upSellItemUrl.item2 ||
            el === this.upSellItemUrl.item1 || el === this.upSellItemUrl.item4 ){
             this.imgUrl.splice(i,1)
             this.imgAddTxt.splice(i,1)
            }
          })
          // // console.log(this.imgAddTxt)
        }
    })
  }
  }

  */

  upsellValidator2() {
    if (this.customer.msg_text !== null) {
      const msgList1 = this.customer.msg_text.replace(/,/g, " ");
      const msgList = msgList1.split(" ");
      msgList.map((val) => {
        if (
          val.toLowerCase() === "pepsi" ||
          val.toLowerCase() === "pepsi," ||
          val.toLowerCase() === "coke" ||
          val.toLowerCase() === "coke," ||
          val.toLowerCase() === "dew" ||
          val.toLowerCase() === "dew," ||
          val.toLowerCase() === "fanta" ||
          val.toLowerCase() === "fanta," ||
          val.toLowerCase() === "liter" ||
          val.toLowerCase() === "soda" ||
          val.toLowerCase() === "soda," ||
          val.toLowerCase() === "pop" ||
          val.toLowerCase() === "nitro," ||
          val.toLowerCase() === "nitro" ||
          val.toLowerCase() === "punch" ||
          val.toLowerCase() === "punch," ||
          val.toLowerCase() === "simply" ||
          val.toLowerCase() === "simply," ||
          val.toLowerCase() === "gatorade" ||
          val.toLowerCase() === "gatorade," ||
          val.toLowerCase() === "pop," ||
          val.toLowerCase() === "drpepper"
        ) {
          this.isPop = false;
          // // // console.log('POP is there')
          this.isShowUpsell = true;
          this.imgUrl.map((el, i) => {
            if (el === this.upSellItemUrl.item3) {
              // // console.log('POP identified');
              this.imgUrl.splice(i, 1);
              this.imgAddTxt.splice(i, 1);
            }
          });
          // // // console.log(this.imgAddTxt)
        }
      });
    }
  }

  upsellValidator3() {
    if (this.customer.msg_text !== null) {
      const msgList1 = this.customer.msg_text.replace(/,/g, " ");
      // // // console.log(msgList1)
      const msgList = msgList1.split(" ");
      // // // console.log(msgList)
      msgList.map((val) => {
        if (
          val.toLowerCase() === "chocolate" ||
          val.toLowerCase() === "chocolates" ||
          val.toLowerCase() === "cookie" ||
          val.toLowerCase() === "cone," ||
          val.toLowerCase() === "cone" ||
          val.toLowerCase() === "cinna" ||
          val.toLowerCase() === "cinna," ||
          val.toLowerCase() === "vanilla," ||
          val.toLowerCase() === "vanilla" ||
          val.toLowerCase() === "strawberry" ||
          val.toLowerCase() === "strawberry," ||
          val.toLowerCase() === "strawbery" ||
          val.toLowerCase() === "strawbery," ||
          val.toLowerCase() === "shake" ||
          val.toLowerCase() === "shakes," ||
          val.toLowerCase() === "shakes" ||
          val.toLowerCase() === "shake," ||
          val.toLowerCase() === "fudge" ||
          val.toLowerCase() === "fudge" ||
          val.toLowerCase() === "sundae," ||
          val.toLowerCase() === "sundae" ||
          val.toLowerCase() === "sunday," ||
          val.toLowerCase() === "sunday" ||
          val.toLowerCase() === "candies," ||
          val.toLowerCase() === "candy," ||
          val.toLowerCase() === "candy" ||
          val.toLowerCase() === "candies" ||
          val.toLowerCase() === "pie," ||
          val.toLowerCase() === "pie" ||
          val.toLowerCase() === "cake" ||
          val.toLowerCase() === "cakes" ||
          val.toLowerCase() === "cakes," ||
          val.toLowerCase() === "cake," ||
          val.toLowerCase() === "mcflurry," ||
          val.toLowerCase() === "mcflurry" ||
          val.toLowerCase() === "flurry," ||
          val.toLowerCase() === "flurry" ||
          val.toLowerCase() === "cookies" ||
          val.toLowerCase() === "brownie," ||
          val.toLowerCase() === "brownies," ||
          val.toLowerCase() === "desserts" ||
          val.toLowerCase() === "dessert" ||
          val.toLowerCase() === "aparts," ||
          val.toLowerCase() === "chocolate," ||
          val.toLowerCase() === "chocolates," ||
          val.toLowerCase() === "cookie," ||
          val.toLowerCase() === "cookies," ||
          val.toLowerCase() === "pickle" ||
          val.toLowerCase() === "pickle," ||
          val.toLowerCase() === "pickles," ||
          val.toLowerCase() === "pickles" ||
          val.toLowerCase() === "bagel," ||
          val.toLowerCase() === "bagel" ||
          val.toLowerCase() === "baggel," ||
          val.toLowerCase() === "baggel" ||
          val.toLowerCase() === "pickels" ||
          val.toLowerCase() === "pickels," ||
          val.toLowerCase() === "brownie" ||
          val.toLowerCase() === "brownies" ||
          val.toLowerCase() === "dessert," ||
          val.toLowerCase() === "desserts,"
        ) {
          //  // // console.log('cookie is there')
          this.isDessert = false;
          this.isShowUpsell = true;
          this.imgUrl.map((el, i) => {
            if (el === this.upSellItemUrl.item2) {
              // // console.log('cookie identified');
              this.imgUrl.splice(i, 1);
              this.imgAddTxt.splice(i, 1);
            }
          });
          // // // console.log(this.imgAddTxt)
        }
      });
    }
  }

  upsellValidator4() {
    if (this.customer.msg_text !== null) {
      const msgList1 = this.customer.msg_text.replace(/,/g, " ");
      const msgList = msgList1.split(" ");
      msgList.map((val) => {
        if (
          val.toLowerCase() === "bread" ||
          val.toLowerCase() === "breads" ||
          val.toLowerCase() === "bread," ||
          val.toLowerCase() === "cheesesticks," ||
          val.toLowerCase() === "cheesesticks" ||
          val.toLowerCase() === "sticks," ||
          val.toLowerCase() === "sticks" ||
          val.toLowerCase() === "stick," ||
          val.toLowerCase() === "stick" ||
          val.toLowerCase() === "fries," ||
          val.toLowerCase() === "fries" ||
          val.toLowerCase() === "breadsticks," ||
          val.toLowerCase() === "breadsticks" ||
          val.toLowerCase() === "knots" ||
          val.toLowerCase() === "chip" ||
          val.toLowerCase() === "chip," ||
          val.toLowerCase() === "chips," ||
          val.toLowerCase() === "chips" ||
          val.toLowerCase() === "chipsn," ||
          val.toLowerCase() === "chipsn" ||
          val.toLowerCase() === "knots," ||
          val.toLowerCase() === "knot," ||
          val.toLowerCase() === "knot" ||
          val.toLowerCase() === "breads,"
        ) {
          this.isBread = false;
          // // console.log('Bread is there');
          this.isShowUpsell = true;
          this.imgUrl.map((el, i) => {
            //  // // console.log('bread indentified')
            if (el === this.upSellItemUrl.item1) {
              this.imgUrl.splice(i, 1);
              this.imgTag.splice(i, 1);
              this.imgAddTxt.splice(i, 1);
            }
          });
          //  // // console.log(this.imgAddTxt)
        }
      });
    }
  }

  rewardsInfo(data) {
    let isRewards = data.rewards.isRewards;
    isRewards = false;
    if (isRewards) {
      this.rewards.availablePoints = data.rewards.availablePoints;
      this.rewards.afterRedemption = data.rewards.afterRedemption;
      this.rewards.items = data.rewards.items;
      this.rewards.code = this.replaceUndefined(data.rewards.code);
      this.rewards.savings = data.rewards.savings;
      this.rewards.isRewards = data.rewards.isRewards;
      if (parseInt(data.rewards.availablePoints) > 0) {
        this.rewards.isPoints = true;
      }
      if (this.isRewardsArr.length > 1) {
        for (let x of this.isRewardsArr) {
          if (x) {
            this.rewards.isRewardsSelected = true;
          }
        }
      }
    }
  }
  customerInfo(data) {
    // tslint:disable-next-line:radix
    this.customer.cash_eligible = parseInt(data.customer.cash_eligible);
    this.isPayAtDoorEligible = data.customer.atDoorEligible;
    this.customer.lastName = data.customer.lastName;
    this.isPayAtStoreEligible = data.customer.atStoreEligible;
    this.customer.cash_opted = data.customer.cash_opted;
    this.customer.cid = data.customer.id;
    this.customer.msg_type = data.customer.msg.type;
    this.pickupText = data.customer.msg.text.replace(this.regex, "");
    this.customer.msg_text = data.customer.msg.text.replace(this.regex, "");
    this.pinState = data.customer.pin_opted;
    // // console.log('pin state');
    // // console.log(this.pinState);
    this.userName = data.customer.name.trim();
    this.isPayAtStore = data.customer.isPayAtStore;
    this.isPayOnline = data.customer.isPayOnline;
    this.isPayAtDoor = data.customer.isPayAtDoor;
    this.isTipDelivery = false;
    // this.customer.showDisclaimer = data.customer.showDisclaimer;
    this.customer.showDisclaimer = false;
    this.customer.isPwd = data.customer.isPassword;
  }

  upsellValidator5() {
    if (this.customer.msg_text !== null) {
      const msgList1 = this.customer.msg_text.replace(/,/g, " ");
      const msgList = msgList1.split(" ");
      msgList.map((val) => {
        if (
          val.toLowerCase() === "poppers" ||
          val.toLowerCase() === "poppers," ||
          val.toLowerCase() === "popper," ||
          val.toLowerCase() === "popper" ||
          val.toLowerCase() === "nuggets" ||
          val.toLowerCase() === "nuggets," ||
          val.toLowerCase() === "mcnuggets," ||
          val.toLowerCase() === "mcnuggets" ||
          val.toLowerCase() === "mcnugget" ||
          val.toLowerCase() === "mcnugget," ||
          val.toLowerCase() === "nugget" ||
          val.toLowerCase() === "crispy," ||
          val.toLowerCase() === "crispy" ||
          val.toLowerCase() === "boneless" ||
          val.toLowerCase() === "boneless," ||
          val.toLowerCase() === "bonein" ||
          val.toLowerCase() === "bonein," ||
          val.toLowerCase() === "bone-in" ||
          val.toLowerCase() === "bone-in," ||
          val.toLowerCase() === "wings" ||
          val.toLowerCase() === "wing" ||
          val.toLowerCase() === "wings," ||
          val.toLowerCase() === "bottle" ||
          val.toLowerCase() === "bottle," ||
          val.toLowerCase() === "bottled," ||
          val.toLowerCase() === "bottle," ||
          val.toLowerCase() === "water" ||
          val.toLowerCase() === "water," ||
          val.toLowerCase() === "wing," ||
          /* || (val.toLowerCase()) === "chips,"
           || (val.toLowerCase()) === "chip,"
           || (val.toLowerCase()) === "chips,"
           || (val.toLowerCase()) === "chips"
           || (val.toLowerCase()) === "chipsn,"
           || (val.toLowerCase()) === "chipsn"  */
          val.toLowerCase() === "nugget," ||
          val.toLowerCase() === "tenders," ||
          val.toLowerCase() === "spice," ||
          val.toLowerCase() === "pumpkin" ||
          val.toLowerCase() === "pumpkin," ||
          val.toLowerCase() === "spice" ||
          val.toLowerCase() === "spices" ||
          val.toLowerCase() === "spices," ||
          val.toLowerCase() === "tenders" ||
          val.toLowerCase() === "tender," ||
          val.toLowerCase() === "tender"
        ) {
          this.isPoppers = false;
          // // console.log('Popper is there');
          this.isShowUpsell = true;
          this.imgUrl.map((el, i) => {
            if (el === this.upSellItemUrl.item4) {
              // // // console.log('Identified Popper')
              this.imgUrl.splice(i, 1);
              this.imgAddTxt.splice(i, 1);
            }
          });
          // // // console.log(this.imgAddTxt)
          // // // console.log(this.imgUrl.length)
        }
      });
    }
  }

  quickRestInfo(data) {
    this.restaurant.address = data.restaurant.address;
    this.restaurant.name = data.restaurant.name;
    this.restaurant.rid = data.restaurant.rid;
    this.restaurant.menuPic = data.restaurant.menuPic;
    this.phoneFormat();
  }

  orderStatus(data) {
    this.isPaid = data.orderStatus.ispaid;
    this.closedOrdrId = data.orderStatus.msg;

    if (data.method.isFuture) {
      this.isStoreOpen = true;
    } else {
      this.isStoreOpen = data.orderStatus.isStoreOpen;
    }
    this.isColg = data.orderStatus.isColg;
    this.quickOrder.isQuickName = data.orderStatus.isProOrder;
    this.showMktOptIn = data.orderStatus.showMktOptIn;
    this.ordrType = data.orderStatus.orderType;
    this.isProOrder = data.orderStatus.isProOrder;
    if (typeof this.ordrType == "undefined") {
      this.ordrType = "text";
    }
  }

  customerCardInfo(data) {
    this.customer.custCode = data.customer.custCode;
    this.dropin = data.customer.dropin;
    if (data.customer.lastFour === " " || data.customer.lastFour === "*") {
      this.pinState = "0";
      this.customer.isPwd = false;
    } else {
      this.customer.lastFour = data.customer.lastFour;
    }
    this.customer.cardType = data.customer.cardType;
  }

  restaurantInfo(data) {
    // // console.log('inside restInfo');
    this.restaurant.futureMktDiscounts = data.restaurant.futureMktDiscounts;
    this.restaurant.refFrndDiscount = data.restaurant.refFrndDiscount;
    this.restaurant.logo = data.restaurant.logo; // " https://www.jetspizza.com/img/logo-white-text.png"
    this.restaurant.address = data.restaurant.address;
    this.restaurant.color = data.restaurant.color;
    this.restaurant.name = data.restaurant.name;
    this.restaurant.rid = data.restaurant.rid;
    this.restaurant.restCode = data.restaurant.restCode;
    this.restaurant.menuPic = data.restaurant.menuPic;
    this.restaurant.isUpsell = data.restaurant.isUpsell;
    this.restaurant.isUpsize = data.restaurant.isUpSize;
    this.restaurant.isReferFrnd = data.restaurant.isReferfriend;

    this.isShowTipState = data.restaurant.isShowTipState;
    if (this.method.mtype === "PICKUP") {
      this.isShowTipState = data.restaurant.isShowTipStatePickup;
    } else {
      this.isShowTipState = data.restaurant.isShowTipStateDelivery;
    }

    // this.fbt.bread =  data.restaurant.upSell[0].bread;
    // this.fbt.wings =  data.restaurant.upSell[1].wings;
    // this.fbt.pop = data.restaurant.upSell[2].pop;
    // Upsell UI
    this.imgAddTxt = data.restaurant.imgAddTxt;
    // // // console.log('imgAddText - route')
    // // // console.log(this.imgAddTxt)
    this.preferredProcessor = data.restaurant.preferredProcessor;
    this.resetImgAddTxt = this.imgAddTxt;
    // // // console.log(this.resetImgAddTxt)
    this.imgUrl = data.restaurant.imgUrl;
    this.resetImgUrl = this.imgUrl;
    this.upSellItemUrl.item1 = this.imgUrl[0];
    this.upSellItemUrl.item2 = this.imgUrl[1];
    this.upSellItemUrl.item3 = this.imgUrl[2];
    this.upSellItemUrl.item4 = this.imgUrl[3];
    this.isSplInst = data.splInst != "*" ? true : false;
    this.splInstText = data.splInst;
  }

  phoneReformat(x) {
    x = x.replace("(", "");
    x = x.replace(")", "");
    x = x.replace("-", "");
    x = x.replace(" ", "");
    return x;
  }
  ETAResp(data) {
    this.ETAResponse.Delivery = data.method.ETAResponse["Delivery"];
    this.ETAResponse.PickUp = data.method.ETAResponse["Pick Up"];
  }

  halloNeeds(data) {
    try {
      let name = data.restaurant.name;
      let addy = data.restaurant.address.split(",")[0];
      let retSt = `At ${name} ${addy} for TODAY`;
      return retSt;
    } catch {
      return "*";
    }
  }

  methodInfo(data) {
    this.method.maddress = data.method.address;
    this.method.mtype = data.method.type.toUpperCase();
    if (this.method.mtype === "PICKUP") {
      // this.isContactFree = false;
    }
    this.ETA = data.method.defaultEta;
    this.method.fOrder = data.method.futureOrder;
    this.isFutureOrder = data.method.isFuture;

    // this.todayText = this.halloNeeds(data)

    // // // console.log('API service ')
    // // // console.log(this.method.mtype)
  }

  addressFromDb(data) {
    this.method.maddress_db = data.method.address_db.replace("*", "");
  }

  dollarValue(data) {
    if (data.couponName.includes("20 Pct Off Pizza:1")) {
      this.couponName = data.couponName.replace(":1, 20 Pct Off Pizza:1, ", "");
    } else if (data.couponName.includes("15 Pct Off Pizza:1")) {
      this.couponName = data.couponName.replace(":1, 15 Pct Off Pizza:1, ", "");
    } else if (data.couponName.includes(":1")) {
      this.couponName = data.couponName.replace(":1", "");
    } else {
      this.couponName = data.couponName;
    }

    this.itemsTotal = data.itemsTotal;
    this.saving = data.savings;
    this.serviceFee = data.serviceFee;
    this.subtotal = data.subtotal;
    this.delivery_fee = data.delivery_fee;
    this.tax = data.tax;
    this.total = parseFloat(data.total);
  }

  removeItem(data) {
    data.buckets.map((x) => {
      const val = `${x.bucket.start}-${x.bucket.end}`;
      this.itemsMarker.push(val);
    });
    // // // console.log(this.itemsMarker)
  }

  paidDollarValue(data) {
    this.paidSavings = data.paidDollarValue.paidSavings;
    this.paidServiceFee = data.serviceFee;
    this.paidSubTotal = data.paidDollarValue.paidSubTotal;
    this.paidTax = data.paidDollarValue.paidTax;
    this.paidTotal = data.paidDollarValue.paidTotal;
    this.paidDeliveryFee = data.paidDollarValue.paidDeliveryFee;
    this.paidTip = data.paidDollarValue.paidTip;
    this.paidCouponName = data.paidDollarValue.paidCouponName.replace(
      ":1, 20 Pct Off Pizza:1,",
      ""
    );
  }

  itemsInfo(data) {
    data.items.map((x, index) => {
      this.items.push(index);
      this.toppings.push(this.replaceUndefined(x.item.Toppings));
      this.qty.push(x.item.quantity);
      this.amounts.push(x.item.price);
      this.itemNames.push(this.replaceUndefined(x.item.name));
      this.sizes.push(this.replaceUndefined(x.item.Size));
      this.cursts.push(this.replaceUndefined(x.item.Crust));
      this.firstHalf.push(this.replaceUndefined(x.item.First_Half));
      this.secondHalf.push(this.replaceUndefined(x.item.Second_Half));
      this.bake.push(this.replaceUndefined(x.item.Bake));
      this.dressing.push(this.replaceUndefined(x.item.Dressing));
      this.drizzle.push(this.replaceUndefined(x.item.Drizzle));
      this.fry.push(this.replaceUndefined(x.item.Fry));
      this.bread.push(this.replaceUndefined(x.item.Bread));
      this.cheese.push(this.replaceUndefined(x.item.Cheese));
      this.seasoning.push(this.replaceUndefined(x.item.Seasoning || x.item.Seasonings));

      this.gravy.push(this.replaceUndefined(x.item.Gravy));
      this.enchilada.push(this.replaceUndefined(x.item.Enchilada));
      this.guacamole.push(this.replaceUndefined(x.item.Guacamole));
      this.soup.push(this.replaceUndefined(x.item.Soup));
      this.sidetwo.push(this.replaceUndefined(x.item.Side_two));
      this.quesadilla.push(this.replaceUndefined(x.item.Quesadilla));
      this.tenderChoice.push(this.replaceUndefined(x.item["Tender choice"]));
      this.burrito.push(this.replaceUndefined(x.item.Burrito));

      this.tacoTortilla.push(this.replaceUndefined(x.item.Taco_tortilla));
      this.lettuce.push(this.replaceUndefined(x.item.Lettuce));
      this.additionals.push(this.replaceUndefined(x.item.Additionals));
      this.yours.push(this.replaceUndefined(x.item.Yours));
      this.crustTopper.push(this.replaceUndefined(x.item.Crust_topper));
      this.calories.push(this.justReplaceUndefined(x.item.Calories));
      this.sauceBase.push(this.replaceUndefined(x.item.Base));
      this.cut.push(this.replaceUndefined(x.item.Cut));
      if (x.item.Addies == "Vio biodegradable foam cup") {
        this.addies.push("-1");
      } else {
        this.addies.push(this.replaceUndefined(x.item.Addies));
      }
      this.isRewardsArr.push(x.item.isRewardItem);
      this.rewardCodeArr.push(x.item.rewardCode);
      this.allergies.push(this.replaceUndefined(x.item.Allergies));
      this.defaults.push(this.replaceUndefined(x.item.Defaults));

      this.drink.push(this.replaceUndefined(x.item.Drink));
      this.side.push(this.replaceUndefined(x.item.Side));
      this.dipping.push(this.replaceUndefined(x.item.Dipping));
      this.dip.push(this.replaceUndefined(x.item.Dip));
      this.style.push(this.replaceUndefined(x.item.Style ||x.item.Styles));
      this.sideBread.push(this.replaceUndefined(x.item.Side_bread));
      this.seasoningPackets.push(
        this.replaceUndefined(x.item["Seasoning packets"])
      );
      this.sauce.push(this.replaceUndefined(x.item.Sauce));
      this.bun.push(this.replaceUndefined(x.item.Bun));
      this.tenderPrep.push(this.replaceUndefined(x.item["Tender prep"]));
      this.syrupChoice.push(this.replaceUndefined(x.item["Syrup choice"]));
      this.silverware.push(this.replaceUndefined(x.item.Silverware));
      this.pieSlice.push(this.replaceUndefined(x.item["Pie slice"]));
      this.remove.push(this.replaceUndefined(x.item.Remove));
      this.proteinChoice.push(this.replaceUndefined(x.item["Protein choice"]));
      this.meatChoice.push(this.replaceUndefined(x.item["Meat choice"]));
      this.iceCreamFlavor.push(
        this.replaceUndefined(x.item["Ice cream flavor"])
      );
      this.fixins.push(this.replaceUndefined(x.item.Fixins));
      this.eggPrep.push(this.replaceUndefined(x.item["Egg prep"]));
      this.dippingSauce.push(this.replaceUndefined(x.item["Dipping sauce"]));
      this.dairy.push(this.replaceUndefined(x.item.Dairy));
      this.sauceType.push(this.replaceUndefined(x.item.Saucetype));
      this.drinks.push(this.replaceUndefined(x.item.Drinks));
      this.pasta.push(this.replaceUndefined(x.item.Pasta));
      this.prep.push(this.replaceUndefined(x.item.Prep));
      this.chips.push(this.replaceUndefined(x.item.Chips));
      this.condiments.push(this.replaceUndefined(x.item.Condiments));
      this.buttersAndJams.push(
        this.replaceUndefined(x.item["Butters and jams"])
      );
      this.breadChoice.push(this.replaceUndefined(x.item["Bread choice"]));
      this.addOns.push(this.replaceUndefined(x.item["Add ons"]));
      this.beverageChoice.push(
        this.replaceUndefined(x.item["Beverage choice"])
      );

      this.addCheese.push(this.replaceUndefined(x.item["Add cheese"]));
      this.threeSelections.push(this.replaceUndefined(x.item["3 selections"]));
      this.twoSides.push(this.replaceUndefined(x.item["2 sides"]));
      this.reciepe.push(this.replaceUndefined(x.item["Recipe"]));
      this.onSide.push(this.replaceUndefined(x.item.onSide));
      this.modNA.push(this.replaceUndefined(x.item.itemNA));
      this.wingType.push(this.replaceUndefined(x.item.Wingtype));
      this.temp.push(this.replaceUndefined(x.item.Temp));
      this.milk.push(this.replaceUndefined(x.item.Milk));
      this.caf.push(this.replaceUndefined(x.item.Caf));
      this.crustFlip.push(this.replaceUndefined(x.item["crust flip"]));
      this.espresso.push(this.replaceUndefined(x.item.Espresso));
      this.meat.push(this.replaceUndefined(x.item.Meat));
      this.notes.push(this.replaceUndefined(x.item.Notes));
      this.meatTopping.push(this.replaceUndefined(x.item.MeatTopping));
      this.veggieTopping.push(this.replaceUndefined(x.item.VeggieTopping));
      this.shell.push(this.replaceUndefined(x.item.Shell));
      this.breadtype.push(this.replaceUndefined(x.item.Breadtype));
      this.tortilla.push(this.replaceUndefined(x.item.Tortilla));
      this.rice.push(this.replaceUndefined(x.item.Rice));
      this.beans.push(this.replaceUndefined(x.item.Beans));
      this.wingDip.push(this.replaceUndefined(x.item["Wing-dip"]));
      this.protein.push(this.replaceUndefined(x.item.Protein));
      this.salsa.push(this.replaceUndefined(x.item.Salsa));
      this.queso.push(this.replaceUndefined(x.item.Queso));
      this.piece.push(this.replaceUndefined(x.item.pieceNo));
      this.required.push(this.replaceUndefined(x.item.Restriction));

      this.options.push(this.replaceUndefined(x.item.Options));
      this.sides.push(this.replaceUndefined(x.item.Sides));
      this.flavor.push(this.replaceUndefined(x.item.Flavor));
      this.extra.push(this.replaceUndefined(x.item.Extras));
      this.kidsDrink.push(this.replaceUndefined(x.item.Kids_drink));
      this.sideTortilla.push(this.replaceUndefined(x.item.Side_tortilla));
      this.isUpSize.push(x.item.upSize);
      // // // console.log(this.isUpSize)
      this.upSizeDisplayString.push(x.item.upSizeTxt);
      this.upSizeText.push(x.item.upSizeString);
      this.notApplicable.push(this.replaceUndefined(x.item.itemNA));
    });
    //comment before pushing
    //data["isError"]=true;
    //data["disText"]="There was a problem adding the coupon: Your selected reward is not valid for this order.";

    if (data["isError"] == undefined) {
      //alert("Hi");
      this.isErrorTimer = false;
    } else {
      if (data.isError == "true" || data.isError == true) {
        this.isErrorTimer = true;
        this.isErrorTimerMsg = data.disText;
        let interval = setInterval(() => {
          this.isErrorTimer = false;
          clearInterval(interval);
        }, 10000);
      } else {
        this.isErrorTimer = false;
      }
    }
    try {
      this.naItems = data.itemsNa.replace("*", "0");
      if (data.itemsNa.length > 5) {
        this.naItems = data.itemsNa.replace("*", "");
      }
      this.keywordText = data.keywordText;
      this.errorText = data.keywordText;
      this.irrelevantText = data.keywordText;
    } catch {
      this.naItems = "0";
      this.keywordText = "-1";
      this.errorText = "-1";
      this.irrelevantText = "-1";
      this.isTotalBox = true;
    }
  }
  resetItems() {
    this.delivery_fee = "0.00";
    this.saving = "0.00";
    this.serviceFee = "0.00";
    this.subtotal = "0.00";
    this.tax = "0.00";
    this.total = 0;
    this.tip = 0.0;
    this.itemsTotal = "0.00";
    this.todayText = "*";
    (this.rewards.availablePoints = ""),
      (this.rewards.afterRedemption = ""),
      (this.rewards.items = []),
      (this.rewards.code = ""),
      (this.rewards.savings = ""),
      (this.rewards.isPoints = false),
      (this.rewards.isRewards = false),
      (this.rewards.isRewardsSelected = false),
      (this.grandTotal = " ");
    this.name = "";
    this.qty = [];
    this.items = [];
    this.toppings = [];
    this.amounts = [];
    this.itemNames = [];
    this.sizes = [];
    this.cursts = [];
    this.firstHalf = [];
    this.secondHalf = [];
    this.bake = [];
    this.piece = [];
    this.wingDip = [];
    this.dressing = [];
    this.seasoning = [];
    this.sides = [];
    this.reciepe = [];
    this.flavor = [];
    this.side = [];
    this.onSide = [];
    this.modNA = [];
    this.crustFlip = [];
    this.drink = [];
    this.sauce = [];
    this.bun = [];
    this.milk = [];
    this.caf = [];
    this.sideBread = [];
    this.options = [];
    this.temp = [];
    this.tacoTortilla = [];
    this.lettuce = [];
    this.yours = [];
    this.additionals = [];
    this.meat = [];
    this.espresso = [];
    this.notes = [];
    this.meatTopping = [];
    this.veggieTopping = [];
    this.shell = [];
    this.breadtype = [];
    this.tortilla = [];
    this.rice = [];
    this.beans = [];
    this.protein = [];
    this.queso = [];
    this.salsa = [];

    this.notApplicable = [];
    this.crustTopper = [];
    this.bread = [];
    this.drizzle = [];
    this.fry = [];
    this.cut = [];
    this.calories = [];
    this.addies = [];
    this.allergies = [];
    this.defaults = [];
    this.dipping = [];
    this.gravy = [];
    this.tenderChoice = [];
    this.enchilada = [];
    this.guacamole = [];
    this.sidetwo = []; //Side_two
    this.quesadilla = [];
    this.soup = [];
    this.burrito = [];
    this.dip = [];
    this.style = [];
    this.extra = [];
    this.kidsDrink = [];
    this.sideTortilla = [];
    this.required = [];
    this.seasoningPackets = [];
    this.sauceBase = [];
    this.drinks = [];
    this.wingType = [];
    this.isRewardsArr = [];
    this.rewardCodeArr = [];
    this.cheese = [];
    this.itemsMarker = [];
    this.isUpSize = [];
    this.tenderPrep = [];
    this.syrupChoice = [];
    this.silverware = [];
    this.pieSlice = [];
    this.remove = [];
    this.proteinChoice = [];
    this.meatChoice = [];
    this.iceCreamFlavor = [];
    this.fixins = [];
    this.eggPrep = [];
    this.dippingSauce = [];
    this.dairy = [];
    this.prep = [];
    this.chips = [];
    this.pasta = [];
    this.condiments = [];
    this.buttersAndJams = [];
    this.breadChoice = [];
    this.addOns = [];
    this.addCheese = [];
    this.threeSelections = [];
    this.twoSides = [];
    this.beverageChoice = [];
    this.upSizeDisplayString = [];
    this.upSizeText = [];

    // this.imgAddTxt = ["10 inch cheesesticks", "Chocolate Chip Cookies", "2 Liter Pepsi", "10 pcs chhicken poppers"]
    /*this.imgUrl = [ "https://ordr-assets.s3.us-east-2.amazonaws.com/upsell/pj/Cheesesticks+Large+Text.jpg"
    ,"https://ordr-assets.s3.us-east-2.amazonaws.com/upsell/pj/Cookie+large+text.jpg"
    ,"https://ordr-assets.s3.us-east-2.amazonaws.com/upsell/pj/Pepsi+Large+Text.jpg"
    ,"https://ordr-assets.s3.us-east-2.amazonaws.com/upsell/pj/Popper+large+text.jpg" ]
     */
    this.imgAddTxt = Array.from(this.resetImgAddTxt);
    this.imgUrl = Array.from(this.resetImgUrl);

    this.naItems = " ";
    this.keywordText = " ";
    this.keywordText = " ";
    this.errorText = " ";
    this.irrelevantText = " ";
    this.upSell.isItem1 = true;
    this.upSell.isItem2 = true;
    this.upSell.isItem3 = true;
    this.upSell.isItem4 = true;
    this.isWing = true;
    this.isBread = true;
    this.isPoppers = true;
    this.isDessert = true;
    this.isPop = true;
    this.isContactFree = false;
    this.naItems = "0";
  }
  helperFunctions() {
    this.keywordValidator();
    this.errorWordValidator();
    this.irrelevantValidator();
    this.phoneFormat();
    this.onlyPayAtStore();
    this.genRefCode();
    this.CustphoneFormat();
    this.name = this.restaurant.name.replace("'", "").toLowerCase();
  }
  // JUMP STATES FUNCTIONS

  /// STATES JUMP FUNCTIONS
  // Tip state with default 15% tip selected
  tipState() {
    this.isContact = false;
    this.isTip = true;
    this.payOnline = false;
    this.payatStore = true;
    this.payOption = true;
    this.isPinStored = false;
    this.defaultTip();
    this.isBt = false;
    // this.currentStateArr.push('tip-state');
  }

  // Name State
  nameState() {
    this.isState_0 = true;
    this.isContact = true;
    this.isTip = false;
    this.isBt = false;
    this.setContactState();
    // this.currentStateArr.push('name-state');
  }

  // Viewstate
  viewState() {
    this.isDeleteBtn = true;
    this.isEditBtn = true;
    this.isAddItemBox = true;
    this.isState_0 = false;
    this.isContact = false;
    this.isBt = false;
    this.isTip = false;
    this.checkoutAllergy = false;
    this.setViewState();
    // this.currentStateArr.push('view-state');
  }

  // Set PIN/Password State
  setPasswordState() {
    this.isContact = false;
    this.isTip = true;
    this.payatStore = true;
    this.payOption = true;
    this.payOnline = false;
    this.isPinStored = true;
    this.isBt = false;
    this.setCreatePinState();
    // this.currentStateArr.push('create-pwd-state');
  }

  // Pay in store or Pay at door -  Submit order page
  payAtStoreState() {
    this.isContact = false;
    this.isTip = true;
    this.payatStore = false;
    this.payOption = true;
    this.payOnline = true;
    this.isBt = false;
    this.payAtStoreState();
    // this.currentStateArr.push('pay-at-store-state');
  }

  // Pay with Password or PIN state
  pinPaymentState() {
    this.isContact = false;
    this.isTip = false;
    this.payatStore = true;
    this.payOption = true;
    this.payOnline = true;
    this.isBt = true;
    this.isBraintree = true;
    this.setPinPaymentState();
    // this.currentStateArr.push('pin-payment-state');
  }

  // Pay with credit card - BT state
  cardPaymentState() {
    this.isOrdrSummary = true;
    this.isContact = false;
    this.isTip = false;
    this.payatStore = true;
    this.payOption = true;
    this.payOnline = true;
    this.isBt = true;
    this.isBraintree = false;
    this.setBtPaymentState();
    // this.currentStateArr.push('card-payment-state');
  }

  // Pay Options screen
  payOptionState() {
    this.isContact = false;
    this.isTip = true;
    this.payOption = false;
    this.payatStore = true;
    this.payOnline = true;
    this.isBt = false;
    this.setPayOption();
    // this.currentStateArr.push('pay-option-state');
  }

  // SET FUNCTIONS TO  SET THE CURRENT STATE

  setViewState() {
    this.currentState = {
      viewState: true,
      isContact: false,
      isTip: false,
      payatStore: false,
      payOption: false,
      payOnline: false,
      isPinStored: false,
      isBt: false,
      isBraintree: false,
    };
    this.checkoutAllergy = false;
  }

  setContactState() {
    this.currentState = {
      viewState: false,
      isContact: true,
      isTip: false,
      payatStore: false,
      payOption: false,
      payOnline: false,
      isPinStored: false,
      isBt: false,
      isBraintree: false,
    };
  }

  setTipState() {
    this.currentState = {
      viewState: false,
      isContact: false,
      isTip: true,
      payatStore: true,
      payOption: true,
      payOnline: false,
      isPinStored: false,
      isBt: false,
      isBraintree: false,
    };
  }

  setPinPaymentState() {
    this.currentState = {
      viewState: false,
      isContact: false,
      isTip: false,
      payatStore: false,
      payOption: false,
      payOnline: false,
      isPinStored: false,
      isBt: true,
      isBraintree: true,
    };
  }

  setBtPaymentState() {
    this.currentState = {
      viewState: false,
      isContact: false,
      isTip: false,
      payatStore: false,
      payOption: false,
      payOnline: false,
      isPinStored: false,
      isBt: true,
      isBraintree: false,
    };
  }

  setCreatePinState() {
    this.currentState = {
      viewState: false,
      isContact: false,
      isTip: true,
      payatStore: true,
      payOption: true,
      payOnline: false,
      isPinStored: true,
      isBt: false,
      isBraintree: false,
    };
  }

  setPayOption() {
    this.currentState = {
      viewState: false,
      isContact: false,
      isTip: true,
      payatStore: true,
      payOption: false,
      payOnline: true,
      isPinStored: false,
      isBt: false,
      isBraintree: false,
    };
  }

  setPayatStoreState() {
    this.currentState = {
      viewState: false,
      isContact: false,
      isTip: true,
      payatStore: false,
      payOption: true,
      payOnline: true,
      isPinStored: false,
      isBt: false,
      isBraintree: false,
    };
  }

  // METHIOD SWAP FUNCTION

  methodSwap(x) {
    this.isMethodChanged = false;
    this.ispickupValidator();
    // IF chabot return error
    if (
      this.keywordText !== "-1" ||
      this.errorText !== "-1" ||
      this.irrelevantText !== "-1"
    ) {
      this.isDeleteBtn = true;
      this.isEditBtn = true;
      this.isAddItemBox = true;
      this.isState_0 = false;
      this.isContact = false;
      this.isBt = false;
      this.isTip = false;
    } else {
      // View-STATE
      if (this.currentState.viewState) {
        // // console.log('view-state');
        if (x.method.type === "pickup") {
        } else {
        }
      }

      // Name -STATE
      if (this.currentState.isContact) {
        // // console.log('name-state');
        if (x.method.type === "pickup") {
          this.nameState();
        } else {
          this.nameState();
        }
      }

      // TIP-STATE
      if (
        this.currentState.isTip &&
        this.currentState.payatStore &&
        this.currentState.payOption &&
        !this.currentState.payOnline &&
        !this.currentState.isPinStored
      ) {
        // // console.log('tip-state');
        if (x.method.type === "pickup") {
          if (x.restaurant.isShowTipState) {
            this.tipState();
          } else {
            this.viewState();
          }
        } else {
          this.tipState();
        }
      }

      // PAY-OPTION STATE
      if (
        this.currentState.isTip &&
        this.currentState.payatStore &&
        !this.currentState.payOption &&
        this.currentState.payOnline &&
        !this.currentState.isPinStored
      ) {
        // // console.log('pay-option-state');
        if (x.method.type === "pickup") {
          if (x.customer.isPayAtStore) {
            this.payOptionState();
          } else {
            if (x.restaurant.isShowTipState) {
              if (this.pinState === "0") {
                // New customer
                this.setPasswordState();
              } else {
                // Existing Customer
                this.tipState();
              }
            } else {
              this.pinPaymentState();
            }
          }
        } else {
          if (x.customer.isPayAtDoor) {
            this.payOptionState();
          } else {
            if (this.pinState === "0") {
              // New customer
              this.setPasswordState();
            } else {
              // Existing Customer
              this.tipState();
            }
          }
        }
      }

      // PAY IN STORE STATE
      if (
        this.currentState.isTip &&
        !this.currentState.payatStore &&
        this.currentState.payOption &&
        this.currentState.payOnline &&
        !this.currentState.isPinStored
      ) {
        // // console.log('pay-at-store-state');
        if (x.method.type === "pickup") {
          if (x.customer.isPayAtStore) {
            this.payAtStoreState();
          } else {
            if (this.pinState === "0") {
              // New Customer
              this.setPasswordState();
            } else {
              // existing customer
              if (x.restaurant.isShowTipState) {
                this.tipState();
              } else {
                this.pinPaymentState();
              }
            }
          }
        } else {
          if (x.customer.isPayAtDoor) {
            this.payAtStoreState();
          } else {
            if (this.pinState === "0") {
              this.setPasswordState();
            } else {
              this.tipState();
            }
          }
        }
      }

      // SET PIN/ PASSWORD
      if (
        this.currentState.isTip &&
        this.currentState.payatStore &&
        this.currentState.payOption &&
        !this.currentState.payOnline &&
        this.currentState.isPinStored
      ) {
        // // console.log('create-pin-state');
        this.setPasswordState();
      }

      // PIN/ PASSWORD PAYMENT
      if (this.currentState.isBt && this.currentState.isBraintree) {
        // // console.log('pin-payment-state');
        if (x.method.type === "pickup") {
          if (x.restaurant.isShowTipState) {
            this.defaultTip();
            this.pinPaymentState();
          } else {
            this.pinPaymentState();
          }
        } else {
          if (x.restaurant.isShowTipState) {
            this.defaultTip();
            this.pinPaymentState();
          } else {
            this.tipState();
          }
        }
      }

      // BT PAYMENT
      if (this.currentState.isBt && !this.currentState.isBraintree) {
        // // console.log('card-payment-state');
        if (x.method.type === "pickup") {
          if (x.restaurant.isShowTipState) {
            this.defaultTip();
            this.cardPaymentState();
          } else {
            this.cardPaymentState();
          }
        } else {
          if (x.restaurant.isShowTipState) {
            this.defaultTip();
            this.cardPaymentState();
          } else {
            this.tipState();
          }
        }
      }
    }
  }

  //NEW LOGIN METHODS
  adminLoginNew(userName, pwd) {
    const userLoginPacket = JSON.stringify({
      emailId: userName,
      password: pwd,
    });
    // // console.log(userLoginPacket);
    const localAddress = `${this.address}re_login`;
    // // console.log(localAddress);
    this.http
      .post<any>(localAddress, userLoginPacket, httpOptions)
      .subscribe((data) => {
        // // console.log('loginRoute');
        // // console.log(data);
        this.LoginInfoNew.next(data);
      });
  }
  passwordResetNew(userName) {
    const userLoginPacket = JSON.stringify({ emailId: userName });
    // // console.log(userLoginPacket);
    const localAddress = `${this.address}resetPassword`;
    // // console.log(localAddress);
    this.http
      .post<any>(localAddress, userLoginPacket, httpOptions)
      .subscribe((data) => {
        // // console.log('loginRoute');
        // // console.log(data);
        this.resetInfoNew.next(data);
      });
  }
  formatTime(time) {
    let timeVal = time.split(" ");
    let timeSplit = timeVal[0].split(":");
    let hours = timeSplit[0];
    let minutes = timeSplit[1];
    let newformat = hours >= 12 ? "PM" : "AM";
    // // console.log(hours, minutes)
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    hours + ":" + minutes + " " + newformat;
    let finalvalue = hours + ":" + minutes + " " + newformat;
    // // console.log(finalvalue)
    return finalvalue;
  }

  cpResetNew(newpassword, hashkey) {
    const userLoginPacket = JSON.stringify({
      hashkey: hashkey + "'",
      password: newpassword,
    });
    // // console.log(userLoginPacket);
    const localAddress = `${this.address}updateCredentails`;
    // // console.log(localAddress);
    // // console.log(userLoginPacket);
    this.http
      .post<any>(localAddress, userLoginPacket, httpOptions)
      .subscribe((data) => {
        // // console.log('loginRoute');
        // // console.log(data);
        this.resetNew.next(data);
      });
  }
 
  stickyHead:any;
  isKeyBoard:any;
  demoPaymentCard:any = {
    cardNumber: '',
    expMonth: '',
    expYear: '',
    cvv: ''
  };
  private chatbotReceiptData = new Subject<any>();
  getchatbotReceipt() {
    return this.chatbotReceiptData.asObservable();
  }
  chatbotReceipt(payload: any) {
    const httpOptionsRecipet = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: '*/*',
        'Access-Control-Allow-Origin': '*'
      }),
      responseType: 'text' as 'json'
    };

    const localAddress = 'https://orderbot.ordrai.com/send_receipt';

    this.http.post<any>(localAddress, payload, httpOptionsRecipet).subscribe(
      (response) => {
        try {
          const parsedData = JSON.parse(response);
          this.chatbotReceiptData.next(parsedData);
        } catch (error) {
          console.warn('Response is not valid JSON:', response);
          this.chatbotReceiptData.next(response); // Send raw response if not JSON
        }
      },
      (error: any) => {
        console.error('Request failed:', error.message);
        this.chatbotReceiptData.next({ error: 'Failed to process receipt' });
      }
    );
  }
  private chatbotDemoData = new Subject<any>();
  getchatbotDemo() {
    return this.chatbotDemoData.asObservable();
  }
  chatbotDemo(payload: any) {
    const localAddress = 'https://orderbot.ordrai.com/chat_order';
    this.http
      .post<any>(localAddress, payload, httpOptions)
      .subscribe((data) => {
        this.chatbotDemoData.next(data);
      });
  }

}
