import { Component, OnInit, Inject } from '@angular/core';
import { APICenterService } from '../apicenter.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '../../../node_modules/@angular/material/dialog';
import { RestContactService } from '../rest-contact.service';

@Component({
  selector: 'app-ordering-steps-dialog',
  templateUrl: './ordering-steps-dialog.component.html',
  styleUrls: ['./ordering-steps-dialog.component.css']
})
export class OrderingStepsDialogComponent implements OnInit {

  constructor(public api: APICenterService, public rest: RestContactService,  public dialogRef: MatDialogRef<OrderingStepsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
