import { RestContactService } from "./../rest-contact.service";
import { OrderingStepsDialogComponent } from "./../ordering-steps-dialog/ordering-steps-dialog.component";
import { Component, OnInit } from "@angular/core";
import { APICenterService } from "../apicenter.service";
import { ActivatedRoute } from "../../../node_modules/@angular/router";

// import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from "../../../node_modules/@angular/material/dialog";
import { DeviceDetectorService } from "../../../node_modules/ngx-device-detector";

@Component({
  selector: "app-rest-offer-page",
  templateUrl: "./rest-offer-page.component.html",
  styleUrls: ["./rest-offer-page.component.css"],
})
export class RestOfferPageComponent implements OnInit {
  deviceInfo = null;
  restName = "";
  restMenuId = "";
  landingData: any;
  show=false;
  constructor(
    public api: APICenterService,
    private restNum: RestContactService,
    private route: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    public dialog: MatDialog
  ) {}
  ngOnInit() {
    this.route.url.subscribe((val) => {
      let sid =
        val[1].path.length === 4
          ? `${val[1].path.substr(0, 2)}0${val[1].path.substr(2)}`
          : val[1].path;
      this.api.cpFetchManageDetail(sid.toUpperCase());
      let fetchmanageDetail = this.api
        .getManageDetails()
        .subscribe((storeResponse) => {
          if (storeResponse.statusCode === 200) {
            this.show=true;
            this.restName = val[0].path;
            this.restMenuId = storeResponse.data;
          }
        });
    });
  }
}
