import { ProServiceService } from './../pro-order/pro-service.service';
import { APICenterService } from './.././apicenter.service';
import { RestContactService } from './../rest-contact.service';
import { ActivatedRoute } from './../../../node_modules/@angular/router';
import { jetsStore } from './../rest-offer-page/jets-store';
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
@Component({
  selector: 'app-store-iam-here',
  templateUrl: './store-iam-here.component.html',
  styleUrls: ['./store-iam-here.component.css']
})
export class StoreIamHereComponent implements OnInit {

  constructor(public pro: ProServiceService,
    public api: APICenterService,
    private restNum: RestContactService,
    private route: ActivatedRoute) { }
  restName;
  crubId;
  restInfo;
  data;
  name = null;
  phone = null;
  vehicle = null;
  notes = null;
  specialOffers = false;
  loading = false;
  ngOnInit() {
    this.route.url
      .subscribe(val => {
        console.log('URL Cutout');
        console.log(val);
        this.crubId = val[1].path;
        this.loading = true;
        this.api.curbInfoUser(this.crubId);
        this.api.getcurbInfoUser()
          .subscribe((data) => {
            console.log(data);
            this.data = data;
            this.loading = false;
            this.api.restaurant.address = data.restInfo[0].completeAddress;
            this.api.displayNum = this.api.formatPhone(data.restInfo[0].restId)
            if (this.data.curbInfo.Name != '*') {
              this.name = this.data.curbInfo.Name;
            }
            if (this.data.curbInfo.CustId != '*') {
              this.phone = this.api.formatPhone(this.data.curbInfo.CustId);
            }
            if (this.data.curbInfo.CarInfo != '*') {
              this.vehicle = this.data.curbInfo.CarInfo;
            }
            if (this.data.curbInfo.OrderInfo.isOptedIn == true) {
              this.specialOffers = true;
            }



          });
      })
  }
  iamhere() {
    var custphone = this.phoneReformat(this.phone);
    this.api.updateCurbInfoUser(
      this.crubId,
      '+1' + custphone,
      this.data.restInfo[0].restId,
      this.vehicle,
      this.notes,
      this.name,
      this.specialOffers
    );
    this.api.getUpdateCurbInfoUser()
      .subscribe((data) => {
        this.data = data;

      });


  }
  phoneReformat(x) {
    x = x.replace('(', '');
    x = x.replace(')', '');
    x = x.replace('-', '');
    x = x.replace(' ', '');
    return x;
  }
}
