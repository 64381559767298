import { APICenterService } from './../apicenter.service';
import { Component, OnInit, NgModule } from '@angular/core';
import { NgForm } from '../../../node_modules/@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  name: string;
  phone = '2159608808';
  displayNum;
  getNum: string;
  isNext = false;
  chainname=''
  lastName=''
  constructor(public api: APICenterService) { }

  ngOnInit() {
    // console.log('quick order');
    // console.log(this.api.quickOrder.isQuickName);
    // console.log('show upsell');
    // console.log(this.api.isShowUpsell);
    this.chainname = this.api.restaurant.name.replace("'", "").toLowerCase();
    console.log('Name state');
    this.api.ispickupValidator();
    this.api.cashEligible();
    this.api.isState_3 = false;
    // console.log(this.api.customer.cid)
    const val = this.api.customer.cid.split('');
    // console.log(val)
    this.displayNum = `(${val[2]}${val[3]}${val[4]}) ${val[5]}${val[6]}${val[7]}-${val[8]}${val[9]}${val[10]}${val[11]}`;
    if (this.api.userName === '*') {
      this.api.userName = null
    }
    if(this.api.customer.lastName==='*'||this.api.customer.lastName===null||this.api.customer.lastName===undefined){
      this.api.customer.lastName='';
    }

    // console.log('Contact');
    // console.log('pay ONline');
    // console.log(this.api.isPayOnline);
    // console.log('At Door');
    // console.log(this.api.isPayAtDoor);
    // console.log('At Store');
    // console.log(this.api.isPayAtStore);
    // console.log('CASH');
    // console.log(this.api.isPayAtStoreEligible);
    // console.log('DOOR');
    // console.log(this.api.isPayAtDoorEligible);

  }

  onBack() {
    this.api.actionbutton("contact_back",	"NS002")
    this.api.setViewState();
    this.api.clickValue = 'Name_Back';
    this.api.clickAnalytics();
    this.api.isDeleteBtn = true;
    this.api.isEditBtn = true;
    this.api.isAddItemBox = true;

    if (this.api.customer.showDisclaimer) {
      this.api.isDisclaimer = true;
      this.api.isContact = false;
    } else {
      this.api.isState_0 = false;
    }
    // console.log('back');

    console.log('on Back - Contact');
   // console.log('isState_0')
   // console.log(this.api.isState_0)
  }

  onNext() {
    // isState_1 - TRUE Hide the view state
    this.api.isState_1 = true;

    this.api.isContact = true;
    this.api.isPinset = true;
    this.api.isDeleteBtn = false;
    this.api.isEditBtn = false;
    this.api.isAddItemBox = false;
    if (this.api.pinState === '0') {

    } else {
      this.api.pinValidator();
    }
  }
  submit(res: NgModule) {
   this.api.clickValue = 'Name_Next';
   this.api.actionbutton("contact_next",	"NS001")
   this.api.clickAnalytics();
   if(this.chainname=='jets'){
     let payload={
      orderId: this.api.ordrID,
      custId: this.api.customer.cid, 
      firstName : this.api.userName,
      lastName:this.api.customer.lastName
    }
    console.log(payload);
    this.api.saveName(payload);
   }
   // console.log(res)
   // console.log('on Next - Contact')
   // console.log('isContact')
   // console.log(this.api.isContact)
    this.api.isState_1 = true;
    this.api.isContact = false;
    this.api.isOrdrSummary = true;


  if ((this.api.isPayOnline) && (this.api.isPayAtDoor) && (this.api.isPayAtStore)) {
      console.log('all Are true');




      if(this.api.isDelivery){
        this.api.checkTipState = true
      }
      if (this.api.method.mtype === "PICKUP") {
      if (this.api.checkTipState || !this.api.isDelivery)  {
        // Delivery Order
        console.log('delivery order');

        if (this.api.isPayAtDoorEligible) {
          // Pay at door Eligible
          this.api.setPayOption();
          console.log('pay at door eligible');
          this.api.isContact = false;
          this.api.isTip = true;
          this.api.payOnline = true; // Online payment
          this.api.payatStore = true; // submit screen - pay at store/pay at door
          this.api.payOption = false; // options online/pay at store/ pay at door
        } else {
          // Pay at door not eligible
          // Hide Contact and SET PIN
          if (this.api.pinState === '0') {
            // New Customer
            this.api.setCreatePinState();
            this.api.isContact = false;
            this.api.isTip = true;
            this.api.payOnline = false;
            this.api.payatStore = true;
            this.api.payOption = true;
            this.api.isPinStored = true;
          } else {
            // Existing Customer
            this.api.setTipState();
            this.api.isContact  = false;
            this.api.isTip = true;
            this.api.payOnline = false;
            this.api.payatStore = true;
            this.api.payOption = true;
            this.api.isPinStored = false;
            this.api.defaultTip();

          }

        }

      } }else {
        // Pick up order
        if (this.api.isPayAtStoreEligible) {
          // Pay at store eligible
          this.api.setPayOption();
          this.api.isContact = false;
          this.api.isTip = true;
          this.api.payOnline = true; // Online payment
          this.api.payatStore = true; // submit screen - pay at store/pay at door
          this.api.payOption = false; // options online/pay at store/ pay at door

        } else {
          console.log('Pay at store  not eligible');
          // pay at store not eligible
          if (this.api.pinState === '0') {
            console.log('New Cust');
            // New Customer
            this.api.isContact = false;
            this.api.isTip = true;
            this.api.payOnline = false;
            this.api.payatStore = true;
            this.api.payOption = true;
            this.api.isPinStored = true;
          } else {
            // Existing Customer
            console.log('existing cust');
            if (this.api.isShowTipState) {
              this.api.isContact = false;
              this.api.isTip = true;
              this.api.payOption = true;
              this.api.payOnline = false;
              this.api.isPinStored = false;
              this.api.payatStore = true;
              this.api.defaultTip();

            } else {
              if (!(this.api.isResetPinClicked)) {
                this.api.isContact  = false;
                this.api.isBt  = true;
                this.api.isBraintree = true;
              } else {
                this.api.isContact  = false;
                this.api.isBt  = true;
                this.api.isBraintree = false;
              }

            }

          }

        }
      }




  } else if ((this.api.isPayAtDoor) && (this.api.isPayAtStore)) {
          console.log ('pay at door and pay at store is true');
          this.api.isContact = false;
          this.api.isTip = true;
          this.api.payOnline = true;
          this.api.payatStore = false;
          this.api.payOption = true;
       } else if ((this.api.isPayOnline) && (this.api.isPayAtDoor) ) {
              console.log ('pay online  and pay at door ');
              if (this.api.isDelivery) {
                this.api.checkTipState = true
              }
              if (this.api.method.mtype === "PICKUP") {
              if (this.api.checkTipState || !this.api.isDelivery)    {
                // Pickup order
                     if (this.api.pinState === '0') {
                      // New customer/ NO PIN set customer
                      // Hide the Contact Show Tip Component => Show SET PIN
                      this.api.setPasswordState();
                     } else {
                       // Existing Customer
                       if (this.api.isShowTipState) {
                         // TIP state true
                         this.api.tipState();

                       } else  {
                         // No Tip state
                         if (this.api.isResetPinClicked) {
                          console.log('reset is clicked');
                          this.api.cardPaymentState();
                        } else  {
                          this.api.pinPaymentState();
                        }

                       }

                     }

                } }else {
                // Delivery Order
                if (this.api.isPayAtDoorEligible) {
                  // Pay at Door is eligible
                  // Hide the Contact, Show Tip Component => Show online/payAtdoor(or)payatStore options
                  this.api.payOptionState();

                } else {
                  // Pay at Door Not Eligible
                  if (this.api.pinState === '0') {
                    // New Customer
                    this.api.setPasswordState();

                  } else  {
                    // existing Customer
                    if (this.api.isShowTipState) {
                      // Show TIP
                      this.api.tipState();

                    } else {
                      this.api.pinPaymentState();
                    }

                  }

                }

              }

           } else if ((this.api.isPayOnline) && (this.api.isPayAtStore) ) {
                 console.log('pay online and pay at store');
                 if (this.api.isDelivery) {
                  this.api.checkTipState = true
                }
                if (this.api.method.mtype === "PICKUP") {
                if (this.api.checkTipState || !this.api.isDelivery)   {
                   // Pickup order
                   if (this.api.isPayAtStoreEligible) {
                     // Pay at store is eligible
                     this.api.payOptionState();
                   } else {
                     // pay at store not eligible
                     if (this.api.pinState === '0') {
                        // New customer/ NO PIN set customer
                        this.api.setPasswordState();

                     } else {
                              // existing customer
                               if (this.api.isShowTipState) {
                                 // show tip state
                                 this.api.tipState();

                               } else {
                                  // NO Tip state
                                  if (this.api.isResetPinClicked) {
                                    console.log('reset is clicked');
                                   this.api.cardPaymentState();
                                  } else  {
                                  this.api.pinPaymentState();
                                  }
                               }

                     }
                   }
                 }} else {
                   // delivery order
                   if (this.api.pinState === '0') {
                      // New customer/ NO PIN set customer
                      this.api.setPasswordState();

                   } else {
                     // existing customer
                     this.api.tipState();
                   }
                 }


               } else {
                    if (!(this.api.isPayOnline)) {

                      // PayOnline is False
                      if (!(this.api.isPayAtDoor)) {

                        // Pay at door is False
                        if (this.api.isPayAtStore) {
                          // Pay at store is TRUE
                              // Next Customer Name State
                            console.log('ONLY PAY AT STORE - Name state');
                            this.api.isTip = true; // TIP state
                            this.api.payOnline = true; // Online payment
                            this.api.payatStore = false; // submit screen - pay at store/pay at door
                            this.api.payOption = true; // options online/pay at store/ pay at door
                        }
                      }  else {
                        // Pay at door is true
                        console.log('ONLY PAY AT Door - Name state');
                        this.api.isTip = true; // TIP state
                        this.api.payOnline = true; // Online payment
                        this.api.payatStore = false; // submit screen - pay at store/pay at door
                        this.api.payOption = true; // options online/pay at store/ pay at door
                       }
                    } else {
                              console.log('online is true');
                              // online is true
                              if (this.api.pinState === '0') {
                                     // New customer/ NO PIN set customer
                                     this.api.setCreatePinState();
                                     console.log('new customer/ No PIN set customer');
                                     this.api.isTip = true;
                                     this.api.payOption = true;
                                     this.api.payatStore = true;
                                     this.api.payOnline = false;
                                     this.api.isPinStored = true;
                                   } else {
                                               // Existing customer
                                               console.log('existing customer');


                                               if (this.api.isDelivery) {
                                                this.api.checkTipState = true
                                              }
                                              if (this.api.method.mtype === "PICKUP") {
                                              if (this.api.checkTipState || !this.api.isDelivery)  {
                                                 // Pick up orders

                                                    if (this.api.isShowTipState) {

                                                      // show Tip State
                                                      this.api.setTipState();
                                                      console.log('show tip state');
                                                      this.api.isContact = false;
                                                      this.api.isTip = true;
                                                      this.api.payOption = true;
                                                      this.api.payatStore = true;
                                                      this.api.payOnline = false;
                                                      this.api.isPinStored = false;
                                                      this.api.defaultTip();

                                                    } else {
                                                      // with out Tip State
                                                      console.log('dont show tip state');
                                                      if (this.api.isResetPinClicked) {
                                                        this.api.setBtPaymentState();
                                                        this.api.isContact = false;
                                                        this.api.isBt = true;
                                                        this.api.isBraintree = false;
                                                      } else {
                                                        this.api.setPinPaymentState();
                                                        this.api.isContact = false;
                                                        this.api.isBt = true;
                                                        this.api.isBraintree = true;
                                                      }


                                                    }

                                               }} else {

                                                      // delivery order
                                                      this.api.setTipState();
                                                      this.api.isContact = false;
                                                      this.api.isTip = true;
                                                      this.api.payOption = true;
                                                      this.api.payatStore = true;
                                                      this.api.payOnline = false;
                                                      this.api.isPinStored = false;
                                                      this.api.defaultTip();

                                               }


                                            }


                    }

                   }







/*
   //console.log('isCashEligible')
   //console.log(this.api.isCashEligible)
    if(this.api.isCashEligible) {
      console.log('cas eligible  - true')
      this.api.isTip = true;
      //console.log('payOnline')
      //console.log(this.api.payOnline)
      this.api.payOnline = true;
      //console.log('payOption')
      //console.log(this.api.payOption)
      this.api.payOption = false;

    } else {
      console.log('cas eligible  - false')
      if(this.api.pinState === '0'){
        console.log('new customer')
            this.api.isTip = true;
            this.api.payOnline = false;
            this.api.payOption = true;
            this.api.isPinStored = true
          }
        else {
          console.log('existing customer')
          this.api.pinValidator()

          if(this.api.isDelivery) {
            console.log('delivery order')
            this.api.isTip = true;
            this.api.isPinStored = false;
            this.api.payOnline= false;
            this.api.payatStore = true;
            this.api.payOption = true;

          } else {
            this.api.isBt = true;

          }
          //console.log('isCashEligible')
          //console.log(this.api.isCashEligible)
          //console.log('isTip')
          //console.log(this.api.isTip)
          this.api.isTip = true;
          //console.log('payOnline')
          //console.log(this.api.payOnline)
          this.api.payOnline = true;
          //console.log('payOption')
          //console.log(this.api.payOption)
          this.api.payOption = false;


        }

    } */
    if (this.api.onUpSellSelect) {
      this.api.resetItems();
      this.api.updateUserMsg();
    }

  }


  result(res: NgModule) {
  console.log('on submit');
  console.log(res);
  this.isNext = true;
  this.api.clickValue = 'Name_Next';
  this.api.clickAnalytics();
  this.api.custInfo(this.api.quickOrder.custId, this.api.ordrID);
  this.api.getPostName()
  .subscribe((data) => {
    console.log(data);
    this.api.quickOrder.custId = this.api.formatPhone(data.customer.id);
         // console.log(res)
   // console.log('on Next - Contact')
   // console.log('isContact')
   // console.log(this.api.isContact)
    // this.api.customer.cid = `+1${this.getNum}`
    this.isNext = false;
    this.api.isState_1 = true;
    this.api.isContact = false;
    this.api.isOrdrSummary = true;


  if ((this.api.isPayOnline) && (this.api.isPayAtDoor) && (this.api.isPayAtStore)) {
      // console.log('all Are true');




      if (this.api.isDelivery) {
        this.api.checkTipState = true
      }
      if (this.api.method.mtype === "PICKUP") {
      if (this.api.checkTipState || !this.api.isDelivery) {
        // Delivery Order
        // console.log('delivery order');

        if (this.api.isPayAtDoorEligible) {
          // Pay at door Eligible
          // console.log('pay at door eligible');
          this.api.isContact = false;
          this.api.isTip = true;
          this.api.payOnline = true; // Online payment
          this.api.payatStore = true; // submit screen - pay at store/pay at door
          this.api.payOption = false; // options online/pay at store/ pay at door
          this.api.defaultTip();
        } else {
          // Pay at door not eligible
          // Hide Contact and SET PIN
          if (this.api.pinState === '0') {
            // New Customer
            this.api.isContact = false;
            this.api.isTip = true;
            this.api.payOnline = false;
            this.api.payatStore = true;
            this.api.payOption = true;
            this.api.isPinStored = true;
          } else {
            // Existing Customer
            this.api.isContact  = false;
            this.api.isTip = true;
            this.api.payOnline = false;
            this.api.payatStore = true;
            this.api.payOption = true;
            this.api.isPinStored = false;
            this.api.defaultTip();

          }

        }

      }} else {
        // Pick up order
        if (this.api.isPayAtStoreEligible) {
          // Pay at store eligible
          this.api.isContact = false;
          this.api.isTip = true;
          this.api.payOnline = true; // Online payment
          this.api.payatStore = true; // submit screen - pay at store/pay at door
          this.api.payOption = false; // options online/pay at store/ pay at door

        } else {
          // console.log('Pay at store  not eligible');
          // pay at store not eligible
          if (this.api.pinState === '0') {
            // console.log('New Cust');
            // New Customer
            this.api.isContact = false;
            this.api.isTip = true;
            this.api.payOnline = false;
            this.api.payatStore = true;
            this.api.payOption = true;
            this.api.isPinStored = true;
          } else {
            // Existing Customer
            // console.log('existing cust');
            if (this.api.isShowTipState) {
              this.api.isContact = false;
              this.api.isTip = true;
              this.api.payOption = true;
              this.api.payOnline = false;
              this.api.isPinStored = false;
              this.api.payatStore = true;
              this.api.defaultTip();

            } else {
              if (!(this.api.isResetPinClicked)) {
                this.api.isContact  = false;
                this.api.isBt  = true;
                this.api.isBraintree = true;
              } else {
                this.api.isContact  = false;
                this.api.isBt  = true;
                this.api.isBraintree = false;
              }

            }

          }

        }
      }




  } else if ((this.api.isPayAtDoor) && (this.api.isPayAtStore)) {
          // console.log ('pay at door and pay at store is true');
          this.api.isContact = false;
          this.api.isTip = true;
          this.api.payOnline = true;
          this.api.payatStore = false;
          this.api.payOption = true;
       } else if ((this.api.isPayOnline) && (this.api.isPayAtDoor) ) {
              // console.log ('pay online  and pay at door ');
              if (this.api.isDelivery) {
                this.api.checkTipState = true
              }
              if (this.api.method.mtype === "PICKUP") {
              if (this.api.checkTipState || !this.api.isDelivery)  {
                // Pickup order


                     if (this.api.pinState === '0') {
                      // New customer/ NO PIN set customer
                      // Hide the Contact Show Tip Component => Show SET PIN
                     this.api.isContact = false;
                     this.api.isTip = true;
                     this.api.payOnline = false;
                     this.api.payatStore = true;
                     this.api.payOption = true;
                     this.api.isPinStored = true;
                     } else {
                       // Existing Customer
                       if (this.api.isShowTipState) {
                         // TIP state true
                         this.api.isContact = false;
                         this.api.isTip = true;
                         this.api.payOnline = false;
                         this.api.payatStore = true;
                         this.api.payOption = true;
                         this.api.isPinStored = false;
                         this.api.defaultTip();

                       } else  {
                         // No Tip state
                         if (this.api.isResetPinClicked) {
                          // console.log('reset is clicked');
                         this.api.isContact = false;
                         this.api.isBt = true;
                         this.api.isBraintree = false;
                        } else  {
                         this.api.isContact = false;
                         this.api.isBt = true;
                         this.api.isBraintree = true;
                        }

                       }

                     }

                } }else {
                // Delivery Order
                if (this.api.isPayAtDoorEligible) {
                  // Pay at Door is eligible
                  // Hide the Contact, Show Tip Component => Show online/payAtdoor(or)payatStore options
                  this.api.isContact = false;
                  this.api.isTip = true;
                  this.api.payOnline = true; // Online payment
                  this.api.payatStore = true; // submit screen - pay at store/pay at door
                  this.api.payOption = false; // options online/pay at store/ pay at door

                } else {
                  // Pay at Door Not Eligible
                  if (this.api.pinState === '0') {
                    // New Customer
                    // Hide Contact
                    this.api.isContact = false;
                    // Show Tip Compononet => SET PIN
                    this.api.isTip = true;
                    this.api.payOnline = false;
                    this.api.payatStore = true;
                    this.api.payOption = true;
                    this.api.isPinStored = true;

                  } else  {
                    // existing Customer
                    if (this.api.isShowTipState) {
                      // Show TIP
                      // Hide Contact
                      this.api.isContact = false;
                      // Show Tip Component ==> TIP OPtions
                      this.api.isTip = true;
                      this.api.payOnline = false;
                      this.api.payatStore = true;
                      this.api.payOption = true;
                      this.api.isPinStored = false;
                      this.api.defaultTip();

                    } else {
                      // No TIP state
                      // Hide the Contact, show Bt Component ==> PIN Payment
                      this.api.isContact = false;
                      this.api.isBt = true;
                      this.api.isBraintree = true;
                    }


                  }


                }


              }

           } else if ((this.api.isPayOnline) && (this.api.isPayAtStore) ) {
                 // console.log('pay online and pay at store');
                 if(this.api.isDelivery && this.api.isPayAtDoor){
                  this.api.checkTipState = true
                } else {
                  this.api.checkTipState = false
                }
                if (this.api.method.mtype === "PICKUP") {
                if (this.api.checkTipState || !this.api.isDelivery)  {
                   // Pickup order
                   if (this.api.isPayAtStoreEligible) {
                     // Pay at store is eligible
                     this.api.payOptionState();

                   } else {
                     // pay at store not eligible
                     if (this.api.pinState === '0') {
                        // New customer/ NO PIN set customer
                        this.api.setPasswordState();

                     } else {
                              // existing customer
                               if (this.api.isShowTipState) {
                                 // show tip state
                                 this.api.tipState();

                               } else {
                                  // NO Tip state
                                  if (this.api.isResetPinClicked) {
                                    // console.log('reset is clicked');
                                   this.api.cardPaymentState();
                                  } else  {
                                  this.api.pinPaymentState();
                                  }
                               }

                     }
                   }
                 } }else {
                   // delivery order
                   if (this.api.pinState === '0') {
                      // New customer/ NO PIN set customer
                      this.api.setPasswordState();

                   } else {
                     // existing customer
                     this.api.tipState();
                   }
                 }


               } else {
                    if (!(this.api.isPayOnline)) {

                      // PayOnline is False
                      if (!(this.api.isPayAtDoor)) {

                        // Pay at door is False
                        if (this.api.isPayAtStore) {
                          // Pay at store is TRUE
                              // Next Customer Name State
                            // console.log('ONLY PAY AT STORE - Name state');
                            this.api.isTip = true; // TIP state
                            this.api.payOnline = true; // Online payment
                            this.api.payatStore = false; // submit screen - pay at store/pay at door
                            this.api.payOption = true; // options online/pay at store/ pay at door
                        }
                      }  else {
                        // Pay at door is true
                        // console.log('ONLY PAY AT Door - Name state');
                        this.api.isTip = true; // TIP state
                        this.api.payOnline = true; // Online payment
                        this.api.payatStore = false; // submit screen - pay at store/pay at door
                        this.api.payOption = true; // options online/pay at store/ pay at door
                       }
                    } else {
                              // console.log('online is true');
                              // online is true
                              if (this.api.pinState === '0') {
                                     // New customer/ NO PIN set customer
                                     this.api.setCreatePinState();
                                     // console.log('new customer/ No PIN set customer');
                                     this.api.isTip = true;
                                     this.api.payOption = true;
                                     this.api.payatStore = true;
                                     this.api.payOnline = false;
                                     this.api.isPinStored = true;
                                   } else {
                                               // Existing customer
                                               // console.log('existing customer');


                                               if (this.api.isDelivery) {
                                                this.api.checkTipState = true
                                              }
                                              if (this.api.method.mtype === "PICKUP") {
                                              if (this.api.checkTipState || !this.api.isDelivery)  {
                                                 // Pick up orders

                                                    if (this.api.isShowTipState) {

                                                      // show Tip State
                                                      this.api.setTipState();
                                                      // console.log('show tip state');
                                                      this.api.isContact = false;
                                                      this.api.isTip = true;
                                                      this.api.payOption = true;
                                                      this.api.payatStore = true;
                                                      this.api.payOnline = false;
                                                      this.api.isPinStored = false;
                                                      this.api.defaultTip();

                                                    } else {
                                                      // with out Tip State
                                                      // console.log('dont show tip state');
                                                      if (this.api.isResetPinClicked) {
                                                        this.api.setBtPaymentState();
                                                        this.api.isContact = false;
                                                        this.api.isBt = true;
                                                        this.api.isBraintree = false;
                                                      } else {
                                                        this.api.pinPaymentState();
                                                        this.api.isContact = false;
                                                        this.api.isBt = true;
                                                        this.api.isBraintree = true;
                                                      }


                                                    }

                                               } }
                                      
                                               else {

                                                      // delivery order
                                                      this.api.setTipState();
                                                      this.api.isContact = false;
                                                      this.api.isTip = true;
                                                      this.api.payOption = true;
                                                      this.api.payatStore = true;
                                                      this.api.payOnline = false;
                                                      this.api.isPinStored = false;
                                                      this.api.defaultTip();

                                               }


                                            }


                    }

                   }

    if (this.api.onUpSellSelect) {
      this.api.resetItems();
      this.api.updateUserMsg();
    }
  });

  this.api.customer.cid = this.getNum;



  }


  name_fn(x) {
    // // console.log(x)
    // console.log(x.value)
    this.api.userName = x.value;
    this.api.userName.trim();
    // console.log(this.api.userName)
  }
  blur(name){
    this.api.userName=name.value.trim();
    
  }
  name_fn_last(x) {
    // // console.log(x)
    // console.log(x.value)
    this.api.customer.lastName = x.value;
    this.api.customer.lastName.trim();
    // console.log(this.api.userName)
  }
  blurLast(name){
    this.api.customer.lastName=name.value.trim();
    
  }
}
