import { Component, OnInit } from '@angular/core';
import { APICenterService } from '../apicenter.service';

@Component({
  selector: 'app-summary4fax',
  templateUrl: './summary4fax.component.html',
  styleUrls: ['./summary4fax.component.css']
})
export class Summary4faxComponent implements OnInit {

  constructor(public api: APICenterService) { }

  ngOnInit() {

  }

}
