import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '../../../node_modules/@angular/router';
import { APICenterService } from '../apicenter.service';
import { RestContactService } from '../rest-contact.service';

@Component({
  selector: 'app-uistart',
  templateUrl: './uistart.component.html',
  styleUrls: ['./uistart.component.css']
})
export class UIStartComponent implements OnInit {

  constructor(public api : APICenterService, private route: ActivatedRoute, private restNum: RestContactService,) { }
  
  restCode

  ngOnInit() {
    this.api.resetItems()
    this.api.isUIOrdrStart = true;
    this.route.url
     .subscribe(val => {
       console.log('URL Cutout')
       console.log(val)
       this.restCode = val[1].path;
       console.log(this.restCode)
     }) 
     this.getRestId() 
     this.api.menuRestId = this.api.restaurant.rid
     this.api.getrestinfo()
  }

  getRestId() {
    if(this.restCode === 'mi026') {
      this.api.restaurant.rid = this.restNum.restaurant.mi026
      console.log(this.api.restaurant.rid)
    } 
  }

}
