import { APICenterService } from './../apicenter.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  year 
  constructor(public api : APICenterService) { }

  ngOnInit() {
    let dt = new Date();
    this.year = dt.getFullYear()
    // console.log(this.year)
    this.api.isFullFooter = true;

  }
 

}
