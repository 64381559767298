export class centercourtStore {

    constructor() { }

    findStore(sid) {
        switch (sid) {
        
                case 'cc01':
                let sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 20% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18328436057',
                    nickName: '24345 Gosling Rd location in Spring',
                    addy1: '24345 Gosling Rd',
                    addy2: 'Spring, TX'
                };
                return sidInfo;
            case 'cc02':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 20% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17134363927',
                    nickName: '9721 Broadway St location in Pearland',
                    addy1: '9721 Broadway St',
                    addy2: 'Pearland, TX'
                };
                return sidInfo;
             case 'cc03':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 20% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18329470440',
                    nickName: '7425 Hwy 6 location in Missouri City',
                    addy1: '7425 Hwy 6',
                    addy2: 'Missouri City, TX'
                };
                return sidInfo;
             case 'cc04':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 20% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12813397462',
                    nickName: '3202 Marina Bay Dr, location in League City',
                    addy1: '3202 Marina Bay Dr,',
                    addy2: 'League City, TX'
                };
                return sidInfo;


            default:
                sidInfo = {
                    discountTxt: '*',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'Texting is the fastest way to order Jet’s!',
                    successPro: 'PRO ORDERS ARE 20% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: 'empty',
                    nickName: 'empty',
                    addy1: 'empty',
                    addy2: 'empty'
                };
                return sidInfo;
        }
        // console.log(cust)
    }
}
