import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { APICenterService } from "../../apicenter.service";
import { ChangeDetectionStrategy } from "@angular/core";
@Component({
  selector: "app-demo-card",
  templateUrl: "./demo-card.component.html",
  styleUrls: ["./demo-card.component.css"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DemoCardsComponent implements OnInit {
  lock = false;
  show = true;
  constructor(public api: APICenterService) {}
  @Output("paymentSubmit") submit: EventEmitter<any> = new EventEmitter();
  ngOnInit(): void {}
  onSubmit() {
    this.lock = true;
    this.submit.emit();
  }
  disabled() {
    if (
      this.api.demoPaymentCard.cardNumber == '' ||
      this.api.demoPaymentCard.expMonth=='' ||
      this.api.demoPaymentCard.expYear == '' ||
      this.api.demoPaymentCard.cvv==''
    ) {
      return true;
    }
    else{
      return false;
    }
  }
  formatCardNumber(event: any) {
    const input = event.target;
    input.value = input.value
      .replace(/\D/g, "")
      .replace(/(\d{4})(?=\d)/g, "$1 ");
    this.api.demoPaymentCard.cardNumber = input.value;
  }
}
