import { ProServiceService } from "./../pro-service.service";
import { APICenterService } from "../../apicenter.service";
import { RestContactService } from "../../rest-contact.service";
import { ActivatedRoute } from "../../../../node_modules/@angular/router";
import { jetsStore } from "../../rest-offer-page/jets/jets-store";
import { chowlyStore } from "src/app/rest-offer-page/chowly/chowly-store";
import { checkmateStore} from "../../rest-offer-page/itsacheckmate/checkmate-store";
import {littleCaesarsStore} from "../../rest-offer-page/little-caesars/little-caesars-store";
 import{ Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
@Component({
  selector: "app-quick-order-base",
  templateUrl: "./quick-order-base.component.html",
  styleUrls: ["./quick-order-base.component.css"],
})
export class QuickOrderBaseComponent implements OnInit {
  constructor(
    public pro: ProServiceService,
    public api: APICenterService,
    private restNum: RestContactService,
    private route: ActivatedRoute
  ) {
    this.deviceDetect();
  }
  restName;
  restId;
  restInfo;
  rid;
  year;
  ngOn;
  ngOnInit() {
    const dt = new Date();
    this.year = dt.getFullYear();
    // console.log(this.year)
    this.api.isFullFooter = true;
    var menulocation = document.getElementById("menubox");
    console.log(menulocation.scrollLeft);
    console.log(menulocation.scrollTop);

    this.route.url.subscribe((val) => {
      console.log("URL Cutout");
      console.log(val);
      this.restName = val[1].path;
      this.restId = val[2].path;
      this.pro.selectedSID = this.restId;
      localStorage.setItem("SID", this.restId);
      console.log(this.restId);
      let sid;
      if (this.restName == "jets") {
        sid = new jetsStore();
      } else if (this.restName == "chowly") {
        sid = new chowlyStore();

      }
      else if(this.restName=='itsacheckmate'){
        sid = new checkmateStore();
      }
      else if(this.restName==='littlecaesars'){
        sid= new littleCaesarsStore();
      }
      else {
        sid = new jetsStore();
      }
      this.api.restName=this.restName;
      this.restInfo = sid.findStore(this.restId);
      console.log(this.restInfo);
      // this.restDisplayNo = this.api.formatPhone(this.restInfo.rid)
      console.log("rest info");
      console.log(this.restInfo.rid);
      this.api.quickOrder.restId = this.restInfo.rid;
      // this.api.restInfo(this.restId)
      this.api.restInfo(this.restInfo.rid);
    });
  }

  deviceDetect() {
    const isMobile = /iPhone|Android/i.test(navigator.userAgent);

    if (isMobile) {
      this.pro.mobile = true;
    } else {
      this.pro.mobile = false;
    }
  }
}
