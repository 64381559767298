import { Component, OnInit, Inject } from "@angular/core";
import { truncate } from "fs";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "../../../../node_modules/@angular/material/dialog";
import { APICenterService } from "../../apicenter.service";

@Component({
  selector: "app-smoothie-king-allergies",
  templateUrl: "./smoothie-king-allergies.component.html",
  styleUrls: ["./smoothie-king-allergies.component.css"],
})
export class SmoothieKingAllergiesComponent implements OnInit {
  constructor(
    public api: APICenterService,
    public dialogRef: MatDialogRef<SmoothieKingAllergiesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    window.scroll(0,0);
  }
  disableNext(){
    for (var i = 0; i < this.api.allergiesList.length; i++) {
      if (this.api.allergiesList[i].value == true) {
       return true
      }
    }
    return false;
  }
  selectAllegry(value) {
    for (var i = 0; i < this.api.allergiesList.length; i++) {
      if (this.api.allergiesList[i].id == "none") {
        this.api.allergiesList[i].value = false;
      }
    }
    if (value.id == "none") {
      for (var i = 0; i < this.api.allergiesList.length; i++) {
        if (this.api.allergiesList[i].id == value.id) {
          this.api.allergiesList[i].value = !this.api.allergiesList[i].value;
        } else {
          this.api.allergiesList[i].value = false;
        }
      }
    } else {
      for (var i = 0; i < this.api.allergiesList.length; i++) {
        if (this.api.allergiesList[i].id == value.id) {
          this.api.allergiesList[i].value = !this.api.allergiesList[i].value;
        }
      }
    }
  }
  close() {
    this.dialogRef.close();
  }
  save(){
    let list=[]
    for (var i = 0; i < this.api.allergiesList.length; i++) {
      if (this.api.allergiesList[i].value == true) {
       list.push(this.api.allergiesList[i].name+" - " + this.api.allergiesList[i].description)
      }
    }
    var payload={
      ordrId : this.api.ordrID,
      allegryList : list
      }
    this.api.updateAllergy(payload);
    this.api.getUpdateAllergy()
    .subscribe((data) => {
    this.api.checkoutAllergy=true;
    this.close();
    })
  }
}
