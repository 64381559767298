export class masterStore {

    constructor() { }

    findStore(sid) {
        switch (sid) {
            case 'ma01':
                let sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF',
                    successPro: 'PRO ORDERS ARE 20% OFF',
                    isTxt: true,
                    rid: '+12019030050',
                    nickName: '401 Hackensack St location in Carlstadt',
                    addy1: '401 Hackensack St',
                    addy2: 'Carlstadt, NJ'
                };
                return sidInfo;
            case 'ma02':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF',
                    successPro: 'PRO ORDERS ARE 20% OFF',
                    isTxt: true,
                    rid: '+19739924500',
                    nickName: '379 E Northfield Rd location in Livingston',
                    addy1: '379 E Northfield Rd',
                    addy2: 'Livingston, NJ'
                };
                return sidInfo;
            case 'ma03':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF ',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF!',
                    successPro: 'PRO ORDERS ARE 20% OFF!',
                    isTxt: true,
                    rid: '+19089644600',
                    nickName: '2704 Morris Ave location in Union',
                    addy1: '2704 Morris Ave',
                    addy2: 'Union, NJ'
                };
                return sidInfo;
            case 'ma04':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 20% OFF',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 20% OFF',
                    successPro: 'PRO ORDERS ARE 20% OFF',
                    isTxt: true,
                    rid: '+19736696777',
                    nickName: '41 Freeman St location in West Orange',
                    addy1: '41 Freeman St',
                    addy2: 'West Orange, NJ'
                };
                return sidInfo;

            default:
                sidInfo = {
                    discountTxt: '*',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'Texting is the fastest way to order Jet’s!',
                    successPro: 'PRO ORDERS ARE 20% OFF ',
                    isTxt: true,
                    rid: 'empty',
                    nickName: 'empty',
                    addy1: 'empty',
                    addy2: 'empty'
                };
                return sidInfo;
        }
        // console.log(cust)
    }
}
