import { APICenterService } from './../apicenter.service';
import { Component, OnInit } from '@angular/core';
import { trigger, transition, animate, style } from '../../../node_modules/@angular/animations';
import { MatDialog } from '../../../node_modules/@angular/material/dialog';
import { MenuDialogComponent } from '../menu-dialog/menu-dialog.component';
import { Router } from '../../../node_modules/@angular/router';

@Component({
  selector: 'app-quick-start-page',
  templateUrl: './quick-start-page.component.html',
  styleUrls: ['./quick-start-page.component.css'],
  animations: [
    trigger('fade-out', [
      transition ('* => void', [
        animate(1500, style({ opacity:0}))
      ] )
    ])
  ]
})
export class QuickStartPageComponent implements OnInit {

  constructor(public api: APICenterService, public dialog: MatDialog, private _router : Router) { }

  ngOnInit() {
  }
  pickup() {
    this.api.quickOrder.isDelivery = false;
  }

  delivery() {
    this.api.quickOrder.isDelivery = true;
  }

  onUpdate(){
    this.api.quickOrder.restId = '+17344422075'
    console.log(this.api.quickOrder)
    this.api.quickOrdr()
    this.api.getPostQuickOrdr()
    .subscribe((data) => {
      this.api.ordrID = data.ordrId
      const routeId = `/myordr/${this.api.ordrID}`
      this._router.navigate([routeId]) 
    })
  }

  openDialog(): void {
    this.api.clickValue = 'Menu'
    this.api.clickAnalytics() 
    const dialogRef = this.dialog.open(MenuDialogComponent, {
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}
