import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "../../../node_modules/@angular/material/dialog";
import { APICenterService } from "../apicenter.service";

@Component({
  selector: "app-login-popup",
  templateUrl: "./login-popup.component.html",
  styleUrls: ["./login-popup.component.css"],
})
export class LoginPopupComponent implements OnInit {
  constructor(
    public api: APICenterService,
    public dialogRef: MatDialogRef<LoginPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  username: any;
  password: any;
  lastdigits = "";
  showLogin = true;
  loginError = false;
  loginErrorMessage = "";
  emailError = false;
  emailErrorMessage = "";
  otpScreen = false;
  forgotPasswordEmail = false;
  otp = "";

  passwordScreen = false;
  passwordError = false;
  passwordErrorMessage = "";
  newPassword = "";
  newPasswordConfirm = "";
  passwordLength = false;
  passwordAlphabet = false;
  passwordNumeric = false;
  passwordSpecialCharacter = false;
  emailForReset = "";
  otpError = false;
  otpErrorMessage = "";
  newCodeText = false;
  loading = false;
  ngOnInit() {}
  async signin() {
    this.emailReset();
    this.otpReset();
    this.resetPasswordReset();
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.username)) {
      this.loginError = false;
      this.loginErrorMessage = "";
      let payload = {
        customerId: this.api.customer.cid,
        emailId: this.username,
        password: this.password,
        RestId: this.api.restaurant.rid,
        orderId: this.api.ordrID,
      };
      this.loading = true;
      this.api.popupLoginSuccess = true;
      this.api.resetItems();
      this.api.onSend = true;
      localStorage.setItem("punchUserName", this.username);
      localStorage.setItem("punchPassword", this.password);
      this.api.updateUserMsg();
      this.api.getsummaryData().subscribe((data) => {
        console.log(data);
        if (this.api.loginSuccess == "loggedout") {
          this.loginError = true;
          this.loading = false;
          this.loginErrorMessage = "Oops! The user name or password provided is incorrect.";
        } else {
          this.close();
        }
      });
    } else {
      this.loginError = true;
      this.loginErrorMessage = "Please enter a valid email.";
    }
  }
  close() {
    this.dialogRef.close();
  }
  emailValidation() {
    if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emailForReset)
    ) {
      return false;
    } else {
      return true;
    }
  }
  requestNewCode() {
    this.otpError = false;
    this.otpErrorMessage = "";
    let payload = {
      emailId: this.emailForReset,
      custId: this.api.customer.cid,
    };
    console.log(payload);
    this.loading = true;
    this.api.resetPunchhPassword(payload);
    this.api.getresetPunchhPassword().subscribe((data) => {
      this.loading = false;
      if (data.status == "200") {
        this.newCodeText = true;
        this.otp = "";
      } else {
        this.otpError = true;
        this.otpErrorMessage = data.msg;
      }
    });
  }
  submitEmail() {
    this.emailError = false;
    this.emailErrorMessage = "";

    if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emailForReset)
    ) {
      this.emailError = true;
      this.emailErrorMessage = "Please enter a valid Email";
    } else {
      let payload = {
        emailId: this.emailForReset,
        custId: this.api.customer.cid,
      };
      console.log(payload);
      this.loading = true;
      this.api.resetPunchhPassword(payload);
      this.api.getresetPunchhPassword().subscribe((data) => {
        this.loading = false;
        if (data.custPhone) {
          this.lastdigits = data.custPhone.substr(data.custPhone.length - 4);
        }
        if (data.status == "200") {
          this.forgotPasswordEmail = false;
          this.otpScreen = true;
          this.otp = "";
          this.newCodeText = false;
        } else {
          this.emailError = true;
          this.emailErrorMessage = data.msg;
        }
      });
    }
  }
  forgotPassword() {
    this.loginReset();
    this.otpReset();
    this.resetPasswordReset();
    this.forgotPasswordEmail = true;
    this.showLogin = false;
  }
  backToLoginScreen() {
    this.forgotPasswordEmail = false;
    this.showLogin = true;
  }
  backToEmailScreen() {
    this.forgotPasswordEmail = true;
    this.otpScreen = false;
  }
  onOtpChange($event) {
    this.otp = "";
    this.otp = $event;
  }
  validateOtp() {
    this.otpError = false;
    this.otpErrorMessage = "";
    let payload = {
      otp: this.otp,
      emailId: this.emailForReset,
      custId: this.api.customer.cid,
      orderId: this.api.ordrID,
    };
    this.loading = true;
    this.api.validatePunchhOTP(payload);
    this.api.getvalidatePunchhOTP().subscribe((data) => {
      this.loading = false;
      if (data.status == "200") {
        this.otpScreen = false;
        this.passwordScreen = true;
      } else {
        this.otpError = true;
        this.otpErrorMessage = "Please enter a valid verification code.";
      }
    });
  }

  validation(newPassword) {
    var numbersregex = /^(?=.*[0-9])/;
    var alphabetsregex = /^(?=.*[a-zA-Z])/;
    var specialcharregex = /^(?=.*[!@#$%^&*])/;
    var numbers = numbersregex.test(newPassword);
    var alphabets = alphabetsregex.test(newPassword);
    var special = specialcharregex.test(newPassword);
    if (newPassword.length >= 8) {
      this.passwordLength = true;
    } else {
      this.passwordLength = false;
    }
    if (numbers == true) {
      this.passwordNumeric = true;
    } else {
      this.passwordNumeric = false;
    }
    if (alphabets == true) {
      this.passwordAlphabet = true;
    } else {
      this.passwordAlphabet = false;
    }
    if (special == true) {
      this.passwordSpecialCharacter = true;
    } else {
      this.passwordSpecialCharacter = false;
    }
  }
  passworddisable() {
    if (this.newPassword != "" || this.newPassword != null) {
      if (
        this.passwordLength &&
        this.passwordAlphabet &&
        this.passwordNumeric &&
        this.passwordSpecialCharacter
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
  submitPassword() {
    this.passwordError = false;
    this.passwordErrorMessage = "";
    if (this.newPassword != this.newPasswordConfirm) {
      this.passwordError = true;
      this.passwordErrorMessage = "Passwords Don't Match.";
    } else if (this.newPassword == this.newPasswordConfirm) {
      let payload = {
        emailId: this.emailForReset,
        password: this.newPassword,
      };
      this.loading = true;
      this.api.updatePunchhPassword(payload);
      this.api.getupdatePunchhPassword().subscribe((data) => {
        this.loading = false;
        if (data.status == "200") {
          this.showLogin = true;
          this.passwordScreen = false;
        } else {
          this.passwordError = true;
          this.passwordErrorMessage = data.msg;
        }
      });
    }
  }
  formatPhone(data) {
    return data;
  }
  loginReset() {
    this.loginError = false;
    this.loginErrorMessage = "";
    this.username = "";
    this.password = "";
  }
  emailReset() {
    this.emailError = false;
    this.emailErrorMessage = "";
    this.emailForReset = "";
  }
  otpReset() {
    this.otpError = false;
    this.otpErrorMessage = "";
    this.otp = "";
  }
  resetPasswordReset() {
    this.passwordError = false;
    this.passwordErrorMessage = "";
    this.newPassword = "";
    this.newPasswordConfirm = "";
  }
}
