export class jetsStore {
    
    constructor() { }
    findStore(sid) {
        switch (sid) {
            case 'co01':
                let sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13037071100',
                    nickName: 'S. Broadway & W. Mineral Ave. location in Littleton',
                    addy1: 'S. Broadway & W. Mineral Ave.',
                    addy2: 'Littleton, CO'
                };
                return sidInfo;
            case 'ga16':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17702370006',
                    nickName: '2695 Sugarloaf Pkwy, location in Lawrenceville',
                    addy1: '2695 Sugarloaf Pkwy',
                    addy2: 'Lawrenceville, GA'
                };
            return sidInfo;

            case 'co11':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+17207981072',
                        nickName: '3926 Wadsworth Boulevard, location in Wheat Ridge',
                        addy1: '3926 Wadsworth Boulevard',
                        addy2: 'Wheat Ridge, CO'
                    };
            return sidInfo;
                
                case 'fl45':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17276148008',
                    nickName: '1001 W Bay Drive, location in Largo',
                    addy1: '1001 W Bay Drive',
                    addy2: 'Largo, FL'
                };
                return sidInfo;
                case 'tx18':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13465005387',
                    nickName: '2222 Rayford Rd, location in Spring',
                    addy1: '2222 Rayford Rd',
                    addy2: 'Spring, TX'
                };
                return sidInfo;
            case 'co02':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13037415387',
                    nickName: 'S. Holly St. & E. County Line Rd. location in Centennial',
                    addy1: 'S. Holly St. & E. County Line Rd.',
                    addy2: 'Centennial, CO'
                };
                return sidInfo;
            case 'fl16':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12394955387',
                    nickName: 'Corkscrew & Three Oaks location in Estero',
                    addy1: 'Corkscrew & Three Oaks',
                    addy2: 'Estero, FL'
                };
                return sidInfo;
            case 'fl30':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+19543409999',
                    nickName: 'W. Sample Rd. & University location in Coral Springs',
                    addy1: 'W. Sample Rd. & University',
                    addy2: 'Coral Springs, FL'
                };
                return sidInfo;
            case 'fl35':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: true,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18133970000',
                    nickName: 'Tampa location on N Dale Mabry Hwy',
                    addy1: '14624 N. Dale Mabry Hwy',
                    addy2: 'Tampa, FL'
                };
                return sidInfo;
            case 'fl13':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12394345389',
                    nickName: '4009 Santa Barbara Blvd location in Naples',
                    addy1: '4009 Santa Barbara Blvd',
                    addy2: 'Naples, FL'
                };
                return sidInfo;
            case 'il05':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12245347151',
                    nickName: 'Dempster St. location in Skokie',
                    addy1: 'Dempster St.',
                    addy2: 'Skokie, IL'
                };
                return sidInfo;
            case 'il21':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17737847070',
                    nickName: 'N. Clark & Carmen location in Chicago',
                    addy1: 'N. Clark & Carmen',
                    addy2: 'Chicago, IL'
                };
                return sidInfo;
            case 'ky03':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18592240133',
                    nickName: 'Palomar Centre location in Lexington',
                    addy1: 'Palomar Centre',
                    addy2: 'Lexington, KY'
                };
                return sidInfo;

            case 'ky07':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15022390000',
                    nickName: 'Bardstown Rd. N. of Seatonville Rd. location in Fern Creek',
                    addy1: 'Bardstown Rd. N. of Seatonville Rd.',
                    addy2: 'Fern Creek, KY'
                };
                return sidInfo;
            case 'ky10':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18592315387',
                    nickName: 'S. Broadway & Virginia Ave. location in Lexington',
                    addy1: 'S. Broadway & Virginia Ave.',
                    addy2: 'Lexington, KY'
                };
                return sidInfo;

            case 'ky12':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15028954655',
                    nickName: 'S. Hubbards Ln. & Shelbyville Rd. location in St Matthews',
                    addy1: 'S. Hubbards Ln. & Shelbyville Rd.',
                    addy2: 'St Matthews, KY'
                };
                return sidInfo;

                case 'in09':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13175774538',
                    nickName: '7538 Shadeland Ave, location in Indianapolis',
                    addy1: '7538 Shadeland Ave,',
                    addy2: 'Indianapolis, IN'
                };
                return sidInfo;
            case 'mi102':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+12693290528',
                        nickName: '631 Romence Rd location in Portage',
                        addy1: '631 Romence Rd',
                        addy2: 'Portage, MI'
                };
                return sidInfo;
            case 'tx17':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+18177545387',
                        nickName: '4621 W Bailey Boswell Rd location in TX',
                        addy1: '4621 W Bailey Boswell Rd',
                        addy2: 'Fort Worth, TX'
                    };
                    return sidInfo;

                    case 'co03':
                        sidInfo = {
                            discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                            isPro: false,
                            isServiceFee: false,
                            successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                            successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                            isTxt: true,
                            rid: '+17209906000',
                            nickName: '13724 E Quincy Ave location in Aurora',
                            addy1: '13724 E Quincy Ave',
                            addy2: 'Aurora, CO'
                        };
                        return sidInfo;
                    case 'co07':
                            sidInfo = {
                                discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                                isPro: false,
                                isServiceFee: false,
                                successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                                successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                                isTxt: true,
                                rid: '+13037575387',
                                nickName: '2306 S Colorado Blvd location in Denver',
                                addy1: '2306 S Colorado Blvd',
                                addy2: 'Denver, CO'
                        };
                        return sidInfo;
                    case 'mi54':
                            sidInfo = {
                                discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                                isPro: false,
                                isServiceFee: false,
                                successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                                successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                                isTxt: true,
                                rid: '+17347830999',
                                nickName: '28398 Telegraph Rd location in Flat Rock',
                                addy1: '28398 Telegraph Rd',
                                addy2: 'Flat Rock, MI'
                            };
                            return sidInfo;
          
            case 'co04':
                        sidInfo = {
                            discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                            isPro: false,
                            isServiceFee: false,
                            successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                            successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                            isTxt: true,
                            rid: '+17204407200',
                            nickName: '19878 Hilltop Rd location in Parker',
                            addy1: '19878 Hilltop Rd',
                            addy2: 'Parker, CO'
                    };
                    return sidInfo;

            case 'il03':
                        sidInfo = {
                            discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                            isPro: false,
                            isServiceFee: false,
                            successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                            successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                            isTxt: true,
                            rid: '+18478916900',
                            nickName: '1847 W Irving Park, location in Schaumburg',
                            addy1: '1847 W Irving Park',
                            addy2: 'Schaumburg, IL'
                        };
                        return sidInfo;
                case 'ut01':
                            sidInfo = {
                                discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                                isPro: false,
                                isServiceFee: false,
                                successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                                successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                                isTxt: true,
                                rid: '+13854261496',
                                nickName: '817 West Antelope Drive, location in Syracuse',
                                addy1: '817 West Antelope Drive',
                                addy2: 'Syracuse, UT'
                            };
                            return sidInfo;

                case 'mi47':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18103416100',
                    nickName: '5530 Fenton Rd location in New York City',
                    addy1: '5530 Fenton Rd',
                    addy2: 'Flint, MI'
                };
                return sidInfo;


                case 'co10':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+17205774400',
                        nickName: '10731 E Alameda Ave location in Aurora',
                        addy1: '10731 E Alameda Ave',
                        addy2: 'Aurora, CO'
                    };
                    return sidInfo;


                    case 'mi117':
                        sidInfo = {
                            discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                            isPro: false,
                            isServiceFee: false,
                            successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                            successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                            isTxt: true,
                            rid: '+18106001088',
                            nickName: '3093 South Linden Road location in Flint',
                            addy1: '3093 South Linden Road',
                            addy2: 'Flint, MI'
                        };
                        return sidInfo;

                        case 'ny06':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16468611605',
                    nickName: '728 Amsterdam Ave location in New York City',
                    addy1: '728 Amsterdam Ave',
                    addy2: 'New York, NY'
                };
                return sidInfo;
                        
                        case 'mi59':
                            sidInfo = {
                                discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                                isPro: false,
                                isServiceFee: false,
                                successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                                successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                                isTxt: true,
                                rid: '+18107211015',
                                nickName: '1812 S Van Dyke Rd location in Imlay City',
                                addy1: '1812 S Van Dyke Rd',
                                addy2: 'Imlay City, MI'
                            };
                            return sidInfo;


                case 'ny04':
                        sidInfo = {
                            discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                            isPro: false,
                            isServiceFee: false,
                            successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                            successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                            isTxt: true,
                            rid: '+17168170092',
                            nickName: '5401 Sheridan Dr location in Williamsville',
                            addy1: '5401 Sheridan Dr',
                            addy2: 'Williamsville, NY'
                        };
                        return sidInfo;
                        case 'ny05':
                            sidInfo = {
                                discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                                isPro: false,
                                isServiceFee: false,
                                successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                                successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                                isTxt: true,
                                rid: '+13472952856',
                                nickName: '305 Flatbush Ave, location in Brooklyn',
                                addy1: '305 Flatbush Ave',
                                addy2: 'Brooklyn, NY'
                            };
                            return sidInfo; 

            case 'ky13':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18593355387',
                    nickName: 'Sand Lake Dr. & Richmond Rd. location in Lexington',
                    addy1: 'Sand Lake Dr. & Richmond Rd.',
                    addy2: 'Lexington, KY'
                };
                return sidInfo;
            case 'ky14':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15024585387',
                    nickName: 'Bardstown Rd. & Trevilian Way location in Louisville',
                    addy1: 'Bardstown Rd. & Trevilian Way',
                    addy2: 'Louisville, KY'
                };
                return sidInfo;
            case 'ky15':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15024261181',
                    nickName: 'Westport Rd. location in Louisville',
                    addy1: 'Westport Rd.',
                    addy2: 'Louisville, KY'
                };
                return sidInfo;


                case 'nc01':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18288832888',
                    nickName: '102 College Station Dr location in Brevard',
                    addy1: '102 College Station Dr',
                    addy2: 'Brevard, NC'
                };
                return sidInfo;

            case 'nc05':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+19198453337',
                    nickName: 'Creedmoor, South of Strickland location in Raleigh',
                    addy1: 'Creedmoor, South of Strickland',
                    addy2: 'Raleigh, NC'
                };
                return sidInfo;


            case 'nc08':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17048451015',
                    nickName: 'Park Center Drive S. of Matthews Twp. Pkwy. location in Matthews',
                    addy1: 'Park Center Drive S. of Matthews Twp. Pkwy.',
                    addy2: 'Matthews, NC'
                };
                return sidInfo;
            case 'nc10':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17045951991',
                    nickName: 'W. Mallard Creek Church & Mallard Creek Rd. location in University City',
                    addy1: 'W. Mallard Creek Church & Mallard Creek Rd.',
                    addy2: 'University City, NC'
                };
                return sidInfo;
            case 'nc11':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17045447227',
                    nickName: 'Pineville-Matthews Rd. location in Pineville',
                    addy1: 'Pineville-Matthews Rd.',
                    addy2: 'Pineville, NC'
                };
                return sidInfo;
            case 'nc13':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+19198778660',
                    nickName: 'Wake Forest Rd. (South of 440) location in Raleigh',
                    addy1: 'Wake Forest Rd. (South of 440)',
                    addy2: 'Raleigh, NC'
                };
                return sidInfo;
            case 'nc16':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17042902101',
                    nickName: 'Old Charlotte Highway location in Monroe',
                    addy1: 'Old Charlotte Highway',
                    addy2: 'Monroe, NC'
                };
                return sidInfo;
            case 'mi01':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15862685720',
                    nickName: '14 Mile E. of Ryan location in Sterling Heights',
                    addy1: '14 Mile E. of Ryan',
                    addy2: 'Sterling Heights, MI'
                };
                return sidInfo;
            case 'mi02':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15868263900',
                    nickName: '15 & Schoenherr location in Sterling Heights',
                    addy1: '15 & Schoenherr',
                    addy2: 'Sterling Heights, MI'
                };
                return sidInfo;
            case 'mi04':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15862963330',
                    nickName: '13 Mile & Hayes Rd Location in Warren',
                    addy1: '31085 Hayes Rd',
                    addy2: 'Warren, MI'
                };
                return sidInfo;
            case 'mi05':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15865588588',
                    nickName: 'Ryan Rd, North of 12 Mile location',
                    addy1: '29202 Ryan Rd',
                    addy2: 'Warren,MI'
                };
                return sidInfo;
            case 'mi06':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15862287440',
                    nickName: '17 Mile & Garfield Rd Location in Clinton Twp',
                    addy1: '39101 Garfield',
                    addy2: 'Clinton Township, MI'
                };
                return sidInfo;
            case 'mi13':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15864150990',
                    nickName: '12 ½ Mile & Harper Ave Location in St. Clair Shores',
                    addy1: '30114 Harper',
                    addy2: 'St. Clair Shores, MI'
                };

                return sidInfo;
            case 'mi51':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: true,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS', isTxt: true,
                    rid: '+17342813844',
                    nickName: 'Dix Toledo Rd Location in Southgate',
                    addy1: '15211 Dix Toledo Rd',
                    addy2: 'Southgate, MI'
                };

                return sidInfo;
            case 'mi16':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15867313700',
                    nickName: 'Van Dyke & 21 Mile location in Shelby Township',
                    addy1: 'Van Dyke S. of 21 Mile Rd.',
                    addy2: 'Shelby Township, MI'
                };

                return sidInfo;
            case 'mi17':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13138843838',
                    nickName: 'Mack & Vernier location in Grosse Pointe Woods',
                    addy1: 'Mack & Vernie',
                    addy2: 'Grosse Pointe Woods, MI'
                };

                return sidInfo;
            case 'mi24':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15867397490',
                    nickName: 'Auburn & Dequindre location in Shelby Township',
                    addy1: 'Auburn & Dequindre',
                    addy2: 'Shelby Township, MI'
                };

                return sidInfo;

            case 'mi26':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: true,

                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS', 
                    isTxt: true,
                    rid: '+15869780888',
                    nickName: '18 Mile & Ryan Rd Location in Sterling Hts.',
                    addy1: '40808 Ryan Rd',
                    addy2: 'Sterling Heights, MI'
                };
                return sidInfo;

                case 'fl34':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON YOUR FIRST ORDER',
                        isPro: true,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF ON YOUR FIRST ORDER',
                        successPro: 'PRO ORDERS ARE 15% OFF ON YOUR FIRST ORDER',
                        isTxt: true,
                        rid: '+19414295387',
                        nickName: '1159 Sumter Blvd location in North Port',
                        addy1: '1159 Sumter Blvd',
                        addy2: 'North Port, FL'
                    };
                    return sidInfo;
                    
            case 'mi57':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12488521100',
                    nickName: 'Auburn & Crooks location in Rochester Hills',
                    addy1: 'Auburn & Crooks',
                    addy2: 'Rochester Hills, MI'
                };
                return sidInfo;
            case 'mi60':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13132994538',
                    nickName: 'Goddard, East of Pardee location in Taylor',
                    addy1: 'Goddard, East of Pardee',
                    addy2: 'Taylor, MI'
                };
                return sidInfo;
            case 'mi82':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13133814538',
                    nickName: 'Allen Rd. & White Ave. location in Allen Park',
                    addy1: 'Allen Rd. & White Ave.',
                    addy2: 'Allen Park, MI'
                };
                return sidInfo;
            case 'mi92':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15865325387',
                    nickName: '42918 Schoenherr Rd Location in Sterling Hts.',
                    addy1: '42918 Schoenherr Rd',
                    addy2: 'Sterling Heights, MI'
                };
                return sidInfo;


          case 'mi07':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12486502900',
                    nickName: '1425 N Rochester Rd location in Rochester Hills',
                    addy1: '1425 N Rochester Rd',
                    addy2: 'Rochester Hills, MI'
                };
                return sidInfo;
                
            case 'mi95':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13133865387',
                    nickName: 'Mayflower & Fort St. location in Lincoln Park',
                    addy1: 'Mayflower & Fort St.',
                    addy2: 'Lincoln Park, MI'
                };
                return sidInfo;
            case 'mi132':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15865988600',
                    nickName: '23 Mile & Card Rd Location in Macomb',
                    addy1: '23 Mile Rd',
                    addy2: 'Macomb, MI'
                };
                return sidInfo;
           
           
                case 'mi164':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15866771100',
                    nickName: '26 Mile & Romeo Plank location in Macomb',
                    addy1: '26 Mile & Romeo Plank',
                    addy2: 'Macomb, MI'
                };
                return sidInfo;


                case 'mi110':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+12486680606',
                        nickName: '49402 Pontiac Trail location in Wixom',
                        addy1: '49402 Pontiac Trail',
                        addy2: 'Wixom, MI'
                    };
                    return sidInfo;
                case 'mi172':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+18103595770',
                        nickName: '5590 Main St location in Lexington',
                        addy1: '5590 Main St',
                        addy2: 'Lexington, MI'
                    };
                    return sidInfo;

              case 'in04':
                        sidInfo = {
                            discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                            isPro: false,
                            isServiceFee: false,
                            successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                            successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                            isTxt: true,
                            rid: '+13175959900',
                            nickName: '11785 Commercial Dr location in Fishers',
                            addy1: '11785 Commercial Dr',
                            addy2: 'Fishers, IN'
                        };
                        return sidInfo;
                        
                case 'mi61':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+17344574200',
                        nickName: '414 N Telegraph Rd location in Monroe',
                        addy1: '414 N Telegraph Rd',
                        addy2: 'Monroe, MI'
                    };
                    return sidInfo;




            case 'in14':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18122822220',
                    nickName: 'Jeffersonville Location',
                    addy1: '1725 E 10TH ST SUITE F',
                    addy2: 'Jeffersonville, IN'
                };
                return sidInfo;
            case 'ky05':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15024911700',
                    nickName: 'J-Town / Hikes Point Location',
                    addy1: '3624A S Hurstbourne Pkwy',
                    addy2: 'J-Town/Hikes Point,IN'
                };
                return sidInfo;

            case 'ky11':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15022444440',
                    nickName: 'Location at 235 Blankenbaker Pkwy',
                    addy1: '235 Blankenbaker Pkwy',
                    addy2: 'Louisville'
                };
                return sidInfo;


         case 'il28':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16188929900',
                    nickName: '5200 N Illinois St location in Fariview Heights',
                    addy1: '5200 N Illinois St',
                    addy2: 'Fairview Heights, IL'
                };
                return sidInfo;

            case 'fl08':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17277720313',
                    nickName: 'Palm Harbor Location',
                    addy1: 'Hwy 19 and Nebraska Ave',
                    addy2: 'Palm Harbor, FL'
                };
                return sidInfo;
            case 'fl17':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18637090999',
                    nickName: 'Lakeland Location',
                    addy1: 'Florida Ave,',
                    addy2: 'Lakeland, FL'
                };
                return sidInfo;
            case 'mi30':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12486562920',
                    nickName: 'Shelby Twp Location',
                    addy1: '25 and Shelby Rd',
                    addy2: 'Shelby Twp, MI'
                };
                return sidInfo;
            case 'mi50':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12489695387',
                    nickName: 'Oxford Location',
                    addy1: 'Washington St',
                    addy2: 'Oxford, MI'
                };
                return sidInfo;
            case 'mi78':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12488147559',
                    nickName: 'Lake Orion Location',
                    addy1: 'Clarkston and Lapeer Rd',
                    addy2: 'Lake Orion, MI'
                };
                return sidInfo;
            case 'oh06':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16147710500',
                    nickName: 'Hilliard Location on Main St',
                    addy1: '3858 MAIN ST',
                    addy2: 'HILLIARD, OH'
                };
                return sidInfo;
            case 'oh10':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16148488800',
                    nickName: 'Location near Polaris Fashion Place',
                    addy1: 'Near Polaris Fashion Place',
                    addy2: 'Columbus, OH'
                };
                return sidInfo;
            case 'oh11':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16143260300',
                    nickName: 'Upper Arlington Location',
                    addy1: 'Reed Rd at Henderson Rd',
                    addy2: 'Upper Arlington, OH'
                };
                return sidInfo;
            case 'oh12':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16144752000',
                    nickName: 'Gahanna Location on N Hamilton Rd',
                    addy1: 'N Hamilton Rd',
                    addy2: 'Gahanna, OH'
                };
                return sidInfo;
            case 'oh14':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16148825387',
                    nickName: 'Westerville Location',
                    addy1: 'N State St',
                    addy2: 'Westerville, OH'
                };
                return sidInfo;
            case 'oh18':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16146590075',
                    nickName: 'Dublin location at Hard Rd and Emerald Pkwy',
                    addy1: 'Hard Rd at Emerald Pkwy',
                    addy2: 'Dublin, OH'
                };
                return sidInfo;
            case 'oh19':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16147644500',
                    nickName: 'Woerner Temple Rd and Emerald Pkwy location',
                    addy1: 'Woerner Temple Rd at Emerald Pkwy',
                    addy2: 'Dublin, OH'
                };
                return sidInfo;
            case 'oh21':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17405481616',
                    nickName: 'Lewis Center Location',
                    addy1: 'Pullman Dr by Kroger',
                    addy2: 'Lewis Center, OH'
                };
                return sidInfo;
            case 'oh22':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16147597200',
                    nickName: 'McNaughten Rd in Columbus',
                    addy1: 'McNaughten Rd at E Broad St',
                    addy2: 'Columbus, OH'
                };
                return sidInfo;
            case 'oh25':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17409651172',
                    nickName: 'Sunbury Location',
                    addy1: 'State and Cherry St',
                    addy2: 'Sunbury, OH'
                };
                return sidInfo;
            case 'oh26':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16148444400',
                    nickName: 'High St location near Dublin-Granville Rd',
                    addy1: 'High St near Dublin-Granville Rd',
                    addy2: 'Sharon Township, OH'
                };
                return sidInfo;
            case 'oh30':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16148552404',
                    nickName: 'Plain Twp Location',
                    addy1: 'Fodor Rd near Kroger',
                    addy2: 'Plain Township, OH'
                };
                return sidInfo;
            case 'oh31':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16147510800',
                    nickName: 'Pickerington Location',
                    addy1: 'Clint Dr in Pickerington',
                    addy2: 'Pickerington, OH'
                };
                return sidInfo;
            case 'oh37':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17403631600',
                    nickName: 'Delaware Location',
                    addy1: 'Troy Rd',
                    addy2: 'Delaware, OH'
                };
                return sidInfo;
                case 'co06':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+17208268200',
                        nickName: '7735 W Long Dr suite 10 location in Littleton',
                        addy1: '7735 W Long Dr',
                        addy2: 'Littleton, CO'
                    };
                    return sidInfo;
            case 'oh39':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+14193530316',
                    nickName: 'Bowling Green Location',
                    addy1: '1216 N MAIN ST',
                    addy2: 'BOWLING GREEN, OH'
                };
                return sidInfo;
            case 'oh41':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16149915999',
                    nickName: 'Grove City Location',
                    addy1: '3160 BROADWAY',
                    addy2: 'GROVE CITY, OH'
                };
                return sidInfo;
            case 'oh43':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16145020428',
                    nickName: 'Columbus location on 1242 W 5th Ave',
                    addy1: '1242 W 5TH AVE',
                    addy2: 'COLUMBUS, OH'
                };
                return sidInfo;
            case 'mi23':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12485479880',
                    nickName: 'Berkley Location',
                    addy1: '2823 COOLIDGE HWY',
                    addy2: 'BERKLEY'
                };
                return sidInfo;
            case 'mi88':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12486455387',
                    nickName: 'Southfield Location',
                    addy1: '30120 SOUTHFIELD RD',
                    addy2: 'SOUTHFIELD, MI'
                };
                return sidInfo;

            case 'il19':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17736375387',
                    nickName: '1805 N Harlem Ave location',
                    addy1: '1805 N. Harlem Ave',
                    addy2: 'Chicago, IL'
                };
                return sidInfo;

            case 'il04':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16309103100',
                    nickName: 'Darien location',
                    addy1: '2425 75th St',
                    addy2: 'Darien, IL'
                };
                return sidInfo;

            case 'fl10':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17272101840',
                    nickName: 'Dunedin location',
                    addy1: '1350 Main St',
                    addy2: 'Dunedin, FL'
                };
                return sidInfo;
            case 'mi42':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12484268400',
                    nickName: '12 Mile and Orchard Lake location',
                    addy1: '27897 Orchard Lake Rd',
                    addy2: 'Farmington Hills, MI'
                };
                return sidInfo;
            case 'il18':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17739931111',
                    nickName: '3951 N Kimball Ave Location',
                    addy1: 'N Kimball Ave',
                    addy2: 'Chicago'
                };
                return sidInfo;

            case 'il24':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17739055387',
                    nickName: 'Logan Square Location',
                    addy1: 'Logan Square',
                    addy2: 'Chicago'
                };
                return sidInfo;

            case 'mi10':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15867595387',
                    nickName: '11908 E 10 Mile Rd Location',
                    addy1: 'E 10 Mile Rd',
                    addy2: 'Warren'
                };
                return sidInfo;

            case 'mi25':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15867169800',
                    nickName: '51058 D W Seaton Dr Location',
                    addy1: 'W Seaton Dr',
                    addy2: 'New Baltimore'
                };
                return sidInfo;

            case 'mi84':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18109660705',
                    nickName: '1345 24th St Location',
                    addy1: '24th St',
                    addy2: 'Port Huron'
                };
                return sidInfo;
            
                case 'ga04':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+17706499950',
                        nickName: '4401 Shallowford Rd location in Roswell',
                        addy1: '4401 Shallowford Rd',
                        addy2: 'Roswell, GA'
                    };
                    return sidInfo;

            case 'mi114':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12693722600',
                    nickName: '3321 Stadium Dr Location',
                    addy1: 'Stadium Dr',
                    addy2: 'Kalamazoo'
                };
                return sidInfo;

            case 'mi169':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18106434050',
                    nickName: '159 Chartier Rd Location',
                    addy1: 'Chartier Rd',
                    addy2: 'Marine City'
                };
                return sidInfo;

            case 'mi104':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16167241454',
                    nickName: '4499 Ivanrest Ave SW Location',
                    addy1: 'Ivanrest Ave SW',
                    addy2: 'Grandville'
                };
                return sidInfo;

            case 'mi126':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16168974800',
                    nickName: '11635 Fulton St E Location',
                    addy1: 'Fulton St E',
                    addy2: 'Lowell'
                };
                return sidInfo;
            case 'mi62':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13138394800',
                    nickName: '15235 E 7 Mile Rd Location in Eastpointe',
                    addy1: '7 Mile',
                    addy2: 'Eastpointe'
                };
                return sidInfo;

            case 'mi146':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12694882000',
                    nickName: '5151 Gull Rd Location',
                    addy1: 'Gull Rd',
                    addy2: 'Kalamazoo'
                };
                return sidInfo;

            case 'mi130':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+19894004440',
                    nickName: 'Mission St Location',
                    addy1: 'S Mission St',
                    addy2: 'Mt. Pleasant'
                };
                return sidInfo;

            case 'mi12':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15867744448',
                    nickName: '19380 10 Mile Rd Location',
                    addy1: '10 Mile Rd',
                    addy2: 'Eastpointe'
                };
                return sidInfo;
            case 'fl18':
                sidInfo = {
                    discountTxt: '*',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'Texting is the fastest way to order Jet’s!',
                    successPro: 'Texting is the fastest way to order Jet’s!',
                    isTxt: true,
                    rid: '+18136555388',
                    nickName: 'Brandon Location',
                    addy1: 'W Lumsden Rd ',
                    addy2: 'Brandon'
                };
                return sidInfo;
            case 'il01':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16309047777',
                    nickName: 'Naperville-South Location',
                    addy1: 'Route 59',
                    addy2: 'Naperville-South'
                };
                return sidInfo;
            case 'il02':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18475379999',
                    nickName: 'Wheeling/Buffalo Grove Location',
                    addy1: 'E Lake Cook Rd',
                    addy2: 'Wheeling/Buffalo Grove'
                };
                return sidInfo;
            case 'il07':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16309221111',
                    nickName: 'Naperville-North Location',
                    addy1: 'E Ogden Ave',
                    addy2: 'Naperville-North'
                };
                return sidInfo;
            case 'il12':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18155775387',
                    nickName: 'Joliet Location',
                    addy1: 'Essington Rd',
                    addy2: 'Joliet'
                };
                return sidInfo;
            case 'il16':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16309788500',
                    nickName: 'Aurora Location',
                    addy1: 'E New York St',
                    addy2: 'Aurora'
                };
                return sidInfo;
            case 'pa01':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+14123439400',
                    nickName: '1810 Dormont Ave Location',
                    addy1: 'Dormont Ave',
                    addy2: 'Pittsburgh'
                };
                return sidInfo;


            case 'oh23':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+14409547700',
                    nickName: '35535 Euclid Ave Location',
                    addy1: '35535 Euclid Ave',
                    addy2: 'Willoughby'
                };
                return sidInfo;
            case 'il15':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13124652280',
                    nickName: '207 W. Superior St Location',
                    addy1: '207 W. Superior St',
                    addy2: 'Chicago'
                };
                return sidInfo;
            case 'il23':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13125867290',
                    nickName: '1025 W. Madison St. Location',
                    addy1: '1025 W. Madison St.',
                    addy2: 'Chicago'
                };
                return sidInfo;
            case 'il02':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18475379999',
                    nickName: '1065 E Lake Cook Rd Location',
                    addy1: '1065 E Lake Cook Rd',
                    addy2: 'Wheeling/Buffalo Grove'
                };
                return sidInfo;
            case 'mi162':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON TEXT ORDERS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF',
                    successPro: 'PRO ORDERS ARE 15% OFF',
                    isTxt: true,
                    rid: '+16168918800',
                    nickName: '9349 Cherry Valley Location',
                    addy1: '9349 Cherry Valley Ave SE',
                    addy2: 'Caledonia'
                };
                return sidInfo;
            case 'oh38':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12343340932',
                    nickName: '3473 Hudson Dr. Location',
                    addy1: '3473 Hudson Dr.',
                    addy2: 'Cuyahoga Falls'
                };
                return sidInfo;
            case 'fl14':
                sidInfo = {
                    discountTxt: '*',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'Texting is the fastest way to order Jet’s!',
                    successPro: 'Texting is the fastest way to order Jet’s!',
                    isTxt: true,
                    rid: '+18136545388',
                    nickName: '13429 Fishhawk Boulevard Location',
                    addy1: '13429 Fishhawk Boulevard',
                    addy2: 'Lithia'
                };
                return sidInfo;
            case 'fl32':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15615135387',
                    nickName: '1204 Royal Palm Beach Blvd Location',
                    addy1: '1204 Royal Palm Beach Blvd',
                    addy2: 'Royal Palm Beach'
                };
                return sidInfo;
            case 'mi77':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15869497500',
                    nickName: '47115 Gratiot Ave Location',
                    addy1: '47115 Gratiot Ave',
                    addy2: 'Chesterfield'
                };
                return sidInfo;
            case 'mi97':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16162815450',
                    nickName: '4443 Breton Rd Location',
                    addy1: '4443 Breton Rd SE',
                    addy2: 'Kentwood'
                };
                return sidInfo;
            case 'mi115':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+19896339999',
                    nickName: '203 W Wackerly St Location',
                    addy1: '203 W Wackerly St',
                    addy2: 'Midland'
                };
                return sidInfo;
            case 'mi116':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18104870400',
                    nickName: '230 W Main St Location',
                    addy1: '230 W Main St',
                    addy2: 'Flushing'
                };
                return sidInfo;
            case 'ny01':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17168776700',
                    nickName: '2165 Delaware Ave Location',
                    addy1: '2165 Delaware Ave',
                    addy2: 'Buffalo'
                };
                return sidInfo;
            case 'tn41':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+14236160616',
                    nickName: '1983 W. Morris Blvd. Location',
                    addy1: '1983 W. Morris Blvd',
                    addy2: 'Morristown'
                };
                return sidInfo;
            case 'tn20':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16156671557',
                    nickName: '2666 Memorial Blvd. Location',
                    addy1: '2666 Memorial Blvd',
                    addy2: 'Springfield'
                };
                return sidInfo;

            case 'mi97':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16162815450',
                    nickName: '4443 Breton Rd SE location in Kentwood',
                    addy1: '4443 Breton Rd SE',
                    addy2: 'Kentwood, MI'
                };
                return sidInfo;

            case 'mi106':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18102315500',
                    nickName: '9646 Chilson Commons Cir location in Pinckney',
                    addy1: '9646 Chilson Commons Cir',
                    addy2: 'Pinckney, MI'
                };
                return sidInfo;
            case 'tx21':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+17377073133',
                        nickName: '1420 Cypress Creek Road, location in Cedar Park',
                        addy1: '1420 Cypress Creek Road',
                        addy2: 'Cedar Park, TX'
                    };
                    return sidInfo;
            case 'mn05':
                        sidInfo = {
                            discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                            isPro: false,
                            isServiceFee: false,
                            successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                            successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                            isTxt: true,
                            rid: '+13202305387',
                            nickName: '19 7th Ave S, location in Saint Cloud',
                            addy1: '19 7th Ave S',
                            addy2: 'Saint Cloud, MN'
                        };
                        return sidInfo;
            case 'mi143':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16166569700',
                    nickName: '6750 Kalamazoo Ave SE location in Grand Rapids',
                    addy1: '6750 Kalamazoo Ave SE',
                    addy2: 'Grand Rapids, MI'
                };
                return sidInfo;
            case 'mi151':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16162615387',
                    nickName: '952 28th St SW location in Wyoming',
                    addy1: '952 28th St SW',
                    addy2: 'Wyoming, MI'
                };
                return sidInfo;
            case 'in16':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+12195295387',
                        nickName: '1613 E 37th, location in Hobart',
                        addy1: '1613 E 37th',
                        addy2: 'Hobart, IN'
                    };
                    return sidInfo;

            case 'mi161':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16168784748',
                    nickName: '8233 Byron Center Ave SW location in Byron Center',
                    addy1: '8233 Byron Center Ave SW',
                    addy2: 'Byron Center, MI'
                };
                return sidInfo;
            case 'mi162':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16168918800',
                    nickName: '9349 Cherry Valley Ave SE location in Caledonia',
                    addy1: '9349 Cherry Valley Ave SE',
                    addy2: 'Caledonia, MI'
                };
                return sidInfo;
            case 'ky06':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18592735387',
                    nickName: '4222 Saron Dr location in Lexington',
                    addy1: '4222 Saron Dr',
                    addy2: 'Lexington, KY'
                };
                return sidInfo;
            case 'il11':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17738572080',
                    nickName: '2811 N Ashland Ave location in Chicago',
                    addy1: '2811 N Ashland Ave',
                    addy2: 'Chicago, IL'
                };
                return sidInfo;
            case 'sc04':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON TEXT ORDERS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15%',
                    successPro: 'PRO ORDERS ARE 15%',
                    isTxt: true,
                    rid: '+18649891900',
                    nickName: '405 The Parkway location in Greer',
                    addy1: '405 The Parkway',
                    addy2: 'Greer, SC'
                };
                return sidInfo;
            case 'tx10':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18174535387',
                    nickName: '1601 E Debbie Ln location in Mansfield',
                    addy1: '1601 E Debbie Ln',
                    addy2: 'Mansfield, TX'
                };
                return sidInfo;



            case 'mi160':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16162258655',
                    nickName: '1925 W. Washington St location in Greenville',
                    addy1: '1925 W. Washington St',
                    addy2: 'Greenville, MI'
                };
                return sidInfo;
            case 'sc02':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON TEXT ORDERS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15%',
                    successPro: 'PRO ORDERS ARE 15%',
                    isTxt: true,
                    rid: '+18642811110',
                    nickName: '216 Scuffletown Rd location in Simpsonville',
                    addy1: '216 Scuffletown Rd',
                    addy2: 'Simpsonville, SC'
                };
                return sidInfo;
            case 'mi29':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12486238727',
                    nickName: '5308 Dixie Hwy location in Waterford Twp',
                    addy1: '5308 Dixie Hwy',
                    addy2: 'Waterford Twp, MI'
                };
                return sidInfo;
            case 'mi98':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12483913400',
                    nickName: '1019 S Baldwin Rd location in Lake Orion',
                    addy1: '1019 S Baldwin Rd',
                    addy2: 'Lake Orion, MI'
                };
                return sidInfo;
            case 'il22':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17739048283',
                    nickName: '1811 W North Ave location in Chicago',
                    addy1: '1811 W North Ave',
                    addy2: 'Chicago, IL'
                };
                return sidInfo;
            case 'il25':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17732496508',
                    nickName: '1144 S Wabash Ave location in Chicago',
                    addy1: '1144 S Wabash Ave',
                    addy2: 'Chicago, IL'
                };
                return sidInfo;
            case 'tx01':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18172818700',
                    nickName: '5040 N Tarrant Pkwy location in Fort Worth',
                    addy1: '5040 N Tarrant Pkwy',
                    addy2: 'Fort Worth, TX'
                };
                return sidInfo;
            case 'tx06':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18172815387',
                    nickName: '8509 Davis Blvd location in North Richland Hills',
                    addy1: '8509 Davis Blvd',
                    addy2: 'North Richland Hills, TX'
                };
                return sidInfo;
            case 'tx11':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+19722215387',
                    nickName: '2001 Cross Timbers Rd location in Flower Mound',
                    addy1: '2001 Cross Timbers Rd',
                    addy2: 'Flower Mound, TX'
                };
                return sidInfo;
            case 'tx12':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18174943365',
                    nickName: '2248 Central Dr location in Bedford',
                    addy1: '2248 Central Dr',
                    addy2: 'Bedford, TX'
                };
                return sidInfo;

            case 'mi83':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18106321400',
                    nickName: '1800 Old US 23 location in Howell',
                    addy1: '1800 Old US 23',
                    addy2: 'Howell, MI'
                };
                return sidInfo;
            case 'mi93':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13132977000',
                    nickName: '4718 Anthony Wayne Dr location in Detroit',
                    addy1: '4718 Anthony Wayne Dr',
                    addy2: 'Detroit, MI'
                };
                return sidInfo;
            case 'mi107':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13135335323',
                    nickName: '24335 Plymouth Rd location in Redford',
                    addy1: '24335 Plymouth Rd',
                    addy2: 'Redford, MI'
                };
                return sidInfo;
            case 'mi135':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+19897234100',
                    nickName: '1669 E Main St location in Owosso',
                    addy1: '1669 E Main St',
                    addy2: 'Owosso, MI'
                };
                return sidInfo;
            case 'mi140':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16166629333',
                    nickName: '3735 Baldwin St location in Hudsonville',
                    addy1: '3735 Baldwin St',
                    addy2: 'Hudsonville, MI'
                };
                return sidInfo;
            case 'mi157':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12699482228',
                    nickName: '1180 M-43 location in Hastings',
                    addy1: '1180 M-43',
                    addy2: 'Hastings, MI'
                };
                return sidInfo;

            case 'mi03':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15862684010',
                    nickName: '37160 Dequindre Rd location in Sterling Heights',
                    addy1: '37160 Dequindre Rd',
                    addy2: 'Sterling Heights, MI'
                };
                return sidInfo;
            case 'mi08':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12484733999',
                    nickName: '32630 W Seven Mile Rd location in Livonia',
                    addy1: '32630 W Seven Mile Rd',
                    addy2: 'Livonia, MI'
                };
                return sidInfo;
            case 'mi41':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17347284400',
                    nickName: '6546 N Wayne Rd location in Westland',
                    addy1: '6546 N Wayne Rd',
                    addy2: 'Westland, MI'
                };
                return sidInfo;
            case 'mi63':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15867526200',
                    nickName: '64860 Van Dyke location in Washington',
                    addy1: '64860 Van Dyke',
                    addy2: 'Washington, MI'
                };
                return sidInfo;
            case 'mi68':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18103641538',
                    nickName: '825 Michigan Ave location in Marysville',
                    addy1: '825 Michigan Ave',
                    addy2: 'Marysville, MI'
                };
                return sidInfo;
            case 'mi87':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12483578300',
                    nickName: '23941 W 9 Mile Rd location in Southfield',
                    addy1: '23941 W 9 Mile Rd',
                    addy2: 'Southfield, MI'
                };
                return sidInfo;
            case 'mi141':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16167911777',
                    nickName: '4482 Lake Michigan Dr NW location in Walker',
                    addy1: '4482 Lake Michigan Dr NW',
                    addy2: 'Walker, MI'
                };
                return sidInfo;
            case 'ga03':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17707925700',
                    nickName: '3600 Dallas Hwy location in Marietta',
                    addy1: '3600 Dallas Hwy',
                    addy2: 'Marietta, GA'
                };
                return sidInfo;
            case 'ga10':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17709521711',
                    nickName: '2900 Delk Rd location in Marietta',
                    addy1: '2900 Delk Rd',
                    addy2: 'Marietta, GA'
                };
                return sidInfo;
            case 'ga17':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+14042570222',
                    nickName: '6615 Roswell Rd NE location in Sandy Springs',
                    addy1: '6615 Roswell Rd NE',
                    addy2: 'Sandy Springs, GA'
                };
                return sidInfo;
            case 'wi01':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+14148315800',
                    nickName: '9129 W Greenfield Ave location in West Allis',
                    addy1: '9129 W Greenfield Ave',
                    addy2: 'West Allis, WI'
                };
                return sidInfo;
            case 'wi04':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12627885007',
                    nickName: '2491 N 124th St location in Brookfield',
                    addy1: '2491 N 124th St',
                    addy2: 'Brookfield, WI'
                };
                return sidInfo;
            case 'wi07':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+14145330082',
                    nickName: '6813 N Green Bay Ave location in Glendale',
                    addy1: '6813 N Green Bay Ave',
                    addy2: 'Glendale, WI'
                };
                return sidInfo;

            case 'mi39':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18109855559',
                    nickName: '2844 Pine Grove Ave location in Port Huron',
                    addy1: '2844 Pine Grove Ave',
                    addy2: 'Port Huron, MI'
                };
                return sidInfo;
            case 'tn16':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+14237571616',
                    nickName: '3600 Hixson Pike location in Chattanooga',
                    addy1: '3600 Hixson Pike',
                    addy2: 'Chattanooga, TN'
                };
                return sidInfo;

            case 'mi91':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17342227544',
                    nickName: '3127 Oak Valley Dr. Location',
                    addy1: '3127 Oak Valley Dr',
                    addy2: 'Ann Arbor, MI'
                };
                return sidInfo;

            case 'mi109':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17347285387',
                    nickName: '35681 Van Born Rd. Location',
                    addy1: '35681 Van Born Rd',
                    addy2: 'Romulus, MI'
                };
                return sidInfo;
            case 'mi156':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16168950895',
                    nickName: '5135 Lake Michigan Dr. Location',
                    addy1: '5135 Lake Michigan Dr',
                    addy2: 'Allendale Twp, MI'
                };
                return sidInfo;
            case 'tn10':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18655660750',
                    nickName: '7331 Kingston Pike Location',
                    addy1: '7331 Kingston Pike',
                    addy2: 'Knoxville, TN'
                };
                return sidInfo;
            case 'ny02':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16465969418',
                    nickName: '112 9th Ave location in New York',
                    addy1: '112 9th Ave',
                    addy2: 'New York, NY'
                };
                return sidInfo;
            case 'mi75':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15172661300',
                    nickName: '1390 S Main St location in Adrian',
                    addy1: '1390 S Main St',
                    addy2: 'Adrian, MI'
                };
                return sidInfo;
            case 'mi79':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17346977500',
                    nickName: '284 E Columbia Ave location in Belleville',
                    addy1: '284 E Columbia Ave',
                    addy2: 'Belleville, MI'
                };
                return sidInfo;
            case 'mi86':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17342291399',
                    nickName: '19138 Telegraph Rd location in Brownstown',
                    addy1: '19138 Telegraph Rd',
                    addy2: 'Brownstown, MI'
                };
                return sidInfo;
            case 'mi128':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17344841900',
                    nickName: '1298 Anna J Stepp Rd location in Ypsilanti',
                    addy1: '1298 Anna J Stepp Rd',
                    addy2: 'Ypsilanti, MI'
                };
                return sidInfo;
            case 'mi149':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17343321300',
                    nickName: '1749 Plymouth Rd location in Ann Arbor',
                    addy1: '1749 Plymouth Rd',
                    addy2: 'Ann Arbor, MI'
                };
                return sidInfo;
            case 'mi166':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15178151810',
                    nickName: '1207 W Chicago Blvd location in Tecumseh',
                    addy1: '1207 W Chicago Blvd',
                    addy2: 'Tecumseh, MI'
                };
                return sidInfo;
            case 'tn03':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16158855387',
                    nickName: '5205 Old Hickory Blvd location in Hermitage',
                    addy1: '5205 Old Hickory Blvd',
                    addy2: 'Hermitage, TN'
                };
                return sidInfo;
            case 'tn13':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16158224221',
                    nickName: '112 Saundersville Rd location in Hendersonville',
                    addy1: '112 Saundersville Rd',
                    addy2: 'Hendersonville, TN'
                };
                return sidInfo;
            case 'tn18':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16154510252',
                    nickName: '122 Belvedere Dr N location in Gallatin',
                    addy1: '122 Belvedere Dr N',
                    addy2: 'Gallatin, TN'
                };
                return sidInfo;
            case 'tn27':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16157544100',
                    nickName: '2037 N Mt Juliet Rd location in Mount Juliet',
                    addy1: '2037 N Mt Juliet Rd',
                    addy2: 'Mount Juliet, TN'
                };
                return sidInfo;
            case 'tn28':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16152625051',
                    nickName: '721 Gallatin Ave location in Nashville',
                    addy1: '721 Gallatin Ave',
                    addy2: 'Nashville, TN'
                };
                return sidInfo;
            case 'ga02':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17704341523',
                    nickName: '4425 S Cobb Dr SE location in Smyrna',
                    addy1: '4425 S Cobb Dr SE',
                    addy2: 'Smyrna, GA'
                };
                return sidInfo;
            case 'ga06':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17704278788',
                    nickName: '4100 Jiles Rd location in Kennesaw',
                    addy1: '4100 Jiles Rd',
                    addy2: 'Kennesaw, GA'
                };
                return sidInfo;
            case 'mi19':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15869541960',
                    nickName: '37525 Harper Ave location in Clinton Twp',
                    addy1: '37525 Harper Ave',
                    addy2: 'Clinton Twp, MI'
                };
                return sidInfo;
            case 'mi34':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12487882511',
                    nickName: '31130 Haggerty Rd location in Farmington Hills',
                    addy1: '31130 Haggerty Rd',
                    addy2: 'Farmington Hills, MI'
                };
                return sidInfo;
            case 'mi119':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15177887833',
                    nickName: '2109 W Michigan Ave location in Jackson',
                    addy1: '2109 W Michigan Ave',
                    addy2: 'Jackson, MI'
                };
                return sidInfo;

            case 'mi48':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12484461400',
                    nickName: '21955 Pontiac Trail location in South Lyon',
                    addy1: '21955 Pontiac Trail',
                    addy2: 'South Lyon, MI'
                };
                return sidInfo;
            case 'mi148':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16168445387',
                    nickName: '610 N Beacon Blvd location in Grand Haven',
                    addy1: '610 N Beacon Blvd',
                    addy2: 'Grand Haven, MI'
                };
                return sidInfo;

            case 'mi139':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12317330101',
                    nickName: '950 W Norton Ave location in Muskegon',
                    addy1: '950 W Norton Ave',
                    addy2: 'Muskegon, MI'
                };
                return sidInfo;

            case 'fl24':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13212032675',
                    nickName: '558 S Hunt Club Blvd location in Apopka',
                    addy1: '558 S Hunt Club Blvd',
                    addy2: 'Apopka, FL'
                };
                return sidInfo;
            case 'fl29':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15618525700',
                    nickName: '8903 Glades Rd location in Boca Raton',
                    addy1: '8903 Glades Rd',
                    addy2: 'Boca Raton, FL'
                };
                return sidInfo;
            case 'tn21':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+19017568883',
                    nickName: '1400 N Germantown Pkwy location in Cordova',
                    addy1: 'N Germantown Pkwy',
                    addy2: 'Cordova, TN'
                };
                return sidInfo;
            case 'tn32':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+19013835387',
                    nickName: '7780 US-64 location in Bartlett',
                    addy1: 'US-64',
                    addy2: 'Bartlett, TN'
                };
                return sidInfo;
            case 'tn37':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+19012074200',
                    nickName: '4695 Poplar Ave location in Memphis',
                    addy1: 'Poplar Ave',
                    addy2: 'Memphis, TN'
                };
                return sidInfo;
            case 'sc03':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18435520311',
                    nickName: '5325 Park Forest Pkwy location in Nprth Charleston',
                    addy1: 'Park Forest Pkwy',
                    addy2: 'North Charleston, SC'
                };
                return sidInfo;
            case 'il06':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 10% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 10% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 10% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18475935387',
                    nickName: '59 W Golf Rd location in Arlington Heights',
                    addy1: 'W Golf Rd',
                    addy2: 'Arlington Heights, IL'
                };
                return sidInfo;
            case 'nc06':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+19194694949',
                    nickName: '1253 NW Maynard Rd location in Cary',
                    addy1: 'NW Maynard Rd',
                    addy2: 'Cary, NC'
                };
                return sidInfo;


            case 'mi36':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17344165385',
                    nickName: '44473 Ann Arbor Rd location in Plymouth',
                    addy1: '44473 Ann Arbor Rd',
                    addy2: 'Plymouth, MI'
                };
                return sidInfo;
            case 'mi43':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17344140831',
                    nickName: '7301 N Lilley Rd location in Canton',
                    addy1: '7301 N Lilley Rd',
                    addy2: 'Canton, MI'
                };
                return sidInfo;
            case 'mi66':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17343978700',
                    nickName: '41266 Palmer Rd location in Canton',
                    addy1: '41266 Palmer Rd',
                    addy2: 'Canton, MI'
                };
                return sidInfo;
            case 'mi120':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17349818500',
                    nickName: '45241 Cherry Hill Rd location in Canton',
                    addy1: '45241 Cherry Hill Rd',
                    addy2: 'Canton, MI'
                };
                return sidInfo;
            case 'mi121':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17344149800',
                    nickName: '47273 Five Mile Rd location in Plymouth',
                    addy1: '47273 Five Mile Rd',
                    addy2: 'Plymouth, MI'
                };
                return sidInfo;

            case 'mi154':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12315270122',
                    nickName: '215 Maple St location in Big Rapids',
                    addy1: '215 Maple St',
                    addy2: 'Big Rapids, MI'
                };
                return sidInfo;
            case 'nc03':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12522150091',
                    nickName: '420 E Arlington Blvd location in Greenville',
                    addy1: '420 E Arlington Blvd',
                    addy2: 'Greenville, NC'
                };
                return sidInfo;
            case 'fl21':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: true,

                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS', isTxt: false,
                    rid: '+12394581940',
                    nickName: '80 Hancock Bridge Pkwy location in Cape Coral',
                    addy1: '80 Hancock Bridge Pkwy',
                    addy2: 'Cape Coral, FL'
                };
                return sidInfo;
            case 'fl22':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: true,

                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS', isTxt: false,
                    rid: '+12392457019',
                    nickName: '13550 Reflections Pkwy location in Fort Myers',
                    addy1: '13550 Reflections Pkwy',
                    addy2: 'Fort Myers, FL'
                };
                return sidInfo;
            case 'fl25':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: true,

                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS', isTxt: false,
                    rid: '+12397315500',
                    nickName: '17940 N Tamiami Trail location in North Fort Myers',
                    addy1: '17940 N Tamiami Trail',
                    addy2: 'North Fort Myers, FL'
                };
                return sidInfo;
            case 'fl31':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: true,

                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS', isTxt: false,
                    rid: '+12392573270',
                    nickName: '643 Cape Coral Pkwy E location in Cape Coral',
                    addy1: '643 Cape Coral Pkwy E',
                    addy2: 'Cape Coral, FL'
                };
                return sidInfo;
            case 'fl38':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: true,

                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS', isTxt: false,
                    rid: '+12393014800',
                    nickName: '3300 Bonita Beach Rd SW location in Bonita Springs',
                    addy1: '3300 Bonita Beach Rd SW',
                    addy2: 'Bonita Springs, FL'
                };
                return sidInfo;
            case 'fl39':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: true,

                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS', isTxt: false,
                    rid: '+19416134040',
                    nickName: '24001 Peachland Blvd location in Port Charlotte',
                    addy1: '24001 Peachland Blvd,',
                    addy2: 'Port Charlotte, FL'
                };
                return sidInfo;

            case 'mi14':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12485481210',
                    nickName: '333 W Eleven Mile Rd location in Madison Heights',
                    addy1: '333 W Eleven Mile Rd',
                    addy2: 'Madison Heights, MI'
                };
                return sidInfo;
            case 'mi53':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15173399606',
                    nickName: '1640 Haslett Rd location in Haslett',
                    addy1: '1640 Haslett Rd',
                    addy2: 'Haslett, MI'
                };
                return sidInfo;
            case 'mi58':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+19897319929',
                    nickName: '111 West St location in Gaylord',
                    addy1: '111 West St',
                    addy2: 'Gaylord, MI'
                };
                return sidInfo;
            case 'mi94':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12319339309',
                    nickName: '1130 S Garfield Ave location in Traverse City',
                    addy1: '1130 S Garfield Ave',
                    addy2: 'Traverse City, MI'
                };
                return sidInfo;
            case 'mi99':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17344299700',
                    nickName: '978 E Michigan Ave location in Saline',
                    addy1: '978 E Michigan Ave',
                    addy2: 'Saline, MI'
                };
                return sidInfo;
            case 'il08':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12173523333',
                    nickName: '512 S Neil St location in Champaign',
                    addy1: '512 S Neil St',
                    addy2: 'Champaign, IL'
                };
                return sidInfo;
            case 'il17':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12173529992',
                    nickName: '1907 W Springfield Ave location in Champaign',
                    addy1: '1907 W Springfield Ave',
                    addy2: 'Champaign, IL'
                };
                return sidInfo;
            case 'mi18':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15867587733',
                    nickName: '22912 Ryan Rd location in Warren',
                    addy1: '22912 Ryan Rd',
                    addy2: 'Warren, MI'
                };
                return sidInfo;
            case 'mi133':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12319439999',
                    nickName: '3985 Beitner Rd location in Traverse City',
                    addy1: '3985 Beitner Rd',
                    addy2: 'Traverse City, MI'
                };
                return sidInfo;
            case 'ky02':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18593444400',
                    nickName: '3420 Valley Plaza Pkwy location in Fort Wright',
                    addy1: '3420 Valley Plaza Pkwy',
                    addy2: 'Fort Wright, KY'
                };
                return sidInfo;
            case 'in01':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13178155555',
                    nickName: '2764 E 146th St location in Carmel',
                    addy1: '2764 E 146th St',
                    addy2: 'Carmel, IN'
                };
                return sidInfo;



            case 'in13':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13172525445',
                    nickName: '2635 E 62nd St location in Indianapolis',
                    addy1: '2635 E 62nd St',
                    addy2: 'Indianapolis, IN'
                };
                return sidInfo;
            case 'in02':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13178815387',
                    nickName: '954 N State Rd location in Greenwood',
                    addy1: '954 N State Rd',
                    addy2: 'Greenwood, IN'
                };
                return sidInfo;
            case 'ky01':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18593846600',
                    nickName: '7220 Burlington Pike location in Florence',
                    addy1: '7220 Burlington Pike',
                    addy2: 'Florence, KY'
                };
                return sidInfo;
            case 'mi123':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16166474444',
                    nickName: '4575 W River Dr location in Comstock Park',
                    addy1: '4575 W River Dr',
                    addy2: 'Comstock Park, MI'
                };
                return sidInfo;
            case 'mi131':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16162421333',
                    nickName: '1215 Fuller Ave location in Grand Rapids',
                    addy1: '1215 Fuller Ave',
                    addy2: 'Grand Rapids, MI'
                };
                return sidInfo;
            case 'mi136':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12695650800',
                    nickName: '590 W Columbia Ave location in Battle Creek',
                    addy1: '590 W Columbia Ave',
                    addy2: 'Battle Creek, MI'
                };
                return sidInfo;
            case 'mi142':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16169401888',
                    nickName: '6275 28th St location in Grand Rapids',
                    addy1: '6275 28th St',
                    addy2: 'Grand Rapids, MI'
                };
                return sidInfo;
            case 'mi144':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16162420555',
                    nickName: '2163 Wealthy St location in Grand Rapids',
                    addy1: '2163 Wealthy St',
                    addy2: 'Grand Rapids, MI'
                };
                return sidInfo;
            case 'mi147':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16165462900',
                    nickName: '11190 Chicago Dr location in Zeeland',
                    addy1: '11190 Chicago Dr',
                    addy2: 'Zeeland, MI'
                };
                return sidInfo;


            case 'mi55':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17346715387',
                    nickName: '2031 West Rd location in Trenton',
                    addy1: '2031 West Rd',
                    addy2: 'Trenton, MI'
                };
                return sidInfo
            case 'mi100':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12482030007',
                    nickName: '4082 W Maple Rd location in Bloomfield',
                    addy1: '4082 W Maple Rd',
                    addy2: 'Bloomfield, MI'
                };
                return sidInfo
            case 'mi71':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12486769566',
                    nickName: '525 N Main St location in Milford',
                    addy1: '525 N Main St',
                    addy2: 'Milford, MI'
                };
                return sidInfo
            case 'mi108':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13135841400',
                    nickName: '13735 Warren Ave location in Dearborn',
                    addy1: '13735 Warren Ave',
                    addy2: 'Dearborn, MI'
                };
                return sidInfo
            case 'mi22':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12483660312',
                    nickName: '1575 Union Lake Rd location in Commerce Twp',
                    addy1: '1575 Union Lake Rd',
                    addy2: 'Commerce Twp, MI'
                };
                return sidInfo
            case 'mi165':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16169408860',
                    nickName: '820 Forest Hill Ave location in Grand Rapids',
                    addy1: '820 Forest Hill Ave',
                    addy2: 'Grand Rapids, MI'
                };
                return sidInfo



            case 'mi124':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12314871600',
                    nickName: '302 W Mitchell St location in Petoskey',
                    addy1: '302 W Mitchell St',
                    addy2: 'Petoskey, MI'
                };
                return sidInfo
            case 'in12':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13175717070',
                    nickName: '1490 E 86th St location in Indianapolis',
                    addy1: '1490 E 86th St',
                    addy2: 'Indianapolis, IN'
                };
                return sidInfo
            case 'oh33':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+19372364444',
                    nickName: '6241 Old Troy Pike location in Huber Heights',
                    addy1: '6241 Old Troy Pike',
                    addy2: 'Huber Heights, OH'
                };
                return sidInfo

            case 'mi90':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12488890011',
                    nickName: '901 Nordic Dr location in White Lake',
                    addy1: '901 Nordic Dr',
                    addy2: 'White Lake, MI'
                };
                return sidInfo
            case 'oh09':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+19374344400',
                    nickName: '5843 Far Hills Ave location in Dayton',
                    addy1: '5843 Far Hills Ave',
                    addy2: 'Dayton, OH'
                };
                return sidInfo
            case 'oh27':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+19377485555',
                    nickName: '228 W Central Ave location in Springboro',
                    addy1: '228 W Central Ave',
                    addy2: 'Springboro, OH'
                };
                return sidInfo
            case 'oh36':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+19374263333',
                    nickName: '3979 Indian Ripple Rd location in Beavercreek',
                    addy1: '3979 Indian Ripple Rd',
                    addy2: 'Beavercreek, OH'
                };
                return sidInfo;

            case 'mi113':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17344249810',
                    nickName: '7011 Dexter Ann Arbor Rd location in Dexter',
                    addy1: '7011 Dexter Ann Arbor Rd',
                    addy2: 'Dexter, MI'
                };
                return sidInfo;

            case 'fl23':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12395924369',
                    nickName: '9853 Tamiami Trail location in Naples',
                    addy1: '9853 Tamiami Trail',
                    addy2: 'Naples, FL'
                };
                return sidInfo;
            case 'oh16':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+19374311111',
                    nickName: '2418 Esquire Dr location in Beavercreek',
                    addy1: '2418 Esquire Dr',
                    addy2: 'Beavercreek, OH'
                };
                return sidInfo;



            case 'mi85':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12486823744',
                    nickName: '3375 Orchard Lake Rd location in Keego Harbor',
                    addy1: '3375 Orchard Lake Rd',
                    addy2: 'Keego Harbor, MI'
                };
                return sidInfo;
            case 'oh45':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17407883330',
                    nickName: '792 Hebron Rd location in Heath',
                    addy1: '792 Hebron Rd',
                    addy2: 'Heath, OH'
                };
                return sidInfo;

            case 'oh46':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16143927686',
                    nickName: '5953 Sunbury Rd, location in Westerville',
                    addy1: '5953 Sunbury Rd',
                    addy2: 'Westerville, OH'
                };
                return sidInfo;

            case 'mi28':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13133595387',
                    nickName: '3945 S Telegraph Rd location in Dearborn Heights',
                    addy1: '3945 S Telegraph Rd',
                    addy2: 'Dearborn Heights, MI'
                };
                return sidInfo;
            case 'mi74':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17344209756',
                    nickName: '15450 N Haggerty Rd location in Plymouth',
                    addy1: '15450 N Haggerty Rd',
                    addy2: 'Plymouth, MI'
                };
                return sidInfo;

            case 'fl15':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17273972000',
                    nickName: '9649 Bay Pines Blvd location in St. Petersburg',
                    addy1: '9649 Bay Pines Blvd',
                    addy2: 'St. Petersburg, FL'
                };
                return sidInfo
            case 'fl33':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+19547825387',
                    nickName: '437 E Atlantic Blvd location in Pompano Beach',
                    addy1: '437 E Atlantic Blvd',
                    addy2: 'Pompano Beach, FL'
                };
                return sidInfo
            case 'fl05':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+19545655387',
                    nickName: '1300 E Oakland Park Blvd location in Oakland Park',
                    addy1: '1300 E Oakland Park Blvd',
                    addy2: 'Oakland Park, FL'
                };
                return sidInfo

            case 'mi89':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13132742600',
                    nickName: '23541 Ford Rd location in Dearborn',
                    addy1: '23541 Ford Rd',
                    addy2: 'Dearborn, MI'
                };
                return sidInfo
            case 'oh15':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+14404601700',
                    nickName: '5350 Mayfield Rd location in Lyndhurst',
                    addy1: '5350 Mayfield Rd',
                    addy2: 'Lyndhurst, OH'
                };
                return sidInfo


            case 'mi73':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12489262111',
                    nickName: 'S. Commerce & N. Pontiac Trail Location in Walled Lake',
                    addy1: '1126 N Pontiac Trl',
                    addy2: 'Walled Lake, MI'
                };
                return sidInfo;
            case 'tn04':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16159047277',
                    nickName: '4167 Franklin Rd location in Murfreesboro',
                    addy1: '4167 Franklin Rd',
                    addy2: 'Murfreesboro, TN'
                };
                return sidInfo;
            case 'tn07':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16152231028',
                    nickName: '539 Almaville Rd. location in Smyrna',
                    addy1: '539 Almaville Rd.',
                    addy2: 'Smyrna, TN'
                };
                return sidInfo;
            case 'tn19':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16158901214',
                    nickName: '2975 S Rutherford Blvd location in Murfreesboro',
                    addy1: '2975 S Rutherford Blvd',
                    addy2: 'Murfreesboro, TN'
                };
                return sidInfo;
            case 'tn22':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16158965550',
                    nickName: '3325 Memorial Blvd location in Murfreesboro',
                    addy1: '3325 Memorial Blvd',
                    addy2: 'Murfreesboro, TN'
                };
                return sidInfo;
            case 'tn14':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16154486276',
                    nickName: '130 Long Hollow Pike location in Goodlettsville',
                    addy1: '130 Long Hollow Pike',
                    addy2: 'Goodlettsville, TN'
                };
                return sidInfo;
            case 'tn23':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+19314310002',
                    nickName: '2965 Fort Campbell Blvd location in Clarksville',
                    addy1: '2965 Fort Campbell Blvd',
                    addy2: 'Clarksville, TN'
                };
                return sidInfo;
            case 'tn30':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+19316472000',
                    nickName: '1849 Madison St location in Clarksville',
                    addy1: '1849 Madison St',
                    addy2: 'Clarksville, TN'
                };
                return sidInfo;
            case 'ga01':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON FIRST ORDER',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON FIRST ORDER',
                    successPro: 'PRO ORDERS ARE 15% OFF ON FIRST ORDER',
                    isTxt: true,
                    rid: '+17706328559',
                    nickName: '1257 N Peachtree Pkwy location in Peachtree City',
                    addy1: '1257 N Peachtree Pkwy',
                    addy2: 'Peachtree City, GA'
                };
                return sidInfo;
            case 'mi111':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16164472555',
                    nickName: '5150 Northland Dr location in Grand Rapids',
                    addy1: '5150 Northland Dr',
                    addy2: 'Grand Rapids, MI'
                };
                return sidInfo;
            case 'nc09':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13368546060',
                    nickName: '1564 Highwoods Blvd location in Greensboro',
                    addy1: '1564 Highwoods Blvd',
                    addy2: 'Greensboro, NC'
                };
                return sidInfo;
            case 'nc17':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13362797099',
                    nickName: '2408 Spring Garden St location in Greensboro',
                    addy1: '2408 Spring Garden St',
                    addy2: 'Greensboro, NC'
                };
                return sidInfo;

            case 'mi159':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12317792000',
                    nickName: '8846 E Boon Rd Location in Cadillac',
                    addy1: '8846 E Boon Rd',
                    addy2: 'Cadillac, MI'
                };
                return sidInfo;
            case 'tn15':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18656750505',
                    nickName: '11124 Kingston Pike location in Farragut',
                    addy1: '11124 Kingston Pike',
                    addy2: 'Farragut, TN'
                };
                return sidInfo;
            case 'tn05':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16156621110',
                    nickName: '8082 Highway 100 location in Nashville',
                    addy1: '8082 Highway 100',
                    addy2: 'Nashville, TN'
                };
                return sidInfo;
            case 'tn06':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16158331200',
                    nickName: '15538 Old Hickory Blvd location in Nashville',
                    addy1: '15538 Old Hickory Blvd',
                    addy2: 'Nashville, TN'
                };
                return sidInfo;
            case 'tn02':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16159415455',
                    nickName: '10654 Concord Rd location in Brentwood',
                    addy1: '10654 Concord Rd',
                    addy2: 'Brentwood, TN'
                };
                return sidInfo;
            case 'tn17':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16152922020',
                    nickName: '2010 Richard Jones Rd location in Nashville',
                    addy1: '2010 Richard Jones Rd',
                    addy2: 'Nashville, TN'
                };
                return sidInfo;
            case 'tn26':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16156415333',
                    nickName: '3534 Murfreesboro Pike location in Antioch',
                    addy1: '3534 Murfreesboro Pike',
                    addy2: 'Antioch, TN'
                };
                return sidInfo;
            case 'il14':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17082630150',
                    nickName: '17105 88th Ave location in Tinley Park',
                    addy1: '17105 88th Ave',
                    addy2: 'Tinley Park, IL'
                };
                return sidInfo;
        case 'az03':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+16232257856',
                        nickName: '20229 N 67th location in Glendale',
                        addy1: '20229 N 67th',
                        addy2: 'Glendale, AZ'
                    };
                    return sidInfo;
        case 'az04':
                    sidInfo = {
                            discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                            isPro: false,
                            isServiceFee: false,
                            successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                            successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                            isTxt: true,
                            rid: '+16028882535',
                            nickName: '1835 E Guadalupe Rd, location in Tempe',
                            addy1: '1835 E Guadalupe Rd',
                            addy2: 'Tempe, AZ'
                        };
                        return sidInfo;
        
            case 'fl43':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12397995387',
                    nickName: '7211 Vanderbilt Beach Rd location in Naples',
                    addy1: '7211 Vanderbilt Beach Rd',
                    addy2: 'Naples, FL'
                };
                return sidInfo;
                
        case 'tn42':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+16157128772',
                        nickName: '101 Creekside Crossing location in Brentwood',
                        addy1: '101 Creekside Crossing',
                        addy2: 'Brentwood, TN'
                    };
                    return sidInfo;
            
            case 'mi15':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15864120040',
                    nickName: '16852 21 Mile Rd location in Macomb',
                    addy1: '16852 21 Mile Rd',
                    addy2: 'Macomb, MI'
                };
                return sidInfo;
            case 'mi46':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12484495387',
                    nickName: '1053 Novi Rd location in Northville',
                    addy1: '1053 Novi Rd',
                    addy2: 'Northville, MI'
                };
                return sidInfo;


                case 'il27':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17733124445',
                    nickName: '7070 N Clark St location in Chicago',
                    addy1: '7070 N Clark St',
                    addy2: 'Chicago, IL'
                };
                return sidInfo;

                case 'mo01':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+16362208929',
                        nickName: '14940 Manchester Rd location in Ballwin',
                        addy1: '14940 Manchester Rd',
                        addy2: 'Ballwin, MO'
                    };
                    return sidInfo;
                case 'tx05':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+12147050860',
                        nickName: '7151 Preston Rd location in Frisco',
                        addy1: '7151 Preston Rd',
                        addy2: 'Frisco, TX'
                    };
                    return sidInfo;

              case 'il35':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18478935387',
                    nickName: '1312 E Rand Rd, location in Prospect Heights',
                    addy1: '1312 E Rand Rd',
                    addy2: 'Prospect Heights, IL'
                };
                return sidInfo;
                
                case 'il31':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+17737398553',
                        nickName: '1348 E 55th St location in Chicago',
                        addy1: '1348 E 55th St',
                        addy2: 'Chicago, IL'
                    };
                    return sidInfo;

              case 'il32':
                        sidInfo = {
                            discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                            isPro: false,
                            isServiceFee: false,
                            successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                            successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                            isTxt: true,
                            rid: '+17084078881',
                            nickName: '11232 Lincoln Hwy location in Mokena',
                            addy1: '11232 Lincoln Hwy',
                            addy2: 'Mokena, IL'
                        };
                        return sidInfo;
            case 'mi31':
                            sidInfo = {
                                discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                                isPro: false,
                                isServiceFee: false,
                                successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                                successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                                isTxt: true,
                                rid: '+12486741700',
                                nickName: '4238 Pontiac Lake Rd, location in Waterford',
                                addy1: '4238 Pontiac Lake Rd',
                                addy2: 'Waterford, MI'
                            };
                            return sidInfo;

                            case 'oh09':
                                sidInfo = {
                                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                                    isPro: false,
                                    isServiceFee: false,
                                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                                    isTxt: true,
                                    rid: '+19374344400',
                                    nickName: '5843 Far Hills Ave, location in Dayton',
                                    addy1: '5843 Far Hills Ave',
                                    addy2: 'Dayton, OH'
                                };
                                return sidInfo;

                            case 'oh27':
                                sidInfo = {
                                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                                    isPro: false,
                                    isServiceFee: false,
                                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                                    isTxt: true,
                                    rid: '+19377485555',
                                    nickName: '228 W Central Ave, location in Springboro',
                                    addy1: '228 W Central Ave',
                                    addy2: 'Springboro, OH'
                                };
                                return sidInfo;

                case 'tx23':
                                sidInfo = {
                                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                                    isPro: false,
                                    isServiceFee: false,
                                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                                    isTxt: true,
                                    rid: '+19366005387',
                                    nickName: '3776 FM-1488, location in Conroe',
                                    addy1: '3776 FM-1488',
                                    addy2: 'Conroe, TX'
                                };
                                return sidInfo;
                    case 'tx25':
                                    sidInfo = {
                                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                                        isPro: false,
                                        isServiceFee: false,
                                        successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                                        isTxt: true,
                                        rid: '+15127671688',
                                        nickName: '1201 Barbara Jordan Blvd, location in Austin',
                                        addy1: '1201 Barbara Jordan Blvd',
                                        addy2: 'Austin, TX'
                                    };
                                    return sidInfo;
            case 'il30':
                        sidInfo = {
                            discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                            isPro: false,
                            isServiceFee: false,
                            successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                            successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                            isTxt: true,
                            rid: '+18722745387',
                            nickName: '5255 N Milwaukee Ave location in Chicago',
                            addy1: '5255 N Milwaukee Ave',
                            addy2: 'Chicago, IL'
                        };
                        return sidInfo;


            case 'mi150':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12484735387',
                    nickName: '39711 Grand River Ave location in Novi',
                    addy1: '39711 Grand River Ave',
                    addy2: 'Novi, MI'
                };
                return sidInfo;
            
            case 'mi112':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12488588800',
                    nickName: '43173 Woodward Ave location in Bloomfield Hills',
                    addy1: '43173 Woodward Ave',
                    addy2: 'Bloomfield Hills, MI'
                };
                return sidInfo;
                 
            case 'mi125':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+17344339700',
                        nickName: '506 N Main St location in Chelsea',
                        addy1: '506 N Main St',
                        addy2: 'Chelsea, MI'
                    };
                    return sidInfo;
    
            case 'mi37':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15865666300',
                    nickName: '13785 23 Mile Rd location in Shelby Twp',
                    addy1: '13785 23 Mile Rd',
                    addy2: 'Shelby Twp, MI'
                };
                return sidInfo;

            case 'mi11':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12485281800',
                    nickName: '5102 Rochester Rd Location in Troy',
                    addy1: '5102 Rochester Rd',
                    addy2: 'Troy, MI'
                };
                return sidInfo;
            case 'tn09':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16153298600',
                    nickName: '214 25th Ave location in Nashville',
                    addy1: '214 25th Ave',
                    addy2: 'Nashville, TN'
                };
                return sidInfo;
            case 'az01':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+14808957492',
                    nickName: '4245 S Arizona Ave location in Chandler',
                    addy1: '4245 S Arizona Ave',
                    addy2: 'Chandler, AZ'
                };
                return sidInfo;
            case 'az02':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+14809989889',
                    nickName: '10855 N Tatum Blvd location in Phoenix',
                    addy1: '10855 N Tatum Blvd',
                    addy2: 'Phoenix, AZ'
                };
                return sidInfo;
            case 'mo02':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13146955320',
                    nickName: '234 W Lockwood Ave location in Webster Groves',
                    addy1: '234 W Lockwood Ave',
                    addy2: 'Webster Groves, MO'
                };
                return sidInfo;


            case 'mi09':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12482884488',
                    nickName: '230 W 14 Mile Rd location in Clawson',
                    addy1: '230 W 14 Mile Rd',
                    addy2: 'Clawson, MI'
                };
                return sidInfo;
            case 'mi35':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12486377549',
                    nickName: '151 N Eton St location in Birmingham',
                    addy1: '151 N Eton St',
                    addy2: 'Birmingham, MI'
                };
                return sidInfo;
            case 'mi70':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18102257454',
                    nickName: '8705 W Grand River Rd location in Brighton',
                    addy1: '8705 W Grand River Rd',
                    addy2: 'Brighton, MI'
                };
                return sidInfo;
            case 'mi105':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12486276300',
                    nickName: '11 S Ortonville Rd location in Ortonville',
                    addy1: '11 S Ortonville Rd',
                    addy2: 'Ortonville, MI'
                };
                return sidInfo;
            case 'oh17':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15137934488',
                    nickName: '10040 Montgomery Rd location in Cincinnati',
                    addy1: '10040 Montgomery Rd',
                    addy2: 'Cincinnati, OH'
                };
                return sidInfo;
            case 'oh20':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15137740207',
                    nickName: '11928 Montgomery Rd location in Cincinnati',
                    addy1: '11928 Montgomery Rd',
                    addy2: 'Cincinnati, OH'
                };
                return sidInfo;
            case 'oh24':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15138605387',
                    nickName: '6679 Dixie Hwy location in Fairfield',
                    addy1: '6679 Dixie Hwy',
                    addy2: 'Fairfield, OH'
                };
                return sidInfo;
            case 'oh29':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15132480918',
                    nickName: '5976 Meijer Dr location in Milford',
                    addy1: '5976 Meijer Dr',
                    addy2: 'Milford, OH'
                };
                return sidInfo;
            case 'oh32':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15134745387',
                    nickName: '8190 Beechmont Ave location in Cincinnati',
                    addy1: '8190 Beechmont Ave',
                    addy2: 'Cincinnati, OH'
                };
                return sidInfo;
            case 'in11':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17654460011',
                    nickName: '210 S Creasy Ln location in Lafayette',
                    addy1: '210 S Creasy Ln',
                    addy2: 'Lafayette, IN'
                };
                return sidInfo;


            case 'mo06':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13145482585',
                    nickName: '12536 Olive Blvd location in Creve Coeur',
                    addy1: '12536 Olive Blvd',
                    addy2: 'Creve Couer, MO'
                };
                return sidInfo;
            case 'ky09':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12709381001',
                    nickName: '1625 Campbell Ln location in Bowling Green',
                    addy1: '1625 Campbell Ln',
                    addy2: 'Bowling Green, OH'
                };
                return sidInfo;

            case 'mo07':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13143510955',
                    nickName: '3533 Hampton Ave location in St. Louis',
                    addy1: '3533 Hampton Ave',
                    addy2: 'St. Louis, MO'
                };
                return sidInfo;
            case 'ky04':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18592917000',
                    nickName: '1305 Monmouth St location in Newport',
                    addy1: '1305 Monmouth St',
                    addy2: 'Newport, KY'
                };
                return sidInfo;
            case 'fl12':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON YOUR FIRST ORDER',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON YOUR FIRST ORDER',
                    successPro: 'PRO ORDERS ARE 15% OFF ON YOUR FIRST ORDER',
                    isTxt: true,
                    rid: '+19413124225',
                    nickName: '5045 Fruitville Rd location in Sarasota',
                    addy1: '5045 Fruitville Rd',
                    addy2: 'Sarasota, FL'
                };
                return sidInfo;
            case 'fl26':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON YOUR FIRST ORDER',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON YOUR FIRST ORDER',
                    successPro: 'PRO ORDERS ARE 15% OFF ON YOUR FIRST ORDER',
                    isTxt: true,
                    rid: '+19417395387',
                    nickName: '8721 E State Rd 70 location in Bradenton',
                    addy1: '8721 E State Rd 70',
                    addy2: 'Bradenton, FL'
                };
                return sidInfo;




                case 'fl09':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+17274414464',
                        nickName: '2045 Gulf to Bay Blvd location in Clearwater',
                        addy1: '2045 Gulf to Bay Blvd',
                        addy2: 'Clearwater, FL'
                    };
                    return sidInfo;
                case 'tx08':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+12814859700',
                        nickName: '2708 Pearland Pkwy location in Pearland',
                        addy1: '2708 Pearland Pkwy',
                        addy2: 'Pearland, TX'
                    };
                    return sidInfo;
               case 'mi38':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+17342669333',
                        nickName: '29445 Five Mile Rd location in Livonia',
                        addy1: '29445 Five Mile Rd',
                        addy2: 'Livonia, MI'
                    };
                    return sidInfo;
                case 'mi127':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+15173232322',
                        nickName: '6227 W Saginaw Hwy location in Lansing',
                        addy1: '6227 W Saginaw Hwy',
                        addy2: 'Lansing, MI'
                    };
                    return sidInfo;
                case 'mi32':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON YOUR FIRST ORDER',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+12484751900',
                        nickName: '3429 Five Points Dr location in Auburn hills',
                        addy1: '3429 Five Points Dr',
                        addy2: 'Auburn Hills, MI'
                    };
                    return sidInfo;
                case 'fl19':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON YOUR FIRST ORDER',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+19042217700',
                        nickName: '13475 Atlantic Blvd location in Jacksonville',
                        addy1: '13475 Atlantic Blvd',
                        addy2: 'Jacksonville, FL'
                    };
                    return sidInfo;
                case 'mi20':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON YOUR FIRST ORDER',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+13138755200',
                        nickName: '10035 Joseph Campau Ave location in Hamtramck',
                        addy1: '10035 Joseph Campau Ave',
                        addy2: 'Hamtramck, MI'
                    };
                    return sidInfo;


                    case 'wi02':
                        sidInfo = {
                            discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                            isPro: false,
                            isServiceFee: false,
                            successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                            successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                            isTxt: true,
                            rid: '+19206878510',
                            nickName: '202 W Main St location in Little Chute',
                            addy1: '202 W Main St',
                            addy2: 'Little Chute, WI'
                        };
                        return sidInfo;
                    case 'oh01':
                        sidInfo = {
                            discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                            isPro: false,
                            isServiceFee: false,
                            successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                            successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                            isTxt: true,
                            rid: '+14198417700',
                            nickName: '5392 Monroe St location in Toledo',
                            addy1: '5392 Monroe St',
                            addy2: 'Toledo, OH'
                        };
                        return sidInfo;
                    case 'oh05':
                        sidInfo = {
                            discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                            isPro: false,
                            isServiceFee: false,
                            successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                            successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                            isTxt: true,
                            rid: '+14193816700',
                            nickName: '4733 Glendale Ave location in Toledo',
                            addy1: '4733 Glendale Ave',
                            addy2: 'Toledo, IN'
                        };
                        return sidInfo;
                    case 'mi170':
                        sidInfo = {
                            discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                            isPro: false,
                            isServiceFee: false,
                            successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                            successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                            isTxt: true,
                            rid: '+17345686300',
                            nickName: '3606 W Sterns Rd location in Lambertville',
                            addy1: '3606 W Sterns Rd',
                            addy2: 'Lambertville, MI'
                        };
                        return sidInfo;

                        case 'mi67':
                            sidInfo = {
                                discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                                isPro: false,
                                isServiceFee: false,
                                successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                                successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                                isTxt: true,
                                rid: '+18106675387',
                                nickName: '1225 Summit Dr location in Lapeer',
                                addy1: '1225 Summit Dr',
                                addy2: 'Lapeer, MI'
                            };
                            return sidInfo;
                        case 'mi21':
                            sidInfo = {
                                discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                                isPro: false,
                                isServiceFee: false,
                                successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                                successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                                isTxt: true,
                                rid: '+13134179770',
                                nickName: '17646 Mack Ave location in Grosse Pointe',
                                addy1: '17646 Mack Ave',
                                addy2: 'Grosse Pointe, MI'
                            };
                            return sidInfo;

                
            case 'mi49':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12487370770',
                    nickName: '6641 Orchard Lake Rd location in West Bloomfield',
                    addy1: '6641 Orchard Lake Rd',
                    addy2: 'West Bloomfield, MI'
                };
                return sidInfo;
                case 'mi101':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+18106583400',
                    nickName: '614 N State Rd location in Davison',
                    addy1: '614 N State Rd',
                    addy2: 'Davison, MI'
                };
                return sidInfo;

                case 'in03':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+13172289565',
                        nickName: '2464 Lake Cir Dr location in Indianapolis',
                        addy1: '2464 Lake Cir Dr',
                        addy2: 'Indianapolis, IN'
                    };
                    return sidInfo;

                case 'oh47':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+16143007178‬',
                        nickName: '50 E Nationwide Blvd location in Columbus',
                        addy1: '50 E Nationwide Blvd',
                        addy2: 'Columbus, OH'
                    };
                    return sidInfo;

                case 'mi81':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON YOUR FIRST ORDER',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+13132590500',
                        nickName: '3420 E Jefferson Ave location in Detroit',
                        addy1: '3420 E Jefferson Ave',
                        addy2: 'Detroit, MI'
                    };
                    return sidInfo;
                    case 'in05':
                        sidInfo = {
                            discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                            isPro: false,
                            isServiceFee: false,
                            successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                            successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                            isTxt: true,
                            rid: '+15742725387',
                            nickName: '4123 Grape Rd location in Mishawaka',
                            addy1: '4123 Grape Rd',
                            addy2: 'Mishawaka, IN'
                        };
                        return sidInfo;
                    case 'in10':
                        sidInfo = {
                            discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                            isPro: false,
                            isServiceFee: false,
                            successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                            successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                            isTxt: true,
                            rid: '+15743333382',
                            nickName: '153 County Rd 6 location in Elkhart',
                            addy1: '153 County Rd 6',
                            addy2: 'Elkhart, IN'
                        };
                        return sidInfo;




            case 'fl40':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON YOUR FIRST ORDER',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON YOUR FIRST ORDER',
                    successPro: 'PRO ORDERS ARE 15% OFF ON YOUR FIRST ORDER',
                    isTxt: true,
                    rid: '+19417295387',
                    nickName: '9556 Buffalo Rd location in Palmetto',
                    addy1: '9556 Buffalo Rd',
                    addy2: 'Palmetto, FL'
                };
                return sidInfo;
            


                case 'tn12':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+19314879700',
                        nickName: '1021 Crossings Blvd location in Spring Hill',
                        addy1: '1021 Crossings Blvd',
                        addy2: 'Spring Hill, TN'
                    };
                    return sidInfo;
                case 'tn24':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+19315482200',
                        nickName: '1940 Shady Brook St location in Columbia',
                        addy1: '1940 Shady Brook St',
                        addy2: 'Columbia, TN'
                    };
                    return sidInfo;
                case 'mi64':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+15867278400',
                        nickName: '66755 Gratiot Ave location in Richmond',
                        addy1: '66755 Gratiot Ave',
                        addy2: 'Richmond, MI'
                    };
                    return sidInfo;
                case 'oh29':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+15132480918',
                        nickName: '5976 Meijer Dr location in Milford',
                        addy1: '5976 Meijer Dr',
                        addy2: 'Milford, OH'
                    };
                    return sidInfo;

                    case 'fl37':
                        sidInfo = {
                            discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                            isPro: false,
                            isServiceFee: false,
                            successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                            successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                            isTxt: true,
                            rid: '+19548585387',
                            nickName: '4637 S University Dr location in Davie',
                            addy1: '4637 S University Dr',
                            addy2: 'Davie, FL'
                        };
                        return sidInfo;
                    case 'mi76':
                        sidInfo = {
                            discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                            isPro: false,
                            isServiceFee: false,
                            successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                            successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                            isTxt: true,
                            rid: '+19896841111',
                            nickName: '1901 S Euclid Ave location in Bay City',
                            addy1: '1901 S Euclid Ave',
                            addy2: 'Bay City, MI'
                        };
                        return sidInfo;
                    case 'mi122':
                        sidInfo = {
                            discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                            isPro: false,
                            isServiceFee: false,
                            successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                            successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                            isTxt: true,
                            rid: '+19897935387',
                            nickName: '5789 State St location in Saginaw',
                            addy1: '5789 State St',
                            addy2: 'Saginaw, MI'
                        };
                        return sidInfo;
                    case 'mi138':
                        sidInfo = {
                            discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                            isPro: false,
                            isServiceFee: false,
                            successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                            successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                            isTxt: true,
                            rid: '+19894977777',
                            nickName: '5695 Bay Rd location in Saginaw',
                            addy1: '5695 Bay Rd',
                            addy2: 'Saginaw, MI'
                        };
                        return sidInfo;
                    case 'mi145':
                        sidInfo = {
                            discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                            isPro: false,
                            isServiceFee: false,
                            successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                            successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                            isTxt: true,
                            rid: '+18106875555',
                            nickName: '425 W Vienna St location in Clio',
                            addy1: '425 W Vienna St',
                            addy2: 'Clio, MI'
                        };
                        return sidInfo;


                        case 'oh42':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15137715387',
                    nickName: '10001 Springfield Pike location in Cincinnati',
                    addy1: '10001 Springfield Pike',
                    addy2: 'Cincinnati, OH'
                };
                return sidInfo;

                case 'nv02':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17252584440',
                    nickName: '1321 W Sunset Rd location in Henderson',
                    addy1: '1321 W Sunset Rd',
                    addy2: 'Henderson, NV'
                };
                return sidInfo;

                case 'fl41':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+18633330322',
                        nickName: '4973 US Hwy 98 location in Lakeland',
                        addy1: '4973 US Hwy 98 N',
                        addy2: 'Lakeland, FL'
                    };
                    return sidInfo;
                case 'oh34':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+15139222000',
                        nickName: '5132 Delhi Pike location in Cincinnati',
                        addy1: '5132 Delhi Pike',
                        addy2: 'Cincinnati, OH'
                    };
                    return sidInfo;

            case 'mi33':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12485842267',
                    nickName: '8910 W 8 Mile Rd location in Ferndale',
                    addy1: '8910 W 8 Mile Rd',
                    addy2: 'Ferndale, MI'
                };
                return sidInfo;
                case 'in02':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13178815387',
                    nickName: '954 N State Road 135 location in Greenwood',
                    addy1: '954 N State Road 135',
                    addy2: 'Greenwood, IN'
                };
                return sidInfo;

                case 'co09':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13034666725',
                    nickName: '3800 W 144th Avenue, location in Broomfield',
                    addy1: '3800 W 144th Avenue',
                    addy2: 'Broomfield, CO'
                };
                return sidInfo;

                case 'mi22':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12483660312',
                    nickName: '1575 Union Lake Rd, location in Commerce Charter Twp',
                    addy1: '1575 Union Lake Rd',
                    addy2: 'Commerce Charter Twp, MI'
                };
                return sidInfo;

                case 'mi27':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12484428900',
                    nickName: '30790 Grand River Ave, location in Farmington',
                    addy1: '30790 Grand River Ave',
                    addy2: 'Farmington, MI'
                };
                return sidInfo;

                case 'mi71':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12486769566',
                    nickName: '525 N Main St, location in Milford',
                    addy1: '525 N Main St',
                    addy2: 'Milford, MI'
                };
                return sidInfo;

                case 'wi06':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+14142763000',
                        nickName: '1857 E. Kenilworth PL location in Milwaukee',
                        addy1: '1857 E. Kenilworth PL',
                        addy2: 'Milwaukee, WI'
                    };
                    return sidInfo;
                
                    case 'tx22':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12818888825',
                    nickName: '2611 Mills Branch Dr location in Kingwood',
                    addy1: '2611 Mills Branch Dr',
                    addy2: 'Kingwood, TX'
                };
                return sidInfo;
                
                case 'oh44':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+15135747777',
                        nickName: '5865 Harrison Ave location in Cincinnati',
                        addy1: '5865 Harrison Ave',
                        addy2: 'Cincinnati, OH'
                    };
                    return sidInfo;


                        case 'wi09':
                            sidInfo = {
                                discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                                isPro: false,
                                isServiceFee: false,
                                successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                                successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                                isTxt: true,
                                rid: '+12623338620',
                                nickName: '530 W Sunset Dr location in Waukesha',
                                addy1: '530 W Sunset Dr',
                                addy2: 'Waukesha, WI'
                            };
                            return sidInfo;
                        case 'nc14':
                            sidInfo = {
                                discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                                isPro: false,
                                isServiceFee: false,
                                successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                                successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                                isTxt: true,
                                rid: '+17043337631',
                                nickName: '1800 Rozzelles Ferry Rd location in Charlotte',
                                addy1: '1800 Rozzelles Ferry Rd',
                                addy2: 'Charlotte, NC'
                            };
                            return sidInfo;
                        case 'tx13':
                            sidInfo = {
                                discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                                isPro: false,
                                isServiceFee: false,
                                successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                                successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                                isTxt: true,
                                rid: '+12819424520',
                                nickName: '24225 Kuykendahl Rd location in Tomball',
                                addy1: '24225 Kuykendahl Rd',
                                addy2: 'Tomball, TX'
                            };
                            return sidInfo;

                            case 'ny03':
                                sidInfo = {
                                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                                    isPro: false,
                                    isServiceFee: false,
                                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                                    isTxt: true,
                                    rid: '+16464484400',
                                    nickName: '1165 2nd Ave location in New York',
                                    addy1: '1165 2nd Ave',
                                    addy2: 'New York, NY'
                                };
                                return sidInfo;




                                case 'co05':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17209125387',
                    nickName: '98 Wadsworth Blvd suite 129 location in Lakewood',
                    addy1: '98 Wadsworth Blvd',
                    addy2: 'Lakewood, CO'
                };
                return sidInfo;


                case 'tn01':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16155958883',
                    nickName: '1400 Liberty Pike location in Franklin',
                    addy1: '1400 Liberty Pike',
                    addy2: 'Franklin, TN'
                };
                return sidInfo;
            case 'tn39':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON ALL PIZZAS',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+16154353375',
                        nickName: '1110 Hillsboro Rd location in Franklin',
                        addy1: '1110 Hillsboro Rd',
                        addy2: 'Franklin, TN'
                };
                return sidInfo;
            case 'oh07':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15137770666',
                    nickName: '7035 Yankee Rd location in Liberty Twp',
                    addy1: '7035 Yankee Rd',
                    addy2: 'Liberty Twp, OH'
                };
                return sidInfo;
            case 'oh03':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15132298620',
                    nickName: '7916 Mason Montgomery Rd location in Mason',
                    addy1: '7916 Mason Montgomery Rd',
                    addy2: 'Mason, OH'
                };
                return sidInfo;
            case 'oh04':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+14402437802',
                    nickName: '7554 Pearl Rd location in Middleburg Heights',
                    addy1: '7554 Pearl Rd',
                    addy2: 'Middleburg Heights, OH'
                };
                return sidInfo;
        
            case 'oh35':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+14408882100',
                    nickName: '5344 Pearl Rd location in Parma',
                    addy1: '5344 Pearl Rd',
                    addy2: 'Parma, OH'
                };
                return sidInfo;
            case 'oh40':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+12163281900',
                    nickName: '7878 Broadview Rd location in Parma',
                    addy1: '7878 Broadview Rd',
                    addy2: 'Parma, OH'
                };
                return sidInfo;

                case 'tx04':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+15122801010',
                        nickName: '9001 Brodie Ln location in Austin',
                        addy1: '9001 Brodie Ln',
                        addy2: 'Austin, TX'
                    };
                    return sidInfo;
     
                case 'oh13':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                        successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                        isTxt: true,
                        rid: '+14408921200',
                        nickName: '25947 Detroit Rd location in Westlake',
                        addy1: '25947 Detroit Rd',
                        addy2: 'Westlake, OH'
                    };
                    return sidInfo;

                    case 'tx19':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+15125994356',
                    nickName: '8900 S Congress Ave location in Austin',
                    addy1: '8900 S Congress Ave',
                    addy2: 'Austin, TX'
                };
                return sidInfo;
            case 'tx20':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17374009292',
                    nickName: '3003 S Lamar Blvd location in Austin',
                    addy1: '3003 S Lamar Blvd',
                    addy2: 'Austin, TX'
                };
                return sidInfo;

            case 'oh08':
                sidInfo = {
                    discountTxt: 'FOR A LIMITED TIME GET 15% OFF PIZZAS AT MENU PRICE',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF PIZZAS AT MENU PRICE',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17408819396',
                    nickName: '8882 Moreland St location in Powell',
                    addy1: '8882 Moreland St',
                    addy2: 'Powell, OH'
                };
                return sidInfo;
            
            case 'stores':
                    sidInfo = {
                        discountTxt: 'FOR A LIMITED TIME GET 15% OFF ON YOUR FIRST ORDER',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'TEXT ORDERS ARE 15% OFF ON YOUR FIRST ORDER',
                        successPro: 'PRO ORDERS ARE 15% OFF ON YOUR FIRST ORDER',
                        isTxt: true,
                        rid: '+1941729538799',
                        nickName: '',
                        addy1: '',
                        addy2: ''
                    };
                    return sidInfo;

            case 'demo':
                sidInfo = {
                    discountTxt: '*',
                    isPro: true,

                    isServiceFee: false, successTxt: "Texting is the fastest way to order Jet’s!",
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: false,
                    rid: '+15862033838',
                    nickName: 'Research park dr ',
                    addy1: '3917 Research Park Dr',
                    addy2: 'Ann Arbor, MI'
                };
                return sidInfo;
            case 'xxx':
                return '+14194661111';
            case 'xxx':
                return '+12159608888';
            default:
                sidInfo = {
                    discountTxt: '*',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'Texting is the fastest way to order Jet’s!',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: 'empty',
                    nickName: 'empty',
                    addy1: 'empty',
                    addy2: 'empty'
                };
                return sidInfo;
        }
        // console.log(cust)
    }
}
