import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-content',
  templateUrl: './test-content.component.html',
  styleUrls: ['./test-content.component.css']
})
export class TestContentComponent implements OnInit {
  
  isbtnClicked = false
  constructor() { }

  ngOnInit() {
    this.passwordCheck('Karthi@1892')
  }
  
  passwordCheck(x) {
    const checkList = {
      caps : [],
      small: [],
      number: [],
      symbol: []
    }
    for(let i = 0; i<x.length; i++) {
      if(x.charCodeAt(i) >= 65 || x.charCodeAt(i) < 90){
        console.log(x.charCodeAt(i))
      }
    }
  }
  onClick() {
     console.log('button is clicked')
     this.isbtnClicked = true
  }

}
