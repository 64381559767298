export class hungerrushStore {

    constructor() { }

    findStore(sid) {
        switch (sid) {
            case 'hr360':
                let sidInfo = {
                    discountTxt: '*',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'Texting is the fastest way to order pizza!',
                    successPro: 'Texting is the fastest way to order pizza!',
                    isTxt: true,
                    rid: '+15512911234',
                    nickName: '1315 West Sam Houston Pkwy location in North Houston',
                    addy1: 'West Sam Houston Pkwy',
                    addy2: 'Houston, TX'
                };
                return sidInfo;
            case 'hr361':
                    sidInfo = {
                        discountTxt: '*',
                        isPro: false,
                        isServiceFee: false,
                        successTxt: 'Texting is the fastest way to order pizza!',
                        successPro: 'Texting is the fastest way to order pizza!',
                        isTxt: true,
                        rid: '+15512911234',
                        nickName: '1315 West Sam Houston Pkwy location in North Houston',
                        addy1: 'West Sam Houston Pkwy',
                        addy2: 'Houston, TX'
                    };
                    return sidInfo;

            default:
                sidInfo = {
                    discountTxt: '*',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'Texting is the fastest way to order Jet’s!',
                    successPro: 'PRO ORDERS ARE 20% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: 'empty',
                    nickName: 'empty',
                    addy1: 'empty',
                    addy2: 'empty'
                };
                return sidInfo;
        }
        // console.log(cust)
    }
}
