import { APICenterService } from './../apicenter.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-up-sell-items',
  templateUrl: './up-sell-items.component.html',
  styleUrls: ['./up-sell-items.component.css']
})
export class UpSellItemsComponent implements OnInit {
  isItem1Added =  false;
  isItem2Added =  false;
  isItem3Added =  false;
  isItem4Added =  false;
  imgList = [1,2,3]

  constructor(public api: APICenterService) { }

  ngOnInit() {
   //console.log('Upsell Arr')
   // console.log(this.api.imgAddTxt)
   
  }

  item1() {
    this.api.upSell.isItem1 = false;
    this.isItem1Added = true;
  }
  
  item2() {
    this.api.upSell.isItem2 = false;
    this.isItem2Added = true;
  }

  item3() {
    this.api.upSell.isItem3 = false;
    this.isItem3Added = true;
  }
  
  item4() {
    this.api.upSell.isItem4 = false;
    this.isItem4Added = true;
  }


  items(i) {
    // console.log(this.api.imgTag[i])
    if (typeof(this.api.imgAddTxt[i]) === 'undefined') {

    } else {
      this.api.customer.msg_text = `${this.api.customer.msg_text},${this.api.imgAddTxt[i]}` 
      this.resetAdd()
    }
   
  }
 
  resetAdd(){
    this.api.resetItems()
    this.api.onSend = true;
    this.api.isSummaryTitle = false;
    this.api.isAddItemBox = false;
    this.api.isTotalBox = false;
    this.api.isShowUpsell = false;
    this.api.updateUserMsg();
  }


  
  undoItem1() {
    this.api.upSell.isItem1 = true;
    this.isItem1Added = false;

  }
  undoItem2() {
    this.api.upSell.isItem2 = true;
    this.isItem2Added = false;
  }
  undoItem3() {
    this.api.upSell.isItem3 = true;
    this.isItem3Added = false;
  }
  undoItem4() {
    this.api.upSell.isItem4 = true;
    this.isItem4Added = false;
  }

  onNext(){
    if(this.isItem1Added) {
      this.api.onUpSellSelect = true;
      this.api.customer.msg_text = `${this.api.customer.msg_text},${this.api.upsellAddString.item1}` 
    }
    if(this.isItem2Added){
      this.api.onUpSellSelect = true;
      this.api.customer.msg_text = `${this.api.customer.msg_text},${this.api.upsellAddString.item2}` 
    }
    if(this.isItem3Added){
      this.api.onUpSellSelect = true;
      this.api.customer.msg_text = `${this.api.customer.msg_text},${this.api.upsellAddString.item3}` 
    }
    if(this.isItem4Added){
      this.api.onUpSellSelect = true;
      this.api.customer.msg_text = `${this.api.customer.msg_text},${this.api.upsellAddString.item4}` 
    }

    console.log(this.api.customer.msg_text)
    this.api.isShowUpsell = false;

  }

  onBack() {

    this.api.isDeleteBtn = true;
    this.api.isEditBtn = true;
    this.api.isAddItemBox = true;

    if(this.api.customer.showDisclaimer) {
      this.api.isDisclaimer = true;
      this.api.isContact = false;
    } else {
      this.api.isState_0 = false;
    }
  }

}
