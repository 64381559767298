import { Component, OnInit } from '@angular/core';
import { APICenterService } from '../../apicenter.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-new-login',
  templateUrl: './new-login.component.html',
  styleUrls: ['./new-login.component.css']
})
export class NewLoginComponent implements OnInit {
  username: any;
  password: any;
  errorFlag = false;
  successFlag = false;
  unauthorizedflag = false;
  loading = false;
  loginSuccessful = false;
  resetpassword = false;
  errorMessage = '';
  resetSuccessFullFlag=false;
 // user  =  new UserList();
 // roles = new Roles();
  routes = [{
    'userType': null,
    'routes': null,
  }];


    constructor(private router: Router, private api: APICenterService) {
      const url = window.location.href;
      if (url.indexOf('unauthorized') > -1) {
        console.log('unauthorized');
        this.unauthorizedflag = true;

      }
     }

     ngOnInit() {
       localStorage.clear();
      /*let roles = this.roles.getRoles()
      console.log('userList')
      console.log(roles)
      const userName  = "mi026@jetspizza.com"
      const pwd = "jetsPizza"
      this.api.adminLogin(userName, pwd)
      this.api.getLoginInfo()
      .subscribe((data) => {
        console.log(data)
        this.routes[0].userType = data.userType;
        this.routes[0].routes = data.routes
      })
      console.log(this.routes)*/

     // this.restInfo = sid.findStore(this.restMenuId)
     }
     resetPassword() {
       this.resetpassword = true;
     }
  login() {
    this.errorFlag = false;
    this.loading = true;
    if(this.username== null|| this.username==''|| this.username==undefined){
      this.errorFlag=true;
      this.loading = false;
      this.errorMessage="Username Can't be Empty."
    }
   else if(this.password== null|| this.password==''|| this.password==undefined){
    this.errorFlag=true;
    this.loading = false;
    this.errorMessage="Password Can't be Empty."
  }
  else{
    this.api.adminLoginNew(this.username.toLowerCase(), this.password);
    this.api.getLoginInfoNew()
      .subscribe((data) => {
        console.log('login info');
        console.log(data);
        if(data.statusCode==200){
          this.loading = false;
          localStorage.setItem("userName",data.data.userName+'  ');
          localStorage.setItem("allstoresGroupId",data.data.groupId);
          localStorage.setItem("loginid",data.data.userId);
          localStorage.setItem("routes",JSON.stringify(data.data.routes));
          localStorage.setItem("userTypeId",data.data.userTypeId);
          localStorage.setItem("roleId",data.data.roleId);
          localStorage.setItem("childUserTypeAccess",data.data.childUserTypeAccess);
          localStorage.setItem("childUserTypeId",data.data.childUserTypeId);
          localStorage.setItem("userEmail",this.username);
          
          this.errorFlag=false;
          this.errorMessage="";
          this.router.navigate(['control/manage']);
       //  alert("login Successful");
          

        }
        else if(data.statusCode==201){
          this.loading = false;
          this.errorFlag=true;
          this.errorMessage=data.message;
        }
        /*if (data !== 'wrong pwd') {
          localStorage.setItem('UserRole', data.userType);
          // this.routes = data.routes
          // this.cp.loginUser = data.userName
          localStorage.setItem('userName', data.userName);
          console.log(this.cp.loginUser);
          this.loginSuccessful = true;
          this.routes[0].userType = data.userType;
          this.routes[0].routes = data.routes;
          this.setlocalstorage();
        } else {
          this.errorFlag = true;
          this.errorMessage = 'Invalid Credentials';
          this.successFlag = false;
        }
         */
      });
    }
  
  }
  reset(username) {
    console.log(username);
    if (username != null || username !== undefined || username !== '') {
      this.api.passwordResetNew(username.toLowerCase());
      this.api.getpasswordResetNew()
      .subscribe((data) => {
         console.log(data);
         if(data.statusCode==200){
          this.errorFlag=false;
          this.resetSuccessFullFlag=true;
         }
         else if(data.statusCode==201){
          this.errorFlag=true;
          this.errorMessage=data.message;
        }
    });
  }
  }
  backToLogin() {
    this.username = '';
    this.errorFlag = false;
    this.resetpassword = false;
    this.resetSuccessFullFlag=false;
    localStorage.removeItem('URL');
    this.router.navigate(['/login']);
  }
  /*setlocalstorage() {
   console.log('lol');
   this.errorFlag = false;
   this.successFlag = true;
    const values = new Array();
    const oneday = new Date();
    oneday.setHours(oneday.getHours() + 72 ); // three days from now on
    values.push('Session Valid Till');
    values.push(oneday);
    localStorage.setItem('Date', values.join(';'));

   

    localStorage.setItem('roles', JSON.stringify(this.routes));


   if (localStorage.getItem('URL') != null && localStorage.getItem('URL') !== undefined) {
      const redirect = localStorage.getItem('URL');
      localStorage.removeItem('URL'); // remove url when redirecting


      window.location.href = redirect;
     
    } else {
  
    const role = localStorage.getItem('UserRole');
    if (role === 'ordrsuperadmin') {
      
      window.location.href = '/control';
    }
    if (role === 'ordruser') {
     
      window.location.href = '/control/creport';
    }
    if (role === 'ordradmin') {
    
     window.location.href = '/today/' + this.username;
    }
    }
    this.loading = false;

  } */


}
