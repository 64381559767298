import { APICenterService } from './../apicenter.service';
import { Component, OnInit, NgModule } from '@angular/core';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.css']
})
export class DisclaimerComponent implements OnInit {

  constructor(public api: APICenterService) { }

  ngOnInit() {
  }

  onNextDis() {
    this.api.clickValue = 'Reviewed Order Summary'
    this.api.clickAnalytics()
    this.api.isContact = true;
    this.api.isDisclaimer = false;
    if(this.api.isDelivery) {
      this.api.isTip = true;
    }
  }

  onBack() {
    this.api.clickValue = 'Back Disclaimer'
    this.api.clickAnalytics()
    this.api.isState_0 = false;
  }
 
 isSkip(val) {
  this.api.clickValue = 'Checked Skip Disclaimer'
  this.api.clickAnalytics()
  this.api.userSelectionDisClaimer = !(val.checked)
  //console.log(this.api.userSelectionDisClaimer)
  //console.log(!this.api.userSelectionDisClaimer)
 }


}
