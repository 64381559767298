import { Component, OnInit, ChangeDetectorRef  } from '@angular/core';
import { AudioRecordingService } from './audio-recording.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { APICenterService } from '../apicenter.service';
import {DataService} from './websocket';
import { jetsStore } from './../rest-offer-page/jets-store';
import { ProServiceService } from './../pro-order/pro-service.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-audio-mic',
  templateUrl: './audio-mic.component.html',
  styleUrls: ['./audio-mic.component.css']
})

export class AudioMicComponent implements OnInit {
  
  isRecording = false;
  recordedTime;
  blobUrl;
  loading=false;
  isShowTxt = false;
  istxt1 = false;
  istxt2 = false;
  istxt3 = false;
  isNext = false;
 
  myfile: any;
  file: any;
  selectedFiles: FileList;
  restName
  restId;
  restInfo;
  interval;
  time;
  constructor(public api: APICenterService,private _router: Router,
    private route: ActivatedRoute,
    private service: DataService,public pro: ProServiceService,private audioRecordingService: AudioRecordingService, private domSanitizer: DomSanitizer, private cd: ChangeDetectorRef, private sanitizer: DomSanitizer) {
      this.time=this.api.time;
      this.interval = setInterval(
        () => {
          if(this.api.disaplyVar.length>5 && this.api.disaplyVar!='Listening...'){
         if(this.time!=this.api.time){
           clearInterval(this.interval);
           this.done();
         }
        }
        },
        100
      );
      this.deviceDetect();
      this.route.url
      .subscribe(val => {
        console.log('URL Cutout');
        console.log(val);
        this.restName =  val[1].path;
        this.restId = val[2].path;
        this.pro.selectedSID = this.restId;
        localStorage.setItem('SID', this.restId );
        const sid  =  new jetsStore();
        this.restInfo = sid.findStore(this.restId);
        this.api.quickOrder.restId = this.restInfo.rid;
        this.api.restInfo(this.restInfo.rid);
 
      });  this.route.url
    .subscribe(val => {
      console.log('URL Cutout');
      console.log(val);
      this.restId = val[1].path;
      
      this.api.feedbackInfoUser(this.restId);
      this.api.getfeedbackInfoUser()
        .subscribe((data) => {
          console.log(data);
          this.api.restaurant.address = data.restInfo[0].completeAddress;
          this.api.displayNum = this.api.formatPhone(data.restInfo[0].restId);
          this.api.quickOrder.restId= data.restInfo[0].restId
        });
    })
  }

  deviceDetect() {
    const isMobile = /iPhone|Android/i.test(navigator.userAgent);

    if (isMobile) {
      this.pro.mobile = true;
    } else {
      this.pro.mobile = false;
    }
  }
  ngOnInit() {
    this.audioRecordingService.recordingFailed().subscribe(() => {
     // console.log('recording is failed')
      this.isRecording = false;
     location.reload();

    });

    this.audioRecordingService.getRecordedTime().subscribe((time) => {
      this.recordedTime = time;
     // console.log('Recording time')
     // console.log(this.recordedTime)
    });
    

    this.audioRecordingService.getRecordedBlob().subscribe((data) => {        
      this.blobUrl = data.blob;


      
    });

    
    this.startRecording()
  }

  download(){
    this.audioRecordingService.download()
  }
  async startRecording() {
    if (!this.isRecording) {
    
      this.cd.detectChanges();
      this.audioRecordingService.startRecording();
      this.isRecording = true;
    }
  }
  changed(){
    this.done();
  }
  refresh(){
    
    this.api.textVar='';
    this.api.disaplyVar='';
    window.location.reload();
  }
  resetState() {
    this.api.isEdit = false;
    this.api.isState_0 = false;
    this.api.isContact = false;
    this.api.isCompSummary = false;
    this.api.isMethod = false;
    this.api.isPinset = false;
    this.api.isTip = false;
    this.api.isPaymentSuccess = false;
    this.api.isPinPayment = false;
    this.api.isBt = false;
    this.api.payOnline = true;
    this.api.payOption = false;
    this.api.payatStore = false;
  }
  done(){
    this.isNext = true;
    this.loading=true;
    this.audioRecordingService.stopRecording();
    this.api.quickOrder.fullText=this.api.textVar;
    this.api.resetItems();
    this.api.quickOrdr();
    this.api.getPostQuickOrdr()
      .subscribe((data) => {
       
        this.api.ordrID = data.ordrId;
        this.resetState();
        if (data.ordrId.error) {
         console.log(data.ordrId.error);
        } else { 
        //this.orderError = false;
        this.loading=false;
        const routeId = `/myordr/${this.api.ordrID}`;
        this._router.navigate([routeId]);
        }
      });
  }

  txtArr() {
    setTimeout(()=>{ 
            this.istxt1 = false;
            this.istxt2 = true;
            this.call3rdTxt()
              }, 2700);
    
  }
  call3rdTxt() {
    setTimeout(()=>{ 
      this.istxt2 = false;
      this.istxt3 = true;
      this.call3rdTxt()
        }, 2700);
   
  }

  abortRecording() {  
    if (this.isRecording) {
      this.isRecording = false;
      this.audioRecordingService.abortRecording();
    }
  }

  stopRecording() {
    if (this.isRecording) {
      this.isShowTxt = true;
      this.istxt1 = true;
      if(this.isShowTxt){
        this.txtArr()
      }
      this.audioRecordingService.stopRecording();
      this.isRecording = false;

      
      
      
    }


  }


  clearRecordedData() {
    this.blobUrl = null;
  }

  ngOnDestroy(): void {
    this.abortRecording();
  }

}
