export class GiovannisStore {
    constructor() {}
    findStore(sid) {
      switch (sid) {
        case "stores":
          let sidInfo = {
            discountTxt: "FOR A LIMITED TIME GET 20% OFF ON YOUR FIRST ORDER",
            isPro: false,
            isServiceFee: false,
            successTxt: "TEXT ORDERS ARE 20% OFF ON YOUR FIRST ORDER",
            successPro: "PRO ORDERS ARE 20% OFF ON YOUR FIRST ORDER",
            isTxt: true,
            rid: "+1941729538799",
            nickName: "",
            addy1: "",
            addy2: "",
          };
          return sidInfo;

          case 'gv01':
                sidInfo = {
                    discountTxt: 'GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17403553922',
                    nickName: '1925 11th St location in Portsmouth',
                    addy1: '1925 11th St',
                    addy2: 'Portsmouth, OH'
                };
                return sidInfo;
            case 'gv02':
                sidInfo = {
                    discountTxt: 'GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+14124020607',
                    nickName: '403 N Lock Ave location in Louisa',
                    addy1: '403 N Lock Ave',
                    addy2: 'Louisa, KY'
                };
                    return sidInfo;
            case 'gv03':
                sidInfo = {
                    discountTxt: 'GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17403535374',
                    nickName: '4438 Scioto Trail location in Portsmouth',
                    addy1: '4438 Scioto Trail',
                    addy2: 'Portsmouth, OH'
                };
                return sidInfo;
            case 'gv04':
                sidInfo = {
                    discountTxt: 'GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16063245157',
                    nickName: '724 Greenup Ave location in Ashland',
                    addy1: '724 Greenup Ave',
                    addy2: 'Ashland, KY'
                };
                return sidInfo;
            case 'gv05':
                sidInfo = {
                    discountTxt: 'GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17403549335',
                    nickName: '4301 Gallia Pike location in Franklin Furnace',
                    addy1: '4301 Gallia Pike',
                    addy2: 'Franklin Furnace, OH'
                };
                return sidInfo;
            case 'gv06':
                sidInfo = {
                    discountTxt: 'GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13047431122',
                    nickName: '1208 Trenol Rd location in Milton',
                    addy1: '1208 Trenol Rd',
                    addy2: 'Milton, WV'
                };
                return sidInfo;
            case 'gv07':
                sidInfo = {
                    discountTxt: 'GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16067398277',
                    nickName: '3264 Louisa Rd location in Catlettsburg',
                    addy1: '3264 Louisa Rd',
                    addy2: 'Catlettsburg, KY'
                };
                return sidInfo;

                case 'gv08':
                sidInfo = {
                    discountTxt: 'GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+16069499983',
                    nickName: '22 Hall Addition location in Banner',
                    addy1: '22 Hall Addition',
                    addy2: 'Banner, KY'
                };
                return sidInfo;
            case 'gv09':
                sidInfo = {
                    discountTxt: 'GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+13043976522',
                    nickName: '3422 Teays Valley Road location in Hurricane',
                    addy1: '3422 Teays Valley Road',
                    addy2: 'Hurricane, WV'
                };
                return sidInfo;
            case 'gv10':
                sidInfo = {
                    discountTxt: 'GET 15% OFF ON ALL PIZZAS',
                    isPro: false,
                    isServiceFee: false,
                    successTxt: 'TEXT ORDERS ARE 15% OFF ON ALL PIZZAS',
                    successPro: 'PRO ORDERS ARE 15% OFF ON ALL PIZZAS',
                    isTxt: true,
                    rid: '+17402593660',
                    nickName: '10689 US-23 location in Lucasville',
                    addy1: '10689 US-23',
                    addy2: 'Lucasville, OH'
                };
                return sidInfo;
  
        case "demo":
          sidInfo = {
            discountTxt: "*",
            isPro: false,
  
            isServiceFee: false,
            successTxt: "Texting is the fastest way to order Giovanni's!",
            successPro: "PRO ORDERS ARE 20% OFF ON ALL PIZZAS",
            isTxt: true,
            rid: "+15862033838",
            nickName: "Research park dr ",
            addy1: "3917 Research Park Dr",
            addy2: "Ann Arbor, MI",
          };
          return sidInfo;
       
        default:
          sidInfo = {
            discountTxt: "*",
            isPro: false,
            isServiceFee: false,
            successTxt: "Texting is the fastest way to order Giovanni's!",
            successPro: "PRO ORDERS ARE 20% OFF ON ALL PIZZAS",
            isTxt: true,
            rid: "empty",
            nickName: "empty",
            addy1: "empty",
            addy2: "empty",
          };
          return sidInfo;
      }
      // console.log(cust)
    }
  }
  