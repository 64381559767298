import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-new',
  templateUrl: './home-new.component.html',
  styleUrls: ['./home-new.component.css']
})
export class HomeNewComponent implements OnInit {
  testimonalsText="a";
  testimonalsSaidBy="b"
  constructor() { }

  ngOnInit() {
    if(window.location.href.indexOf("HostedPaymentStatus")>-1){
      parent.postMessage(window.location.href,'*');
    }
   
  }
  tryit(){
    window.open('https://www.txtordr.com/jets/share');

  }
  tryquick(){
    window.open('https://www.txtordr.com/quick/jets/mi26');
  }
}
