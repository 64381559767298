///<reference types="@types/googlemaps" />
import {
  Component,
  QueryList,
  ViewChildren,
  ElementRef,
  OnInit,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { APICenterService } from "../apicenter.service";
import { HttpClient } from "@angular/common/http";
import { DeviceDetectorService } from "ngx-device-detector";
@Component({
  selector: "app-demo-chatbot",
  templateUrl: "./demo-chatbot.component.html",
  styleUrls: ["./demo-chatbot.component.css"],
})
export class DemoChatbotComponent implements OnInit, AfterViewInit {
  hovered: boolean = false;
  landing: boolean = true;
  loading: boolean = false;
  collapsed: boolean = true;
  suggestionsData: any = [];
  activeSuggestionData: any = [];
  userTypedText: any = "";
  autocompleteInput: string = "";
  message_data: any = [];
  url: any;
  nextSendPayload: any = [];
  showAdress: boolean = false;
  suggUrl = "assets/json/suggestions.json";
  firstMessage: boolean = true;
  showSummary = false;
  userId: string = "";
  phoneNumber: string = "";
  showPhoneNumber: boolean = false;
  orderAgain = false;
  deviceString: any = "";
  @ViewChild("heightElement") heightElement?: ElementRef;
  @ViewChild("addresstext") addresstext: any;
  @ViewChildren("divElement") divElement!: QueryList<ElementRef>;
  constructor(
    private route: ActivatedRoute,
    public api: APICenterService,
    private http: HttpClient,
    private deviceService: DeviceDetectorService
  ) {
    // this.datadogService.logInfo('Demo Component used by - ' + val[2].path);
    this.suggestionsJson();
    this.startConversation();
  }
  log() {
    // this.datadogService.logInfo('Demo started by - ' + this.userId);
  }
  ngAfterViewInit() {}

  sendHeightUpdate() {
    window.parent.postMessage(
      { type: "setHeight", device: this.deviceString },
      "*"
    );
  }
  sendHeightUpdateCollapsed() {
    window.parent.postMessage(
      { type: "unsetHeight", device: this.deviceString },
      "*"
    );
  }
  deviceDetect() {
    this.api.isMobile = this.deviceService.isMobile();
    if (this.api.isMobile) {
      this.deviceString = "mobile";
    } else {
      this.deviceString = "desktop";
    }
    /* window.parent.postMessage(
      { type: "initalsetting", device: this.deviceString },
      "*"
    ); */
  }
  suggestionsJson() {
    let errorcode = this.getErrorCodes().subscribe((data: any) => {
      errorcode.unsubscribe();
      this.suggestionsData = data.suggestions;
    });
  }
  getErrorCodes() {
    return this.http.get(this.suggUrl);
  }
  triggerAutocomplete() {
    setTimeout(() => {
      this.getPlaceAutocomplete();
    }, 1000);
  }
  startConversation() {
    this.route.url.subscribe((val) => {
      let sid =
        val[2].path.length === 4
          ? `${val[2].path.substr(0, 2)}0${val[2].path.substr(2)}`
          : val[2].path;
      this.api.cpFetchManageDetail(sid.toUpperCase());
      let fetchmanageDetail = this.api
        .getManageDetails()
        .subscribe((storeResponse) => {
          if (storeResponse.statusCode === 200) {
            let initalPayload = {
              callSid: "CAfab86",
              chainId: val[1].path,
              contextInfo: {
                text: "",
              },
              customerText: "",
              isUpdate: false,
              module: "methodsModule",
              prompt: [],
              restId: storeResponse.data.rid,
              start: true,
            };

            this.chatOrder(initalPayload);
          }
        });
    });
  }

  chatOrder(payload: any) {
    this.loading = true;
    if (Object.keys(payload.contextInfo).length === 0) {
      payload.contextInfo["text"] = "";
    }
    this.api.chatbotDemo(payload);
    let sub = this.api.getchatbotDemo().subscribe((detail) => {
      this.loading = false;
      this.formatResponse(detail.body);
      sub.unsubscribe();
    });
  }
  formatResponse(response: any) {
    let messagePayload;
    this.nextSendPayload = [];
    if (this.firstMessage) {
      this.firstMessage = false;
      messagePayload = {
        msg_Cust: "Hi! " + response.prompt[0].prompt,
        msg_Reply: "*",
        isCompleted: false,
      };
    } else {
      if (
        response.prompt[0].prompt
          .toLowerCase()
          .includes("thank you for ordering with")
      ) {
        this.showPhoneNumber = true;
        messagePayload = {
          msg_Cust:
            "Thank you for your order with Jets! To track your pizza in real time, and get an estimated delivery time, please share your phone number.",
          msg_Reply: "*",
          isCompleted: false,
        };
      } else if (
        response.prompt[0].prompt
          .toLowerCase()
          .includes("not in our delivery range")
      ) {
        messagePayload = {
          msg_Cust:
            "Looks like you are not in our delivery range. Would you like to order pickup?",
          msg_Reply: "*",
          isCompleted: false,
        };
      } else if (
        response.prompt[0].prompt
          .toLowerCase()
          .includes("may i send you a link")
      ) {
        messagePayload = {
          msg_Cust: "demo-card",
          msg_Reply: "*",
          isCompleted: false,
        };
      } else {
        messagePayload = {
          msg_Cust: response.prompt[0].prompt,
          msg_Reply: "*",
          isCompleted: false,
        };
      }
    }
    this.api.ordrID = response.orderId;
    this.nextSendPayload = response;
    this.populateActiveSuggestion(response.prompt[0].prompt);
    this.pushFunc(messagePayload);
  }

  populateActiveSuggestion(question: any) {
    this.showAdress = false;
    if (question === "Please say a complete valid address") {
      this.triggerAutocomplete();
      this.showAdress = true;
    }
    if (question.toLowerCase().includes("your name please?")) {
      this.api.isSummaryTitle = true;

      this.api.postFlask();
      this.api.getUpdatedPost().subscribe((x) => {
        this.showSummary = true;
        this.userTypedText = "";
        let messagePayload = {
          msg_Cust: "May I know your name, please?",
          msg_Reply: "*",
          isCompleted: false,
        };
        this.pushFunc(messagePayload);
      });
    }
    this.activeSuggestionData = [];
    this.suggestionsData.forEach((ques: any) => {
      if (question.toLowerCase().includes(ques.question.toLowerCase())) {
        this.activeSuggestionData = ques;
      }
    });
    this.scrollToTarget(this.message_data.length);
  }
  toCapitalize(str: any) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  ngOnInit(): void {
    this.deviceDetect();
    let restartChat = localStorage.getItem("restartChat");
    if (restartChat == "true") {
      localStorage.setItem("restartChat", "false");
      this.landing = false;
      this.log();
    }
  }

  send(text: any, button: any) {
    if (text == "" || text == undefined || text == null || text == "*") {
      return;
    } else {
      if (button != null && button.value === "1 20oz pepsi") {
        button.clicked = true;
      }
      this.activeSuggestionData = [];
      if (text.toLowerCase().includes("pay with a card")) {
        let messagePayload1 = {
          msg_Cust: "*",
          msg_Reply: "Pay with a card",
          isCompleted: false,
        };
        this.pushFunc(messagePayload1);
        this.scrollToTarget(this.message_data.length);
        let messagePayload = {
          msg_Cust: "demo-card",
          msg_Reply: "*",
          isCompleted: false,
        };
        this.pushFunc(messagePayload);
        this.scrollToTarget(this.message_data.length);
        this.populateActiveSuggestion("may i send you a link over");
      } else if (text === "samplecard") {
        this.api.demoPaymentCard = {
          cardNumber: "4111 1111 1111 1111",
          expMonth: "01",
          expYear: "29",
          cvv: "911",
        };
      } else if (text === "card payment make store payment call") {
        this.userTypedText = "";
        this.nextSendPayload.prompt[0].response = "pay at store";
        this.nextSendPayload.customerText = "pay at store";
        this.nextSendPayload.start = false;
        this.chatOrder(this.nextSendPayload);
      } else {
        this.userTypedText = "";
        this.nextSendPayload.prompt[0].response = text;
        this.nextSendPayload.customerText = text;
        this.nextSendPayload.start = false;
        let messagePayload = {
          msg_Cust: "*",
          msg_Reply: text,
          isCompleted: false,
        };
        this.pushFunc(messagePayload);
        this.chatOrder(this.nextSendPayload);
      }
    }
  }
  focus() {
    this.api.stickyHead = true;
    this.api.isKeyBoard = true;
    const pacContainer = document.querySelector(".pac-container");
    if (pacContainer) {
      pacContainer.classList.add("custom-style-1");
    }
  }
  blur() {
    this.api.stickyHead = false;
    this.api.isKeyBoard = false;
  }
  modelchanged($event: any) {
    this.userTypedText = $event;
  }
  getPlaceAutocomplete() {
    const pacContainer = document.querySelector(".pac-container");

    const autocomplete = new google.maps.places.Autocomplete(
      this.addresstext.nativeElement,
      {
        strictBounds: false,
        componentRestrictions: { country: "US" },
        types: [],
      }
    );
    google.maps.event.addListener(autocomplete, "place_changed", () => {
      const place = autocomplete.getPlace();
      this.userTypedText = place.formatted_address;
    });
  }
  paymentSubmit() {
    this.send("card payment make store payment call", null);
  }
  onPhoneInput(event: any) {
    let input = event.target.value.replace(/\D/g, "");

    if (input.length > 3 && input.length <= 6) {
      input = `(${input.slice(0, 3)}) ${input.slice(3)}`;
    } else if (input.length > 6) {
      input = `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(
        6,
        10
      )}`;
    }

    this.phoneNumber = input;
  }
  allowOnlyNumbers(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    // Allow only digits (0-9) and control keys (e.g., backspace)
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  }
  sendPhone(phone: any) {
    if (phone == "" || phone == undefined || phone == null || phone == "*") {
      return;
    } else {
      let messagePayload = {
        msg_Cust: "*",
        msg_Reply: phone,
        isCompleted: false,
      };
      this.pushFunc(messagePayload);

      let payload = {
        order_id: this.nextSendPayload.orderId,
        cust_id: this.nextSendPayload.custId,
        customer_phone: phone.replace(/\D/g, ""),
      };
      this.api.chatbotReceipt(payload);
      let sub = this.api.getchatbotReceipt().subscribe((detail) => {
        let messagePayload = {
          msg_Cust: "You'll receive your ETA shortly. Thank you!",
          msg_Reply: "*",
          isCompleted: false,
        };
        this.pushFunc(messagePayload);
        this.scrollToTarget(this.message_data.length - 1);
        this.orderAgain = true;
        sub.unsubscribe();
      });
    }
  }
  pushFunc(messageData: any) {
    for (var i = 0; i < this.message_data.length; i++) {
      this.message_data[i].isCompleted = true;
    }
    this.message_data.push(messageData);
  }
  reload() {
    localStorage.setItem("restartChat", "true");
    this.sendHeightUpdateCollapsed();
    window.location.reload();
  }
  scrollToTarget(id: any) {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 100);
  }

  formatMessages(text: any) {
    let text1 = text.replaceAll(" . ", " ");

    return text1.replace(" say ", " type ");
  }
}
