import { APICenterService } from './../apicenter.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '../../../node_modules/@angular/material/dialog';
import { MenuDialogComponent } from '../menu-dialog/menu-dialog.component';
import { trigger, transition, animate, style } from '../../../node_modules/@angular/animations';
import { Router } from '../../../node_modules/@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';




@Component({
  selector: 'app-get-cust-msg',
  templateUrl: './get-cust-msg.component.html',
  styleUrls: ['./get-cust-msg.component.css'],
  animations: [
    trigger('fade-out', [
      transition ('* => void', [
        animate(1500, style({ opacity:0}))
      ] )
    ])
  ]
})
export class GetCustMsgComponent implements OnInit {

  constructor(public api: APICenterService,private spinnerService: Ng4LoadingSpinnerService, public dialog: MatDialog, private _router : Router) { }
  isPickupVal = false;
  msgWithAddress
  isLoading = false;

  ngOnInit() {
  }


  openDialog(): void {
    this.api.clickValue = 'Menu'
    this.api.clickAnalytics() 
    const dialogRef = this.dialog.open(MenuDialogComponent, {
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  delivery() {
    this.api.isDelivery = true;
  }

  pickup() { 
    this.api.isDelivery = false;
  }

  getAddress(event){
    console.log(event)
    this.api.editStateAddress = event.formatted_address
  }

  onUpdate() {
    this.isLoading = true;
    this.spinnerService.show();
    if(this.api.isDelivery){
      this.msgWithAddress = `${this.api.customer.msg_text} delivery to ${this.api.editStateAddress} `;
      this.api.customer.msg_text =  this.msgWithAddress;
      } else {
        this.api.customer.msg_text
      }
    this.api.genUIOrdr()
    this.api.getUpdatedUIOrdr()
      .subscribe((x) => {
        this.spinnerService.hide();
        this.isLoading = false;
        const routeId = `/myordr/${x.id}`
        this._router.navigate([routeId])
      }) 
  }


}
