import {
    Directive,
    Renderer2,
    HostListener,
    Input,
    ElementRef
  } from "@angular/core";
  import { ProServiceService } from './../pro-service.service';
  @Directive({
    selector: "[appStickyHeader]"
  })
  export class StickyHeaderDirective {
    @Input() public topOffset = 75;
    @Input() isContent = false;
  
    @HostListener("window:scroll", ["$event"])
    public windowScrolled($event) {
      console.log(this.isContent);
      if (this.isContent) {
        this.windowScrollEventContent();
      } else {
        this.windowScrollEvent($event);
      }
    }
    constructor(private el: ElementRef, private renderer: Renderer2,public pro: ProServiceService) {}
  
    windowScrollEventContent() {
      const isReachingTop = this.getTop() > this.topOffset;
  
      if (isReachingTop) {
      //  this.renderer.setStyle(this.el.nativeElement, "margin-top", "40px");
      } else {
     //   this.renderer.setStyle(this.el.nativeElement, "margin-top", "initial");
      }
    }
  
    windowScrollEvent($event) {
      console.log(window.pageYOffset);
      console.log(document.documentElement.scrollTop);
      console.log(document.body.scrollTop);
      const isReachingTop = this.getTop() > this.topOffset;
      if (isReachingTop) {
        this.pro.emptySpace=true;
        this.pro.stickyHead=true;
        console.log( "stickyhEADFLAG"+this.pro.stickyHead)
       // this.renderer.setStyle(this.el.nativeElement, "position", "fixed");
        //this.renderer.setStyle(this.el.nativeElement, "top", "20px");
        //this.renderer.setStyle(this.el.nativeElement, "z-index", "1");
       // this.renderer.setStyle(this.el.nativeElement, "display", "block");
        this.renderer.setStyle("#header", "display", "none");
      } else {
       
      }
      if(!this.pro.isKeyBoard){
      if(window.pageYOffset < 25 ){
        this.pro.stickyHead=false;
        this.renderer.setStyle("#header", "display", "unset");
        this.pro.emptySpace=false;
        //this.renderer.setStyle(this.el.nativeElement, "top", "unset");
      }
    }
    }
  
    getTop() {
      return (
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop || 0
      );
    }
  }
  