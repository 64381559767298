import { APICenterService } from './../../apicenter.service';
import { Component, OnInit } from '@angular/core';
import { ProServiceService } from '../pro-service.service';
import { MatDialog } from "@angular/material/dialog";
import { LoginPopupComponent } from "../../login-popup/login-popup.component";
@Component({
  selector: 'app-pro-navbar',
  templateUrl: './pro-navbar.component.html',
  styleUrls: ['./pro-navbar.component.css']
})
export class ProNavbarComponent implements OnInit {
  name: string
  constructor(public pro: ProServiceService, public api: APICenterService,public dialog: MatDialog) { }


  ngOnInit() {
    this.api.getUpdatedPost()
      .subscribe((val) => {
        
        this.name = this.api.restaurant.name.replace("'", "").toLowerCase();
        
        this.deviceDetect();
        /*if (this.name == "freebirds" && !this.api.puchLoggedin) {
         if(!this.api.popupLoginSuccess){
          const dialogRef = this.dialog.open(LoginPopupComponent, {
            width: "99%",
            disableClose: true,
            maxWidth: "unset",
            

          });
    
          dialogRef.afterClosed().subscribe(() => {
            // console.log('The dialog was closed');
            
          });
        }
      }*/
      })
      setInterval(() => {
        this.name = this.api.restaurant.name.replace("'", "").toLowerCase()
      }, 200);
  }
  removeStateAndZip(address) {
    
    return address.replace(/\b[A-Z]{2} \d{5}$/, '');
}
  deviceDetect() {
    const isMobile = /iPhone|Android/i.test(navigator.userAgent);
    if (this.api.restaurant.rid == "+15512911234"){
      this.pro.mobile = true;
    } else {
      if (isMobile) {
        this.pro.mobile = true;
      } else {
        this.pro.mobile = false;
      }
    }
    
  }
}