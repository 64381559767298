import { APICenterService } from './../apicenter.service';
import { Component, OnInit } from '@angular/core';

import { RestContactService } from '../rest-contact.service';


declare function myTest() : any
@Component({
  selector: 'app-test-comp',
  templateUrl: './test-comp.component.html',
  styleUrls: ['./test-comp.component.css']
})
export class TestCompComponent implements OnInit {
  /*obj = {
    title : ['1      Deep Dish  pizza     $16.76\n','2    Pepsi        $2.67\n','1     Plain Jets Wings      $9.24\n', '1    Antipasto Salad       $8.99\n'],
    topping : ['Pepperoni\n','-1','-1','Minus Tomatoes\n'],
    firstHalf : ['Mushrooms\n','-1','-1','-1'],
    secondHalf : ['Black Olives\n','-1','-1','-1' ],
    bake : ['Well Done\n','-1','-1','-1'],
    dipping : ['-1','-1','Bbq\n','Ranch\n']
  } */
  obj
  msg
  showPrint = false;
 
  constructor(public api: APICenterService, public rest: RestContactService) { 
    
  }

  ngOnInit() {
    
  }

  send(){
    this.rest.selectedRest.rid = '+17344422075'
    this.rest.selectedRest.msg = this.msg
    this.api.homePage()
    this.api.getHomePageInfo()
     .subscribe((x) => {

        this.obj = {
         title : this.api.ppTitle,
         size: this.api.sizes,
         topping: this.api.toppings,
         firstHalf: this.api.firstHalf,
         secondHalf: this.api.secondHalf,
         bake: this.api.bake,
         crustTopper : this.api.crustTopper,
         sauce: this.api.sauce,
         sides: this.api.sides,
         dressing: this.api.dressing,
         seasoning: this.api.seasoning,
         drizzle: this.api.drizzle,
         bread: this.api.bread,
         drink: this.api.drink,
         side:  this.api.side,
         cheese: this.api.cheese,
         sauceBase:  this.api.sauceBase,
         dipping: this.api.dipping,
         cut :  this.api.cut,
         extras: this.api.extra,
         wingType: this.api.wingType,
         seasoningPackets :  this.api.seasoningPackets,
        
         cust : {
          isDelivery: this.api.isDelivery,
          custString: this.api.customer.msg_text,
          method: this.api.method.maddress,
          methodType: this.api.method.mtype,
          name: this.api.userName,
          cid: this.api.customer.cid,
          orderCount: '4'
         },
         
         dollarVal : {
           discount: this.api.saving,
           tax: this.api.tax,
           tip: this.api.tip,
           deliveryFee: this.api.delivery_fee,
           total: this.api.total,
           couponName:  this.api.couponName

         }

       }
       this.showPrint = true;
       console.log(this.obj)
     })
     
  }


  


}
