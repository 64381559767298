import { RestContactService } from './../../rest-contact.service';
import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from '../../../../node_modules/ngx-device-detector';
import { APICenterService } from '../../apicenter.service';
import { ReferFriendDialogComponent } from '../refer-friend-dialog/refer-friend-dialog.component';
import { MatDialog } from '../../../../node_modules/@angular/material/dialog';
import { Router } from '../../../../node_modules/@angular/router';


@Component({
  selector: 'app-refer-friend',
  templateUrl: './refer-friend.component.html',
  styleUrls: ['./refer-friend.component.css']
})
export class ReferFriendComponent implements OnInit {
  deviceInfo = null;
  text: string = " "
  name: string
  constructor(public api: APICenterService, public config: RestContactService, private _router: Router, private deviceService: DeviceDetectorService, public dialog: MatDialog) {
    this.deviceDetect()
  }

  deviceDetect() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.api.isMobile = this.deviceService.isMobile();
    this.api.isTablet = this.deviceService.isTablet();
    this.api.isDesktopDevice = this.deviceService.isDesktop();
    console.log(this.deviceInfo);
    this.api.deviceName = this.deviceInfo.userAgent.split('(')[1].split(';')[0]
    this.api.deviceOs = this.deviceInfo.userAgent.split('(')[1].split(';')[1]

  }

  ngOnInit() {
    this.name = this.api.restaurant.name.replace("'", "").toLowerCase()
    if (this.name == 'hungerrush'){
      this.text = `Hey here's a cool way to order ${this.api.restaurant.name} Pizza! https://txtordr.com/${this.name}/hr360 Try it out!`
    } else {
      this.text = `Hey here's a cool way to order ${this.api.restaurant.name} Pizza! https://txtordr.com/${this.name}/share Try it out!`
    }
  }

  referFriend() {
    console.log('Refer a friend button is clicked')
    this.api.clickValue = 'Refer-a-friend-btn'
    this.api.clickAnalytics()
    console.log('button is clicked')
    if (this.api.isMobile) {
      if (this.api.deviceName == 'iPhone') {
        console.log('IPHONE')
        window.location.href = `sms: &body=` + encodeURIComponent(this.text);
      } else {
        console.log('MAY BE andriod')
        window.location.href = `sms:?body=` + encodeURIComponent(this.text);
      }
    } else if (this.api.isDesktopDevice) {
      let routeId

      if (this.name == 'hungerrush'){
        routeId = `/${this.name}/hr360`
      } else {
        routeId = `/${this.name}/share`
      }
      this._router.navigate([routeId])

      
      // const dialogRef = this.dialog.open(ReferFriendDialogComponent, {
      //});

      // dialogRef.afterClosed().subscribe(result => {
      //  console.log('The dialog was closed');
      //});

    } else if (this.api.isTablet) {
      console.log('TABLET')

      let routeId

      if (this.name == 'hungerrush'){
        routeId = `/${this.name}/hr360`
      } else {
        routeId = `/${this.name}/share`
      }
      this._router.navigate([routeId])
   
      /*
      const dialogRef = this.dialog.open(ReferFriendDialogComponent, {
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      }); */
    }
  }

}
