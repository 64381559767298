import { APICenterService } from './../apicenter.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '../../../node_modules/@angular/forms';

@Component({
  selector: 'app-pin-payment',
  templateUrl: './pin-payment.component.html',
  styleUrls: ['./pin-payment.component.css']
})
export class PinPaymentComponent implements OnInit {
  pin_txt;
  paymentSubmitted = false;
  showPasswordForm  = false;
  dummyVal = '';

  constructor(public api: APICenterService) { }

  ngOnInit() {
    this.api.isDeleteBtn = false;
    this.api.isEditBtn = false;
    this.api.isAddItemBox = false;
    // console.log('isPwd');
    // console.log(this.api.customer.isPwd);
    if (!this.api.quickOrder.isQuickName) {
        // Text order customer
        if (this.api.customer.isPwd && this.api.pinState === '1') {
          // both true => Pin state
          this.showPasswordForm = false;
        } else {
          if (this.api.customer.isPwd) {
            //  only password is set
            this.showPasswordForm = true;
          } else {
             // PIN only customer
             this.showPasswordForm = false;
          }
        }
    } else  {
      // pro order customer
      if (this.api.customer.isPwd && this.api.pinState === '1') {
        // both true => Password state
        this.showPasswordForm = true;
      } else {
        if (this.api.customer.isPwd) {
          //  only password is set
          this.showPasswordForm = true;
        } else {
           // PIN only customer
           this.showPasswordForm = false;
        }
    }
  }
}

  submit(f: NgForm) {
    if (f.value.pinText.length === 4 ) {
      this.api.isFirstLoad = false;
      this.api.clickAanlysis.push('Pay using PIN - Submit order');
      // console.log('form')
      // console.log(f.value)
      this.api.pinPayment = f.value.pinText;
      // console.log(this.userPin_1);
      this.api.customer.isPwd = false;
      this.api.validatePin();
      this.api.pinMsg = true;
      this.paymentSubmitted = true;
    }
  }
  submitPwd(f: NgForm) {
    this.api.isFirstLoad = false;
    {
      this.api.clickAanlysis.push('Pay using PASSWORD - Submit order');
      // console.log('form')
      // console.log(f.value)
      this.api.passwordPayment = f.value.pinText;
      // console.log(this.userPin_1);
      this.api.validatePin();
      this.api.pinMsg = true;
      this.paymentSubmitted = true;
    }
  }

  onBack() {
    this.api.isFirstLoad = false;
    this.api.clickValue = 'Back Pay using PIN';
    this.api.clickAnalytics();
    this.api.isDeleteBtn = true;
    this.api.isEditBtn = true;
    this.api.isAddItemBox = true;
    this.api.isState_2 = false;
    this.api.isUserPin = false;
    this.api.isBt = false;

    this.api.isFirstWrong = false;
    this.api.isSecondWrong = false;
    this.api.isThridWrong = false;

    if ((this.api.isPayOnline) && (this.api.isPayAtDoor) && (this.api.isPayAtStore)) {
      // // console.log('all Are true');
      if (this.api.isDelivery) {
        this.api.checkTipState = true
      }
      if (this.api.method.mtype === "PICKUP") {
      if (this.api.checkTipState || !this.api.isDelivery)  {
        // PickUp Order
        if (this.api.isShowTipState) {
          // Show Tip STate
          this.api.tipState();

        } else {
          // Hide Tip State
          if (this.api.isPayAtStoreEligible) {
            // Pay at store eligible
            this.api.payOptionState();
          } else  {
            // Pay at store not eligible
            this.api.nameState();
          }

        }
      }} else {
        // Delivery Order
        this.api.tipState();
      }

      } else if ((this.api.isPayAtDoor) && (this.api.isPayAtStore)) {
        // console.log ('pay at door and pay at store is true');
          } else if ((this.api.isPayOnline) && (this.api.isPayAtDoor) ) {
                // console.log ('pay online  and pay at door ');
                if (this.api.isDelivery) {
                  this.api.checkTipState = true
                }
                if (this.api.method.mtype === "PICKUP") {
                if (this.api.checkTipState || !this.api.isDelivery)  {
                  // Pickup orders
                  if (this.api.isShowTipState) {
                    // Show Tip State
                    this.api.tipState();

                  } else {
                    // No Tip State
                     // Hide Bt ==> PIN Payment
                     this.api.nameState();
                  }
                } }else {
                  // Delivery Order
                  if (this.api.isPayAtDoorEligible) {
                      // Pay At door Eligible
                    if (this.api.isShowTipState) {
                      // Show Tip State
                      this.api.tipState();


                    } else {
                      // No Tip State
                      this.api.payOptionState();
                    }


                  } else {
                    // Pay AT door not eligible
                     // Hide Bt ==> PIN Payment
                   this.api.isBt = false;
                   if (this.api.isShowTipState) {
                     // Show Tip State
                     this.api.tipState();

                   } else {
                     // No Tip State
                     this.api.nameState();
                   }
                  }
                }

              } else if ((this.api.isPayOnline) && (this.api.isPayAtStore) ) {
                      // console.log('pay online and pay at store');
                      if (this.api.isDelivery) {
                        this.api.checkTipState = true
                      }
                      if (this.api.method.mtype === "PICKUP") {
                      if (this.api.checkTipState || !this.api.isDelivery)   {
                        if (!(this.api.pinState === '0'))  {
                          // existing customer
                          if (this.api.isPayAtStoreEligible) {
                            // // pay at store
                            if (this.api.isShowTipState) {
                              // show tip state
                              this.api.tipState();

                            } else {
                              // Dont show tip state
                              this.api.payOptionState();
                            }

                          } else  {
                            // Not pay at store eligible
                            if (this.api.isShowTipState) {
                              // show tip state
                              this.api.tipState();

                            } else {

                              // NO show tip state
                              this.api.nameState();

                            }

                          }


                        }

                      } }else {
                        // delivery order
                        this.api.tipState();

                      }

                  } else {
                        if (this.api.isPayOnline) {
                                  // console.log('online is true');
                                  // online is true
                                  if (!(this.api.pinState === '0')) {
                                                  // Existing customer
                                                  // console.log('existing customer');


                                                  if (this.api.isDelivery) {
                                                    this.api.checkTipState = true
                                                  }
                                                  if (this.api.method.mtype === "PICKUP") {
                                                  if (this.api.checkTipState || !this.api.isDelivery)  {
                                                    // Pick up orders

                                                        if (this.api.isShowTipState) {

                                                          // show Tip State
                                                          this.api.tipState();

                                                        } else {
                                                          // with out Tip State
                                                          // console.log('dont show tip state');
                                                          this.api.nameState();
                                                        }

                                                  } }else {

                                                          // delivery order
                                                          this.api.tipState();

                                                  }


                                                }


                        }

                      }





  }

  payCC() {
    this.api.actionbutton("pay_with_different_card",	"PS005")
    this.api.isFirstLoad = false;
    this.api.clickValue = 'Pay with diff card';
    this.api.clickAnalytics();
    // console.log('@ PIN CCC')
    // console.log(this.api.isBraintree);
    this.api.addCard();
    this.api.dropin = this.api.preferredProcessor
    // console.log('@ PinPayment')
    // console.log(this.api.diffCard)
    this.api.diffCard = true;
    this.api.isDiffCardClicked = true;
    // console.log(this.api.diffCard)

  }

  onChange(val) {
  this.dummyVal = this.api.pinPayment;
  this.api.isFirstLoad = false;
  const len = val.target.value;
  // console.log(this.dummyVal);
  // console.log(len.length);
  if (len.length >= 0) {
    // console.log('inside Onchange');
    this.api.isFirstWrong = false;
    this.api.isSecondWrong = false;
  }
  }

  reset() {
    this.api.actionbutton("reset_pin",	"PS004")
    this.api.isFirstLoad = false;
    this.api.clickValue = 'Reset Pin';
    this.api.clickAnalytics();
    this.api.addCard();
    this.api.dropin = this.api.preferredProcessor
    this.api.isResetPinClicked = true;
    this.api.pinFailure = true;
    this.api.isBt = false;
  }



}
